import { ITaskListApiModel, ITaskListCreationRequest, ITaskListTransferRequest, ITaskListUpdateRequest, ITaskReorderRequest, TaskboardsClient, TaskListCreationRequest, TaskListTransferRequest, TaskListUpdateRequest, TaskReorderRequest } from "@app/Areas/Optimus/ApiClient/TaskboardsClient";


export interface ITaskListsService {
    getTaskLists: (vaultId: number, boardId: number) => Promise<ITaskListApiModel[]>;
    getTaskList: (vaultId: number, boardId: number, taskListId: number) => Promise<ITaskListApiModel>;
    createTaskList: (vaultId: number, boardId: number, creationRequest: ITaskListCreationRequest) => Promise<ITaskListApiModel>;
    updateTaskList: (vaultId: number, boardId: number, taskListId: number, updateRequest: ITaskListUpdateRequest) => Promise<ITaskListApiModel>;
    archiveTaskList: (vaultId: number, boardId: number, taskListId: number) => Promise<void>;
    reorderTaskList: (vaultId: number, boardId: number, taskListId: number, reorderRequest: ITaskReorderRequest) => Promise<ITaskListApiModel>;
    transferTaskList: (vaultId: number, boardId: number, taskListId: number, transferRequest: ITaskListTransferRequest) => Promise<ITaskListApiModel>;
}

const client = new TaskboardsClient();

function createTaskListService() {
    const taskListService: ITaskListsService = {
        getTaskLists: (vaultId: number, boardId: number) => client.taskListsAll(vaultId, boardId),
        getTaskList: (vaultId: number, boardId: number, taskListId: number) => client.taskListsGET(vaultId, boardId, taskListId),
        createTaskList: (vaultId: number, boardId: number, creationRequest: ITaskListCreationRequest) => client.taskListsPOST(vaultId, boardId, new TaskListCreationRequest(creationRequest)),
        updateTaskList: (vaultId: number, boardId: number, taskListId: number, updateRequest: ITaskListUpdateRequest) => client.taskListsPUT(vaultId, boardId, taskListId, new TaskListUpdateRequest(updateRequest)),
        archiveTaskList: (vaultId: number, boardId: number, taskListId: number) => client.taskListsDELETE(vaultId, boardId, taskListId, false),
        reorderTaskList: (vaultId: number, boardId: number, taskListId: number, reorderRequest: ITaskReorderRequest) => client.position(vaultId, boardId, taskListId, new TaskReorderRequest(reorderRequest)),
        transferTaskList: (vaultId: number, boardId: number, taskListId: number, transferRequest: ITaskListTransferRequest) => client.transfer2(vaultId, boardId, taskListId, new TaskListTransferRequest(transferRequest))
    }
    return taskListService;
}

export default createTaskListService;