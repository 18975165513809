import { useCallback, useRef, useState } from 'react';
import createProjectService from '@optimus/Services/ProjectService/ProjectService';
import { getVaultProjects } from '@app/Areas/Optimus/State/DataStores/ProjectStore/ProjectReducer/ProjectReducer';
import ProjectEditor from './ProjectEditor';
import { ProjectEditorModel } from './ProjectEditorModel';
import NewProjectDialog from './NewProjectDialog';
import useCurrentVault from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useCurrentVault';
import { useProjectDispatchContext } from '@app/Areas/Optimus/State/DataStores/ProjectStore/ProjectContextProvider';
import { IProjectCreationRequest, IProjectApiModel } from '@app/Areas/Optimus/ApiClient/ProjectsClient';

const blankVault: IProjectCreationRequest = {
    projectName: '',
    urls: {},
    tags: []
};

function mapCreationDetailsToProject(project: IProjectCreationRequest): ProjectEditorModel {
    return {
        name: project.projectName,
        urls: project.urls,
        tags: project.tags
    }
}

function NewProject() {
    const [newProject, setNewProject] = useState<IProjectCreationRequest>(blankVault);
    const [createdProject, setCreatedProject] = useState<IProjectApiModel | null>(null);
    const projectsDispatch = useProjectDispatchContext();
    const [isSending, setIsSending] = useState(false)
    const isMounted = useRef(true)
    const projectService = createProjectService();
    const currentVault = useCurrentVault();

    function projectEdited(event: any) {
        setNewProject({
            ...newProject,
            [event.target.name]: event.target.value
        })
    }

    const handleSave = () => {
        if (!createdProject && !isSending) {
            sendRequest();
        }
    }

    const sendRequest = useCallback(async () => {
        if (currentVault?.id) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await projectService.createProject(currentVault.id, newProject);
            if (result) {
                setCreatedProject(result);
            }
            projectsDispatch(getVaultProjects(currentVault.id));

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [ currentVault, newProject, isSending]
    ); // update the callback if the state changes

    return currentVault && (
        <NewProjectDialog handleSave={handleSave}>
            { !createdProject && <ProjectEditor
                project={mapCreationDetailsToProject(newProject)}
                handleProjectChange={projectEdited}
                title={`New - ${currentVault.title}`}
                isSaving={isSending}
            /> }
            { createdProject && JSON.stringify(createdProject) }
        </NewProjectDialog>
    ) || null;
}

export default NewProject;
