import { IGroupApiModel } from '@app/Areas/Optimus/ApiClient/BookmarksClient';
import useSectionIdParam from '../../../../App/NavigationContext/hooks/useSectionIdParam/useSectionIdParam';
import useGroupsAsDictionary from '../useGroupsAsDictionary/useGroupsAsDictionary';

function useSectionGroupsAsDictionary(): {
    [key: number]: IGroupApiModel
 } {
    const sectionId = useSectionIdParam();
    return useGroupsAsDictionary(sectionId);
}

export default useSectionGroupsAsDictionary;