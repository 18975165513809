import React, { useEffect } from 'react';
import Panel from '@optimus/Components/Shared/Panel';
import PanelHeader from '@app/Areas/Optimus/Components/Shared/PanelHeader';
import { DELETE_GROUP_DIALOG_ID, EDIT_GROUP_DIALOG_ID, EDIT_SECTION_DIALOG_ID, NEW_SECTION_DIALOG_ID } from '../../ActionDialogs';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';
import SectionView from '../../Sections/SectionView/SectionView';
import { useSectionDispatchContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider';
import { RESOURCES_SET_SECTION_UNDER_DELETE, RESOURCES_SET_SECTION_UNDER_EDIT, RESOURCES_SET_GROUP_UNDER_EDIT, RESOURCES_SET_GROUP_UNDER_DELETE } from '../../../State/DataStores/ResourceStore/ResourceReducer/ResourceReducer';
import { DELETE_SECTION_DIALOG_ID, NEW_GROUP_DIALOG_ID, NEW_RESOURCE_DIALOG_ID } from '../../ActionDialogs/index';
import useSectionsFetch from '../../../State/DataStores/ResourceStore/hooks/useSectionsFetch/useSectionsFetch';
import useVaultIdParam from '../../../State/App/NavigationContext/hooks/useVaultIdParam/useVaultIdParam';
import useVaultSectionsAsArray from '@app/Areas/Optimus/State/DataStores/ResourceStore/hooks/useVaultSectionsAsArray';
import useSectionIdParam from '../../../State/App/NavigationContext/hooks/useSectionIdParam/useSectionIdParam';
import useGroupsFetch from '../../../State/DataStores/ResourceStore/hooks/useGroupsFetch/useGroupsFetch';
import useResourcesFetch from '../../../State/DataStores/ResourceStore/hooks/useResourcesFetch/useResourcesFetch';
import { OPTIMUS_OPEN_DIALOG } from '@app/Areas/Optimus/State/App/DialogContext/DialogReducer/DialogReducer';
import { useDialogDispatchContext } from '@app/Areas/Optimus/State/App/DialogContext/DialogContextProvider';
import { openDialog } from '../../../State/App/DialogContext/DialogReducer/DialogReducer';
import useGroupIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useGroupIdParam';

function ResourcesHub() {
    useSectionsFetch();
    useGroupsFetch();
    useResourcesFetch();

    const dialogDispatch = useDialogDispatchContext();
    const sectionDispatch = useSectionDispatchContext()
    const vaultId = useVaultIdParam();
    const sectionId = useSectionIdParam();
    const groupId = useGroupIdParam();
    const sections = useVaultSectionsAsArray();
    const { path } = useRouteMatch();

    const openNewSectionDialog = () => {
        dialogDispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: NEW_SECTION_DIALOG_ID
        })
    }

    const openNewGroupDialog = () => {
        dialogDispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: NEW_GROUP_DIALOG_ID
        })
    }

    const openNewResourceDialog = () => {
        dialogDispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: NEW_RESOURCE_DIALOG_ID
        })
    }

    const openEditSectionDialog = () => {
        dialogDispatch(openDialog(EDIT_SECTION_DIALOG_ID))
        sectionDispatch({
            type: RESOURCES_SET_SECTION_UNDER_EDIT,
            sectionId
        })
    }

    const openDeleteSectionDialog = () => {
        dialogDispatch(openDialog(DELETE_SECTION_DIALOG_ID))
        sectionDispatch({
            type: RESOURCES_SET_SECTION_UNDER_DELETE,
            sectionId
        })
    }

    const openDeleteGroupDialog = () => {
        dialogDispatch(openDialog(DELETE_GROUP_DIALOG_ID))
        sectionDispatch({
            type: RESOURCES_SET_GROUP_UNDER_DELETE,
            groupId
        })
    }

    const openEditGroupDialog = () => {
        dialogDispatch(openDialog(EDIT_GROUP_DIALOG_ID))
        sectionDispatch({
            type: RESOURCES_SET_GROUP_UNDER_EDIT,
            groupId
        })
    }

    return (
        <>
            <Panel>
                <PanelHeader
                    headerVariant="h5"
                    header="Resources"
                    actions={[{
                        label: 'New Section',
                        action: openNewSectionDialog
                    }, {
                        label: 'New Group',
                        action: openNewGroupDialog
                    }, {
                        label: 'New Resource',
                        action: openNewResourceDialog
                    }, {
                        label: 'Edit Section',
                        action: openEditSectionDialog
                    }, {
                        label: 'Edit Group',
                        action: openEditGroupDialog
                    }, {
                        label: 'Delete Section',
                        action: openDeleteSectionDialog
                    }, {
                        label: 'Delete Group',
                        action: openDeleteGroupDialog
                    }]}
                />
                <Switch>
                    <Route path={`${path}/:sectionId`}>
                        <SectionView />
                    </Route>
                    <Route path={`${path}`}>
                        { sections && sections.length && <Redirect to={`/optimus/vaults/${vaultId}/deepthought/sections/${sections[0].id}`} /> }
                    </Route>
                </Switch>
            </Panel>
        </>
    )
}

export default ResourcesHub;
