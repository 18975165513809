import { useCallback, useRef, useState } from 'react';
import { useTaskBoardDispatchContext } from '@app/Areas/Optimus/Apps/TaskBoardApp';
import ConfirmationDialog from '@app/Areas/Optimus/Components/Shared/ConfirmationDialog';
import { DELETE_TASKLIST_DIALOG_ID } from '@app/Areas/Optimus/Ecosystems/ActionDialogs';
import useTaskListUnderDelete from '../../../hooks/_TaskLists/useTaskListUnderDelete';
import createTaskListService from '../../../services/TaskListsService';
import { BOARDS_REMOVE_TASKLIST_UNDER_DELETE, BOARDS_CLEAR_TASKLIST_UNDER_DELETE } from '../../../state/TaskBoardReducer/TaskBoardReducer';

function DeleteTaskList() {
    const taskListUnderDelete = useTaskListUnderDelete();    
    const boardDispatch = useTaskBoardDispatchContext();
    const [isSending, setIsSending] = useState(false);
    const taskListService = createTaskListService();
    const isMounted = useRef(true);

    const sendRequest = useCallback(async (callback) => {
        if (taskListUnderDelete?.taskList.id) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            await taskListService.archiveTaskList(
                taskListUnderDelete?.parentVaultId,
                taskListUnderDelete?.parentBoardId,
                taskListUnderDelete.taskList.id
            );
            boardDispatch({
                type: BOARDS_REMOVE_TASKLIST_UNDER_DELETE,
                taskListId: taskListUnderDelete?.taskList.id
            })
            callback();
            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [taskListUnderDelete, isSending]
    ); // update the callback if the state changes

    const handleConfirm = (callback: () => void) => {
        if (!isSending) {
            sendRequest(callback);
        }
    }

    const cleanUp = () => {
        boardDispatch({
            type: BOARDS_CLEAR_TASKLIST_UNDER_DELETE
        })
    }

    return taskListUnderDelete && (
        <ConfirmationDialog
            title="Delete Task List"
            description={`Are you sure you want to delete the task list '${taskListUnderDelete?.taskList.name}'`}
            dialogId={DELETE_TASKLIST_DIALOG_ID}
            onConfirm={handleConfirm}
            onClose={cleanUp}
        />
    ) || null;
}

export default DeleteTaskList;
