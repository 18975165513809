import useVaultIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import useDeckIdParam from '../../../../App/NavigationContext/hooks/useDeckIdParam/useDeckIdParam';
import { useDeckStateContext } from '@app/Areas/Optimus/State/DataStores/DeckStore/DeckContextProvider';

function useDeck() {
    const deckStateContext = useDeckStateContext();
    const vaultId = useVaultIdParam();
    const deckId = useDeckIdParam();

    if (!deckId) {
        return null;
    }

    const deck = deckStateContext?.decks?.byId[deckId];

    if (!deck || deck?.parentVaultId !== vaultId) {
        return null;
    }

    return deck.deck;

}

export default useDeck;
