import { IDialogContextModel } from "../DialogReducer/DialogReducer";
import DialogReducer from '../DialogReducer/DialogReducer';
import createReducerContexts from '@app/Areas/Optimus/Contexts/createReducerContexts';


const initialState: IDialogContextModel = {
  actionDialogs: {}
}

export const {
  StateContext: DialogStateContext,
  DispatchContext: DialogDispatchContext,
  Providers: DialogContextProvider,
  useStateContext: useDialogStateContext,
  useDispatchContext: useDialogDispatchContext,
} = createReducerContexts<IDialogContextModel>(DialogReducer, "dialogs", initialState);
