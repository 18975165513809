import React, { createContext, Dispatch, useContext } from "react";
import { DispatcherThunk, useThunkReducer } from "@optimus/Hooks/useThunkReducer";
import createUserProfileService from "@optimus/Services/UserProfileService";
import OptimusStyleProvider from "../../OptimusStyleProvider";
import { UserProfileApiModel } from "../../ApiClient/UsersClient";

export const OPTIMUS_SET_USERPROFILE = 'OPTIMUS_SET_USERPROFILE';
export interface ActionDialogsContext {
  openedDialog?: string;
}

export interface IOptimusContextModel {
  userprofile?: UserProfileApiModel;
  actionDialogs?: ActionDialogsContext;
}

const initialState: IOptimusContextModel = {};

 /**
  * The reducer for managing Optimus State
  * @param state
  * @param action
  */
 function OptimusReducer(state: any, action: any) {
  switch (action.type) {
      case OPTIMUS_SET_USERPROFILE:
          return {
              ...state,
              userprofile: action.userprofile
          }
      default:
          throw new Error();
  }
}

/**
 * Action creator for setting the current optimus context user profile.
 */
export function setCurrentOptimusContextUserprofile() {
  return async (dispatch: Dispatch<any>) => {

    // try get context from state first
    const profileService = createUserProfileService();
    return profileService.getProfile().then((result) => {
      dispatch({
        type: OPTIMUS_SET_USERPROFILE,
        userprofile: result
      })
    });
  }
}

let OptimusContextStateContext = createContext({} as IOptimusContextModel)
let OptimusContextDispatchContext = createContext({} as DispatcherThunk<(state: any, action: any) => any>)

// AppContext
  // DialogContext
  // DebugContext
// DataStores
  // UserStore
  // VaultStore
  // ResourceStore
  // ProjectStore
  // DeckStore
  // VideoStore
  // TaskStore
  // InitiativeStore

function OptimusContextProviders({ children }: React.PropsWithChildren<{}>) {
  const [state, dispatch] = useThunkReducer(OptimusReducer, initialState, "optimusContext");
  return (
    <>
      <OptimusContextDispatchContext.Provider
        value={dispatch}
      >
        <OptimusContextStateContext.Provider
          value={state}
        >
          <OptimusStyleProvider>
            { children }
          </OptimusStyleProvider>
        </OptimusContextStateContext.Provider>
      </OptimusContextDispatchContext.Provider>
    </>
  );
}

function useOptimusStateContext() {
  return useContext<IOptimusContextModel>(OptimusContextStateContext);
}

function useOptimusDispatchContext() {
  return useContext<DispatcherThunk<(state: any, action: any) => any>>(OptimusContextDispatchContext);
}

export {
  OptimusContextStateContext,
  OptimusContextDispatchContext,
  OptimusContextProviders,
  useOptimusStateContext,
  useOptimusDispatchContext
};