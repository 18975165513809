import EditorDialog from "@app/Areas/Optimus/Components/Shared/EditorDialog";
import { EDIT_TASKITEM_DIALOG_ID } from '@app/Areas/Optimus/Ecosystems/ActionDialogs';
import useVaultsAsDictionary from "@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useVaultsAsDictionary";
import useTaskItemUnderView from '@app/Areas/Optimus/Apps/TaskBoard/hooks/_TaskItems/useTaskItemUnderView';
import { useCallback, useEffect, useRef, useState } from "react";
import TaskItemEditor, { TaskItemEditorModel } from './TaskItemEditor';
import createTaskItemService from "../../services/TaskItemService";
import { BOARDS_CLEAR_TASKITEM_UNDER_VIEW, getVaultBoardTaskLists } from "../../state/TaskBoardReducer/TaskBoardReducer";
import { useTaskBoardDispatchContext } from "../../state/TaskBoardContextProvider";
import { ITaskItemUpdateRequest, ITaskItemApiModel } from "@app/Areas/Optimus/ApiClient/TaskboardsClient";


const blankTaskItem: ITaskItemUpdateRequest = {
    title: '',
    description: '',
    status: 0,
    initiativeIds: [],
    newParentId: 0
};

function mapUpdateDetailsToTaskItem(
    taskItem: ITaskItemUpdateRequest
): TaskItemEditorModel {
    return {
        title: taskItem.title,
        description: taskItem.description
    }
}

function EditTaskItem() {
    const taskItemUnderView = useTaskItemUnderView();
    const vaults = useVaultsAsDictionary();
    const [isSending, setIsSending] = useState(false)
    const [taskItemUpdateRequest, setTaskitemUpdateRequest] = useState<ITaskItemUpdateRequest | null>(blankTaskItem);
    const [updatedTaskItem, setUpdatedTaskItem] = useState<ITaskItemApiModel | null>(null);
    const taskItemService = createTaskItemService();
    const boardsDispatch = useTaskBoardDispatchContext();
    const isMounted = useRef(true);
    const taskItem = taskItemUnderView?.taskItem;    
    const currentParentVaultId = taskItemUnderView?.parentVaultId;
    const currentParentVault = currentParentVaultId ? vaults[currentParentVaultId] : null;

    useEffect(() => {
        if (!!taskItemUnderView?.taskItem && !!taskItemUnderView.parentVaultId) {
            setTaskitemUpdateRequest({
                ...taskItemUpdateRequest,
                title: taskItemUnderView?.taskItem?.title || '',
                description: taskItemUnderView?.taskItem?.description || '',
                status: taskItemUnderView?.taskItem?.status || 0,
                initiativeIds: [], // TODO: Support initiative Ids
                newParentId: taskItemUnderView?.parentTaskListId,
            });
        }
    }, [taskItemUnderView]);

    function taskItemEdited(event: any) {
        if (taskItemUpdateRequest !== null) {
            setTaskitemUpdateRequest({
                ...taskItemUpdateRequest,
                [event.target.name]: event.target.value
            })
        }
    }

    const handleSave = () => {
        if (!updatedTaskItem && !isSending) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        setTaskitemUpdateRequest(null);
        setUpdatedTaskItem(null);
        boardsDispatch({
            type: BOARDS_CLEAR_TASKITEM_UNDER_VIEW
        });
    }

    const sendRequest = useCallback(async () => {
        if (currentParentVaultId && taskItemUnderView?.taskItem?.id && !!taskItemUpdateRequest) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await taskItemService.updateTaskItem(
                currentParentVaultId,
                taskItemUnderView.parentBoardId,
                taskItemUnderView.parentTaskListId,
                taskItemUnderView?.taskItem?.id,
                taskItemUpdateRequest);
            if (result) {
                setUpdatedTaskItem(result);
            }
            boardsDispatch(getVaultBoardTaskLists(currentParentVaultId, taskItemUnderView.parentBoardId, false));

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [ currentParentVaultId, taskItemUpdateRequest, isSending]
    ); // update the callback if the state changes

    return currentParentVault && taskItemUpdateRequest && <EditorDialog
        title="Edit TaskItem"
        saveTitle="Update TaskItem"
        dialogId={EDIT_TASKITEM_DIALOG_ID}
        handleSave={handleSave}
        onClose={cleanUp}
    >
        { !updatedTaskItem && <TaskItemEditor title="Edit" taskItem={mapUpdateDetailsToTaskItem(taskItemUpdateRequest)} handleTaskItemChange={taskItemEdited} />}
        { updatedTaskItem && JSON.stringify(updatedTaskItem) }
    </EditorDialog> || null;
}

export default EditTaskItem;
