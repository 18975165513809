import React from 'react';
import { Link } from 'react-router-dom';
import BreadcrumbPaper from '@optimus/Components/Shared/BreadcrumbPaper';
import { Breadcrumbs } from '@material-ui/core';

export interface BreadcrumbProps {
    fragments: {
        path: string;
        label: string;
    }[]
}

function Breadcrumb({
    fragments
}: BreadcrumbProps) {
    return (
        <BreadcrumbPaper>
            <Breadcrumbs aria-label="breadcrumb">
                { fragments.map(f => (
                    <Link to={f.path}>
                    {/* <Link color="inherit" href="/" onClick={handleClick} className={classes.link}> */}
                        {/* <HomeIcon className={classes.icon} /> */}
                        {f.label}
                    </Link>
                ))}
            </Breadcrumbs>
        </BreadcrumbPaper>
    )
}

export default Breadcrumb;
