import { Vault } from '../../../models/entities/vault.entity';
import { Section } from '../../../models/entities/section.entity';
import { Group } from '../../../models/entities/group.entity';
import { Resource } from '../../../models/entities/resource.entity';
import { Playlist } from '../../../models/entities/playlist.entity';
import { Deck } from '../../../models/entities/deck.entity';
import { Video } from '../../../models/entities/video.entity';
import { Card } from '../../../models/entities/card.entity';

export interface FetchEvent {
    key: string
    label: string
}

export enum VaultViewMode {
    None,
    Section,
    Playlst,
    Deck
}

export interface AppState {
    currentVaultView: VaultViewMode
    vault: Vault | null,
    userVaults: Vault[],
    section: Section | null,
    userSections: Section[],
    group: Group | null,
    userGroups: Group[],
    resource: Resource | null,
    userResources: Resource[],
    playlist: Playlist | null,
    userPlaylists: Playlist[],
    deck: Deck | null,
    userDecks: Deck[],
    video: Video | null,
    userVideos: Video[],
    card: Card | null,
    userCards: Card[],
    fetchingEvents: FetchEvent[]
}

const InitialAppState: AppState = {
    currentVaultView: VaultViewMode.None,
    vault: null,
    userVaults: [],
    section: null,
    userSections: [],
    group: null,
    userGroups: [],
    resource: null,
    userResources: [],
    playlist: null,
    userPlaylists: [],
    deck: null,
    userDecks: [],
    video: null,
    userVideos: [],
    card: null,
    userCards: [],
    fetchingEvents: []
};

export default InitialAppState;