import { IBoardApiModel } from '@app/Areas/Optimus/ApiClient/TaskboardsClient';
import { useTaskBoardStateContext } from '@app/Areas/Optimus/Apps/TaskBoardApp';
import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { ITaskBoardContextModel } from '../../../state/TaskBoardReducer/TaskBoardReducer';

function useVaultBoardsAsDictionary(): {
    [key: number]: IBoardApiModel
 } {
    const vaultId = useVaultIdParam();
    const state: ITaskBoardContextModel = useTaskBoardStateContext();

    if (vaultId === null) {
        return {};
    }

    const boards =
        Object.entries(state.boards.byId)
            .filter(([key, value]) => value.parentVaultId === vaultId)
            .reduce((map, [key, value]) => ({
                ...map,
                [key]: value.board,
            }), {});

    return boards;
}

export default useVaultBoardsAsDictionary;