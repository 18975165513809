import React from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import VaultsList from '../VaultsList';
import VaultView from '../VaultView';

function VaultsHub() {
    const { path } = useRouteMatch();
    return (
        <>
            <Route exact path={`${path}`}>
                <VaultsList />
            </Route>
            <Route path={`${path}/:vaultId`}>
                <VaultView />
            </Route>
        </>
    )
}

export default VaultsHub;
