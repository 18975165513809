import { ITaskListApiModel, ITaskItemApiModel } from '@app/Areas/Optimus/ApiClient/TaskboardsClient';
import { useTaskBoardStateContext } from '@app/Areas/Optimus/Apps/TaskBoardApp';
import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { ITaskBoardContextModel } from '../../../state/TaskBoardReducer/TaskBoardReducer';

function useTaskItemsAsArray(boardId: number | null, taskListId: number | null): ITaskListApiModel[] {
    const vaultId = useVaultIdParam();
    const state: ITaskBoardContextModel = useTaskBoardStateContext();

    if (vaultId === null || boardId === null || taskListId === null) {
        return [];
    }

    const taskItems =
        Object.values(state.taskItems.byId)
            .filter((value) => value.parentVaultId === vaultId && value.parentBoardId === boardId && value.parentTaskListId === taskListId)
            .sort(({ taskItem: taskItem1 }: {
                taskItem: ITaskItemApiModel
            }, { taskItem: taskItem2 }: {
                taskItem: ITaskItemApiModel
            }) => {
                if (taskItem1?.order !== undefined && taskItem2?.order !== undefined) {
                    return taskItem1.order - taskItem2.order;
                }

                return 0;                
            })
            .map(s => s.taskItem);

    return taskItems;
}

export default useTaskItemsAsArray;
