import { IProjectsContextModel } from '../ProjectReducer/ProjectReducer';
import ProjectsReducer from '@app/Areas/Optimus/State/DataStores/ProjectStore/ProjectReducer/ProjectReducer';
import createReducerContexts from '@app/Areas/Optimus/Contexts/createReducerContexts';

const initialState: IProjectsContextModel = {
  projects: {
      byId: {},
      allIds: [],
      selected: null,
      selectedToEdit: null,
      selectedToDelete: null
  }
}

export const {
  StateContext: ProjectStateContext,
  DispatchContext: ProjectDispatchContext,
  Providers: ProjectContextProvider,
  useStateContext: useProjectStateContext,
  useDispatchContext: useProjectDispatchContext,
} = createReducerContexts<IProjectsContextModel>(ProjectsReducer, "projects", initialState);
