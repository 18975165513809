import { useEffect } from 'react';
import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { getVaultSections, SECTIONS_RESET } from '@optimus/State/DataStores/ResourceStore/ResourceReducer/ResourceReducer';
import { useSectionDispatchContext } from '../../ResourceContextProvider';

/**
 * Hooks into the current vault id and fetches the appropriate sections
 */
function useSectionsFetch(
    presetVaultId: number | null = null
) {
    const vaultIdParam = useVaultIdParam();
    const vaultId = presetVaultId !== null ? presetVaultId : vaultIdParam;

    const sectionDispatch = useSectionDispatchContext();

    useEffect(() => {
        async function fetchSections() {
            if (vaultId) {
                sectionDispatch(getVaultSections(vaultId));
            }
        }
        fetchSections();

        return function cleanup() {
            sectionDispatch({ type: SECTIONS_RESET })
        }
    }, [vaultId]);
}

export default useSectionsFetch;
