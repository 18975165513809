import PanelHeader from "@app/Areas/Optimus/Components/Shared/PanelHeader";
import { Theme } from "@fluentui/theme/lib/types";
import { Grid, TextField, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

export interface PlaylistEditorModel {
    title: string;
    description: string;
    source: string;
    sourceId: string;
}

export interface PlaylistEditorProps {
    playlist: PlaylistEditorModel;
    handlePlaylistChange: (event: any) => void;
    isSaving: boolean;
    title?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
        marginBottom: 20
    },
    form: {
        padding: '0.75rem'
    }
  }),
);

function PlaylistEditor({
    title,
    isSaving,
    playlist,
    handlePlaylistChange
}: PlaylistEditorProps) {
    const classes = useStyles();

    return (
        <form className={classes.form}>
            { isSaving && <Typography variant="h4">Saving Board...</Typography> }
            <PanelHeader headerVariant="h5" header={`Playlist - ${title}`}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.input}
                            fullWidth id="playlist-title-input"
                            label="Title"
                            name="title"
                            value={playlist.title}
                            onChange={handlePlaylistChange}
                        />
                        <TextField
                            className={classes.input}
                            fullWidth id="playlist-description-input"
                            label="Description"
                            name="description"
                            value={playlist.description}
                            onChange={handlePlaylistChange}
                        />
                    </Grid>
                </Grid>
            </PanelHeader>
        </form>
    )
}

export default PlaylistEditor;
