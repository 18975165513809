import { createContext, useContext } from 'react';
import { DispatcherThunk, useThunkReducer } from '@optimus/Hooks/useThunkReducer';
import ResourceReducer, { IResourcesContextModel } from '../ResourceReducer/ResourceReducer';

const initialState: IResourcesContextModel = {
  sections: {
      byId: {},
      allIds: [],
      selected: null,
      selectedToEdit: null,
      selectedToDelete: null
  },
  groups: {
    byId: {},
    allIds: [],
    selected: null,
    selectedToEdit: null,
    selectedToDelete: null
  },
  resources: {
    byId: {},
    allIds: [],
    selected: null,
    selectedToEdit: null,
    selectedToDelete: null
  }
}

let SectionContextStateContext = createContext({} as IResourcesContextModel)
let SectionContextDispatchContext = createContext({} as DispatcherThunk<(state: any, action: any) => any>)

function ResourceContextProvider({ children }: React.PropsWithChildren<{}>) {
    const [sectionState, sectionDispatch] = useThunkReducer(ResourceReducer, initialState, "resources");

    return (
      <SectionContextDispatchContext.Provider
        value={sectionDispatch}
      >
        <SectionContextStateContext.Provider
          value={sectionState}
        >
          { children }
        </SectionContextStateContext.Provider>
      </SectionContextDispatchContext.Provider>
    );
}

function useSectionStateContext() {
    return useContext<IResourcesContextModel>(SectionContextStateContext);
}

function useSectionDispatchContext() {
    return useContext<DispatcherThunk<(state: any, action: any) => any>>(SectionContextDispatchContext);
}

  export {
    SectionContextStateContext,
    SectionContextDispatchContext,
    ResourceContextProvider,
    useSectionStateContext,
    useSectionDispatchContext
  };