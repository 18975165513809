import { useState } from 'react';
import EditorDialog from '@optimus/Components/Shared/EditorDialog';
import { BoardEditorModel } from './BoardEditorModel';
import BoardEditor from './BoardEditor';
import createBoardService from '../../../services/BoardsService';
import { getVaultBoards } from '../../../state/TaskBoardReducer/TaskBoardReducer';
import { useTaskBoardDispatchContext } from '../../../state/TaskBoardContextProvider/TaskBoardContextProvider';
import { NEW_BOARD_DIALOG_ID } from '@app/Areas/Optimus/Ecosystems/ActionDialogs';
import useCreateVaultChildEntity from '@app/Areas/Optimus/Hooks/_operations/useCreateVaultChildEntity';
import useCurrentVault from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useCurrentVault';
import { ISectionCreationRequest } from '@app/Areas/Optimus/ApiClient/BookmarksClient';
import { IBoardCreationRequest, IBoardApiModel } from '@app/Areas/Optimus/ApiClient/TaskboardsClient';

const blankBoard: ISectionCreationRequest = {
    name: ''
};

function mapCreationDetailsToBoard(board: IBoardCreationRequest): BoardEditorModel {
    return {
        name: board.name
    }
}

function NewBoard() {
    const boardService = createBoardService();
    const boardDispatch = useTaskBoardDispatchContext();
    const currentVault = useCurrentVault();
    const [createdBoard, setCreatedBoard] = useState<IBoardApiModel | null>(null);
    const [boardCreationRequest, setBoardCreationRequest] = useState<IBoardCreationRequest>(blankBoard);

    function boardEdited(event: any) {
        setBoardCreationRequest({
            ...boardCreationRequest,
            [event.target.name]: event.target.value
        })
    }

    const {
        isSending,
        sendRequest,
    } = useCreateVaultChildEntity<
        IBoardCreationRequest,
        IBoardApiModel
    >(
        (v: number, cr: IBoardCreationRequest) => boardService.createBoard(v, cr),
        boardCreationRequest,
        (result: IBoardApiModel) => setCreatedBoard(result),
        (vaultId: number) => boardDispatch(getVaultBoards(vaultId)),
    );

    const handleSave = () => {
        if (!createdBoard && !isSending) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        setBoardCreationRequest(blankBoard);
        setCreatedBoard(null);
    }

    return currentVault && (
        <EditorDialog
            title="New Board"
            saveTitle="Save Board"
            dialogId={NEW_BOARD_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            { !createdBoard && <BoardEditor
                board={mapCreationDetailsToBoard(boardCreationRequest)}
                handleBoardChange={boardEdited}
                title={currentVault.title}
                isSaving={isSending}
            /> }
            { createdBoard && JSON.stringify(createdBoard) }
        </EditorDialog> ) || null;
}

export default NewBoard;
