import { Route, Switch, useRouteMatch } from "react-router";
import YouTube from "react-youtube";
import useCurrentVideo from "../../State/DataStores/VideoStore/hooks/useCurrentVideo/useCurrentVideo";


function VideoRouting({}: {}) {
    const { path } = useRouteMatch();

    const video = useCurrentVideo();

    return (
        <Switch>
            <Route path={`${path}/videos/:videoId`}>
                { video && <><YouTube
                    videoId={video?.sourceId}
                />
                { JSON.stringify(video) }</> }
            </Route>
        </Switch>
    )
}

export default VideoRouting;