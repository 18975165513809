import React from "react";
import styled from 'styled-components';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { IGroupApiModel } from "@app/Areas/Optimus/ApiClient/BookmarksClient";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    );
  }

export interface GroupSelectorProps {
    selectedSection: number | null;
    selectGroup: (event: React.ChangeEvent<{}>, value: any) => void;
    selectedGroup: number | null;
    groups: IGroupApiModel[];
}

function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const Root = styled.div`
    display: grid;
    grid-gap: 8px;
    grid-template-rows: 48px minmax(0, 1fr);
    grid-template-columns: 260px minmax(0, 1fr);
    height: 100%;
`;

const ResourceView = styled.div`
    grid-column: 1 / span 2;
    height: 100%;
`;

function GroupSelector({
    selectedSection,
    selectedGroup,
    selectGroup,
    groups,
    children
}: React.PropsWithChildren<GroupSelectorProps>) {
    const groupTabsMap: {
        [key: string]: number
    } = groups.reduce((map: {
        [key: string]: number
    }, g, i) => ({
        ...map,
        [i]: g.id
    }), {});

    const tabSearch = Object.entries(groupTabsMap).find(([key, value]) => value === selectedGroup);
    const tabIndex = tabSearch && tabSearch.length && +tabSearch[0];

    function tabSelect(event: React.ChangeEvent<{}>, value: number) {
        const newGroup = groupTabsMap[value];
        selectGroup(event, newGroup);
    }

    return (
        <Root>
            <div>
                { children }
            </div>
            <AppBar position="static" color="default">
                <Tabs
                    value={tabIndex}
                    onChange={tabSelect}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    { groups.map((group, i) => (
                        <Tab label={group.name} {...a11yProps(i)} />
                    ))}
                </Tabs>
            </AppBar>
        </Root>
    )
}

export default GroupSelector;