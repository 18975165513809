import React, { useState } from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import Column from '../Column/Column';

import { PropsWithChildren } from 'react';
import useVaultIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import NewTaskListComponent from '../_TaskLists/NewTaskList/NewTaskListComponent';
import DeleteDropTile from '../DeleteDropTile/DeleteDropTile';
import { useDialogDispatchContext } from '@app/Areas/Optimus/State/App/DialogContext/DialogContextProvider';
import { openDialog } from '@app/Areas/Optimus/State/App/DialogContext/DialogReducer/DialogReducer';
import { DELETE_TASKITEM_DIALOG_ID, DELETE_TASKLIST_DIALOG_ID, TRANSFER_TASKITEM_DIALOG_ID, TRANSFER_TASKLIST_DIALOG_ID } from '@app/Areas/Optimus/Ecosystems/ActionDialogs';
import { BOARDS_SET_TASKITEM_UNDER_DELETE, BOARDS_SET_TASKLIST_UNDER_DELETE, reorderTaskList, moveTaskItem, BOARDS_SET_TASKITEM_UNDER_TRANSFER, BOARDS_SET_TASKLIST_UNDER_TRANSFER } from '../../state/TaskBoardReducer/TaskBoardReducer';
import { useTaskBoardDispatchContext } from '../../state/TaskBoardContextProvider/TaskBoardContextProvider';
import useBoardIdParam from '../../hooks/_Boards/useBoardIdParam';
import useTaskListsAsArray from '../../hooks/_TaskLists/useTaskListsAsArray';
import MoveDropTile from '../MoveDropTile';
import { ITaskListApiModel } from '@app/Areas/Optimus/ApiClient/TaskboardsClient';

export const Controls = styled.div`    
    display: grid;
    grid-template-columns: repeat( auto-fit, 220px );
    padding: 0.5em;
    grid-gap: 1em;
    margin-bottom: 0.5em;
`;

const Control = styled.div`    
    display: flex;
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    max-width: 100%;
    overflow: auto;
`;

export interface InnerListProps {
    column: ITaskListApiModel;
    index: any;
}

function InnerList({ column, ...rest }: PropsWithChildren<InnerListProps>) {
    if (column.name !== undefined) {
        return <Column title={column.name} lifeCycleStage={column.lifecycleStage} column={column} {...rest} />;
    }
    
    return null;
}

const MemoList = React.memo(InnerList);

function TaskBoard() {    
    const vaultId = useVaultIdParam();
    const boardId = useBoardIdParam();
    const taskState = useTaskListsAsArray(boardId);
    const boardDispatch = useTaskBoardDispatchContext();
    const dialogDispatch = useDialogDispatchContext();
    const [typeUnderDrag, setTypeUnderDrag] = useState<string | undefined>();

    const onBeforeCapture = (initial: any) => {
        if (initial.draggableId.includes('tasklist')) {
            setTypeUnderDrag('column');
        } else if (initial.draggableId.includes('taskitem')) {
            setTypeUnderDrag('task');
        }

        
    }

    const onDragStart = (start: any, provided: any) => {
        // console.log(`You have lifted the task in position ${start.source.index + 1}`)
    }

    const onDragUpdate = (update: any, provided: any) => {
        const message = update.destination
            ? `You have moved the task to position ${update.destination.index + 1}`
            : `You are currently not over a droppable area`;

        // provided.announce(message)
    }

    const openDeleteTaskItemDialog = (taskListId: number, taskItemId: number) => {
        if (taskListId && taskItemId) {
            dialogDispatch(openDialog(DELETE_TASKITEM_DIALOG_ID));
            boardDispatch({
                type: BOARDS_SET_TASKITEM_UNDER_DELETE,
                taskItemId: taskItemId,
            })
        }
    }

    const openTransferTaskItemDialog = (taskListId: number, taskItemId: number) => {
        if (taskListId && taskItemId) {
            dialogDispatch(openDialog(TRANSFER_TASKITEM_DIALOG_ID));
            boardDispatch({
                type: BOARDS_SET_TASKITEM_UNDER_TRANSFER,
                taskItemId: taskItemId,
            })
        }
    }

    const openTransferTaskListDialog = (taskListId: number) => {
        if (taskListId) {
            dialogDispatch(openDialog(TRANSFER_TASKLIST_DIALOG_ID));
            boardDispatch({
                type: BOARDS_SET_TASKLIST_UNDER_TRANSFER,
                taskListId: taskListId,
            })
        }
    }

    const openDeleteTaskListDialog = (taskListId: number) => {
        if (taskListId) {
            dialogDispatch(openDialog(DELETE_TASKLIST_DIALOG_ID));
            boardDispatch({
                type: BOARDS_SET_TASKLIST_UNDER_DELETE,
                taskListId: taskListId,
            })
        }
    }

    const triggerArchiveTaskItem = (taskListId: number, taskItemId: number) => {
        openDeleteTaskItemDialog(taskListId, taskItemId);
    }

    const triggerTransferTaskItem = (taskListId: number, taskItemId: number) => {
        openTransferTaskItemDialog(taskListId, taskItemId);
    }

    const triggerTransferTaskList = (taskListId: number) => {
        openTransferTaskListDialog(taskListId);
    }

    const triggerArchiveTaskList = (taskListId: number) => {
        openDeleteTaskListDialog(taskListId);
    }

    const onDragEnd = async ({ destination, source, draggableId, type }: DropResult, provided: any) => {

        setTypeUnderDrag(undefined);

        const message = destination
            ? `You have moved from the position ${source.index +1} to ${destination.index + 1}`
            : `The task has been returned to its starting position of ${source.index + 1}`;

        provided.announce(message);

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === 'column') {
            const taskListId = +(draggableId.split('-')[1]);

            if (destination.droppableId === 'delete-tile') {                
                triggerArchiveTaskList(taskListId);
            } else if (destination.droppableId === 'move-tile') {
                triggerTransferTaskList(taskListId);
            } else {               
                if (vaultId && boardId && taskListId) {
                    return boardDispatch(reorderTaskList(vaultId, boardId, taskListId, destination.index, false));
                }
            }
        }

        // Move items
        if (type === 'task') {            
            const taskItemId = +(draggableId.split('-')[1]);
            const sourceTaskListId = +(source.droppableId.split('-')[1]);

            if (destination.droppableId === 'delete-tile') {
                triggerArchiveTaskItem(sourceTaskListId, taskItemId);
            } else if (destination.droppableId === 'move-tile') {
                triggerTransferTaskItem(sourceTaskListId, taskItemId);
            } else {
                const targetTaskListId = +(destination.droppableId.split('-')[1]);
                const newPosition = destination.index;
    
                if (vaultId && boardId && sourceTaskListId && targetTaskListId && taskItemId && newPosition !== undefined) {
                    console.log('moving task')
                    return boardDispatch(moveTaskItem(
                        vaultId,
                        boardId,
                        sourceTaskListId,
                        targetTaskListId,
                        taskItemId,
                        newPosition,
                        false)
                    );
                }
            }
        }
    }

    return taskState ? <DragDropContext
                onBeforeCapture={onBeforeCapture}
                onDragStart={onDragStart}
                onDragUpdate={onDragUpdate}
                onDragEnd={onDragEnd}
            >
                <Controls>
                    <Control>
                        <NewTaskListComponent
                            vaultId={vaultId}
                            boardId={boardId}
                            label="New TaskList"
                        />
                    </Control>
                    {
                        typeUnderDrag && <Control>                            
                            <DeleteDropTile label={`Delete ${typeUnderDrag}`} droppableId="delete-tile" itemType={typeUnderDrag} />                            
                        </Control>
                    }
                    {
                        typeUnderDrag && <Control>                            
                            <MoveDropTile label={`Move ${typeUnderDrag}`} droppableId="move-tile" itemType={typeUnderDrag} />                            
                        </Control>
                    }
                </Controls>
                <Droppable droppableId="all-columns" direction="horizontal" type="column">
                    { (provided) => (
                        <Container {...provided.droppableProps} ref={provided.innerRef}>
                            { taskState
                                .map((column, i) => {
                                    const { id, name } = column;

                                    if (id !== undefined && name !== undefined) {
                                        return <MemoList index={i} key={name} column={column} />; 
                                    }
                                    
                                    return null;
                                })
                            }                            
                            {provided.placeholder}
                        </Container>
                    )}      
                </Droppable>
    </DragDropContext> : null;
}

export default TaskBoard;
