import { useState } from "react";
import EditorDialog from "@app/Areas/Optimus/Components/Shared/EditorDialog";
import { NEW_PLAYLIST_DIALOG_ID } from "@app/Areas/Optimus/Ecosystems/ActionDialogs";
import useCreateVaultChildEntity from "@app/Areas/Optimus/Hooks/_operations/useCreateVaultChildEntity";
import createPlaylistService from "@app/Areas/Optimus/Services/PlaylistService";
import useCurrentVault from "@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useCurrentVault";
import { useVideoDispatchContext } from "@app/Areas/Optimus/State/DataStores/VideoStore/VideoContextProvider/VideoContextProvider";
import { getVaultPlaylists } from "@app/Areas/Optimus/State/DataStores/VideoStore/VideoReducer/VideoReducer";
import PlaylistEditor, { PlaylistEditorModel } from "../PlaylistEditor/PlaylistEditor";
import { IVideoPlaylistCreationRequest, IVideoPlaylistApiModel } from "@app/Areas/Optimus/ApiClient/VideosClient";

const blankPlaylist: IVideoPlaylistCreationRequest = {
    title: '',
    tags: [],
    description: '',
}

function mapCreationDetailsToPlaylist(playlist: IVideoPlaylistCreationRequest): PlaylistEditorModel {
    return {
        title: playlist.title,
        description: playlist.description || '',
        source: '', // TODO: Support source and source id in api request model
        sourceId: ''
    }
}

function NewPlaylist() {
    const playlistService = createPlaylistService();
    const playlistDispatch = useVideoDispatchContext();
    const currentVault = useCurrentVault();
    const [createdPlaylist, setCreatedPlaylist] = useState<IVideoPlaylistApiModel | null>(null);
    const [playlistCreationRequest, setPlaylistCreationRequest] = useState<IVideoPlaylistCreationRequest>(blankPlaylist);

    function playlistEdited(event: any) {
        setPlaylistCreationRequest({
            ...playlistCreationRequest,
            [event.target.name]: event.target.value
        })
    }

    const {
        isSending,
        sendRequest
    } = useCreateVaultChildEntity<
        IVideoPlaylistCreationRequest,
        IVideoPlaylistApiModel
    >(
        (v: number, cr: IVideoPlaylistCreationRequest) => playlistService.createPlaylist(v, cr),
        playlistCreationRequest,
        (result: IVideoPlaylistApiModel) => setCreatedPlaylist(result),
        (vaultId: number) => playlistDispatch(getVaultPlaylists(vaultId)),

    );

    const handleSave = () => {
        if (!createdPlaylist && !isSending) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        setPlaylistCreationRequest(blankPlaylist);
        setCreatedPlaylist(null);
    }

    return currentVault && (
        <EditorDialog
            title="New Playlist"
            saveTitle="Save Playlist"
            dialogId={NEW_PLAYLIST_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            { !createdPlaylist && <PlaylistEditor
                playlist={mapCreationDetailsToPlaylist(playlistCreationRequest)}
                handlePlaylistChange={playlistEdited}
                title={currentVault.title}
                isSaving={isSending}
            /> }
            { createdPlaylist && JSON.stringify(createdPlaylist) }
        </EditorDialog>
    )
}

export default NewPlaylist;
