import { useCallback, useRef, useState } from 'react';
import ConfirmationDialog from '@app/Areas/Optimus/Components/Shared/ConfirmationDialog';
import { DELETE_TASKITEM_DIALOG_ID } from '@app/Areas/Optimus/Ecosystems/ActionDialogs';
import createTaskItemService from '../../../services/TaskItemService';
import { BOARDS_REMOVE_TASKITEM_UNDER_DELETE, BOARDS_CLEAR_TASKITEM_UNDER_DELETE } from '../../../state/TaskBoardReducer/TaskBoardReducer';
import { useTaskBoardDispatchContext } from '../../../state/TaskBoardContextProvider/TaskBoardContextProvider';
import useTaskItemUnderDelete from '../../../hooks/_TaskItems/useTaskItemUnderDelete';

function DeleteTaskItem() {
    const taskItemUnderDelete = useTaskItemUnderDelete();    
    const boardDispatch = useTaskBoardDispatchContext();
    const [isSending, setIsSending] = useState(false);    
    const taskItemService = createTaskItemService();
    const isMounted = useRef(true);

    const sendRequest = useCallback(async (callback) => {
        if (taskItemUnderDelete?.taskItem.id) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await taskItemService.archiveTaskItem(
                taskItemUnderDelete?.parentVaultId,
                taskItemUnderDelete?.parentBoardId,
                taskItemUnderDelete?.parentTaskListId,
                taskItemUnderDelete?.taskItem.id);
            if (result) {
                boardDispatch({
                    type: BOARDS_REMOVE_TASKITEM_UNDER_DELETE,
                    taskItemId: taskItemUnderDelete?.taskItem.id
                })
                callback();
            }
            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [taskItemUnderDelete, isSending]
    ); // update the callback if the state changes

    const handleConfirm = (callback: () => void) => {
        if (!isSending) {
            sendRequest(callback);
        }
    }

    const cleanUp = () => {
        boardDispatch({
            type: BOARDS_CLEAR_TASKITEM_UNDER_DELETE
        })
    }

    return taskItemUnderDelete && (
        <ConfirmationDialog
            title="Delete Task Item"
            description={`Are you sure you want to delete the task item '${taskItemUnderDelete?.taskItem.title}'`}
            dialogId={DELETE_TASKITEM_DIALOG_ID}
            onConfirm={handleConfirm}
            onClose={cleanUp}
        />
    ) || null;
}

export default DeleteTaskItem;
