import Panel from '@optimus/Components/Shared/Panel';
import DecksList from '../../DecksList';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import Pane from '../../../../Components/Shared/Pane/Pane';
import { DeepThoughtColor } from '@app/Areas/Optimus/Tokens/colors';
import { DeckIcon } from '@app/Areas/Optimus/Components/Shared/AppsIcons';
import PanelHeader from '@app/Areas/Optimus/Components/Shared/PanelHeader';
import { DELETE_DECK_DIALOG_ID, EDIT_DECK_DIALOG_ID } from '../../../ActionDialogs/index';
import { DECKS_SET_DECK_UNDER_DELETE, DECKS_SET_DECK_UNDER_EDIT } from '../../../../State/DataStores/DeckStore/DeckReducer/DeckReducer';
import useVaultIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import useVaultDecksAsArray from '../../../../State/DataStores/DeckStore/hooks/useVaultDecksAsArray/useVaultDecksAsArray';
import useDecksFetch from '@app/Areas/Optimus/State/DataStores/DeckStore/hooks/useDecksFetch/useDecksFetch';
import useDeck from '../../../../State/DataStores/DeckStore/hooks/useDeck/useDeck';
import { useDialogDispatchContext } from '@app/Areas/Optimus/State/App/DialogContext/DialogContextProvider';
import { openDialog } from '../../../../State/App/DialogContext/DialogReducer/DialogReducer';
import { useDeckDispatchContext } from '@app/Areas/Optimus/State/DataStores/DeckStore/DeckContextProvider/DecksContextComponent';
import { IDeckApiModel } from '@app/Areas/Optimus/ApiClient/DecksClient';

const useClasses = makeStyles((theme: Theme) =>
    {
        return createStyles({
            // root: {
            //     height: '100%',
            //     background: 'rgba(0,0,0,0.05)',
            //     backdropFilter: 'blur(10px)',
            //     boxShadow: `0 0px 10px #3cb8dc99`,
            //     border: `1px solid #3cb8dc99`,
            // },
            // deckViewContent: {
            //     height: '100%',
            //     [theme.breakpoints.down('sm')]: {
            //         paddingLeft: 0,
            //         paddingRight: 0,
            //         paddingTop: 0,
            //         paddingBottom: 0,
            //     },
            //     [theme.breakpoints.up('md')]: {
            //         paddingLeft: 0,
            //         paddingRight: 0,
            //         paddingTop: 0,
            //         paddingBottom: 0,
            //     },
            // },
            deepthought: {
                color: DeepThoughtColor
            }
        })
    }
)

function DeckView() {
    useDecksFetch();
    const classes = useClasses();
    const history = useHistory();
    const { path } = useRouteMatch();
    const vaultId = useVaultIdParam();
    const decks = useVaultDecksAsArray();
    const deck = useDeck();
    const dialogDispatch = useDialogDispatchContext();
    const deckDispatch = useDeckDispatchContext();
    // const navDispatch = useNavDispatchContext();

    // useEffect(() => {
    //     navDispatch({
    //         type: NAVCONTEXT_SET_LOCATION,
    //         location: LOCATION_DECKS_DECK_VIEW
    //     });

    //     return () => {
    //         navDispatch({
    //             type: NAVCONTEXT_CLEAR_LOCATION
    //         });
    //     }
    // }, []);

    const goToDeck = (deckId?: number) => {
        if (deckId && vaultId) {
            const deckPath = generatePath(path, { deckId, vaultId });
            history.push(deckPath);
        }
    }

    const openEditDeckDialog = (deck: IDeckApiModel) => {
        dialogDispatch(openDialog(EDIT_DECK_DIALOG_ID));
        deckDispatch({
            type: DECKS_SET_DECK_UNDER_EDIT,
            deckId: deck.id
        })
    }

    const openDeleteDeckDialog = (deck: IDeckApiModel) => {
        dialogDispatch(openDialog(DELETE_DECK_DIALOG_ID));
        deckDispatch({
            type: DECKS_SET_DECK_UNDER_DELETE,
            deckId: deck.id
        })
    }

    return (
        <>
            <div style={{height: '100%', display: 'grid', gridTemplateRows: '1fr', gridTemplateColumns: '300px 1fr'}}>
                <div style={{padding: '10px', marginLeft: 'auto', marginRight: 'auto', height: '100%', overflowY: 'auto'}}>
                    <Pane>
                        <DecksList decks={decks} goToDeck={goToDeck} />
                    </Pane>
                </div>
                <div style={{padding: '10px', height: '100%'}}>
                    <Panel>
                        { deck && <PanelHeader
                            header={`${deck.title}`}
                            subheader={`${deck.description}`}
                            headerVariant="h6"
                            Icon={() => <DeckIcon className={classes.deepthought} style={{width: '2em', height: '2em'}} />}
                            actions={[{
                                label: 'Edit',
                                action: () => openEditDeckDialog(deck)
                            }, {
                                label: 'Delete',
                                action: () => openDeleteDeckDialog(deck)
                            }, {
                                label: 'Add New Card',
                                // action: () => openDeleteProjectDialog(deck)
                                action: () => {}
                            }]}
                        >
                            { JSON.stringify(decks) }
                            <p>This is the deck view { JSON.stringify(deck) }</p>
                        </PanelHeader> }
                        { deck === null && <Typography variant="h3">
                            Unable to find request Deck
                        </Typography> }
                    </Panel>
                </div>
            </div>
            {/* <ConsoleFrame>
                <div className={classes.root}>
                    <Grid container xs style={{ height: '100%' }}>
                        <Grid item md={2} style={{ overflowY: 'auto', height: '100%' }}>
                        </Grid>
                        <Grid item md={10}>
                        </Grid>
                    </Grid>
                </div>
            </ConsoleFrame> */}
        </>
    )
}

export default DeckView;
