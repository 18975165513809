import { useVaultStateContext } from '../../VaultContextProvider';


function useVaultUnderDelete() {
    const vaultStateContext = useVaultStateContext();
    const vaultUnderDeleteId = vaultStateContext.vaults.selectedToDelete;

    if (!vaultUnderDeleteId) {
        return null;
    }

    const vault = vaultStateContext?.vaults?.byId[vaultUnderDeleteId];

    if (!vault) {
        return null
    }

    return vault;
}

export default useVaultUnderDelete;
