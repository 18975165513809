import { AppState, VaultViewMode, FetchEvent } from './initial-app-state';
import { Vault } from '../../../models/entities/vault.entity';
import { Section } from '../../../models/entities/section.entity';
import { Group } from '../../../models/entities/group.entity';
import { Resource } from '../../../models/entities/resource.entity';
import { Playlist } from '../../../models/entities/playlist.entity';
import { Deck } from '../../../models/entities/deck.entity';
import { Video } from '../../../models/entities/video.entity';
import { Card } from '../../../models/entities/card.entity';

export class AppStore {
    private state: AppState;
    private setState: React.Dispatch<React.SetStateAction<AppState>>;

    constructor(state: AppState, setState: React.Dispatch<React.SetStateAction<AppState>>) {
        this.state = state;
        this.setState = setState;
    }

    GetState(): AppState {
        return this.state;
    }

    SetCurrentVault(vault: Vault) {
        this.setState(currentState => ({
            ...currentState,
            vault
        }))
    }

    ResetCurrentVault() {
        this.setState(currentState => ({
            ...currentState,
            vault: null
        }))
    }

    GetCurrentVault(): Vault | null {
        return this.state?.vault || null;
    }

    SetCurrentSection(section: Section) {
        this.setState(currentState => ({
            ...currentState,
            section
        }))
    }

    ResetCurrentSection() {
        this.setState(currentState => ({
            ...currentState,
            section: null
        }))
    }

    GetCurrentDeck() {
        return this.state?.deck || null;
    }

    SetCurrentDeck(deck: Deck) {
        this.setState(currentState => ({
            ...currentState,
            deck
        }))
    }

    ResetCurrentDeck() {
        this.setState(currentState => ({
            ...currentState,
            deck: null
        }))
    }

    SetCurrentCard(card: Card) {
        this.setState(currentState => ({
            ...currentState,
            card
        }))
    }

    ResetCurrentCard() {
        this.setState(currentState => ({
            ...currentState,
            card: null
        }))
    }

    GetCurrentCard() {
        return this.state?.card || null;
    }

    GetCurrentSection() {
        return this.state?.section || null;
    }

    SetCurrentPlaylist(playlist: Playlist) {
        this.setState(currentState => ({
            ...currentState,
            playlist
        }))
    }

    ResetCurrentPlaylist() {
        this.setState(currentState => ({
            ...currentState,
            playlist: null
        }))
    }

    GetCurrentPlaylist() {
        return this.state?.playlist || null;
    }

    SetCurrentVideo(video: Video) {
        this.setState(currentState => ({
            ...currentState,
            video
        }))
    }

    ResetCurrentVideo() {
        this.setState(currentState => ({
            ...currentState,
            video: null
        }))
    }

    GetCurrentVideo() {
        return this.state?.video || null;
    }

    SetVaults(vaults: Vault[]) {
        this.setState(currentState => ({
            ...currentState,
            userVaults: vaults
        }))
    }

    GetUserVaults(): Vault[] {
        return this.state.userVaults;
    }

    SetSections(sections: Section[]) {
        this.setState(currentState => ({
            ...currentState,
            userSections: sections
        }))
    }

    GetSections(): Section[] {
        return this.state.userSections;
    }

    SetPlaylists(playlists: Playlist[]) {
        this.setState(currentState => ({
            ...currentState,
            userPlaylists: playlists
        }))
    }

    GetPlaylists(): Playlist[] {
        return this.state.userPlaylists;
    }

    SetVideos(videos: Video[]) {
        this.setState(currentState => ({
            ...currentState,
            userVideos: videos
        }))
    }

    GetUserVideos(): Video[] {
        return this.state.userVideos;
    }

    SetUserCards(cards: Card[]) {
        this.setState(currentState => ({
            ...currentState,
            userCards: cards
        }))
    }

    GetUserCards(): Card[] {
        return this.state.userCards;
    }

    SetDecks(decks: Deck[]) {
        this.setState(currentState => ({
            ...currentState,
            userDecks: decks
        }))
    }

    GetDecks(): Deck[] {
        return this.state.userDecks;
    }

    SetVaultView(mode: VaultViewMode) {
        this.setState(currentState => ({
            ...currentState,
            currentVaultView: mode
        }))
    }

    GetVaultView() {
        return this.state.currentVaultView;
    }

    SetGroups(groups: Group[]) {
        this.setState(currentState => ({
            ...currentState,
            userGroups: groups
        }))
    }

    GetUserGroups() {
        return this.state.userGroups;
    }

    SetCurrentGroup(group: Group) {
        this.setState(currentState => ({
            ...currentState,
            group
        }))
    }

    ResetCurrentGroup() {
        this.setState(currentState => ({
            ...currentState,
            group: null
        }))
    }

    GetCurrentGroup() {
        return this.state?.group || null;
    }

    SetCurrentResource(resource: Resource) {
        this.setState(currentState => ({
            ...currentState,
            resource
        }))
    }

    ResetCurrentResource() {
        this.setState(currentState => ({
            ...currentState,
            resource: null
        }))
    }

    GetCurrentResource() {
        return this.state?.resource;
    }

    SetUserResources(resources: Resource[]) {
        this.setState(currentState => ({
            ...currentState,
            userResources: resources
        }))
    }

    GetUserResources() {
        return this.state.userResources;
    }

    AddFetchingEvent(key: string, label: string) {
        const event: FetchEvent = {
            key,
            label
        }

        this.setState(currentState => ({
            ...currentState,
            fetchingEvents: [
                ...currentState.fetchingEvents,
                event
            ]
        }))
    }

    RemoveFetchingEvent(key: string) {
        this.setState(currentState => {
            const events = currentState.fetchingEvents.filter(e => e.key !== key);
            return {
                ...currentState,
                fetchingEvents: events
            }
        })
    }

    TryGetFetchingEvent(key: string) {
        return this.state.fetchingEvents.find(f => f.key === key);
    }

    GetAllFetchingEvents() {
        return this.state.fetchingEvents;
    }
}