import styled from 'styled-components';
import useGroupsAsDictionary from "@app/Areas/Optimus/State/DataStores/ResourceStore/hooks/useGroupsAsDictionary";
import useSectionGroupsFetch from "@app/Areas/Optimus/State/DataStores/ResourceStore/hooks/useSectionGroupsFetch";
import useVaultSectionsAsDictionary from "@app/Areas/Optimus/State/DataStores/ResourceStore/hooks/useVaultSectionsAsDictionary";
import { Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Assigner } from "./assigner";
import { ResourceAssignmentModel } from "./assigner/ResourceAssignmentModel";
import { ResourceEditor } from "./ResourceEditor";
import Verifier from './verifier';
import { ResourceEditorModel } from './ResourceEditorModel';
import useSectionsFetch from '@app/Areas/Optimus/State/DataStores/ResourceStore/hooks/useSectionsFetch';
import { IGroupApiModel, IResourceApiModel, IResourceCreationRequest, ISectionApiModel, ResourceCreationAssignment } from '@app/Areas/Optimus/ApiClient/BookmarksClient';

const VerifierWrapper = styled.div`
    padding: 20px 0;
`;

function mapCreationDetailsToResource(creationDetails: IResourceCreationRequest): ResourceEditorModel {
    return {
        id: '',
        title: creationDetails.title,
        classification: creationDetails.classification || '',
        url: creationDetails.url,
        description: creationDetails.description || '',
        thumbnail: creationDetails.thumbnail || '',
        tags: creationDetails.tags,
        additionalGraph: creationDetails.additionalGraph || {},
        siteName: creationDetails.siteName || '',
        author: creationDetails.author || '',
        publicationDate: creationDetails.publicationDate || new Date(),
        isRecommended: creationDetails.isRecommended,
        isPublic: creationDetails.isPublic,
    }
}

export interface NewResourceFormProps {
    vaultId: number | null;
    newResource:IResourceCreationRequest;
    setNewResource: React.Dispatch<React.SetStateAction<IResourceCreationRequest>>;
    assignments: ResourceAssignmentModel[];
    setAssignments: (value: React.SetStateAction<ResourceAssignmentModel[]>) => void;
    createdResource: IResourceApiModel | null;
    setCreatedResource: React.Dispatch<React.SetStateAction<IResourceApiModel | null>>;
    blankResource: IResourceCreationRequest;
    isSaving: boolean;
    presetUrlToVerify: string | null;
}

function NewResourceForm({
    vaultId,
    newResource,
    setNewResource,
    assignments,
    setAssignments,
    createdResource,
    setCreatedResource,
    blankResource,
    presetUrlToVerify,
    isSaving,
}: NewResourceFormProps) {
    const [selectedSection, setSelectedSection] = useState<number | null>(null);
    const [selectedGroup, setSelectedGroup] = useState<number | null>(null);    

    const sections = useVaultSectionsAsDictionary(vaultId);
    const groups = useGroupsAsDictionary(selectedSection, vaultId);

    useSectionsFetch(vaultId);
    useSectionGroupsFetch(selectedSection, vaultId);

    const serialisedAssignments = JSON.stringify(assignments);
    useEffect(() => {
        setNewResource({
            ...newResource,
            assignments: assignments.map(a => new ResourceCreationAssignment(a.assignment))
        })
    }, [serialisedAssignments]);

    useEffect(() => {
        setSelectedGroup(null);
    }, [selectedSection])

    function selectSection(event: React.ChangeEvent<{ value: unknown }>) {
        const section = sections[event.target.value as number];
        if (section && section.id) {
            setSelectedSection(section.id);
        }
    }

    function selectGroup(event: React.ChangeEvent<{ value: unknown }>) {
        const group = groups[event.target.value as number];
        if (group && group.id) {
            setSelectedGroup(group.id);
        }
    }

    function resourceEdited(event: any) {
        setNewResource({
            ...newResource,
            [event.target.name]: event.target.value
        })
    }

    function resetResource(event: any) {
        setAssignments([]);
        setCreatedResource(null);
        setNewResource(blankResource);
    }

    function addAssignment(section: ISectionApiModel, group: IGroupApiModel) {
        if (section.id && group.id) {
            const assignmentExists = assignments.some((a) =>
                a.assignment.grandParentId === section.id
                && a.assignment.parentId === group.id
            );

            if (!assignmentExists) {
                const newAssignment: ResourceAssignmentModel = {
                    assignment: {
                        grandParentId: section.id,
                        parentId: group.id
                    },
                    label: `${section.name}/${group.name}`
                }
                setAssignments([
                    ...assignments,
                    newAssignment
                ])
            }
        }
    }

    function removeAssignment(assignmentIndex: number) {
        const updatedAssignments = assignments;
        for(let i = 0; i < updatedAssignments.length; i++) {
            if (i === assignmentIndex) {
                updatedAssignments.splice(i, 1);
            }
        }
        setAssignments([...updatedAssignments]);
    }

    return <div>
    { createdResource && <>
        { isSaving && 'is saving' }
        <Button onClick={resetResource} color="primary" variant="contained">Add New Resource</Button>
        { JSON.stringify(createdResource) }
    </> }
    <VerifierWrapper>
        <Verifier
            vaultId={vaultId}
            presetUrlToVerify={presetUrlToVerify}
            newResource={newResource}
            setNewResource={setNewResource}
        />
    </VerifierWrapper>
    <ResourceEditor
        resource={mapCreationDetailsToResource(newResource)}
        handleResourceChange={resourceEdited}
        handleReset={resetResource}
        isSaving={false}
    >
        <Typography variant="h5" gutterBottom>Groups</Typography>
        <Assigner
            sections={sections}
            groups={groups}
            assignments={assignments}
            addAssignment={addAssignment}
            removeAssignment={removeAssignment}
            selectedSection={selectedSection}
            selectSection={selectSection}
            selectedGroup={selectedGroup}
            selectGroup={selectGroup}
        />
    </ResourceEditor>
</div>
}

export default NewResourceForm;
