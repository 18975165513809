import { Redirect, Route, Switch } from "react-router-dom";
import useNavigatorRedirects from "./hooks/useNavigatorRedirects";
import useNavigatorRoutes from "./hooks/useNavigatorRoutes";
import useVaultsLandingRedirect from "./hooks/useVaultsLandingRedirect";

function Navigator() {
    useVaultsLandingRedirect();

    const routes = useNavigatorRoutes();
    const redirects = useNavigatorRedirects();

    return (
        <Switch>
            { routes.map(({ path, component: RouteComponent }) => (
                <Route path={path}>
                    <RouteComponent />
                </Route>
            ))}
            { redirects.map(({ path, redirectPath }) => (
                <Route path={`${path}`}>
                    <Redirect to={redirectPath} />
                </Route>
            ))}
        </Switch>
    )
}

export default Navigator;