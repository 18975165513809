import { Dispatch } from "react";
import createSectionService from '../../../../Services/SectionService/SectionService';
import createGroupService from '@app/Areas/Optimus/Services/GroupService';
import createResourceService from '../../../../Services/ResourceService/ResourceService';
import { ISectionApiModel, IGroupApiModel, IResourceApiModel } from "@app/Areas/Optimus/ApiClient/BookmarksClient";

export const RESOURCES_SET_SECTIONS = 'RESOURCES_SET_SECTIONS';
export const SECTIONS_RESET = 'SECTIONS_RESET';
export const RESOURCES_SET_SECTION_UNDER_EDIT = 'RESOURCES_SET_SECTION_UNDER_EDIT';
export const RESOURCES_CLEAR_SECTION_UNDER_EDIT = 'RESOURCES_CLEAR_SECTION_UNDER_EDIT';
export const RESOURCES_SET_SECTION_UNDER_DELETE = 'RESOURCES_SET_SECTION_UNDER_DELETE';
export const RESOURCES_CLEAR_SECTION_UNDER_DELETE = 'RESOURCES_CLEAR_SECTION_UNDER_DELETE';

export const RESOURCES_SET_GROUPS = 'RESOURCES_SET_GROUPS';
export const GROUPS_RESET = 'GROUPS_RESET';
export const RESOURCES_SET_GROUP_UNDER_EDIT = 'RESOURCES_SET_GROUP_UNDER_EDIT';
export const RESOURCES_CLEAR_GROUP_UNDER_EDIT = 'RESOURCES_CLEAR_GROUP_UNDER_EDIT';
export const RESOURCES_SET_GROUP_UNDER_DELETE = 'RESOURCES_SET_GROUP_UNDER_DELETE';
export const RESOURCES_CLEAR_GROUP_UNDER_DELETE = 'RESOURCES_CLEAR_GROUP_UNDER_DELETE';


export const RESOURCES_RESET = 'RESOURCES_RESET';
export const RESOURCES_SET_RESOURCES = 'RESOURCES_SET_RESOURCES';

export const RESOURCES_SET_RESOURCE_UNDER_EDIT = 'RESOURCES_SET_RESOURCE_UNDER_EDIT';
export const RESOURCES_CLEAR_RESOURCE_UNDER_EDIT = 'RESOURCES_CLEAR_RESOURCE_UNDER_EDIT';
export const RESOURCES_SET_RESOURCE_UNDER_DELETE = 'RESOURCES_SET_RESOURCE_UNDER_DELETE';
export const RESOURCES_CLEAR_RESOURCE_UNDER_DELETE = 'RESOURCES_CLEAR_RESOURCE_UNDER_DELETE';

export interface IResourcesContextModel {
    sections: {
        byId: {
            [sectionId: string]: {
                section: ISectionApiModel;
                parentVaultId: number;
                groupIds: number[];
            }
        },
        allIds: number[];
        selected: string | null;
        selectedToEdit: string | null;
        selectedToDelete: string | null;
    },
    groups: {
        byId: {
            [groupId: string]: {
                group: IGroupApiModel;
                parentVaultId: number;
                parentSectionId: number;
                resources: string[];
            }
        },
        allIds: string[];
        selected: string | null;
        selectedToEdit: string | null;
        selectedToDelete: string | null;
    },
    resources: {
        byId: {
            [resourceId: string]: {
                resource: IResourceApiModel;
                parents: {
                    parentVaultId: number;
                    parentSectionId: number;
                    parentGroupId: number;
                } []
            }
        },
        allIds: string[];
        selected: string | null;
        selectedToEdit: string | null;
        selectedToDelete: string | null;
    }
}

/**
 * The reducer for managing Section State
 * @param state
 * @param action
 */
function ResourceReducer(state: IResourcesContextModel, action: any): IResourcesContextModel {
    switch (action.type) {
        case RESOURCES_SET_SECTIONS:
            return {
                ...state,
                sections: { // TODO: Fix nuking of sections when assigning sections and groups
                    ...state.sections,
                    byId: {
                        ...action.sections.reduce((newSections: {
                            [sectionId: string]: {
                                section: ISectionApiModel;
                                parentVaultId: string;
                                groupIds: string[];
                            }
                        }, s: ISectionApiModel) => {
                            if (s.id) {
                                return {
                                    ...newSections,
                                    [s.id]: {
                                        section: s,
                                        parentVaultId: action.vaultId,
                                        groupIds: []
                                    }
                                }
                            }
                            return newSections;
                        }, {})
                    },
                    allIds: [
                        // TODO: Merge existing Ids
                        ...action.sections.map((s: ISectionApiModel) => s.id)
                    ],
                }
            }
        case RESOURCES_SET_GROUPS:
            return {
                ...state,
                groups: { // TODO: Fix nuking of sections when assigning sections and groups
                    ...state.groups,
                    byId: {
                        ...action.groups.reduce((newGroups: {
                            [groupId: string]: {
                                group: IGroupApiModel;
                                parentVaultId: number;
                                parentSectionId: number;
                                resourceIds: number[];
                            }
                        }, g: IGroupApiModel) => {
                            if (g.id) {
                                return {
                                    ...newGroups,
                                    [g.id]: {
                                        group: g,
                                        parentVaultId: action.vaultId,
                                        parentSectionId: action.sectionId,
                                        resourceIds: []
                                    }
                                }
                            }
                            return newGroups;
                        }, {})
                    },
                    allIds: [
                        // TODO: Merge existing Ids
                        ...action.groups.map((g: IGroupApiModel) => g.id)
                    ],
                }
            }
        case RESOURCES_SET_RESOURCES:
            return {
                ...state,
                resources: {
                    ...state.resources,
                    byId: {
                        ...action.resources.reduce((newResources: {
                            [resourceId: string]: {
                                resource: IResourceApiModel;
                                parents: {
                                    parentVaultId: number;
                                    parentSectionId: number;
                                    parentGroupId: number;
                                } []
                            }
                        }, r: IResourceApiModel) => {
                            if (r.id) {
                                return {
                                    ...newResources,
                                    [r.id]: {
                                        resource: r,
                                        // TODO: Merge existing parents
                                        parents: [{
                                            parentVaultId: action.vaultId,
                                            parentSectionId: action.sectionId,
                                            parentGroupId: action.groupId,
                                        }]
                                    }
                                }
                            }
                            return newResources;
                        }, {})
                    },
                    allIds: [
                        // TODO: Merge existing Ids
                        ...action.resources.map((r: IResourceApiModel) => r.id)
                    ],
                }
            }
        case SECTIONS_RESET: {
            return state;
            // return {}
        }
        case GROUPS_RESET:
            return state;
        case RESOURCES_RESET:
            return state;
        //     return {
        //         ...state,
        //         sections: {
        //             ...state.sections,
        //             [action.vaultId]: {
        //                 ...state.sections[action.vaultId],
        //                 [action.sectionId]: {
        //                     ...state.sections[action.vaultId][action.sectionId],
        //                     groups: {}
        //                 }
        //             }
        //         }
        //     }
        // }
        case RESOURCES_SET_SECTION_UNDER_EDIT: {
            return {
                ...state,
                sections: {
                    ...state.sections,
                    selectedToEdit: action.sectionId
                }
            }
        }
        case RESOURCES_CLEAR_SECTION_UNDER_EDIT: {
            return {
                ...state,
                sections: {
                    ...state.sections,
                    selectedToEdit: null
                }
            }
        }
        case RESOURCES_SET_RESOURCE_UNDER_EDIT: {
            return {
                ...state,
                resources: {
                    ...state.resources,
                    selectedToEdit: action.resourceId
                }
            }
        }
        case RESOURCES_CLEAR_RESOURCE_UNDER_EDIT: {
            return {
                ...state,
                resources: {
                    ...state.resources,
                    selectedToEdit: null
                }
            }
        }
        case RESOURCES_SET_RESOURCE_UNDER_DELETE: {
            return {
                ...state,
                resources: {
                    ...state.resources,
                    selectedToDelete: action.resourceId
                }
            }
        }
        case RESOURCES_CLEAR_RESOURCE_UNDER_DELETE: {
            return {
                ...state,
                resources: {
                    ...state.resources,
                    selectedToDelete: null
                }
            }
        }
        case RESOURCES_SET_GROUP_UNDER_EDIT: {
            return {
                ...state,
                groups: {
                    ...state.groups,
                    selectedToEdit: action.groupId
                }
            }
        }
        case RESOURCES_CLEAR_GROUP_UNDER_EDIT: {
            return {
                ...state,
                groups: {
                    ...state.groups,
                    selectedToEdit: null
                }
            }
        }
        case RESOURCES_SET_SECTION_UNDER_DELETE: {
            return {
                ...state,
                sections: {
                    ...state.sections,
                    selectedToDelete: action.sectionId
                }
            }
        }
        case RESOURCES_CLEAR_SECTION_UNDER_DELETE: {
            return {
                ...state,
                sections: {
                    ...state.sections,
                    selectedToDelete: null
                }
            }
        }
        case RESOURCES_SET_GROUP_UNDER_DELETE: {
            return {
                ...state,
                groups: {
                    ...state.groups,
                    selectedToDelete: action.groupId
                }
            }
        }
        case RESOURCES_CLEAR_GROUP_UNDER_DELETE: {
            return {
                ...state,
                groups: {
                    ...state.groups,
                    selectedToDelete: null
                }
            }
        }
        default:
            throw new Error();
    }
}

export default ResourceReducer;

/**
 * Action creator for fetching the vault sections.
 */
export function getVaultSections(vaultId: number) {
    return async (dispatch: Dispatch<any>) => {
        const sectionService = createSectionService();
        return sectionService.getSections(vaultId).then((result) => {
            dispatch({
                type: RESOURCES_SET_SECTIONS,
                vaultId: vaultId,
                sections: result,
            })
        });
    }
  }

/**
 * Action creator for fetching the section groups
 */
export function getVaultSectionGroups(
    vaultId: number,
    sectionId: number,
    isCancelled: boolean
) {
    return async (dispatch: Dispatch<any>) => {
        const groupService = createGroupService();
        return groupService.getGroups(vaultId, sectionId)
            .then((result) => {
                if (!isCancelled) {
                    dispatch({
                        type: RESOURCES_SET_GROUPS,
                        vaultId: vaultId,
                        sectionId: sectionId,
                        groups: result
                    })
                }
            })
            .catch((error) => {
                if (!isCancelled) {
                    console.log(`error fetching groups: ${vaultId} - ${sectionId} `, error)
                }
            });
    }
  }

  /**
   * Action creator for fetching group resources
   */
  export function getVaultSectionGroupResources(
    vaultId: number,
    sectionId: number,
    groupId: number,
    isCancelled: boolean
  ) {
    return async (dispatch: Dispatch<any>) => {
        const resourceService = createResourceService();
        return resourceService.getResources(vaultId, sectionId, groupId)
            .then((result) => {
                if(!isCancelled) {
                    dispatch({
                        type: RESOURCES_SET_RESOURCES,
                        vaultId: vaultId,
                        sectionId: sectionId,
                        groupId: groupId,
                        resources: result
                    })
                }
            })
            .catch((error) => {
                if (!isCancelled) {
                    console.log(`error fetching resources: ${vaultId} - ${sectionId} - ${groupId} `, error)
                }
            });
    }
  }