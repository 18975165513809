import createReducerContexts from '../../../../Contexts/createReducerContexts';
import { IVaultsContextModel } from '../VaultReducer/VaultReducer';
import VaultContextReducer from '../VaultReducer';

const initialState: IVaultsContextModel = {
    vaults: {
        byId: {},
        allIds: [],
        selected: null,
        selectedToEdit: null,
        selectedToDelete: null,
        loadingState: 'unloaded'
    }
}

export const {
    StateContext: VaultStateContext,
    DispatchContext: VaultDispatchContext,
    Providers: VaultContextProvider,
    useStateContext: useVaultStateContext,
    useDispatchContext: useVaultDispatchContext,
} = createReducerContexts<IVaultsContextModel>(VaultContextReducer, "vaults", initialState);
