import { IResourceApiModel } from "@app/Areas/Optimus/ApiClient/BookmarksClient";
import { IVideoCreationRequest } from "@app/Areas/Optimus/ApiClient/VideosClient";
import createUrlVerifyService from "@app/Areas/Optimus/Services/UrlVerifyService/UrlVerifyService";
import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { UrlInput } from "@app/components/url-input";
import { Typography } from "@material-ui/core";
import { useCallback, useRef, useState } from "react";

export interface VideoVerifierProps {
    newVideo: IVideoCreationRequest,
    setNewVideo: React.Dispatch<any>;
    reset: () => void;
    playlistId: number;
}

function extractYouTubeVideoId(url: string) {
    const regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
    const match = url.match(regExp);
    return (match && match[1].length==11)? match[1] : '';
}

function mapVerifiedVideo({
        title,
        url,
        classification,
        description,
        thumbnail,
        tags,
        additionalGraph,
        siteName,
        author,
        isPublic,
        publicationDate
    }: IResourceApiModel,
    editorModel: IVideoCreationRequest,
    playlistId: number
) {

    const sourceId = (url && extractYouTubeVideoId(url)) || '';

    const mappedVideo: IVideoCreationRequest = {
        title: title || editorModel.title,
        tags: tags || editorModel.tags,
        publishDate: publicationDate || editorModel.publishDate,
        isPublic: isPublic || editorModel.isPublic,
        source: siteName || editorModel.source,
        sourceId,
        url: url,
        description: description || editorModel.description,
        publisher: author || editorModel.publisher, // TODO: Return publisher for videos
        thumbnailUrl: thumbnail || editorModel.thumbnailUrl,
        assignments: [{
          parentId: playlistId  
        }],
        duration: editorModel.duration // TODO: Introduce duration
    }

    return mappedVideo;
}

function VideoVerifier({
    newVideo,
    setNewVideo,
    playlistId,
    reset
}: VideoVerifierProps) {
    const vaultId = useVaultIdParam();

    const isMounted = useRef(true);
    const [isVerifying, setIsVerifying] = useState(false);
    const [urlToVerify, setUrlToVerify] = useState('');

    const urlVerifyService = createUrlVerifyService();

    const lookupUrl = useCallback(async () => {
            if (!vaultId || isVerifying) {
                return;
            }

            setIsVerifying(true);

            reset();

            const verificationResult = await urlVerifyService.verifyUrl(vaultId, urlToVerify);

            if (verificationResult?.resource) {
                // Video Specific checks
                const video = verificationResult.resource;
                
                if (video.classification?.toLocaleLowerCase().includes('video')) {
                    // Treat resource as a video
                    setNewVideo(mapVerifiedVideo(video, newVideo, playlistId));
                }

                // once the request is sent, update state again
                if (isMounted.current) { // only update if we are still mounted
                    setIsVerifying(false)
                }
            }
        }, [vaultId, urlToVerify, isVerifying]
    );

    function handleUrlChange(event: any) {
        setUrlToVerify(event.target.value);
    }

    return (
        <>
            { !isVerifying &&
                <UrlInput onVerify={() => lookupUrl()} handleInput={handleUrlChange} currentUrl={urlToVerify || ''} />
            }
            {
                isVerifying && <Typography variant="h5" gutterBottom>Verifying Url...</Typography>
            }
        </>
    )
}

export default VideoVerifier;
