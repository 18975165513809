import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { getVaultProjects, PROJECTS_RESET } from '../../ProjectReducer/ProjectReducer';
import { useEffect } from 'react';
import { useProjectDispatchContext } from '../../ProjectContextProvider';

function useProjectsFetch() {
    const vaultId = useVaultIdParam();
    const projectDispatch = useProjectDispatchContext();

    useEffect(() => {
        async function fetchProjects() {
            if (vaultId) {
                projectDispatch(getVaultProjects(vaultId));
            }
        }
        fetchProjects();

        return function cleanup() {
            projectDispatch({ type: PROJECTS_RESET })
        }
    }, [vaultId]);
}

export default useProjectsFetch;