import useSectionIdParam from '../../../../App/NavigationContext/hooks/useSectionIdParam/useSectionIdParam';
import useVaultIdParam from '../../../../App/NavigationContext/hooks/useVaultIdParam/useVaultIdParam';
import { IResourcesContextModel } from '../../ResourceReducer/ResourceReducer';
import { useSectionStateContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider';
import { IGroupApiModel } from '@app/Areas/Optimus/ApiClient/BookmarksClient';

function useSectionGroupsAsArray(): IGroupApiModel[] {
    const vaultId = useVaultIdParam();
    const sectionId = useSectionIdParam();
    const state: IResourcesContextModel = useSectionStateContext();

    if (vaultId === null || sectionId === null) {
        return [];
    }

    const groups =
        Object.values(state.groups.byId)
            .filter((g) => g.parentSectionId === sectionId && g.parentVaultId === vaultId)
            .map(g => g.group);

    return groups;
}

export default useSectionGroupsAsArray;
