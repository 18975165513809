import { IDeckApiModel } from '@app/Areas/Optimus/ApiClient/DecksClient';
import { Dispatch } from 'react';
import createDeckService from '../../../../Services/DeckService/DeckService';

export const DECKS_SET_DECKS = 'DECKS_SET_DECKS';
export const DECKS_DECKS_RESET = 'DECKS_DECKS_RESET';

export const DECKS_DECK_NOT_FOUND = 'DECKS_DECK_NOT_FOUND';
export const DECKS_DECK_RESET = 'DECKS_DECK_RESET';
export const DECKS_CLEAR_EDIT = 'DECKS_CLEAR_EDIT';
export const DECKS_SET_DECK_UNDER_EDIT = 'DECKS_SET_DECK_UNDER_EDIT';
export const DECKS_SET_DECK_UNDER_DELETE = 'DECKS_SET_DECK_UNDER_DELETE';
export const DECKS_CLEAR_DELETE = 'DECKS_CLEAR_DELETE';

export interface IDecksContextModel {
    decks: {
        byId: {
            [deckId: string]: {
                deck: IDeckApiModel;
                parentVaultId: number;
            }
        },
        allIds: number[];
        selected: string | null;
        selectedToEdit: string | null;
        selectedToDelete: string | null;
    }
}

function DeckReducer(state: IDecksContextModel, action: any) {
    switch (action.type) {
        case DECKS_SET_DECKS:
            return {
                ...state,
                decks: {
                    ...state.decks,
                    byId: {
                        ...action.decks.reduce((newDecks: {

                        }, d: IDeckApiModel) => {
                            if (d.id) {
                                return {
                                    ...newDecks,
                                    [d.id]: {
                                        deck: d,
                                        parentVaultId: action.vaultId,
                                        cardIds: []
                                    }
                                }
                            }
                        }, {})
                    },
                    allIds: [
                        // TODO: Merge existing Ids
                        ...action.decks.map((d: IDeckApiModel) => d.id)
                    ]
                }
            }
        case DECKS_SET_DECK_UNDER_EDIT:
            return {
                ...state,
                decks: {
                    ...state.decks,
                    selectedToEdit: action.deckId
                }
            }
        case DECKS_CLEAR_EDIT:
            return {
                ...state,
                decks: {
                    ...state.decks,
                    selectedToEdit: null
                }
            }
        case DECKS_SET_DECK_UNDER_DELETE:
            return {
                ...state,
                decks: {
                    ...state.decks,
                    selectedToDelete: action.deckId
                }
            }
        case DECKS_CLEAR_DELETE:
            return {
                ...state,
                decks: {
                    ...state.decks,
                    selectedToDelete: null
                }
            }
        case DECKS_DECKS_RESET:
            return state;
        //     return {
        //         ...state,
        //         decks: {}
        //     }
        case DECKS_DECK_RESET:
            return state;
            // return {
            //     ...state,
            //     currentDeck: null
            // }
        default:
            throw new Error();
    }
}

export default DeckReducer;

/**
 * Action creator for fetching the vault decks.
 */
export function getVaultDecks(vaultId: number) {
    return async (dispatch: Dispatch<any>) => {
        const deckService = createDeckService();
        return deckService.getDecks(vaultId).then((result) => {
            dispatch({
                type: DECKS_SET_DECKS,
                vaultId: vaultId,
                decks: result,
            })
        });
    }
  }
