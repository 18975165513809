import {
    makeStyles,
    Theme,
    createStyles,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    List,
    Grid,
    Button
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ResourceAssignmentModel } from './ResourceAssignmentModel';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { ISectionApiModel, IGroupApiModel } from '@app/Areas/Optimus/ApiClient/BookmarksClient';

export interface AssignerProps {
    sections: { [key: number]: ISectionApiModel };
    groups: { [key: number]: IGroupApiModel };
    assignments: ResourceAssignmentModel[];
    addAssignment: (section: ISectionApiModel, group: IGroupApiModel) => void;
    removeAssignment: (assignmentIndex: number) => void,
    selectedSection: number | null;
    selectSection: (event: any) => void;
    selectedGroup: number | null,
    selectGroup: (event: any) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid #000',
      padding: 20,
      width: '100%',
      margin: 0
    },
    groups: {
        paddingLeft: 20,
        paddingRight: 20
    }
  }),
);

export function Assigner({
    assignments,
    sections,
    selectedSection,
    selectSection,
    groups,
    selectedGroup,
    selectGroup,
    addAssignment,
    removeAssignment
}: AssignerProps) {
    const classes = useStyles();
    return (
        <>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={3}>
                    <div>
                        <InputLabel id="assigned-section-label">Section</InputLabel>
                        <Select
                            fullWidth
                            labelId="assigned-section-label"
                            id="section"
                            value={selectedSection}
                            onChange={selectSection}
                        >
                            {
                                Object.entries(sections)
                                    .sort(([aKey, a], [bKey, b]) => {

                                        if (!a?.name || !b?.name) {
                                            return 0;
                                        }

                                        if(a.name < b.name) { return -1; }
                                        if(a.name > b.name) { return 1; }

                                        return 0;
                                    })
                                    .map(([key, value]) => (<MenuItem value={key}>{value.name}</MenuItem>))
                            }
                        </Select>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    { selectedSection &&
                        <div>
                            <InputLabel id="assigned-group-label">Group</InputLabel>
                            <Select
                                fullWidth
                                labelId="assigned-group-label"
                                id="group"
                                value={selectedGroup}
                                onChange={selectGroup}
                            >
                                { Object.entries(groups).map(([key, value]) => (<MenuItem value={key}>{value.name}</MenuItem>))}
                            </Select>
                        </div>
                    }
                </Grid>
                <Grid item xs={1}>
                    {
                        selectedSection !== null &&
                        selectedGroup !== null &&
                        <Button onClick={() => addAssignment(
                            sections[selectedSection], groups[selectedGroup]
                        )} color="primary" variant="contained">
                            Add assignment
                        </Button>
                    }
                </Grid>
                <Grid item xs={5}>
                    <List dense={false} className={classes.groups}>
                        {
                            assignments.map((a, i) => (
                                <ListItem>
                                    <ListItemText
                                        primary={a.label}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() => removeAssignment(i)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))
                        }
                    </List>
                </Grid>
            </Grid>
        </>
    )
}