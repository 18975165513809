import React from "react";
import Styles from './Styles';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Optimus from '@optimus/index';
import PrivateRoute from "@app/private-route";

function DebugComponent() {
    return (
        <>
            <div>
                <p>VectorSigma</p>
                <p>{new Date().toLocaleDateString()}</p>
                <Link to="/optimus">Optimus</Link>
            </div>
        </>
    )
}

function App() {
    return <>
        <Styles />
        {/* https://www.sitepoint.com/react-router-complete-guide/ */}
        <BrowserRouter>
            <Route exact path="/">
                <DebugComponent />
            </Route>
            <PrivateRoute path="/optimus">
                <Optimus />
            </PrivateRoute>
        </BrowserRouter>
    </>
}

export default App;
