import React, { useState } from 'react';
import { Button, Grid, FormControlLabel, Checkbox, Chip, makeStyles, Theme, createStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel/InputLabel';
import CardMedia from '@material-ui/core/CardMedia';
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ResourceEditorModel } from './ResourceEditorModel';

export interface ResourceEditorProps {
    resource: ResourceEditorModel;
    handleResourceChange: (event: any) => void;
    handleReset: (event: any) => void;
    isSaving: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tags: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    input: {
        marginBottom: 20
    }
  }),
);

export const ResourceEditor = ({
    isSaving,
    handleResourceChange,
    handleReset,
    resource,
    children
}: React.PropsWithChildren<ResourceEditorProps>) => {
    const classes = useStyles();

    const [newTag, setNewTag] = useState();

    function removeTag(index: number) {

        const updatedTags = [
            ...resource.tags
        ];

        for(let i = 0; i < updatedTags.length; i++) {
            if (i === index) {
                updatedTags.splice(i, 1);
            }
        }

        const pseudoEvent = {
            target: {
                name: 'tags',
                value: updatedTags
            }
        }

        handleResourceChange(pseudoEvent);
    }

    function addTag() {
        const updatedTags: (string | undefined)[] = [
            ...resource.tags,
            newTag
        ];

        const pseudoEvent = {
            target: {
                name: 'tags',
                value: updatedTags
            }
        }

        handleResourceChange(pseudoEvent);
    }

    return (
        <>
            {!isSaving &&
                <>
                    <Typography variant="h5" gutterBottom>Resource: { resource.title }</Typography>
                    <Typography gutterBottom>{ resource.url }</Typography>
                    <form>
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <TextField className={classes.input} fullWidth id="resource-title-input" label="Title" name="title" value={resource.title} onChange={handleResourceChange} />
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel id="resource-classification-input-label">Classification</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="resource-classification-input-label"
                                    id="resource-classification-input"
                                    name="classification"
                                    value={resource.classification}
                                    onChange={handleResourceChange}
                                >
                                    <MenuItem value="article">Article</MenuItem>
                                    <MenuItem value="video">Video</MenuItem>
                                    <MenuItem value="snippet">Snippet</MenuItem>
                                    <MenuItem value="tutorial">Tutorial</MenuItem>
                                    <MenuItem value="answer">Answer</MenuItem>
                                    <MenuItem value="library">Library</MenuItem>
                                    <MenuItem value="resource">Resource</MenuItem>
                                    <MenuItem value="framework">Framework</MenuItem>
                                    <MenuItem value="solution">Solution</MenuItem>
                                    <MenuItem value="tool">Tool</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    className={classes.input}
                                    id="resource-description-input"
                                    name="description"
                                    label="Description"
                                    multiline
                                    fullWidth
                                    rowsMax="7"
                                    value={resource.description}
                                    onChange={handleResourceChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField className={classes.input} fullWidth id="resource-sitename-input" label="Site Name" name="siteName" value={resource.siteName} onChange={handleResourceChange} />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField className={classes.input} fullWidth id="resource-author-input" label="Author" name="author" value={resource.author} onChange={handleResourceChange} />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField className={classes.input} fullWidth id="resource-thumbnail-input" label="Thumbnail" name="thumbnail" value={resource.thumbnail} onChange={handleResourceChange} />
                                { resource.thumbnail &&
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={resource.thumbnail}
                                    />
                                }
                            </Grid>
                            <Grid item xs={2}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        name="isPublic"
                                        checked={resource.isPublic}
                                        onChange={(event: any) => {
                                            const pseudoEvent = {
                                                target: {
                                                    name: event.target.name,
                                                    value: event.target.checked
                                                }
                                            }
                                            handleResourceChange(pseudoEvent);
                                        }}
                                        value={resource.isPublic}
                                        color="primary"
                                    />
                                    }
                                    label="Public?"
                                />
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        name="isRecommended"
                                        checked={resource.isRecommended}
                                        onChange={(event: any) => {
                                            const pseudoEvent = {
                                                target: {
                                                    name: event.target.name,
                                                    value: event.target.checked
                                                }
                                            }
                                            handleResourceChange(pseudoEvent);
                                        }}
                                        value={resource.isRecommended}
                                        color="primary"
                                    />
                                    }
                                    label="Recommended?"
                                />
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="normal"
                                    id="resource-publishdate-input"
                                    label="Publish Date"
                                    value={resource.publicationDate}
                                    onChange={(date: any) => {
                                        const pseudoEvent = {
                                            target: {
                                                name: "publishDate",
                                                value: date.toJSDate()
                                            }
                                        }
                                        handleResourceChange(pseudoEvent);
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <Grid container spacing={1}>
                                    <Grid item xs={8}>
                                        <TextField
                                            className={classes.input}
                                            id="resource-tag-input"
                                            name="tags"
                                            label="tags"
                                            fullWidth
                                            onChange={(event: any) => setNewTag(event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button onClick={addTag} color="primary" variant="contained">Add Tag</Button> 
                                    </Grid>
                                </Grid>
                                <div className={classes.tags}>
                                    { resource.tags.map((t: string, i: number) => (
                                        <Chip label={t} onDelete={() => removeTag(i)} color="primary" />
                                    ))}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                { children }
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={handleReset} color="primary" variant="contained">Reset</Button> 
                            </Grid>
                        </Grid>
                    </form>
                </>
            }
        </>
    )
}