import React, { PropsWithChildren } from 'react';
import tw, { styled } from 'twin.macro';
import { transparentize } from 'polished';
import { AppBar, Button, createStyles, Dialog, Grid, IconButton, makeStyles, Slide, Theme, Toolbar, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { useOptimusDispatchContext, useOptimusStateContext } from '@optimus/Contexts/OptimusContext';
import Panel from '@optimus/Components/Shared/Panel';
import { NEW_PROJECT_DIALOG_ID } from '../../ActionDialogs';
import { closeDialog, OPTIMUS_CLOSE_DIALOG } from '@app/Areas/Optimus/State/App/DialogContext/DialogReducer/DialogReducer';
import { useDialogDispatchContext } from '@app/Areas/Optimus/State/App/DialogContext/DialogContextProvider';
import useIsOpenDialog from '../../../State/App/DialogContext/hooks/useIsOpenDialog/useIsOpenDialog';

const DialogContent = styled.div`
    ${tw`p-3`}
    ${props => props.theme.breakpoints.up("md")} {
        ${tw`p-8`}
    }
    margin-left: auto;
    margin-right: auto;
`;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        background: 'rgba(0,0,0,0.1)',
        backdropFilter: 'blur(10px)',
    },
    appBar: {
      position: 'relative',
      background: transparentize(0.9, theme.palette.primary.main),
      boxShadow: `0 0px 10px #3cb8dc99`,
      borderBottom: `1px solid #3cb8dc99`,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

export interface NewProjectDialogProps {
    handleSave: () => void;
}

function NewProjectDialog({
    handleSave,
    children
}: PropsWithChildren<NewProjectDialogProps>) {
    const classes = useStyles();
    const state = useOptimusStateContext();
    const dispatch = useDialogDispatchContext();

    const open = useIsOpenDialog(NEW_PROJECT_DIALOG_ID);
    const handleClose = () => {
        dispatch(closeDialog());
    };

    return (
        <Dialog PaperProps={{
            className: classes.root
        }} fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
            <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="subtitle1" className={classes.title}>
                    Add New Project
                </Typography>
                <Button color="secondary" variant="contained" autoFocus onClick={handleSave}>
                    Save new Project
                </Button>
            </Toolbar>
            </AppBar>
            <DialogContent>
                <Panel>
                    <Grid container spacing={1} alignItems="center">
                        { children }
                    </Grid>
                </Panel>
            </DialogContent>
      </Dialog>
    )
}

export default NewProjectDialog;
