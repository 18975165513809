import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNetworkRequestWithRefresh } from '@app/Areas/Optimus/Hooks/useNetworkRequest';
import { OtherClient, HeartbeatResponse } from '@app/Areas/Optimus/ApiClient/otherClient';

export interface HeartbeatMonitorProps {
    menuId: string;
}

export interface HeatbeatRootProps {
    error: Error | null;
    isLoading: boolean;
    data: HeartbeatResponse | null;
}

const Wrapper = styled.div`
    transition: box-shadow 200ms ease-in;
    box-shadow: ${({ isLoading, error }: HeatbeatRootProps) => {
        return isLoading
            ? '0 2px 10px #00f5ec'
            : error
                ? '0 2px 10px red'
                : '0 2px 10px green';
    }};
`;

function HeartbeatMonitor({
    menuId,
    children
}: React.PropsWithChildren<HeartbeatMonitorProps>) {
    const [error, isLoading, data, refresh] = useNetworkRequestWithRefresh<any>(() => {
        const client = new OtherClient();
        return client.heartbeat()
    }, []);

    useEffect(() => {
        if (error) {
            console.log(error);
        }
    }, [error]);

    useEffect(() => {
        if (!isLoading) {
            setTimeout(() => {
                refresh();
            }, 10000)
        }
    }, [isLoading]);

    return (
        <Wrapper
            error={error}
            isLoading={isLoading}
            data={data}
        >
            {children}
        </Wrapper>
    )
}

export default HeartbeatMonitor;
