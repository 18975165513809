import { ITaskLifecycleApiModel } from "@app/Areas/Optimus/ApiClient/TaskboardsClient";
import { Chip, createStyles, makeStyles, Theme } from "@material-ui/core";

export interface TaskLifecycleBadgeProps {
    lifecycle: ITaskLifecycleApiModel;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badge: {
        fontSize: '0.75em',
        padding: '0.5em'
    },
  }),
);

function TaskLifecycleBadge({
    lifecycle
}: TaskLifecycleBadgeProps) {
    const classes = useStyles();
    const { lifecycleId } = lifecycle;

    if (lifecycleId == undefined) {
        return null;
    }

    const label = mapBadgeToLabel(lifecycleId);
    const backgroundColor = mapBadgeToColor(lifecycleId);

    return <Chip className={classes.badge} label={label} style={{ backgroundColor: backgroundColor }} size="small" />;
}

function mapBadgeToLabel(lifecycleId: number) {
    switch (lifecycleId) {
        case 0:
            return '?';
        case 1:
            return 'Bcklg';
        case 2:
            return 'Rdy';
        case 3:
            return 'Prgrss';
        case 4:
            return 'Test';
        case 5:
            return 'Rviw';
        case 6:
            return 'Apprvl';
        case 7:
            return 'Done';
        case 8:
            return 'Blckd';
    }

    return null;
}

function mapBadgeToColor(lifecycleId: number) {
    switch (lifecycleId) {
        case 0:
            return '#4e4e4e';
        case 1:
            return '#256b8a';
        case 2:
            return '#8a5225';
        case 3:
            return '#867900';
        case 4:
            return '#b72bbc';
        case 5:
            return '#2b83bc';
        case 6:
            return '#be1877';
        case 7:
            return '#2a760e';
        case 8:
            return '#ac0000';
    }

    return '';
}

export default TaskLifecycleBadge;
