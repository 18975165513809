import { useEffect } from "react";
import { PROJECTS_CLEAR_DELETE } from '../../ProjectReducer/ProjectReducer';
import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useProjectDispatchContext, useProjectStateContext } from "../../ProjectContextProvider";

function useProjectUnderDelete() {
    const vaultId = useVaultIdParam();
    const projectsDispatch = useProjectDispatchContext();
    const projectStateContext = useProjectStateContext();
    const projectUnderDeleteId = projectStateContext.projects.selectedToDelete;

    useEffect(() => {
        return function cleanup() {
            projectsDispatch({ type: PROJECTS_CLEAR_DELETE })
        }
    }, [vaultId])

    if (!projectUnderDeleteId) {
        return null;
    }

    const project = projectStateContext?.projects?.byId[projectUnderDeleteId];

    if (!project || project.parentVaultId !== vaultId)
    {
        return null;
    }

    return project;
}

export default useProjectUnderDelete;