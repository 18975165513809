import { useCallback, useRef, useState } from 'react';
import ConfirmationDialog from '../../../Components/Shared/ConfirmationDialog/ConfirmationDialog';
import useDeckUnderDelete from '../../../State/DataStores/DeckStore/hooks/useDeckUnderDelete/index';
import { DELETE_DECK_DIALOG_ID } from '../../ActionDialogs';
import { DECKS_CLEAR_DELETE, getVaultDecks } from '../../../State/DataStores/DeckStore/DeckReducer/DeckReducer';
import createDeckService from '../../../Services/DeckService/DeckService';
import { generatePath, useHistory } from 'react-router-dom';
import useVaultsAsDictionary from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useVaultsAsDictionary';
import { useDeckDispatchContext } from '@app/Areas/Optimus/State/DataStores/DeckStore/DeckContextProvider';

function DeleteDeck() {
    const parsedDeckToDelete = useDeckUnderDelete();
    const vaults = useVaultsAsDictionary();
    const [isSending, setIsSending] = useState(false);
    const currentParentVaultId = parsedDeckToDelete?.parentVaultId;
    const currentParentVault = currentParentVaultId ? vaults[currentParentVaultId] : null;
    const deckService = createDeckService();
    const deckDispatch = useDeckDispatchContext();
    const isMounted = useRef(true);
    const history = useHistory();

    const sendRequest = useCallback(async (callback) => {
        if (currentParentVaultId && parsedDeckToDelete?.deck.id) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await deckService.archiveDeck(currentParentVaultId, parsedDeckToDelete.deck.id);
            if (result) {
                deckDispatch(getVaultDecks(currentParentVaultId));
                goToDecks();
                callback();
            }
            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [currentParentVault, parsedDeckToDelete, isSending]
    ); // update the callback if the state changes

    const goToDecks = () => {
        if (currentParentVaultId) {
            const decksPath = generatePath('/optimus/vaults/:vaultId/deepthought/decks', { vaultId: currentParentVaultId});
            history.push(decksPath);
        }
    }

    const handleConfirm = (callback: () => void) => {
        if (!isSending) {
            sendRequest(callback);
        }
    }

    const cleanUp = () => {
        deckDispatch({
            type: DECKS_CLEAR_DELETE
        })
    }

    return currentParentVault && parsedDeckToDelete && (
        <ConfirmationDialog
            title="Delete Deck"
            description={`Are you sure you want to delete the deck '${parsedDeckToDelete?.deck.title}' and all of its cards?`}
            dialogId={DELETE_DECK_DIALOG_ID}
            onConfirm={handleConfirm}
            onClose={cleanUp}
        />
    ) || null;
}

export default DeleteDeck;
