import { useEffect } from 'react';
import { useSectionDispatchContext, useSectionStateContext } from '../../ResourceContextProvider';
import useVaultIdParam from '../../../../App/NavigationContext/hooks/useVaultIdParam/useVaultIdParam';
import useSectionIdParam from '../../../../App/NavigationContext/hooks/useSectionIdParam/useSectionIdParam';
import { RESOURCES_CLEAR_GROUP_UNDER_EDIT } from '../../ResourceReducer/ResourceReducer';

function useGroupUnderEdit() {
    const vaultId = useVaultIdParam();
    const sectionId = useSectionIdParam();

    const groupDispatch = useSectionDispatchContext();
    const groupStateContext = useSectionStateContext();
    const groupUnderEditId = groupStateContext.groups.selectedToEdit;

    useEffect(() => {
        return function cleanup() {
            groupDispatch({ type: RESOURCES_CLEAR_GROUP_UNDER_EDIT })
        }
    }, [vaultId, sectionId]);

    if (!vaultId || !sectionId || !groupUnderEditId) {
        return null;
    }

    const group = groupStateContext?.groups?.byId[groupUnderEditId];

    if (!group || group.parentSectionId !== sectionId || group.parentVaultId !== vaultId) {
        return null;
    }

    return group;
}

export default useGroupUnderEdit;


