import { useEffect } from "react";
import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { useTaskBoardStateContext, useTaskBoardDispatchContext } from "@app/Areas/Optimus/Apps/TaskBoardApp";
import { BOARDS_CLEAR_BOARD_UNDER_EDIT } from "../../../state/TaskBoardReducer/TaskBoardReducer";
import useBoardIdParam from "../useBoardIdParam";

function useBoardUnderEdit() {
    const vaultId = useVaultIdParam();
    const boardId = useBoardIdParam();

    const boardStateContext = useTaskBoardStateContext();
    const boarUnderEdit = boardStateContext.boards.selectedToEdit;
    const boardDispatch = useTaskBoardDispatchContext();

    useEffect(() => {
        return function cleanup() {
            boardDispatch({
                type: BOARDS_CLEAR_BOARD_UNDER_EDIT
            })
        }
    }, [vaultId])

    if (!boarUnderEdit) {
        return null;
    }

    const board = boardStateContext?.boards?.byId[boarUnderEdit];

    if (
        !board
        || board.parentVaultId !== vaultId
        || board.board.id !== boardId) {
        return null;
    }

    return board;
}

export default useBoardUnderEdit;
