import { useCallback, useRef, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { PROTECT_BOARD_DIALOG_ID } from '@app/Areas/Optimus/Ecosystems/ActionDialogs';
import createBoardService from '../../../services/BoardsService';
import { BOARDS_CLEAR_BOARD_TO_PROTECT, BOARDS_CLEAR_BOARD_UNDER_ARCHIVE, getVaultBoards } from '../../../state/TaskBoardReducer/TaskBoardReducer';
import { useTaskBoardDispatchContext } from '../../../state/TaskBoardContextProvider/TaskBoardContextProvider';
import useBoardToProtect from '../../../hooks/_Boards/useBoardToProtect';
import { BoardUpdateRequest, IBoardProtectionRequest, IBoardProtectionResult } from '@app/Areas/Optimus/ApiClient/TaskboardsClient';
import EditorDialog from '@app/Areas/Optimus/Components/Shared/EditorDialog';
import ProtectionEditor from './ProtectionEditor';
import { ProtectionEditorModel } from './ProtectionEditorModel';
import useVaultsAsDictionary from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useVaultsAsDictionary';

const blankProtectionBoard: IBoardProtectionRequest = {
    protectBoard: true,
    protectionKeyId: '',
    commitChanges: true
}

function mapDetailsToProtectionModel (
    board: IBoardProtectionRequest
): ProtectionEditorModel {
    return {
        protectionKeyId: board.protectionKeyId
    }
}

function ProtectBoard() {
    const history = useHistory();
    const boardToProtect = useBoardToProtect();    
    const vaults = useVaultsAsDictionary();
    const currentParentVaultId = boardToProtect?.parentVaultId;
    const boardsDispatch = useTaskBoardDispatchContext();
    const [isSending, setIsSending] = useState(false);
    const [boardProtectionRequest, setBoardProtectionRequest] = useState<IBoardProtectionRequest | null>(blankProtectionBoard);
    const [updatedBoard, setUpdatedBoard] = useState<IBoardProtectionResult | null>(null);
    const boardService = createBoardService();
    const isMounted = useRef(true);
    const board = boardToProtect?.board;
    const currentParentVault = currentParentVaultId ? vaults[currentParentVaultId] : null;

    const sendRequest = useCallback(async () => {
        if (currentParentVaultId && board?.id && !!boardProtectionRequest) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await boardService.protectBoard(currentParentVaultId, board.id, boardProtectionRequest);
            if (result) {
                setUpdatedBoard(result);
            }
            boardsDispatch(getVaultBoards(currentParentVaultId));

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [ currentParentVaultId, boardProtectionRequest, isSending]
    ); // update the callback if the state changes

    const goToBoards = () => {
        if (currentParentVaultId) {
            const boardsPath = generatePath('/optimus/vaults/:vaultId/taskboards/boards', { vaultId: currentParentVaultId});
            history.push(boardsPath);
        }
    }

    function boardEdited(event: any) {
        if (boardProtectionRequest !== null) {
            setBoardProtectionRequest({
                ...boardProtectionRequest,
                [event.target.name]: event.target.value
            })
        }
    }

    const cleanUp = () => {
        boardsDispatch({
            type: BOARDS_CLEAR_BOARD_TO_PROTECT
        })
    }
    
    const handleSave = () => {
        if (!updatedBoard && !isSending) {
            sendRequest();
        }
    }

    return currentParentVault && boardToProtect && boardProtectionRequest && (
        <EditorDialog
            title="Protect Board"
            saveTitle="Confirm"
            dialogId={PROTECT_BOARD_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            
            <br/>
            { !updatedBoard && <ProtectionEditor
                board={mapDetailsToProtectionModel(boardProtectionRequest)}
                handleChange={boardEdited}
                title={currentParentVault.title}
                isSaving={isSending}
            >
                <div>
                    This will protect the board '{boardToProtect?.board.name}' and all of it's tasklists and tasks.`
                    <br/>
                    Provide a preset Protection Key and click confirm to protect.
                </div>
            </ProtectionEditor> }            
             { updatedBoard && JSON.stringify(updatedBoard) }
        </EditorDialog>
    ) || null;
}

export default ProtectBoard;
