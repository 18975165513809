import Panel from '@app/Areas/Optimus/Components/Shared/Panel';
import PanelHeader from '@app/Areas/Optimus/Components/Shared/PanelHeader';
import useVaultIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import usePlaylistIdParam from '@app/Areas/Optimus/State/DataStores/VideoStore/hooks/usePlaylistIdParam';
import usePlaylistVideosAsArray from '@app/Areas/Optimus/State/DataStores/VideoStore/hooks/usePlaylistVideosAsArray/usePlaylistVideosAsArray';
import useVideosFetch from '@app/Areas/Optimus/State/DataStores/VideoStore/hooks/useVideosFetch/useVideosFetch';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import NewVideo from '../components/_Videos/NewVideo/NewVideo';
import VideoRouting from '../VideoRouting';

export interface PlaylistViewProps {
    isNewVideoOpen: boolean;
    closeNewVideo: () => void;
}

function PlaylistView({
    isNewVideoOpen,
    closeNewVideo
}: PlaylistViewProps) {
    const { pathname } = useLocation();
    useVideosFetch();

    const vaultId = useVaultIdParam();
    const playlistId = usePlaylistIdParam();

    const videos = usePlaylistVideosAsArray();

    return playlistId && <>
        <Panel>
            { isNewVideoOpen && <NewVideo playlistId={playlistId} close={closeNewVideo} /> }
            {
                videos.map(v => (
                    <Link to={`/optimus/vaults/${vaultId}/playlists/${playlistId}/videos/${v.id}`}><p>{v.title}</p></Link>
                ))
            }
            <VideoRouting />
        </Panel>
    </> || null;
}

export default PlaylistView;