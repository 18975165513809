import { useEffect } from 'react';
import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useVideoDispatchContext } from '../../VideoContextProvider/VideoContextProvider';
import { getVaultPlaylists, PLAYLISTS_RESET } from '../../VideoReducer/VideoReducer';

/**
 * Hooks in the current vault id and fetches the appropriate playlists
 */

function usePlaylistsFetch() {
    const vaultId = useVaultIdParam();
    // Gets the playlist action dispatcher from the react context
    const playlistDispatch = useVideoDispatchContext();

    useEffect(() => {
        // Function to fetch playlists based on vault id
        async function fetchPlaylists() {
            if (vaultId) {
                // Dispatches a thunk to get playlists for a vault
                playlistDispatch(getVaultPlaylists(vaultId));
            }
        }
        fetchPlaylists();

        return function cleanup() {
            playlistDispatch({ type: PLAYLISTS_RESET })
        }
    }, [vaultId]); // Hooks into the current vault Id parameter
}

export default usePlaylistsFetch;