import { useCallback, useEffect, useRef, useState } from 'react';
import useVaultsAsDictionary from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useVaultsAsDictionary';
import useResourceUnderEdit from '../../../State/DataStores/ResourceStore/hooks/useResourceUnderEdit/useResourceUnderEdit';
import useGroupIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useGroupIdParam';
import useSectionIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useSectionIdParam';
import useVaultIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import EditorDialog from '@app/Areas/Optimus/Components/Shared/EditorDialog';
import { EDIT_RESOURCE_DIALOG_ID } from '../../ActionDialogs';
import { ResourceEditor } from '../NewResource/ResourceEditor';
import { ResourceEditorModel } from '../NewResource/ResourceEditorModel';
import { useSectionDispatchContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider/ResourceContextProvider';
import { getVaultSectionGroupResources, RESOURCES_CLEAR_RESOURCE_UNDER_EDIT } from '../../../State/DataStores/ResourceStore/ResourceReducer/ResourceReducer';
import createResourceService from '../../../Services/ResourceService/ResourceService';
import { IResourceUpdateRequest, IResourceApiModel } from '@app/Areas/Optimus/ApiClient/BookmarksClient';

function mapUpdateDetailsToResource(creationDetails: IResourceUpdateRequest): ResourceEditorModel {
    return {
        id: '',
        title: creationDetails.title,
        classification: creationDetails.classification || '',
        url: creationDetails.url,
        description: creationDetails.description || '',
        thumbnail: creationDetails.thumbnail || '',
        tags: creationDetails.tags,
        additionalGraph: creationDetails.additionalGraph || {},
        siteName: creationDetails.siteName || '',
        author: creationDetails.author || '',
        publicationDate: creationDetails.publicationDate || new Date(),
        isRecommended: creationDetails.isRecommended,
        isPublic: creationDetails.isPublic,
    }
}

const blankResource: IResourceUpdateRequest = {
    title: '',
    url: '',
    tags: [],
    publicationDate: new Date(),
    isRecommended: false,
    isPublic: false,
    classification: '',
    description: '',
    thumbnail: '',
    additionalGraph: {},
    siteName: '',
    author: '',
    assignments: [],
}

function EditResource() {
    const vaultId = useVaultIdParam();
    const sectionId = useSectionIdParam();
    const groupId = useGroupIdParam();
    const  resourceService = createResourceService();
    const resourceDispatch = useSectionDispatchContext();
    const isMounted = useRef(true);
    const resourceUnderEdit = useResourceUnderEdit();
    const vaults = useVaultsAsDictionary();
    const [isSending, setIsSending] = useState(false)
    const [resourceUpdateRequest, setResourceUpdateRequest] = useState<IResourceUpdateRequest | null>(blankResource);
    const [updatedResource, setUpdatedResource] = useState<IResourceApiModel | null>(null);
    const currentParent = resourceUnderEdit?.parents.find((p) => p.parentVaultId === vaultId && p.parentSectionId === sectionId && p.parentGroupId === groupId);
    const currentParentVaultId = currentParent?.parentVaultId;
    const currentParentVault = currentParentVaultId ? vaults[currentParentVaultId] : null;
    const resource = resourceUnderEdit?.resource;

    const handleSave = () => {
        if (!updatedResource && !isSending) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        setResourceUpdateRequest(null);
        setUpdatedResource(null);
        resourceDispatch({
            type: RESOURCES_CLEAR_RESOURCE_UNDER_EDIT
        })
    }

    const sendRequest = useCallback(async () => {
        if (currentParentVaultId && resource?.id && !!resourceUpdateRequest && sectionId && groupId) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await resourceService.updateResource(currentParentVaultId, resource.id, resourceUpdateRequest);
            if (result) {
                setUpdatedResource(result);
            }
            resourceDispatch(getVaultSectionGroupResources(currentParentVaultId, sectionId, groupId, false));

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [ currentParentVaultId, resourceUpdateRequest, isSending, sectionId, groupId]
    ); // update the callback if the state changes

    useEffect(() => {
        if (!!resourceUnderEdit?.resource) {
            const { resource } = resourceUnderEdit;

            setResourceUpdateRequest({
                ...resourceUpdateRequest,
                title: resource.title || '',
                classification: resource.classification || '',
                url: resource.url || '',
                description: resource.description || '',
                thumbnail: resource.thumbnail || '',
                tags: resource.tags || [],
                additionalGraph: resource.additionalGraph || {},
                siteName: resource.siteName || '',
                author: resource.author || '',
                publicationDate: resource.publicationDate || new Date(),
                isRecommended: resource.isRecommended || false,
                isPublic: resource.isPublic || false,
                assignments: [],
            });
        }
    }, [resourceUnderEdit]);

    function resourceEdited(event: any) {
        if (resourceUpdateRequest !== null) {
            setResourceUpdateRequest({
                ...resourceUpdateRequest,
                [event.target.name]: event.target.value
            })
        }
    }

    function resetResource(event: any) {
        setUpdatedResource(null);
        setResourceUpdateRequest(blankResource);
    }

    return currentParentVault && resourceUpdateRequest && (
        <>
            <EditorDialog
                title="Edit Resource"
                saveTitle="Update Resource"
                dialogId={EDIT_RESOURCE_DIALOG_ID}
                handleSave={handleSave}
                onClose={cleanUp}
            >
                { !updatedResource && <ResourceEditor
                resource={mapUpdateDetailsToResource(resourceUpdateRequest)}
                handleResourceChange={resourceEdited}
                handleReset={resetResource}
                isSaving={isSending}
            >
            </ResourceEditor> }
            { updatedResource && JSON.stringify(updatedResource) }
            </EditorDialog>
        </>
    ) || null;
}

export default EditResource;
