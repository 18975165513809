export { default } from './ActionDialogs';

export const NEW_VAULT_DIALOG_ID = 'NEW_VAULT_DIALOG_ID';
export const EDIT_VAULT_DIALOG_ID = 'EDIT_VAULT_DIALOG_ID';
export const DELETE_VAULT_DIALOG_ID = 'DELETE_VAULT_DIALOG_ID';

export const NEW_PROJECT_DIALOG_ID = 'NEW_PROJECT_DIALOG';
export const EDIT_PROJECT_DIALOG_ID = 'EDIT_PROJECT_DIALOG';
export const DELETE_PROJECT_DIALOG_ID = 'DELETE_PROJECT_DIALOG_ID';

export const NEW_DECK_DIALOG_ID = 'NEW_DECK_DIALOG_ID';
export const EDIT_DECK_DIALOG_ID = 'EDIT_DECK_DIALOG_ID';
export const DELETE_DECK_DIALOG_ID = 'DELETE_DECK_DIALOG_ID';

export const NEW_SECTION_DIALOG_ID = 'NEW_SECTION_DIALOG_ID';
export const EDIT_SECTION_DIALOG_ID = 'EDIT_SECTION_DIALOG_ID';
export const DELETE_SECTION_DIALOG_ID = 'DELETE_SECTION_DIALOG_ID';

export const NEW_GROUP_DIALOG_ID = 'NEW_GROUP_DIALOG_ID';
export const EDIT_GROUP_DIALOG_ID = 'EDIT_GROUP_DIALOG_ID';
export const DELETE_GROUP_DIALOG_ID = 'DELETE_GROUP_DIALOG_ID';

export const NEW_RESOURCE_DIALOG_ID = 'NEW_RESOURCE_DIALOG_ID';
export const EDIT_RESOURCE_DIALOG_ID = 'EDIT_RESOURCE_DIALOG_ID';
export const DELETE_RESOURCE_DIALOG_ID = 'DELETE_RESOURCE_DIALOG_ID';

export const NEW_BOARD_DIALOG_ID = 'NEW_BOARD_DIALOG_ID';
export const EDIT_BOARD_DIALOG_ID = 'EDIT_BOARD_DIALOG_ID';
export const PROTECT_BOARD_DIALOG_ID = 'PROTECT_BOARD_DIALOG_ID';
export const ARCHIVE_BOARD_DIALOG_ID = 'ARCHIVE_BOARD_DIALOG_ID';
export const IMPORT_BOARD_DIALOG_ID = 'IMPORT_BOARD_DIALOG_ID';
export const DELETE_BOARD_DIALOG_ID = 'DELETE_BOARD_DIALOG_ID';
export const RESTORE_BOARD_DIALOG_ID = 'RESTORE_BOARD_DIALOG_ID';

export const DELETE_TASKITEM_DIALOG_ID = 'DELETE_TASKITEM_DIALOG_ID';
export const EDIT_TASKITEM_DIALOG_ID = 'EDIT_TASKITEM_DIALOG_ID';
export const TRANSFER_TASKITEM_DIALOG_ID = 'TRANSFER_TASKITEM_DIALOG_ID';

export const EDIT_TASKLIST_DIALOG_ID = 'EDIT_TASKLIST_DIALOG_ID';
export const DELETE_TASKLIST_DIALOG_ID = 'DELETE_TASKLIST_DIALOG_ID';
export const TRANSFER_TASKLIST_DIALOG_ID = 'TRANSFER_TASKLIST_DIALOG_ID';

export const NEW_PLAYLIST_DIALOG_ID = 'NEW_PLAYLIST_DIALOG_ID';
export const IMPORT_YT_PLAYLIST_DIALOG_ID = 'IMPORT_YT_PLAYLIST_DIALOG_ID';