import { useEffect } from "react";
import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { useTaskBoardStateContext, useTaskBoardDispatchContext } from "@app/Areas/Optimus/Apps/TaskBoardApp";
import { BOARDS_CLEAR_TASKITEM_UNDER_VIEW } from "../../../state/TaskBoardReducer/TaskBoardReducer";
import useBoardIdParam from "../../_Boards/useBoardIdParam";

function useTaskItemUnderView() {
    const vaultId = useVaultIdParam();
    const boardId = useBoardIdParam();

    const boardStateContext = useTaskBoardStateContext();
    const taskItemUnderView = boardStateContext.taskItems.selectedToView;
    const boardDispatch = useTaskBoardDispatchContext();

    useEffect(() => {
        return function cleanup() {
            boardDispatch({
                type: BOARDS_CLEAR_TASKITEM_UNDER_VIEW
            })
        }
    }, [vaultId])

    if (!taskItemUnderView) {
        return null;
    }

    const taskItem = boardStateContext?.taskItems?.byId[taskItemUnderView];

    if (
        !taskItem
        || taskItem.parentVaultId !== vaultId
        || taskItem.parentBoardId !== boardId) {
        return null;
    }

    return taskItem;
}

export default useTaskItemUnderView;


