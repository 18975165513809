import React from 'react';
import { MenuItem, Select } from '@material-ui/core';
import { IBoardApiModel } from '@app/Areas/Optimus/ApiClient/TaskboardsClient';

export interface BoardSelectorProps {
    selectBoard: (event: React.ChangeEvent<{ value: unknown }>) => void;
    selectedBoard: number | null;
    boards: IBoardApiModel[];
}

function BoardSelector({
    selectedBoard,
    selectBoard,
    boards
}: BoardSelectorProps) {
    return (
        <>
            <Select
                fullWidth
                labelId="board-selector-label"
                id="board"
                value={selectedBoard}
                onChange={selectBoard}
            >
                { boards
                    .sort((board1, board2) => {
                        if (!board1?.name || !board2?.name) {
                            return 0;
                        }

                        if(board1.name < board2.name) { return -1; }
                        if(board1.name > board2.name) { return 1; }

                        return 0;
                    })
                    .map((board) => (<MenuItem value={board.id}>{board.name}</MenuItem>)) }
            </Select>
        </>
    )
}

export default BoardSelector;