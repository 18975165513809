import { useEffect } from "react";
import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { useTaskBoardStateContext, useTaskBoardDispatchContext } from "@app/Areas/Optimus/Apps/TaskBoardApp";
import { BOARDS_CLEAR_TASKLIST_UNDER_EDIT } from "../../../state/TaskBoardReducer/TaskBoardReducer";
import useBoardIdParam from "../../_Boards/useBoardIdParam";

function useTaskListUnderEdit() {
    const vaultId = useVaultIdParam();
    const boardId = useBoardIdParam();

    const boardStateContext = useTaskBoardStateContext();
    const taskListUnderEdit = boardStateContext.taskLists.selectedToEdit;
    const boardDispatch = useTaskBoardDispatchContext();

    useEffect(() => {
        return function cleanup() {
            boardDispatch({
                type: BOARDS_CLEAR_TASKLIST_UNDER_EDIT
            })
        }
    }, [vaultId])

    if (!taskListUnderEdit) {
        return null;
    }

     const taskList = boardStateContext?.taskLists?.byId[taskListUnderEdit];

    if (
        !taskList
        || taskList.parentVaultId !== vaultId
        || taskList.parentBoardId !== boardId) {
        return null;
    }

    return taskList;
}

export default useTaskListUnderEdit;
