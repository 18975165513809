import { useCallback, useEffect, useRef, useState } from 'react';
import createVaultService from '@app/Areas/Optimus/Services/VaultService';
import VaultEditor from './VaultEditor';
import { VaultEditorModel } from './VaultEditorModel';
import { useVaultDispatchContext } from '@app/Areas/Optimus/State/DataStores/VaultStore/VaultContextProvider';
import { getInitialUserVaults } from '@app/Areas/Optimus/State/DataStores/VaultStore/VaultReducer/VaultReducer';
import { NEW_VAULT_DIALOG_ID } from '../../ActionDialogs/index';
import EditorDialog from '@app/Areas/Optimus/Components/Shared/EditorDialog';
import { IVaultCreationRequest, IVaultApiModel } from '@app/Areas/Optimus/ApiClient/VaultsClient';

const blankVault: IVaultCreationRequest = {
    vaultName: '',
    description: '',
};

function mapCreationDetailsToVault(vault: IVaultCreationRequest): VaultEditorModel {
    return {
        title: vault.vaultName || '',
        description: vault.description || ''
    }
}

function NewVault() {
    const [isSending, setIsSending] = useState(false)
    const isMounted = useRef(true)
    const vaultService = createVaultService();
    const [createdVault, setCreatedVault] = useState<IVaultApiModel | null>(null);
    const [newVault, setNewVault] = useState<IVaultCreationRequest>(blankVault);
    const dispatch = useVaultDispatchContext();

    // set isMounted to false when we unmount the component
    useEffect(() => {
        return () => {
        isMounted.current = false
        }
    }, [])

    const sendRequest = useCallback(async () => {
        // don't send again while we are sending
        if (isSending) return
        // update state
        setIsSending(true)
        // send the actual request
        const result = await vaultService.createVault(newVault);
        if (result) {
            setCreatedVault(result);
            dispatch(getInitialUserVaults());
        }

        // once the request is sent, update state again
        if (isMounted.current) { // only update if we are still mounted
          setIsSending(false)
        }
      }, [newVault, isSending]
    ); // update the callback if the state changes

    async function saveNewVault() {
        sendRequest();
    }

    function vaultEdited(event: any) {
        setNewVault({
            ...newVault,
            [event.target.name]: event.target.value
        })
    }

    function cleanUp() {
        setNewVault(blankVault);
        setCreatedVault(null);
    }

    return <EditorDialog
            title="New Vault"
            saveTitle="Save Vault"
            dialogId={NEW_VAULT_DIALOG_ID}
            handleSave={() => saveNewVault()}
            onClose={cleanUp}
        >
            { !createdVault && <VaultEditor
                vault={mapCreationDetailsToVault(newVault)}
                handleVaultChange={vaultEdited}
            /> }
            { createdVault && JSON.stringify(createdVault) }
        </EditorDialog>;
}

export default NewVault;
