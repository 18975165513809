import useSectionIdParam from '../../../../App/NavigationContext/hooks/useSectionIdParam/useSectionIdParam';
import useVaultIdParam from '../../../../App/NavigationContext/hooks/useVaultIdParam/useVaultIdParam';
import { IResourcesContextModel } from '../../ResourceReducer/ResourceReducer';
import { useSectionStateContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider';
import useGroupIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useGroupIdParam/useGroupIdParam';
import { IResourceApiModel } from '@app/Areas/Optimus/ApiClient/BookmarksClient';

function useSectionGroupResourcesAsArray(): IResourceApiModel[] {
    const vaultId = useVaultIdParam();
    const sectionId = useSectionIdParam();
    const groupId = useGroupIdParam();

    const state: IResourcesContextModel = useSectionStateContext();

    if (vaultId === null || sectionId === null || groupId === null) {
        return [];
    }

    const resources =
        Object.values(state.resources.byId)
            .filter((value) => value.parents.some(({ parentVaultId, parentSectionId, parentGroupId }) =>
                parentVaultId === vaultId && parentSectionId === sectionId && parentGroupId === groupId
            ))
            .map(r => r.resource);

    return resources;
}

export default useSectionGroupResourcesAsArray;
