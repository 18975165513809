import React, { useState } from 'react';
import { Button, Checkbox, Chip, createStyles, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, Theme, Typography } from '@material-ui/core';
import { Link as LinkIcon } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import { ProjectEditorModel } from './ProjectEditorModel';
import PanelHeader from '@app/Areas/Optimus/Components/Shared/PanelHeader';

export interface ProjectEditorProps {
    project: ProjectEditorModel;
    handleProjectChange: (event: any) => void;
    title?: string;
    // handleReset: (event: any) => void;
    isSaving: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tags: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    input: {
        marginBottom: 20
    },
    form: {
        padding: '0.75rem'
    },
    button: {
        width: '100%'
    }
  }),
);

function ProjectEditor({
    project,
    handleProjectChange,
    title,
    isSaving
}: ProjectEditorProps) {
    const classes = useStyles();
    const [newTag, setNewTag] = useState();
    const [newUrl, setNewUrl] = useState({key: '', value: ''});
    const [selectedUrls, setSelectedUrls] = useState<string[]>([]);
    function removeTag(index: number) {

        const updatedTags = [
            ...project.tags
        ];

        for(let i = 0; i < updatedTags.length; i++) {
            if (i === index) {
                updatedTags.splice(i, 1);
            }
        }

        const pseudoEvent = {
            target: {
                name: 'tags',
                value: updatedTags
            }
        }

        handleProjectChange(pseudoEvent);
    }

    function addTag() {
        const updatedTags: (string | undefined)[] = [
            ...project.tags,
            newTag
        ];

        const pseudoEvent = {
            target: {
                name: 'tags',
                value: updatedTags
            }
        }

        handleProjectChange(pseudoEvent);
    }

    function addUrl() {
        const { key, value } = newUrl;

        const updatedUrls = {
            ...project.urls,
            [key]: value
        };

        const pseudoEvent = {
            target: {
                name: 'urls',
                value: updatedUrls
            }
        }

        handleProjectChange(pseudoEvent);
    }

    function toggleUrl(key: string) {
        if (selectedUrls.indexOf(key)) {
            setSelectedUrls([
                ...selectedUrls,
                key
            ])
        } else {
            setSelectedUrls([
                ...selectedUrls.filter(u => u !== key)
            ])
        }
    }

    function removeSelectedUrls() {
        const urls = project.urls;

        const updatedUrls = Object
            .entries(urls)
            .filter(([key, value]) => selectedUrls.indexOf(key) !== 0)
            .map(([key, value]) => ({
                key, value
            })).reduce((map, v) => ({
                ...map,
                [v.key]: v.value
            }), {});

        const pseudoEvent = {
            target: {
                name: 'urls',
                value: updatedUrls
            }
        }

        handleProjectChange(pseudoEvent);
        setSelectedUrls([]);
    }

    return (
        <form className={classes.form}>
            { isSaving && <Typography variant="h4">Saving Project...</Typography> }
            <PanelHeader headerVariant="h5" header={`Project - ${title}`}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.input}
                            fullWidth id="project-name-input"
                            label="Name"
                            name="projectName"
                            value={project.name}
                            onChange={handleProjectChange}
                        />
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                        <Grid item xs={12} container spacing={1}>
                            <TextField
                                className={classes.input}
                                id="project-tag-input"
                                name="tags"
                                label="Tags"
                                fullWidth
                                onChange={(event: any) => setNewTag(event.target.value)}
                            />
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <Button className={classes.button} onClick={addTag} color="primary" variant="contained">Add Tag</Button>
                        </Grid>
                        <div className={classes.tags}>
                            { project.tags.map((t: string, i: number) => (
                                <Chip label={t} onDelete={() => removeTag(i)} color="primary" />
                            ))}
                        </div>
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                        <PanelHeader header="Project Urls" headerVariant="body1">
                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={classes.input}
                                        id="project-url-name-input"
                                        name="urlName"
                                        label="Url Name"
                                        fullWidth
                                        onChange={(event: any) => setNewUrl({
                                            ...newUrl,
                                            key: event.target.value
                                        })}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={classes.input}
                                        id="project-url-name-input"
                                        name="url"
                                        label="Url"
                                        fullWidth
                                        onChange={(event: any) => setNewUrl({
                                            ...newUrl,
                                            value: event.target.value
                                        })}
                                    />
                                </Grid>
                            </Grid>
                        </PanelHeader>
                        <Grid item md={2} xs={12}>
                            <Button className={classes.button} onClick={addUrl} color="primary" variant="contained">Add New Url</Button> 
                        </Grid>
                        <Grid item md={10} xs={12}>
                            { Object.entries(project.urls).length > 0 && <PanelHeader header="Project Urls" headerVariant="body1">
                                <List dense>
                                    {Object.entries(project.urls).map(([key, value]) => (
                                        <ListItem>
                                            <ListItemIcon>
                                                <LinkIcon />
                                            </ListItemIcon>
                                            <a href={value} target="_blank" rel="noopener noreferrer">
                                                <ListItemText
                                                    primary={key}
                                                    secondary={value}
                                                />
                                            </a>
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    edge="end"
                                                    onChange={() => toggleUrl(key)}
                                                    checked={selectedUrls.indexOf(key) !== -1}
                                                    inputProps={{ 'aria-labelledby': key }}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))}
                                </List>
                            </PanelHeader> }
                            { selectedUrls.length > 0 && <Button onClick={removeSelectedUrls} color="primary" variant="contained">Remove Urls</Button> }
                        </Grid>
                    </Grid>
                </Grid>
            </PanelHeader>
        </form>
    )
}

export default ProjectEditor;
