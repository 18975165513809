import { PropsWithChildren, useEffect } from 'react';
import { useVaultDispatchContext } from '@app/Areas/Optimus/State/DataStores/VaultStore/VaultContextProvider';
import { getInitialUserVaults } from '@app/Areas/Optimus/State/DataStores/VaultStore/VaultReducer/VaultReducer';

function Initialiser({ children }: PropsWithChildren<{}>) {

    const vaultDispatch = useVaultDispatchContext();

    useEffect(() => {
        console.log('Initializing the UI...');
        vaultDispatch(getInitialUserVaults());
    }, []);

    return <>
        { children }
    </>;
}

export default Initialiser;
