import React, { PropsWithChildren } from 'react';
import tw, { styled } from 'twin.macro';
import { Button, createStyles, Dialog, DialogActions, DialogContentText, DialogTitle, makeStyles, Slide, Theme } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { useOptimusStateContext } from '@optimus/Contexts/OptimusContext';
import { closeDialog } from '@app/Areas/Optimus/State/App/DialogContext/DialogReducer/DialogReducer';
import { useDialogDispatchContext } from '@app/Areas/Optimus/State/App/DialogContext/DialogContextProvider';
import useIsOpenDialog from '../../../State/App/DialogContext/hooks/useIsOpenDialog/useIsOpenDialog';

const DialogContent = styled.div`
    ${tw`p-3`}
    ${props => props.theme.breakpoints.up("md")} {
        ${tw`p-8`}
    }
    margin-left: auto;
    margin-right: auto;
`;

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        background: 'rgba(0,0,0,0.4)',
        backdropFilter: 'blur(10px)',
        boxShadow: `0 0px 10px #3cb8dc99`,
        borderBottom: `1px solid #3cb8dc99`,
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

export interface ConfirmationDialogProps {
    onConfirm: (callback: () => void) => void;
    onClose: () => void;
    title: string;
    description: string;
    dialogId: string;
}

function ConfirmationDialog({
    title,
    description,
    onConfirm,
    dialogId,
    onClose
}: PropsWithChildren<ConfirmationDialogProps>) {
    const classes = useStyles();
    const state = useOptimusStateContext();
    const dispatch = useDialogDispatchContext();
    const open = useIsOpenDialog(dialogId);

    const handleClose = () => {
        onClose();
        dispatch(closeDialog());
    };

    const handleConfirm = () =>{
        onConfirm(() => {
            dispatch(closeDialog());
        });
    }

    return (
        <Dialog PaperProps={{
            className: classes.root
        }} open={open} onClose={handleClose} TransitionComponent={Transition}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirm} color="primary">
                    Confirm
                </Button>
            </DialogActions>
      </Dialog>
    )
}

export default ConfirmationDialog;
