import { useEffect } from 'react';
import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useTaskBoardDispatchContext } from '@app/Areas/Optimus/Apps/TaskBoardApp';
import { getVaultBoards, BOARDS_RESET } from '../../../state/TaskBoardReducer/TaskBoardReducer';

function useBoardsFetch() {
    const vaultId = useVaultIdParam();
    const boardDispatch = useTaskBoardDispatchContext();

    useEffect(() => {
        async function fetchBoards() {
            if (vaultId) {
                boardDispatch(getVaultBoards(vaultId));
            }
        }
        fetchBoards();

        return function cleanup(){
            boardDispatch({ type: BOARDS_RESET })
        }
    }, [vaultId]);
}

export default useBoardsFetch;