import React from 'react';
import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import PaperContent from '@optimus/Components/Shared/PaperContent';

const useMaterialClasses = makeStyles((theme: Theme) =>
    {
        return createStyles({
            root: {
              height: '100%',
              background: 'rgba(0,0,0,0.05)',
              backdropFilter: 'blur(10px)',
              boxShadow: `0 0px 10px #3cb8dc99`,
              border: `1px solid #3cb8dc99`,
            },
          })
    }
)

function Panel({
    contentClass,
    rootClass,
    children
}:  React.PropsWithChildren<{
    rootClass?: string,
    contentClass?: string
}>) {
    const classes = useMaterialClasses();
    return (
        <Paper className={`${classes.root} ${rootClass}`}>
            <PaperContent className={contentClass}>
                {children}
            </PaperContent>
        </Paper>
    )
}

export default Panel;
