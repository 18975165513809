import { useCallback, useRef, useState } from 'react';
import ConfirmationDialog from '@app/Areas/Optimus/Components/Shared/ConfirmationDialog';
import { generatePath, useHistory } from 'react-router-dom';
import { RESTORE_BOARD_DIALOG_ID } from '@app/Areas/Optimus/Ecosystems/ActionDialogs';
import createBoardService from '../../services/BoardsService';
import { useTaskBoardDispatchContext } from '../../state/TaskBoardContextProvider';
import { BOARDS_REMOVE_BOARD_UNDER_RESTORE, BOARDS_CLEAR_BOARD_UNDER_RESTORE } from '../../state/TaskBoardReducer/TaskBoardReducer';
import useBoardUnderRestoration from '../../hooks/_Boards/useBoardUnderRestoration';

function RestoreBoard() {
    const history = useHistory();
    const boardUnderRestoration = useBoardUnderRestoration();    
    const currentParentVaultId = boardUnderRestoration?.parentVaultId;
    const boardDispatch = useTaskBoardDispatchContext();
    const [isSending, setIsSending] = useState(false);
    const boardService = createBoardService();
    const isMounted = useRef(true);

    const sendRequest = useCallback(async (callback) => {
        if (boardUnderRestoration?.board.id) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await boardService.unarchiveBoard(
                boardUnderRestoration?.parentVaultId,
                boardUnderRestoration.board.id
            );
            if (result) {
                boardDispatch({
                    type: BOARDS_REMOVE_BOARD_UNDER_RESTORE,
                    boardId: boardUnderRestoration.board.id
                })
                goToBoards();
                callback();
            }
            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [boardUnderRestoration, isSending]
    ); // update the callback if the state changes

    const goToBoards = () => {
        if (currentParentVaultId) {
            const boardsPath = generatePath('/optimus/vaults/:vaultId/taskboards/boards', { vaultId: currentParentVaultId});
            history.push(boardsPath);
        }
    }

    const handleConfirm = (callback: () => void) => {
        if (!isSending) {
            sendRequest(callback);
        }
    }

    const cleanUp = () => {
        boardDispatch({
            type: BOARDS_CLEAR_BOARD_UNDER_RESTORE
        })
    }

    return boardUnderRestoration && (
        <ConfirmationDialog
            title="Restore Board"
            description={`Are you sure you want to restore the board '${boardUnderRestoration?.board.name}' - once it's restored all the contents will also be restored!`}
            dialogId={RESTORE_BOARD_DIALOG_ID}
            onConfirm={handleConfirm}
            onClose={cleanUp}
        />
    ) || null;
}

export default RestoreBoard;
