import { useEffect } from 'react';
import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useSectionDispatchContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider';
import { GROUPS_RESET, getVaultSectionGroups } from '../../ResourceReducer/ResourceReducer';


function useSectionGroupsFetch(
    sectionId: number | null,
    presetVaultId: number | null = null
) {    
    const vaultIdParam = useVaultIdParam();
    const vaultId = presetVaultId !== null ? presetVaultId : vaultIdParam;

    const sectionDispatch = useSectionDispatchContext();

    useEffect(() => {
        let isCancelled = false;
        async function fetchGroups() {
            if (vaultId && sectionId) {
                sectionDispatch(getVaultSectionGroups(vaultId, sectionId, isCancelled));
            }
        }
        fetchGroups();

        return function cleanup() {
            isCancelled = true;
            sectionDispatch({
                type: GROUPS_RESET,
                vaultId,
                sectionId
            })
        }
    }, [vaultId, sectionId])
}

export default useSectionGroupsFetch;
