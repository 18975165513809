import { createContext, useContext } from 'react';
import { DispatcherThunk, useThunkReducer } from '@optimus/Hooks/useThunkReducer';
import VideoReducer, { IVideoContextModel } from '../VideoReducer/VideoReducer';

const initialState: IVideoContextModel = {
    playlists: {
        byId: {},
        allIds: [],
        selected: null,
        selectedToEdit: null,
        selectedToDelete: null
    },
    videos: {
        byId: {},
        allIds: [],
        selected: null,
        selectedToEdit: null,
        selectedToDelete: null
    }
}

let VideoContextStateContext = createContext({} as IVideoContextModel);
let VideoContextDispatchContext = createContext({} as DispatcherThunk<(state: any, action: any) => any>)

function VideoContextProvider({ children }: React.PropsWithChildren<{}>) {
    const [videoState, videoDispatch] = useThunkReducer(VideoReducer, initialState, "videos");

    return (
        <VideoContextDispatchContext.Provider value={videoDispatch}>
            <VideoContextStateContext.Provider
                value={videoState}
            >
                { children }
            </VideoContextStateContext.Provider>
        </VideoContextDispatchContext.Provider>
    );
}

function useVideoStateContext() {
    return useContext<IVideoContextModel>(VideoContextStateContext);
}

function useVideoDispatchContext() {
    return useContext<DispatcherThunk<(state: any, action: any) => any>>(VideoContextDispatchContext);
}

export {
    VideoContextStateContext,
    VideoContextDispatchContext,
    VideoContextProvider,
    useVideoStateContext,
    useVideoDispatchContext
}