import { ChangeEvent, FormEvent, PropsWithChildren, useState } from "react";
import { darken } from "polished";
import { Draggable } from "react-beautiful-dnd";
import styled from 'styled-components';
import { DeepThoughtColor, ZiggyColor } from "@app/Areas/Optimus/Tokens/colors";
import { vaultColor } from "@app/style-tokens";
import { EditIcon, InfoIcon } from "@app/Areas/Optimus/Components/Shared/AppsIcons";
import BoardTextControl from "../BoardTextControl/BoardTextControl";
import { BOARDS_SET_TASKITEM_UNDER_EDIT, BOARDS_CLEAR_TASKITEM_UNDER_EDIT, BOARDS_SET_TASKITEM_UNDER_VIEW } from "../../state/TaskBoardReducer/TaskBoardReducer";
import { useTaskBoardDispatchContext } from "../../state/TaskBoardContextProvider/TaskBoardContextProvider";
import useTaskItemUnderEdit from "../../hooks/_TaskItems/useTaskItemUnderEdit";
import { EDIT_TASKITEM_DIALOG_ID } from "@app/Areas/Optimus/Ecosystems/ActionDialogs";
import { useDialogDispatchContext } from "@app/Areas/Optimus/State/App/DialogContext/DialogContextProvider";
import { OPTIMUS_OPEN_DIALOG } from "@app/Areas/Optimus/State/App/DialogContext/DialogReducer/DialogReducer";
import { ITaskItemApiModel } from "@app/Areas/Optimus/ApiClient/TaskboardsClient";

interface ContainerProps {
    isDragging: boolean;
}

const Container = styled.div<ContainerProps>`
    border: 1px solid lightgrey;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 2px;
    background-color: ${(props) => props.isDragging ? darken(0.2, ZiggyColor) : darken(0.6, DeepThoughtColor)};

    display: flex;
`;

const Handle = styled.div`
    width: 20px;
    height: 20px;
    background-color: ${ZiggyColor};
    border-radius: 4px;
    margin-right: 8px;
`;

const Controls = styled.div`
    width: 20px;
`;

const TaskButton = styled.button`
    opacity: 0.4;
    width: 20px;
    height: 20px;
    margin: 3px 0;
    background-color: ${vaultColor};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 1;
        background-color: ${ZiggyColor};
    }
`;

const Title = styled.div`
    flex-grow: 1;
`;

export interface TaskProps {
    index: number;
    taskListId: number;
    task: ITaskItemApiModel;
    sendEditRequest: (taskListId: number, taskItemId: number, textValue: string, final: () => void) => Promise<void>;
}

function Task({ taskListId, task, sendEditRequest, index }: PropsWithChildren<TaskProps>) {
    const boardDispatch = useTaskBoardDispatchContext();
    const dialogDispatch = useDialogDispatchContext();
    const taskItemUnderEdit = useTaskItemUnderEdit();
    const [textValue, setTextValue] = useState("");

    if (task.id === undefined) {
        return null;
    }

    const idAsString = `taskitem-${task.id}`;

    const triggerTaskEdit = () => {
        if (task.title) {
            setTextValue(task.title);
        }
        boardDispatch({
            type: BOARDS_SET_TASKITEM_UNDER_EDIT,
            taskItemId: task.id
        })
    }

    const triggerTaskInfo = () => {
        dialogDispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: EDIT_TASKITEM_DIALOG_ID
        });
        boardDispatch({
            type: BOARDS_SET_TASKITEM_UNDER_VIEW,
            taskItemId: task.id
        });
    }

    const cancelTaskEdit = () => {
        resetTextEditForm();
    }

    const resetTextEditForm = () => {
        setTextValue('');
        boardDispatch({
            type: BOARDS_CLEAR_TASKITEM_UNDER_EDIT
        })
      };

    const taskIsUnderEdit = taskItemUnderEdit?.taskItem.id === task.id;

    const onNameEditChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTextValue(event.target.value);
    };

    const submitItemChanges = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    
        if (!textValue.length || task?.id === undefined) {
            return;
        }
    
        sendEditRequest(
            taskListId,
            task.id,
            textValue,
            () => resetTextEditForm()
        );
      };

    return (
        task && <Draggable draggableId={idAsString} index={index}>
            { (provided, snapshot) => (
                <Container
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                    aria-roledescription="Press spacebar to lift the task"
                >
                    {
                        taskIsUnderEdit
                        ? <>
                            <BoardTextControl
                                onSubmit={submitItemChanges}  
                                onCancel={cancelTaskEdit}
                                setValue={onNameEditChange}
                                value={textValue}
                            />
                        </>
                        : <>
                            <Handle {...provided.dragHandleProps} />
                            <Title {...provided.dragHandleProps}>
                                { task.title }
                            </Title>
                            <Controls>
                                <TaskButton onClick={triggerTaskEdit}>
                                    <EditIcon />
                                </TaskButton>
                                <TaskButton onClick={triggerTaskInfo}>
                                    <InfoIcon />
                                </TaskButton>
                            </Controls>
                        </>
                    }
                    
                </Container>
            )}
        </Draggable>
    );
}

export default Task;
