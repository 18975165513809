import { useLocation, matchPath } from 'react-router-dom';

function useSectionIdParam() {
    const { pathname } = useLocation();
    const match = matchPath<{
        vaultId?: string;
        sectionId?: string;
    }>(pathname, {
        path: "/optimus/vaults/:vaultId/deepthought/sections/:sectionId",
        exact: false,
        strict: false
    });

    const sectionId = match?.params?.sectionId;

    if (sectionId) {
        return +sectionId;
    }

    return null;
}

export default useSectionIdParam;
