import { useLocation } from "react-router-dom";

function useResourceUrlParam() {
    const search = useLocation().search;
    const resourceUrl = new URLSearchParams(search).get('resourceUrl');

    return resourceUrl;
}

export default useResourceUrlParam;
