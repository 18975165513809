import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useEffect } from 'react';
import { useDeckDispatchContext, useDeckStateContext } from "../../DeckContextProvider";
import { DECKS_CLEAR_EDIT } from '../../DeckReducer/DeckReducer';

function useDeckUnderEdit() {
    const vaultId = useVaultIdParam();
    const deckDispatch = useDeckDispatchContext();
    const deckStateContext = useDeckStateContext();
    const deckUnderEditId = deckStateContext.decks.selectedToEdit;

    useEffect(() => {
        return function cleanup() {
            deckDispatch({ type: DECKS_CLEAR_EDIT })
        }
    }, [vaultId])

    if (!deckUnderEditId) {
        return null;
    }

    const deck = deckStateContext?.decks?.byId[deckUnderEditId];

    if (!deck || deck.parentVaultId !== vaultId) {
        return null;
    }

    return deck;
}

export default useDeckUnderEdit;
