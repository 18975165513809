import { useEffect } from 'react';
import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useSectionDispatchContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider';
import useSectionIdParam from '../../../../App/NavigationContext/hooks/useSectionIdParam/useSectionIdParam';
import useGroupIdParam from '../../../../App/NavigationContext/hooks/useGroupIdParam/useGroupIdParam';
import { getVaultSectionGroupResources, RESOURCES_RESET } from '../../ResourceReducer/ResourceReducer';


function useResourcesFetch() {
    const vaultId = useVaultIdParam();
    const sectionId = useSectionIdParam();
    const groupId = useGroupIdParam();
    const sectionDispatch = useSectionDispatchContext();

    useEffect(() => {
        let isCancelled = false;
        async function fetchResources() {
            if (vaultId && sectionId && groupId) {
                sectionDispatch(getVaultSectionGroupResources(vaultId, sectionId, groupId, isCancelled));
            }
        }
        fetchResources();

        return function cleanup() {
            isCancelled = true;
            sectionDispatch({
                type: RESOURCES_RESET,
                vaultId,
                sectionId,
                groupId
            })
        }
    }, [vaultId, sectionId, groupId])
}

export default useResourcesFetch;
