import styled from "styled-components";

export const Console = styled.div`
    height:  ${(props: {
        isConsoleView: boolean
    }) => props.isConsoleView
        ? '100vh'
        : 'auto'
    };

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 50px 1fr 56px;
    grid-template-areas:
        "ribbonTop"
        "mainContent"
        "ribbonBottom"
    ;
`;

export const Top = styled.div`
    grid-area: ribbonTop;
`;

export const Center = styled.div`
    grid-area: mainContent;
    min-height: 0; // used to allow scrolling when grid is limited to viewport
`;

export const Bottom = styled.div`
    grid-row: ribbonBottom;
`;