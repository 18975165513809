import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { DeepThoughtColor, ZiggyColor } from "@app/Areas/Optimus/Tokens/colors";
import { darken } from "polished";
import React, { ChangeEvent, PropsWithChildren, useCallback, useRef, useState } from "react";
import { Draggable, Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import styled from 'styled-components';
import NewTaskItemComponent from "../_TaskItems/NewTaskItem";
import Task from "../Task";
import { EditIcon, InfoIcon } from "@app/Areas/Optimus/Components/Shared/AppsIcons";
import { vaultColor } from "@app/style-tokens";
import BoardTextControl from "../BoardTextControl/BoardTextControl";
import createTaskItemService from "../../services/TaskItemService";
import createTaskListService from "../../services/TaskListsService";
import { getBoardTaskListItems, getVaultBoardTaskLists, BOARDS_SET_TASKLIST_UNDER_EDIT, BOARDS_CLEAR_TASKLIST_UNDER_EDIT } from "../../state/TaskBoardReducer/TaskBoardReducer";
import { useTaskBoardDispatchContext } from "../../state/TaskBoardContextProvider/TaskBoardContextProvider";
import useBoardIdParam from "../../hooks/_Boards/useBoardIdParam";
import useTaskItemsAsArray from "../../hooks/_TaskItems/useTaskItemsAsArray";
import useBoardTaskItemsFetch from "../../hooks/_TaskItems/useTaskItemsFetch";
import useTaskListUnderEdit from "../../hooks/_TaskLists/useTaskListUnderEdit";
import { ITaskItemApiModel, ITaskItemUpdateRequest, ITaskLifecycleApiModel, ITaskListUpdateRequest, TaskStatus } from "@app/Areas/Optimus/ApiClient/TaskboardsClient";
import TaskLifecycleBadge from "../_TaskLifecycleBadge";
import { useDialogDispatchContext } from "@app/Areas/Optimus/State/App/DialogContext/DialogContextProvider";
import { OPTIMUS_OPEN_DIALOG } from "@app/Areas/Optimus/State/App/DialogContext/DialogReducer/DialogReducer";
import { EDIT_TASKLIST_DIALOG_ID } from "@app/Areas/Optimus/Ecosystems/ActionDialogs";

const Root = styled.div`
    margin: 8px;
    border: 1px solid lightgrey;
    border-radius: 2px;
    width: 220px;

    display: flex;
    flex-direction: column;
`;

const Title = styled.h3`
    display: flex;
    padding: 8px;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    text-align: center;
`;

const TitleText = styled.span`
    flex-grow: 1;
    padding-right: 0;
`;

const Edit = styled.button`
    opacity: 0.4;
    width: 20px;
    height: 20px;
    background-color: ${vaultColor};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        opacity: 1;
        background-color: ${ZiggyColor};
    }
`;

export interface TaskListProps {
    isDraggingOver: boolean;
}

export const TaskList = styled.div<TaskListProps>`
    padding: 8px;
    transition: background-color ease-in 0.2s;
    background-color: ${props => props.isDraggingOver ? darken(0.3, DeepThoughtColor) : 'inherit'};

    flex-grow: 1;
    min-height: 100px;
`;

export interface ColumnProps {
    title: string;
    lifeCycleStage?: ITaskLifecycleApiModel;
    column: any;
    index: number;
}

export interface InnerListProps {
    tasks: ITaskItemApiModel[];
}

function Column({ title, lifeCycleStage, column, index }: PropsWithChildren<ColumnProps>) {

    const vaultId = useVaultIdParam();
    const boardId = useBoardIdParam();

    useBoardTaskItemsFetch(column.id);

    const tasks = useTaskItemsAsArray(boardId, column.id);
    const taskListIdAsString = `tasklist-${column.id}`;

    const isMounted = useRef(true);
    const [isSending, setIsSending] = useState(false);
    const taskItemService = createTaskItemService();
    const taskListService = createTaskListService();
    const boardDispatch = useTaskBoardDispatchContext();
    const dialogDispatch = useDialogDispatchContext();

    const taskListUnderEdit = useTaskListUnderEdit();

    const [textValue, setTextValue] = useState("");
    const [quickEdit, setQuickEdit] = useState(false);

    const sendEditTaskItemRequest = useCallback(async (
        taskListId: number,
        taskItemId: number,
        textValue: string,
        final: () => void
    ) => {
        if (vaultId && boardId && taskListId) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)    
    
            const request: ITaskItemUpdateRequest = {
              title: textValue,
              description: 'TODO: Make this unrequired',
              status: TaskStatus._0,
              initiativeIds: [],
              newParentId: taskListId,
            }
    
            // send the actual request
            var result = await taskItemService.updateTaskItem(vaultId, boardId, taskListId, taskItemId, request);
    
            if (result) {
                boardDispatch(getBoardTaskListItems(vaultId, boardId, taskListId, false));
            }
    
            final();
    
            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [
        vaultId,
        isSending 
      ]); // update the callback if the state changes

    const sendEditTaskListRequest = useCallback(async (
        textValue: string,
        lifeCycleStage: number | undefined,
        final: () => void
    ) => {
        if (vaultId && boardId && column.id) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)    
    
            const request: ITaskListUpdateRequest = {
              name: textValue,              
              newParentId: boardId,
              lifeCycleStage: lifeCycleStage || 0,
              lifeCycleStageIsCustom: false
            }
    
            // send the actual request
            var result = await taskListService.updateTaskList(vaultId, boardId, column.id, request);
    
            if (result) {
                boardDispatch(getVaultBoardTaskLists(vaultId, boardId, false));
            }
    
            final();
    
            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [
        vaultId,
        boardId,
        column.id,
        isSending 
      ]); // update the callback if the state changes

    const listIsUnderEdit = taskListUnderEdit?.taskList.id === column.id;

    const triggerListEdit = () => {
        setQuickEdit(true);
        if (title) {
            setTextValue(title);
        }
        boardDispatch({
            type: BOARDS_SET_TASKLIST_UNDER_EDIT,
            taskListId: column.id
        })        
    }

    const triggerListInfo = () => {
        boardDispatch({
            type: BOARDS_SET_TASKLIST_UNDER_EDIT,
            taskListId: column.id
        });
        dialogDispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: EDIT_TASKLIST_DIALOG_ID
        })
    }

    const submitListChanges = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    
        if (!textValue.length || column?.id === undefined) {
            return;
        }

        sendEditTaskListRequest(textValue, lifeCycleStage?.lifecycleId, () => resetTextEditForm());
    }

    const resetTextEditForm = () => {
        setTextValue('');
        boardDispatch({
            type: BOARDS_CLEAR_TASKLIST_UNDER_EDIT
        })
        setQuickEdit(false);
      };

    const cancelTaskListEdit = () => {
        resetTextEditForm();
    }

    const onNameEditChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTextValue(event.target.value);
    };

    return  <Draggable draggableId={taskListIdAsString} index={index}>
            { (provided) => (
                <Root {...provided.draggableProps} ref={provided.innerRef}>
                    {
                        listIsUnderEdit && quickEdit
                        ? <BoardTextControl onSubmit={submitListChanges} onCancel={cancelTaskListEdit} setValue={onNameEditChange} value={textValue} />
                        : <> <Title {...provided.dragHandleProps}>                            
                            <Edit onClick={triggerListEdit}>
                                <EditIcon />
                            </Edit>                            
                            <Edit onClick={triggerListInfo}>
                                <InfoIcon />
                            </Edit>
                            <TitleText>{ title }</TitleText>
                            { lifeCycleStage && <TaskLifecycleBadge lifecycle={lifeCycleStage} /> }                            
                        </Title>
                        <Droppable droppableId={taskListIdAsString} type="task">
                            { (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
                                <TaskList
                                    ref={provided.innerRef}
                                    { ...provided.droppableProps }
                                    isDraggingOver={snapshot.isDraggingOver}
                                >
                                    { tasks.map((t: ITaskItemApiModel, i: number) =>
                                        <Task
                                            key={t.id}
                                            index={i}
                                            taskListId={column.id}
                                            task={t}
                                            sendEditRequest={sendEditTaskItemRequest}
                                        />) }
                                    {provided.placeholder}
                                    <NewTaskItemComponent label="New Item" vaultId={vaultId} boardId={boardId} taskListId={column.id} />
                                </TaskList>
                            ) }
                        </Droppable> </>
                    }
                </Root>
            )}
        </Draggable>
}

export default Column;
