import React from 'react';
import { generatePath, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { FridayColor, JarvisColor, ZiggyColor } from '@optimus/Tokens/colors';
import { AppBar, BottomNavigation, BottomNavigationAction, createStyles, makeStyles, Theme } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { JarvisIcon, FridayIcon, ZiggyIcon, DeepThoughtIcon } from '@optimus/Components/Shared/AppsIcons/AppsIcons';
import HeartbeatMonitor from './Components/HeartbeatMonitor';
import useCurrentVaultId from '../../State/DataStores/VaultStore/hooks/useCurrentVaultId/useCurrentVaultId';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      top: 'auto',
      bottom: 0,
      background: 'transparent',
      backdropFilter: 'blur(10px)',
    },
    navigation: {
      background: 'transparent'
    },
    ziggy: {
      color: ZiggyColor
    },
    jarvis: {
      color: JarvisColor
    },
    friday: {
      color: FridayColor
    },
  }),
);

function OptimalConsoleBar() {
    const classes = useStyles();
    const { path } = useRouteMatch();
    const history = useHistory();
    const currentVaultId = useCurrentVaultId();
    const menuId = 'primary-optimus-console-bar';

    const goToFriday = () => {
      if (currentVaultId) {
          const newPath = generatePath('/optimus/vaults/:vaultId/friday', { vaultId: currentVaultId });
          history.push(newPath)
      }
    }

    const goToDeepThought = () => {
      if (currentVaultId) {
          const newPath = generatePath('/optimus/vaults/:vaultId/deepthought', { vaultId: currentVaultId });
          history.push(newPath)
      }
    }

    return (
      <AppBar position="fixed" className={classes.appBar}>
            <HeartbeatMonitor menuId={menuId}>
                <Route path={`${path}/:vaultId`}>
                  <BottomNavigation
                    // value={value}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    showLabels
                    className={classes.navigation}
                  >
                    <BottomNavigationAction
                      label="Friday"
                      icon={<FridayIcon className={classes.friday} />}
                      onClick={goToFriday}
                    />
                    <BottomNavigationAction label="Jarvis" icon={<JarvisIcon className={classes.jarvis} />} />
                    <BottomNavigationAction label="DeepThought" icon={<DeepThoughtIcon color="secondary" />} onClick={goToDeepThought} />
                    <BottomNavigationAction label="Ziggy" icon={<ZiggyIcon className={classes.ziggy} />} />
                    <BottomNavigationAction label="Me" icon={<AccountCircleIcon color="primary" />} />
                  </BottomNavigation>
                </Route>
              </HeartbeatMonitor>
        </AppBar>
    )
}

export default OptimalConsoleBar;
