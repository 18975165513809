import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "./react-auth0-spa";

export interface PrivateRouteProps {
    path: string;
}

const PrivateRoute = (privateRouteProps: React.PropsWithChildren<PrivateRouteProps>) => {
    const { children, path } = privateRouteProps;
    const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (loading || isAuthenticated) {
        return;
        }
        const fn = async () => {
        await loginWithRedirect({
            appState: { targetUrl: path }
        });
        };
        fn();
    }, [loading, isAuthenticated, loginWithRedirect, path]);

    const render = (props: any) =>
        isAuthenticated === true ? children : null;

    return <Route {...privateRouteProps} path={path}>{ render }</Route>;
};

export default PrivateRoute;