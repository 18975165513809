import { useRouteMatch } from "react-router-dom";
import VaultsHub from "@app/Areas/Optimus/Ecosystems/Vaults/VaultsHub";
import BookmarkHub from "@app/Areas/Optimus/Ecosystems/Bookmark";

export interface NavigatorRoutes {
    path: string;
    component: () => JSX.Element;    
}

/**
 * Hook to provide the navigator with all registered routes within the app
 */
function useNavigatorRoutes() {
    // Get the current path from the router
    const { path } = useRouteMatch();   

    const routes: NavigatorRoutes[] = [{
        path: `${path}/vaults`,
        component: VaultsHub
    }, {
        path: `${path}/bookmark`,
        component: BookmarkHub
    }];
    
    return routes;
}

export default useNavigatorRoutes;
