import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { useEffect } from "react";
import { useVideoDispatchContext } from "../../VideoContextProvider/VideoContextProvider";
import { getPlaylistVideos, VIDEOS_RESET } from "../../VideoReducer/VideoReducer";
import usePlaylistIdParam from "../usePlaylistIdParam";

function useVideosFetch() {
    const vaultId = useVaultIdParam();
    const playlistId = usePlaylistIdParam();

    const videoDispatch = useVideoDispatchContext();

    useEffect(() => {
        // Function to fetch videos based on vault and playlist ids
        async function fetchVideos() {
            if (vaultId && playlistId) {
                // Dispatches a thunk to get videos for a vault
                videoDispatch(getPlaylistVideos(vaultId, playlistId));
            }
        }
        fetchVideos();

        return function cleanup() {
            videoDispatch({ type: VIDEOS_RESET })
        }
    }, [vaultId, playlistId]) // Hooks into the current vault and playlist id parameters
}

export default useVideosFetch;
