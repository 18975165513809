import { useCallback, useRef, useState } from 'react';
import { useTaskBoardDispatchContext } from '@app/Areas/Optimus/Apps/TaskBoardApp';
import createTaskListService from '../../../services/TaskListsService';
import { getVaultBoardTaskLists } from '../../../state/TaskBoardReducer/TaskBoardReducer';
import ToggleInputButton from '../../BoardInputControl';
import { ITaskListCreationRequest } from '@app/Areas/Optimus/ApiClient/TaskboardsClient';

export interface NewTaskListComponentProps {
    label: string;
    vaultId: number | null;
    boardId: number | null;
}

function NewTaskListComponent({
    label, vaultId, boardId
}: NewTaskListComponentProps) {
    const isMounted = useRef(true);
    const taskListService = createTaskListService();
    const boardDispatch = useTaskBoardDispatchContext();
  
    const [isSending, setIsSending] = useState(false)
    const [textValue, setTextValue] = useState("");


    const sendRequest = useCallback(async (final: () => void) => {
        if (vaultId && boardId) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
    
            const request: ITaskListCreationRequest = {
              name: textValue
            }
    
            // send the actual request
            var result = await taskListService.createTaskList(vaultId, boardId, request);
    
            if (result) {
                boardDispatch(getVaultBoardTaskLists(vaultId, boardId, false));
            }
    
            final();
    
            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [
        vaultId,
        textValue,
        isSending 
      ]); // update the callback if the state changes


    return <ToggleInputButton label={label} setValue={setTextValue} value={textValue} sendRequest={sendRequest} />
}

export default NewTaskListComponent;
