import { IVaultsContextModel } from '../../VaultReducer/VaultReducer';
import { useVaultStateContext } from '../../VaultContextProvider';
import { IVaultApiModel } from '@app/Areas/Optimus/ApiClient/VaultsClient';

function useVaultsAsDictionary(): { [vaultId: string]: IVaultApiModel } {
    const vaultsContext: IVaultsContextModel = useVaultStateContext();

    return Object.entries(vaultsContext.vaults.byId).reduce((dictionary, [key, value]) => {
        return {
            ...dictionary,
            [key]: value.vault
        }
    }, {});
}

export default useVaultsAsDictionary;
