import useNetworkRequest from "@optimus/Hooks/useNetworkRequest";
import { IVaultApiModel, IVaultCreationRequest, IVaultUpdateRequest, VaultApiModel, VaultCreationRequest, VaultsClient, VaultUpdateRequest } from "../../ApiClient/VaultsClient";

export interface IVaultService {
    getVaults: () => Promise<IVaultApiModel[]>;
    useGetVaultsRequest: () => [Error | null, boolean, IVaultApiModel[] | null];
    createVault: (creationRequest: IVaultCreationRequest) => Promise<IVaultApiModel>;
    updateVault: (vaultId: number, updateRequest: IVaultUpdateRequest) => Promise<IVaultApiModel>;
    archiveVault: (vaultId: number) => Promise<IVaultApiModel>;
}

function createVaultService() {
    const client = new VaultsClient();
    const vaultService: IVaultService = {
        // Function to get Vaults, no data fetch lifecycle
        getVaults: () => client.vaultsAll(),
        // Hook to get Profile with data fetch lifecycle
        useGetVaultsRequest: () => {
            const [error, isLoading, data] = useNetworkRequest<VaultApiModel[]>(() => client.vaultsAll(), []);
            return [error, isLoading, data];
        },
        // Function to create a new vault
        createVault: (creationRequest: IVaultCreationRequest) => client.vaultsPOST(new VaultCreationRequest(creationRequest)),
        // Function to update a Vault
        updateVault: (vaultId: number, updateRequest: IVaultUpdateRequest) => client.vaultsPUT(vaultId, new VaultUpdateRequest(updateRequest)),
        // Function to archive a vault and all of its data
        archiveVault: (vaultId: number) => client.vaultsDELETE(vaultId),
    }

    return vaultService;
}

export default createVaultService;