import { Button, Grid, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { IResourceCreationRequest, IResourceApiModel } from "../../ApiClient/BookmarksClient";
import VaultSelector from "../../Components/VaultSelector";
import useVaultsAsArray from "../../State/DataStores/VaultStore/hooks/useVaultsAsArray";
import { ResourceAssignmentModel } from "../Resources/NewResource/assigner/ResourceAssignmentModel";
import useCreateSaveResourceRequest from "../Resources/NewResource/factories/createSaveResourceRequest";
import NewResourceForm from "../Resources/NewResource/NewResourceForm";
import useResourceUrlParam from "./hooks/useResourceUrlParam";

const BookmarkWrapper = styled.div`
    background: rgba(0,0,0,0.1);
    backdrop-filter: blur(10px);
    color: rgba(255,255,255,1);
    padding: 2em;
`;


const blankResource: IResourceCreationRequest = {
    title: '',
    url: '',
    tags: [],
    publicationDate: new Date(),
    isRecommended: false,
    isPublic: false,
    classification: '',
    description: '',
    thumbnail: '',
    additionalGraph: {},
    siteName: '',
    author: '',
    assignments: [],
}

function BookmarkHub() {
    const resourceUrl = useResourceUrlParam();
    const vaults = useVaultsAsArray();
    const [selectedVault, setSelectedVault] = useState<number | null>(null);

    const [newResource, setNewResource] = useState<IResourceCreationRequest>(blankResource);
    const [assignments, setAssignments] = useState<ResourceAssignmentModel[]>([])
    const [createdResource, setCreatedResource] = useState<IResourceApiModel | null>(null);

    useEffect(() => {        
        if (selectedVault === null && vaults.length > 0 && vaults[0].id) {
            setSelectedVault(vaults[0].id);
        }
    }, [selectedVault, vaults])

    const vaultChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newSelectedVault = event.target.value as string;
        setSelectedVault(+newSelectedVault);
    };

    const handleSave = () => {
        if (!createdResource && !isSending) {
            sendRequest();
        }
    }

    const {
        isSending,
        sendRequest
    } = useCreateSaveResourceRequest(
        newResource,
        setCreatedResource,
        (vaultId: number) => {},
        selectedVault
    )

    return <BookmarkWrapper>
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={8}>
                <Typography variant="subtitle1">
                    New Resource
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <VaultSelector
                    selectedVault={selectedVault}
                    handleChange={vaultChange}
                    vaults={vaults}
                />
            </Grid>
            <Grid item xs={2}>
                <Button color="secondary" variant="contained" autoFocus onClick={handleSave}>
                    Save Url to Resources
                </Button>
            </Grid>
        </Grid>        
        <NewResourceForm
            vaultId={selectedVault}
            presetUrlToVerify={resourceUrl}
            newResource={newResource}
            setNewResource={setNewResource}            
            assignments={assignments}
            setAssignments={setAssignments}
            createdResource={createdResource}
            setCreatedResource={setCreatedResource}
            blankResource={blankResource}
            isSaving={isSending}
        />
    </BookmarkWrapper>
}

export default BookmarkHub;
