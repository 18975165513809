import { useLocation, matchPath } from 'react-router-dom';

function useDeckIdParam() {
    const { pathname } = useLocation();
    const match = matchPath<{
        deckId?: string
    }>(pathname, {
        path: "/optimus/vaults/:vaultId/deepthought/decks/:deckId",
        exact: false,
        strict: false
    });

    const deckId = match?.params?.deckId;

    if (deckId) {
        return +deckId;
    }

    return null;
}

export default useDeckIdParam;
