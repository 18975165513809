import configJson from './app_config.json';

export interface AppConfig {
    apiBaseUrl: string
}

// export const config: AppConfig = {
//     apiBaseUrl: 'https://vectorsigma-series1-prod.azurewebsites.net'
//     // apiBaseUrl: 'https://localhost:5001'
//     // apiBaseUrl: 'https://g33kchrisknowledgebase-apptest.azurewebsites.net'
// };

export const config = configJson;
