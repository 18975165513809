import { useState } from 'react';
import { NEW_SECTION_DIALOG_ID } from '../../ActionDialogs/index';
import EditorDialog from '@optimus/Components/Shared/EditorDialog';
import { SectionEditorModel } from './SectionEditorModel';
import SectionEditor from './SectionEditor';
import createSectionService from '../../../Services/SectionService/SectionService';
import { getVaultSections } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceReducer/ResourceReducer';
import { useSectionDispatchContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider/ResourceContextProvider';
import useCurrentVault from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useCurrentVault';
import useCreateVaultChildEntity from '../../../Hooks/_operations/useCreateVaultChildEntity/useCreateVaultChildEntity';
import { ISectionCreationRequest, ISectionApiModel } from '@app/Areas/Optimus/ApiClient/BookmarksClient';

const blankSection: ISectionCreationRequest = {
    name: ''
};

function mapCreationDetailsToSection(section: ISectionCreationRequest): SectionEditorModel {
    return {
        name: section.name
    }
}

function NewSection() {
    const sectionService = createSectionService();
    const sectionDispatch = useSectionDispatchContext();
    const currentVault = useCurrentVault();
    const [createdSection, setCreatedSection] = useState<ISectionApiModel | null>(null);
    const [sectionCreationRequest, setSectionCreationRequest] = useState<ISectionCreationRequest>(blankSection);

    function sectionEdited(event: any) {
        setSectionCreationRequest({
            ...sectionCreationRequest,
            [event.target.name]: event.target.value
        })
    }

    const {
        isSending,
        sendRequest,
    } = useCreateVaultChildEntity<
        ISectionCreationRequest,
        ISectionApiModel
    >(
        (v: number, cr: ISectionCreationRequest) => sectionService.createSection(v, cr),
        sectionCreationRequest,
        (result: ISectionApiModel) => setCreatedSection(result),
        (vaultId: number) => sectionDispatch(getVaultSections(vaultId)),
    );

    const handleSave = () => {
        if (!createdSection && !isSending) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        setSectionCreationRequest(blankSection);
        setCreatedSection(null);
    }

    return currentVault && (
        <EditorDialog
            title="New Section"
            saveTitle="Save Section"
            dialogId={NEW_SECTION_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            { !createdSection && <SectionEditor
                section={mapCreationDetailsToSection(sectionCreationRequest)}
                handleSectionChange={sectionEdited}
                title={currentVault.title}
                isSaving={isSending}
            /> }
            { createdSection && JSON.stringify(createdSection) }
        </EditorDialog> ) || null;
}

export default NewSection;
