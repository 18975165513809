import { ISectionApiModel, IGroupApiModel } from '@app/Areas/Optimus/ApiClient/BookmarksClient';
import GroupSelector from '../GroupSelector/GroupSelector';
import SectionSelector from '../SectionSelector/SectionSelector';

export interface GroupNavigatorProps {
    sections: ISectionApiModel[];
    groups: IGroupApiModel[];
    selectedSection: number | null;
    selectSection: (event: any) => void;
    selectedGroup: number | null;
    selectGroup: (event: React.ChangeEvent<{}>, value: any) => void;
}

function GroupNavigator({
    sections,
    selectSection,
    selectedSection,
    groups,
    selectGroup,
    selectedGroup
}: GroupNavigatorProps) {
    return (
        <GroupSelector
            groups={groups}
            selectGroup={selectGroup}
            selectedGroup={selectedGroup}
            selectedSection={selectedSection}
        >
            <SectionSelector
                sections={sections}
                selectSection={selectSection}
                selectedSection={selectedSection}
            />
        </GroupSelector>
    )
}

export default GroupNavigator;
