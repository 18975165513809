import { useEffect, useState } from "react";
import { useReducer } from "reinspect";

export type Options = {
    haltRequests?: boolean;
}

function useNetworkRequest<T>(
    fetchData: () => Promise<T>,
    dependencies: unknown[],
    options: Options = {}
): [Error | null, boolean, T | null] {
    const { haltRequests } = options;
    const [data, setData] = useState<T | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        let isActive = true;
        const makeRequest = async () => {
            setIsLoading(true);
            setData(null);
            setError(null);

            if (haltRequests) {
                return ;
            }

            try {
                const result = await fetchData();
                if (isActive) {
                    setData(result);
                }
            } catch (e) {
                if (isActive) {
                    setError(e);
                }
            } finally {
                if (isActive) {
                    setIsLoading(false);
                }
            }
        };

        makeRequest();

        return () => {
            isActive = false;
        };
    }, [setIsLoading, setData, setError, ...dependencies])

    return [error, isLoading, data];
}

export default useNetworkRequest;

export function useNetworkRequestWithRefresh<T> (
    fetchData: () => Promise<T>,
    dependencies: unknown[],
    options: Options = {}
): [Error | null, boolean, T | null, () => void] {
    const [forceRefreshFlag, triggerForceRefresh] = useReducer(
        (x) => !x,
        false
    );

    const [error, isLoading, data] = useNetworkRequest(
        fetchData,
        [forceRefreshFlag, ...dependencies],
        options
    );

    return [error, isLoading, data, () => triggerForceRefresh(null)];
}