import { AppStore } from './app-store';
import { AppState } from './initial-app-state';

export interface IStoreFactory<Store> {
    GetStore(state: any, setState: any): Store
}

export class AppStoreFactory implements IStoreFactory<AppStore> {
    GetStore(state: AppState, setState: React.Dispatch<React.SetStateAction<AppState>>): AppStore {
        return new AppStore(state, setState);
    }
}