import { Dispatch } from "react";

export const OPTIMUS_OPEN_DIALOG = 'OPTIMUS_OPEN_DIALOG';
export const OPTIMUS_CLOSE_DIALOG = 'OPTIMUS_CLOSE_DIALOG';

export interface ActionDialogsContext {
    openedDialog?: string;
}

export interface IDialogContextModel {
    actionDialogs?: ActionDialogsContext;
}

/**
  * The reducer for managing Dialog State
  * @param state
  * @param action
  */
 function DialogReducer(state: IDialogContextModel, action: any) {
    switch (action.type) {
        case OPTIMUS_OPEN_DIALOG:
        return {
            ...state,
            actionDialogs: {
              openedDialog: action.openedDialog
            }
          }
      case OPTIMUS_CLOSE_DIALOG:
        return {
          ...state,
          actionDialogs: {
            openedDialog: null
          }
        }
        default:
            throw new Error();
    }
}

export default DialogReducer;

/**
 * Action creator for opening dialog.
 */
export function openDialog(openedDialog: string) {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog,
        });
    }
};

/**
 * Action creator for closing dialog.
 */
export function closeDialog() {
    return async (dispatch: Dispatch<any>) => {
        dispatch({
            type: OPTIMUS_CLOSE_DIALOG,
        });
    }
};