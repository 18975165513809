import { useEffect } from 'react';
import { useSectionDispatchContext, useSectionStateContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider';
import { RESOURCES_CLEAR_GROUP_UNDER_DELETE } from '../../ResourceReducer/ResourceReducer';
import useVaultIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import useSectionIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useSectionIdParam';

function useGroupUnderDelete() {
    const vaultId = useVaultIdParam();
    const sectionId = useSectionIdParam();
    const groupContextDispatchContext = useSectionDispatchContext();
    const groupStateContext = useSectionStateContext();
    const groupUnderDeleteId = groupStateContext.groups.selectedToDelete;

    useEffect(() => {
        return function cleanup() {
            groupContextDispatchContext({ type: RESOURCES_CLEAR_GROUP_UNDER_DELETE })
        }
    }, [vaultId])

    if (!groupUnderDeleteId) {
        return null;
    }

    const group = groupStateContext?.groups?.byId[groupUnderDeleteId];

    if (!group || group.parentVaultId !== vaultId || group.parentSectionId !== sectionId) {
        return null
    }

    return group;
}

export default useGroupUnderDelete;
