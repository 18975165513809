import tw, { styled } from 'twin.macro';

const PaperContent = styled.div`
    ${tw`px-3 py-3`}
    ${props => props.theme.breakpoints.up("md")} {
        ${tw`px-6 py-6`}
    }
`;

export default PaperContent;
