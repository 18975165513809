import { useEffect } from "react";
import { PROJECTS_CLEAR_EDIT } from '../../ProjectReducer/ProjectReducer';
import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useProjectDispatchContext, useProjectStateContext } from "../../ProjectContextProvider";

function useProjectUnderEdit() {
    const vaultId = useVaultIdParam();
    const projectsDispatch = useProjectDispatchContext();
    const projectStateContext = useProjectStateContext();
    const projectUnderEditId = projectStateContext.projects.selectedToEdit;

    useEffect(() => {
        return function cleanup() {
            projectsDispatch({ type: PROJECTS_CLEAR_EDIT })
        }
    }, [vaultId])

    if (!projectUnderEditId) {
        return null;
    }

    const project = projectStateContext?.projects?.byId[projectUnderEditId];

    if (!project || project.parentVaultId !== vaultId) {
        return null;
    }

    return project;
}

export default useProjectUnderEdit;