import { IVaultApiModel } from '@app/Areas/Optimus/ApiClient/VaultsClient';
import { useVaultStateContext } from '../../VaultContextProvider';
import { IVaultsContextModel } from '../../VaultReducer/VaultReducer';

function useVaultsAsArray(): IVaultApiModel[] {
    const vaultsContext: IVaultsContextModel = useVaultStateContext();

    return Object.values(vaultsContext.vaults.byId).map((v) => v.vault);
}

export default useVaultsAsArray;
