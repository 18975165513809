import { useState } from 'react';
import EditorDialog from '@optimus/Components/Shared/EditorDialog';
import createBoardService from '../../../services/BoardsService';
import { getVaultBoards } from '../../../state/TaskBoardReducer/TaskBoardReducer';
import { useTaskBoardDispatchContext } from '../../../state/TaskBoardContextProvider/TaskBoardContextProvider';
import { IMPORT_BOARD_DIALOG_ID } from '@app/Areas/Optimus/Ecosystems/ActionDialogs';
import useCreateVaultChildEntity from '@app/Areas/Optimus/Hooks/_operations/useCreateVaultChildEntity';
import useCurrentVault from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useCurrentVault';
import { IBoardApiModel, FileParameter } from '@app/Areas/Optimus/ApiClient/TaskboardsClient';
import ImportBoardEditor from './ImportBoardEditor';

function ImportBoard() {
    const boardService = createBoardService();
    const boardDispatch = useTaskBoardDispatchContext();
    const currentVault = useCurrentVault();
    const [createdBoard, setCreatedBoard] = useState<IBoardApiModel | null>(null);
    const [importedFile, setImportedFile] = useState<FileParameter>({
        data: null,
        fileName: ''
    });

    function boardEdited(event: any) {
        const file = event.target.files[0];
        setImportedFile({
            data: file,
            fileName: file.Name
        })
    }

    const {
        isSending,
        sendRequest,
    } = useCreateVaultChildEntity<
        FileParameter,
        IBoardApiModel
    >(
        (v: number, imFi: FileParameter) => boardService.importBoard(v, imFi),
        importedFile,
        (result: IBoardApiModel) => setCreatedBoard(result),
        (vaultId: number) => boardDispatch(getVaultBoards(vaultId)),
    );

    const handleSave = () => {
        if (!createdBoard && !isSending && importedFile?.data !== null) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        setImportedFile({
            data: null,
            fileName: ''
        });
        setCreatedBoard(null);
    }

    return currentVault && (
        <EditorDialog
            title="Import Board"
            saveTitle="Import"
            dialogId={IMPORT_BOARD_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            { !createdBoard && <ImportBoardEditor
                handleBoardChange={boardEdited}
                title={currentVault.title}
                isSaving={isSending}
            /> }
            { createdBoard && JSON.stringify(createdBoard) }
        </EditorDialog> ) || null;
}

export default ImportBoard;
