import { BookmarksClient, IUrlVerificationRequest, UrlVerificationRequest, VerificationResultApiModel } from "../../ApiClient/BookmarksClient";

export interface IUrlVerifyService {
    verifyUrl: (vaultId: number, url: string) => Promise<VerificationResultApiModel>;
}

 function createUrlVerifyService() {
    const client = new BookmarksClient();
    const urlVerifyService: IUrlVerifyService = {
        verifyUrl: (vaultId: number, url: string) => {
            const request: IUrlVerificationRequest = {
                urlToVerify: url
            };
            return client.verify(vaultId, new UrlVerificationRequest(request));
        } 
    }

    return urlVerifyService;
 }

 export default createUrlVerifyService;