import React, { PropsWithChildren } from 'react';

interface TabPanelProps {
    index: any;
    value: any;
  }

function TabPanel(props: PropsWithChildren<TabPanelProps>) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && children }
      </div>
    );
}

export default TabPanel;
