import { useEffect } from "react";
import { useTaskBoardStateContext, useTaskBoardDispatchContext } from "@app/Areas/Optimus/Apps/TaskBoardApp";
import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { BOARDS_CLEAR_BOARD_UNDER_ARCHIVE } from "../../../state/TaskBoardReducer/TaskBoardReducer";
import useBoardIdParam from "../useBoardIdParam";

function useBoardUnderArchive() {
    const vaultId = useVaultIdParam();
    const boardId = useBoardIdParam();

    const boardStateContext = useTaskBoardStateContext();
    const boardUnderArchive = boardStateContext.boards.selectedToDelete;
    const boardDispatch = useTaskBoardDispatchContext();

    useEffect(() => {
        return function cleanup() {
            boardDispatch({
                type: BOARDS_CLEAR_BOARD_UNDER_ARCHIVE
            })
        }
    }, [vaultId])

    if (!boardUnderArchive) {
        return null;
    }

    const board = boardStateContext?.boards?.byId[boardUnderArchive];

    if (
        !board
        || board.parentVaultId !== vaultId
        || board.board.id !== boardId) {
        return null;
    }

    return board;
}

export default useBoardUnderArchive;
