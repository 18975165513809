import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Link, Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Grid, makeStyles, Tab, Tabs, Theme, Typography } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
import { DeepThoughtIcon } from '@app/Areas/Optimus/Components/Shared/AppsIcons';
import DecksHub from '../../Decks/DecksHub/DecksHub';
import { DeepThoughtColor } from '@optimus/Tokens/colors';
import DeckView from '../../Decks/DecksHub/DeckView/DeckView';
import ResourcesHub from '../../Resources/ResourcesHub';

interface DeepThoughtTabProps {
    setIndex: () => void;
}

function DeepThoughtTab({ setIndex, children }: PropsWithChildren<DeepThoughtTabProps>) {
    useEffect(() => {
        setIndex()
    }, [setIndex]);

    return <>{ children }</>
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            padding: '0 0.5rem',
        },
        tabs: {
            background: 'rgba(0,0,0, 0.2)'
        },
        deepThought: {
            color: DeepThoughtColor
        },
        consoleGrid: {
            display: 'grid',
            gridTemplateColumns: '1fr',
            gridTemplateRows: '50px 100%',
            height: '100%'
        }
    }),
);

function DeepThoughtTabs({
    tabIndex
}: {
    tabIndex: number
}) {
    const classes = useStyles();
    return (
        <Grid className={classes.tabs} container spacing={0}>
            <Grid className={classes.title} spacing={1} container item
                // xs={12} sm={12} md={2} lg={2} xl={2}
                alignItems="center"
                xs
            >
                <Grid item alignItems="center">
                    <DeepThoughtIcon className={classes.deepThought} />
                </Grid>
                <Grid item alignItems="center" xs>
                    <Typography color="textPrimary" variant="h6">DeepThought</Typography>
                </Grid>
            </Grid>
            <Grid item
                xs={4} sm={4} md={4} lg={4} xl={4}
            >
                <Tabs
                    value={tabIndex}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab component={Link} to="resources"label="Resources" />
                    <Tab component={Link} to="videos" label="Videos"/>
                    <Tab component={Link} to="decks" label="Decks" />
                </Tabs>
            </Grid>
        </Grid>
    );
}

function DeepThoughtPageChrome({
    tabIndex,
    children
}: PropsWithChildren<{
    tabIndex: number
}>) {
    const classes = useStyles();
    return (
        <>
            <div>
                <div>
                    <DeepThoughtTabs tabIndex={tabIndex} />
                </div>
                <div style={{overflowY: 'auto'}}>
                    {children}
                </div>
            </div>
        </>
    )
}

function DeepThoughtGridChrome({
    tabIndex,
    children
}: PropsWithChildren<{
    tabIndex: number
}>) {
    const classes = useStyles();
    return (
        <>
            <div style={{height: '100%', display: 'grid', gridTemplateRows: '50px 1fr', gridTemplateColumns: '1fr'}}>
                <div>
                    <DeepThoughtTabs tabIndex={tabIndex} />
                </div>
                <div style={{overflowY: 'auto'}}>
                    {children}
                </div>
            </div>
        </>
    )
}

function DeepThoughtRouting() {
    const [value, setValue] = useState(0);
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/sections`}>
                <DeepThoughtTab setIndex={() => setValue(0)}>
                    <DeepThoughtTabs tabIndex={value} />
                    <div>
                        <ResourcesHub />
                    </div>
                </DeepThoughtTab>
            </Route>
            <Route path={`${path}/videos`}>
                <DeepThoughtTab setIndex={() => setValue(1)}>
                    Videos
                </DeepThoughtTab>
            </Route>
            <Route path={`${path}/decks/:deckId`}>
                <DeepThoughtTab setIndex={() => setValue(2)}>
                    <DeepThoughtGridChrome tabIndex={value}>
                        <DeckView />
                    </DeepThoughtGridChrome>
                </DeepThoughtTab>
            </Route>
            <Route path={`${path}/decks`}>
                <DeepThoughtTab setIndex={() => setValue(2)}>
                    <DeepThoughtPageChrome tabIndex={value}>
                        <DecksHub />
                    </DeepThoughtPageChrome>
                </DeepThoughtTab>
            </Route>
            <Route path={`${path}`}>
                <Redirect to={`deepthought/decks`} />
            </Route>
        </Switch>
    )
}

export default DeepThoughtRouting;
