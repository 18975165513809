import { useEffect } from 'react';
import { useSectionDispatchContext, useSectionStateContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider';
import { RESOURCES_CLEAR_SECTION_UNDER_DELETE } from '../../ResourceReducer/ResourceReducer';
import useVaultIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam';

function useSectionUnderDelete() {
    const vaultId = useVaultIdParam();
    const sectionContextDispatchContext = useSectionDispatchContext();
    const sectionStateContext = useSectionStateContext();
    const sectionUnderDeleteId = sectionStateContext.sections.selectedToDelete;

    useEffect(() => {
        return function cleanup() {
            sectionContextDispatchContext({ type: RESOURCES_CLEAR_SECTION_UNDER_DELETE })
        }
    }, [vaultId])

    if (!sectionUnderDeleteId) {
        return null;
    }

    const section = sectionStateContext?.sections?.byId[sectionUnderDeleteId];

    if (!section || section.parentVaultId !== vaultId) {
        return null
    }

    return section;
}

export default useSectionUnderDelete;
