import { VideosClient, IVideoPlaylistApiModel, IVideoPlaylistCreationRequest, IVideoPlaylistUpdateRequest, VideoPlaylistCreationRequest, VideoPlaylistUpdateRequest, YouTubePlaylistImportRequest, VideoPlaylistApiModel } from "../../ApiClient/VideosClient";

export interface IPlaylistService {
    getPlaylists: (vaultId: number) => Promise<IVideoPlaylistApiModel[]>;
    createPlaylist: (vaultId: number, creationRequest: IVideoPlaylistCreationRequest) => Promise<IVideoPlaylistApiModel>;
    updatePlaylist: (vaultId: number, playlistId: number, updateRequest: IVideoPlaylistUpdateRequest) => Promise<VideoPlaylistApiModel>;
    archivePlaylist: (vaultId: number, playlistId: number) => Promise<IVideoPlaylistApiModel>;
    importPlaylist: (vaultId: number, youtubePlaylistId: string) => Promise<VideoPlaylistApiModel>;
}

function createPlaylistService() {
    const client = new VideosClient();
    const playlistService: IPlaylistService = {
        getPlaylists: (vaultId: number) => client.videoPlaylistsAll(vaultId),
        createPlaylist: (vaultId: number, creationRequest: IVideoPlaylistCreationRequest) => client.videoPlaylistsPOST(vaultId, new VideoPlaylistCreationRequest(creationRequest)),
        updatePlaylist: (vaultId: number, playlistId: number, updateRequest: IVideoPlaylistUpdateRequest) => client.videoPlaylistsPUT(vaultId, playlistId, new VideoPlaylistUpdateRequest(updateRequest)),
        archivePlaylist: (vaultId: number, playlistId: number) => client.videoPlaylistsDELETE(vaultId, playlistId, false),
        importPlaylist: (vaultId: number, youtubePlaylistId: string) => client.youtubeImport(vaultId, new YouTubePlaylistImportRequest({
            sourceId: youtubePlaylistId
        }))
    }
    return playlistService;
}

export default createPlaylistService;
