import { useTaskBoardDispatchContext } from "@app/Areas/Optimus/Apps/TaskBoardApp";
import EditorDialog from "@app/Areas/Optimus/Components/Shared/EditorDialog";
import { TRANSFER_TASKLIST_DIALOG_ID } from "@app/Areas/Optimus/Ecosystems/ActionDialogs";
import useCurrentVault from "@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useCurrentVault";
import { Grid } from "@material-ui/core";
import { useCallback, useRef, useState } from "react";
import useTaskListUnderTransfer from "../../../hooks/_TaskLists/useTaskListUnderTransfer";
import createTaskListService from "../../../services/TaskListsService";
import { BOARDS_CLEAR_TASKITEM_UNDER_TRANSFER, getVaultBoardTaskLists } from "../../../state/TaskBoardReducer/TaskBoardReducer";
import TransferEditor from "./TransferEditor";

function TransferTaskList() {
    const [isSending, setIsSending] = useState(false);
    const [hasTransferred, setHasTransferred] = useState(false);
    const isMounted = useRef(true);
    const currentVault = useCurrentVault();
    const taskListUnderTransfer = useTaskListUnderTransfer();
    const taskList = taskListUnderTransfer?.taskList;
    const taskListDispatch = useTaskBoardDispatchContext();

    const [selectedBoard, setSelectedBoard] = useState<number | null>(null);
    const taskListService = createTaskListService();

    const sendRequest = useCallback(async () => {
        if (
            taskList?.id && taskListUnderTransfer && selectedBoard
        ) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)

            const result = await taskListService.transferTaskList(
                taskListUnderTransfer.parentVaultId,
                taskListUnderTransfer.parentBoardId,
                taskList.id, {
                destinationVaultId: taskListUnderTransfer.parentVaultId,
                destinationBoardId: selectedBoard,
            });

            if (result) {
                setHasTransferred(true);                
            }
            

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
    }, [taskListUnderTransfer, selectedBoard])

    const handleSave = () => {
        if (!hasTransferred && !isSending) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        taskListDispatch({
            type: BOARDS_CLEAR_TASKITEM_UNDER_TRANSFER
        })
        setHasTransferred(false);

        if (taskListUnderTransfer !== null) {
            taskListDispatch(getVaultBoardTaskLists(
                taskListUnderTransfer.parentVaultId,
                taskListUnderTransfer.parentBoardId,
                false
            ));
        }
    }

    return currentVault && taskList && (
        <EditorDialog
            title="Transfer Task Item"
            saveTitle="Transfer"
            dialogId={TRANSFER_TASKLIST_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            <Grid item xs={12}>
                <h3>Transfer TaskList - "{taskList.name}"</h3>
            </Grid>            
            { hasTransferred && <p>Successfully transferred "{taskList.name}"</p>}
            { !hasTransferred && <TransferEditor
                selectedBoard={selectedBoard}
                setSelectedBoard={setSelectedBoard}
            /> }
        </EditorDialog> ) || null;
}

export default TransferTaskList;

