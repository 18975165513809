import useVaultIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import useSectionIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useSectionIdParam';
import useGroupIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useGroupIdParam';
import useResourceIdParam from '../../../../App/NavigationContext/hooks/useResourceIdParam/useResourceIdParam';
import { IResourcesContextModel } from '../../ResourceReducer/ResourceReducer';
import { useSectionStateContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider';

function useCurrentResource() {
    const vaultId = useVaultIdParam();
    const sectionId = useSectionIdParam();
    const groupId = useGroupIdParam();
    const resourceId = useResourceIdParam();

    const { resources }: IResourcesContextModel = useSectionStateContext();

    if (!vaultId || !sectionId || !groupId || !resourceId) {
        return null;
    }

    const resource = resources.byId[resourceId];

    if (!resource) {
        return null;
    }

    const matchingParent = resource.parents.find((p) => p.parentVaultId === vaultId && p.parentSectionId === sectionId && p.parentGroupId === groupId);

    if (!matchingParent) {
        return null;
    }

    return resource;
}

export default useCurrentResource;
