import { DeckCreationRequest, DecksClient, DeckUpdateRequest, IDeckApiModel, IDeckCreationRequest, IDeckUpdateRequest } from "../../ApiClient/DecksClient";

export interface IDeckService {
    getDecks: (vaultId: number) => Promise<IDeckApiModel[]>;
    getDeck: (vaultId: number, deckId: number) => Promise<IDeckApiModel>;
    createDeck: (vaultId: number, creationRequest: IDeckCreationRequest) => Promise<IDeckApiModel>;
    updateDeck: (vaultId: number, deckId: number, updateRequest: IDeckUpdateRequest) => Promise<IDeckApiModel>;
    archiveDeck: (vaultId: number, deckId: number) => Promise<IDeckApiModel>;
}

function createDeckService() {
    const client = new DecksClient();
    const deckService: IDeckService = {
        getDecks: (vaultId: number) => client.decksAll(vaultId),
        getDeck: (vaultId: number, deckId: number) => client.decksGET(vaultId, deckId),
        createDeck: (vaultId: number, creationRequest: IDeckCreationRequest) => client.decksPOST(vaultId, new DeckCreationRequest(creationRequest)),
        updateDeck: (vaultId: number, deckId: number, updateRequest: IDeckUpdateRequest) => client.decksPUT(vaultId, deckId, new DeckUpdateRequest(updateRequest)),
        archiveDeck: (vaultId: number, deckId: number) => client.decksDELETE(vaultId, deckId, false)
    };

    return deckService;
}

export default createDeckService;
