import { matchPath, useLocation } from "react-router-dom";

function useGroupIdParam() {
    const { pathname } = useLocation();
    const match = matchPath<{
        vaultId?: string;
        sectionId?: string;
        groupId?: string;
    }>(pathname, {
        path: "/optimus/vaults/:vaultId/deepthought/sections/:sectionId/groups/:groupId",
        exact: false,
        strict: false
    });

    const groupId = match?.params?.groupId;

    if (groupId) {
        return +groupId;
    }

    return null;
}

export default useGroupIdParam;
