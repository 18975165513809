import { useCallback, useEffect, useRef } from 'react';
import { useState } from 'reinspect';
import EditorDialog from '../../../Components/Shared/EditorDialog/EditorDialog';
import { EDIT_PROJECT_DIALOG_ID } from '../../ActionDialogs/index';
import ProjectEditor from '../NewProject/ProjectEditor';
import { ProjectEditorModel } from '../NewProject/ProjectEditorModel';
import createProjectService from '@optimus/Services/ProjectService';
import { getVaultProjects, PROJECTS_CLEAR_EDIT } from '@app/Areas/Optimus/State/DataStores/ProjectStore/ProjectReducer/ProjectReducer';
import useProjectUnderEdit from '../../../State/DataStores/ProjectStore/hooks/useProjectUnderEdit/useProjectUnderEdit';
import { useProjectDispatchContext } from '@app/Areas/Optimus/State/DataStores/ProjectStore/ProjectContextProvider';
import useVaultsAsDictionary from '../../../State/DataStores/VaultStore/hooks/useVaultsAsDictionary/useVaultsAsDictionary';
import { IProjectUpdateRequest, IProjectApiModel } from '@app/Areas/Optimus/ApiClient/ProjectsClient';

const blankProject: IProjectUpdateRequest = {
    projectName: '',
    urls: {},
    tags: [],
    newParentId: 0
};

function mapCreationDetailsToProject(
    project: IProjectUpdateRequest
): ProjectEditorModel {
    return {
        name: project.projectName,
        urls: project.urls,
        tags: project.tags
    }
}

function EditProject() {
    const projectUnderEdit = useProjectUnderEdit();
    const vaults = useVaultsAsDictionary();
    const [isSending, setIsSending] = useState(false, 'isSending')
    const [projectUpdateRequest, setProjectUpdateRequest] = useState<IProjectUpdateRequest | null>(blankProject, 'projectUpdateRequest');
    const [updatedProject, setUpdatedProject] = useState<IProjectApiModel | null>(null, 'updatedProject');
    const projectService = createProjectService();
    const projectsDispatch = useProjectDispatchContext();
    const isMounted = useRef(true);
    const project = projectUnderEdit?.project;
    const currentParentVaultId = projectUnderEdit?.parentVaultId;
    const currentParentVault = currentParentVaultId ? vaults[currentParentVaultId] : null;

    useEffect(() => {
        if (!!projectUnderEdit?.project && !!projectUnderEdit.parentVaultId) {
            setProjectUpdateRequest({
                ...projectUpdateRequest,
                newParentId: projectUpdateRequest?.newParentId || projectUnderEdit.parentVaultId,
                projectName: projectUnderEdit.project.name || '',
                urls: projectUnderEdit.project.urls || {},
                tags: [] // TODO: Fix missing tags in Api Model
            });
        }
    }, [projectUnderEdit]);

    function projectEdited(event: any) {
        if (projectUpdateRequest !== null) {
            setProjectUpdateRequest({
                ...projectUpdateRequest,
                [event.target.name]: event.target.value
            })
        }
    }

    const handleSave = () => {
        if (!updatedProject && !isSending) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        setProjectUpdateRequest(null);
        setUpdatedProject(null);
        projectsDispatch({
            type: PROJECTS_CLEAR_EDIT
        })
    }

    const sendRequest = useCallback(async () => {
        if (currentParentVaultId && project?.id && !!projectUpdateRequest) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await projectService.updateProject(currentParentVaultId, project.id, projectUpdateRequest);
            if (result) {
                setUpdatedProject(result);
            }
            projectsDispatch(getVaultProjects(currentParentVaultId));

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [ currentParentVaultId, projectUpdateRequest, isSending]
    ); // update the callback if the state changes

    return currentParentVault && projectUpdateRequest && (
        <EditorDialog
            title="Edit Project"
            saveTitle="Update Project"
            dialogId={EDIT_PROJECT_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            { !updatedProject && <ProjectEditor
                project={mapCreationDetailsToProject(projectUpdateRequest)}
                handleProjectChange={projectEdited}
                title={`Edit - ${currentParentVault.title}`}
                isSaving={isSending}
            /> }
            { updatedProject && JSON.stringify(updatedProject) }
        </EditorDialog>
    ) || null;
}

export default EditProject;
