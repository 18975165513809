import { useTaskBoardStateContext } from "../../../state/TaskBoardContextProvider";
import { ITaskBoardContextModel } from "../../../state/TaskBoardReducer/TaskBoardReducer";

function useBoardMode() {
    const state: ITaskBoardContextModel = useTaskBoardStateContext();

    return state.boardMode;
}

export default useBoardMode;
