import PanelHeader from '@app/Areas/Optimus/Components/Shared/PanelHeader';
import { createStyles, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { PropsWithChildren } from 'react';
import { ProtectionEditorModel } from './ProtectionEditorModel';


export interface ProtectionEditorProps {
    board: ProtectionEditorModel;
    handleChange: (event: any) => void;
    title?: string;
    isSaving: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
        marginBottom: 20
    },
    form: {
        padding: '0.75rem'
    }
  }),
);

function ProtectionEditor({
    title,
    isSaving,
    board: entity,
    handleChange,
    children
}: PropsWithChildren<ProtectionEditorProps>) {
    const classes = useStyles();

    return (
        <form className={classes.form}>
            { isSaving && <Typography variant="h4">Protecting...</Typography> }
            <PanelHeader headerVariant="h5" header={`Protect - ${title}`}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {children}
                        <br/>
                        <TextField
                            className={classes.input}
                            fullWidth id="protection-key-id-input"
                            label="Protection Key Id"
                            name="protectionKeyId"
                            value={entity.protectionKeyId}
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </PanelHeader>
        </form>
    )
}

export default ProtectionEditor;
