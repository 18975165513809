import { useEffect } from 'react';
import useVaultIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useSectionDispatchContext, useSectionStateContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider';
import { RESOURCES_CLEAR_SECTION_UNDER_EDIT } from '../../ResourceReducer/ResourceReducer';


function useSectionUnderEdit() {
    const vaultId = useVaultIdParam();

    const sectionDispatch = useSectionDispatchContext();
    const sectionStateContext = useSectionStateContext();
    const sectionUnderEditId = sectionStateContext.sections.selectedToEdit;

    useEffect(() => {
        return function cleanup() {
            sectionDispatch({ type: RESOURCES_CLEAR_SECTION_UNDER_EDIT })
        }
    }, [vaultId]);

    if (!vaultId || !sectionUnderEditId) {
        return null;
    }

    const section = sectionStateContext?.sections?.byId[sectionUnderEditId];

    if (!section || section.parentVaultId !== vaultId) {
        return null;
    }

    return section;
}

export default useSectionUnderEdit;
