import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { useVideoStateContext } from "../../VideoContextProvider/VideoContextProvider";
import { IVideoContextModel } from "../../VideoReducer/VideoReducer";
import usePlaylistIdParam from "../usePlaylistIdParam";
import useVideoIdParam from "../useVideoIdParam/useVideoIdParam";

function useCurrentVideo() {
    const vaultId = useVaultIdParam();
    const playlistId = usePlaylistIdParam();
    const videoId = useVideoIdParam();

    const { videos }: IVideoContextModel = useVideoStateContext();

    if (!vaultId || !playlistId || !videoId) {
        return null;
    }

    const video = videos.byId[videoId];

    if(!video) {
        return null;
    }

    const matchingParent = video.parents.find(p => p.parentVaultId === vaultId && p.parentPlaylistId === playlistId);

    if(!matchingParent) {
        return null;
    }

    return video.video;
}

export default useCurrentVideo;
