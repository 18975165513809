import { useEffect } from "react";
import { useTaskBoardStateContext, useTaskBoardDispatchContext } from "@app/Areas/Optimus/Apps/TaskBoardApp";
import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { BOARDS_CLEAR_TASKITEM_UNDER_DELETE } from "../../../state/TaskBoardReducer/TaskBoardReducer";
import useBoardIdParam from "../../_Boards/useBoardIdParam";

function useTaskItemUnderDelete() {
    const vaultId = useVaultIdParam();
    const boardId = useBoardIdParam();

    const boardStateContext = useTaskBoardStateContext();
    const taskItemUnderDelete = boardStateContext.taskItems.selectedToDelete;
    const boardDispatch = useTaskBoardDispatchContext();

    useEffect(() => {
        return function cleanup() {
            boardDispatch({
                type: BOARDS_CLEAR_TASKITEM_UNDER_DELETE
            })
        }
    }, [vaultId])

    if (!taskItemUnderDelete) {
        return null;
    }

    const taskItem = boardStateContext?.taskItems?.byId[taskItemUnderDelete];

    if (
        !taskItem
        || taskItem.parentVaultId !== vaultId
        || taskItem.parentBoardId !== boardId) {
        return null;
    }

    return taskItem;
}

export default useTaskItemUnderDelete;


