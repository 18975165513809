import { BookmarksClient, IResourceApiModel, IResourceCreationRequest, IResourceUpdateRequest, ResourceCreationRequest, ResourceUpdateRequest } from "../../ApiClient/BookmarksClient";

export interface IResourceService {
    createResource: (vaultId: number, creationRequest: IResourceCreationRequest) => Promise<IResourceApiModel>;
    getResources: (vaultId: number, sectionId: number, groupId: number) => Promise<IResourceApiModel[]>;
    getResource: (vaultId: number, sectionId: number, groupId: number, resourceId: number) => Promise<IResourceApiModel>;
    updateResource: (vaultId: number, resourceId: number, updateRequest: IResourceUpdateRequest) => Promise<IResourceApiModel>;
    archiveResource: (vaultId: number, sectionId: number, groupId: number, resourceId: number) => Promise<IResourceApiModel>;
}

function createResourceService() {
    const client = new BookmarksClient();
    const resourceService: IResourceService = {
        // Function to create a new resource
        createResource: (vaultId: number, creationRequest: IResourceCreationRequest) => client.resourcesPOST(vaultId, new ResourceCreationRequest(creationRequest)),
        // Function to get all resources
        getResources: (vaultId: number, sectionId: number, groupId: number) => client.resourcesAll(vaultId, sectionId, groupId),
        // Function to get a resource
        getResource: (vaultId: number, sectionId: number, groupId: number, resourceId: number) => client.resourcesGET(vaultId, sectionId, groupId, resourceId),
        // Function to update a resource
        updateResource: (vaultId: number, resourceId: number, updateRequest: IResourceUpdateRequest) => client.resourcesPUT(vaultId, resourceId, new ResourceUpdateRequest(updateRequest)),
        // Function to archive a resource
        archiveResource: (vaultId: number, sectionId: number, groupId: number, resourceId: number) => client.resourcesDELETE(vaultId, sectionId, groupId, resourceId, false),
    }

    return resourceService;
}

export default createResourceService;