import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { IDecksContextModel } from '../../DeckReducer/DeckReducer';
import { useDeckStateContext } from '../../DeckContextProvider';

function useVaultDecksAsArray() {
    const vaultId = useVaultIdParam();
    const state: IDecksContextModel = useDeckStateContext();

    if (vaultId === null) {
        return [];
    }

    const decks =
        Object.values(state.decks.byId)
            .filter((d) => d.parentVaultId === vaultId)
            .map(d => d.deck);

    return decks;
}

export default useVaultDecksAsArray;