import { useSectionDispatchContext, useSectionStateContext } from '../../ResourceContextProvider';
import useSectionIdParam from '../../../../App/NavigationContext/hooks/useSectionIdParam/useSectionIdParam';
import useGroupIdParam from '../../../../App/NavigationContext/hooks/useGroupIdParam/useGroupIdParam';
import useVaultIdParam from '../../../../App/NavigationContext/hooks/useVaultIdParam/useVaultIdParam';
import { RESOURCES_CLEAR_RESOURCE_UNDER_EDIT } from '../../ResourceReducer/ResourceReducer';
import { useEffect } from 'react';

function useResourceUnderEdit() {
    const vaultId = useVaultIdParam();
    const sectionId = useSectionIdParam();
    const groupId = useGroupIdParam();

    const resourceDispatch = useSectionDispatchContext();
    const resourceStateContext = useSectionStateContext();
    const resourceUnderEditId = resourceStateContext.resources.selectedToEdit;

    useEffect(() => {
        return function cleanup() {
            resourceDispatch({ type: RESOURCES_CLEAR_RESOURCE_UNDER_EDIT })
        }
    }, [vaultId, sectionId, groupId]);

    if (!vaultId || !sectionId || !groupId || !resourceUnderEditId) {
        return null;
    }

    const resource = resourceStateContext?.resources?.byId[resourceUnderEditId];

    const matchingParent = resource.parents.find((p) => p.parentVaultId === vaultId && p.parentSectionId === sectionId && p.parentGroupId === groupId);

    if (!matchingParent) {
        return null;
    }

    return resource;
}

export default useResourceUnderEdit;


