import { PropsWithChildren, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import BoardsHub from '../TaskBoardsHub/TaskBoardsHub';

interface DeepThoughtTabProps {
    setIndex: () => void;
}

function DeepThoughtTab({ setIndex, children }: PropsWithChildren<DeepThoughtTabProps>) {
    useEffect(() => {
        setIndex()
    }, [setIndex]);

    return <>{ children }</>
}

function TaskBoardRouting() {
    const [value, setValue] = useState(0);
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/boards`}>
                <DeepThoughtTab setIndex={() => setValue(0)}>
                    <BoardsHub />
                </DeepThoughtTab>
            </Route>
            <Route path={`${path}`}>
                <Redirect to={`taskboards/boards`} />
            </Route>
        </Switch>
    )
}

export default TaskBoardRouting;
