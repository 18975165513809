import React, { PropsWithChildren } from 'react';
import { Divider, SvgIconTypeMap, IconButton, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { AppCtaIcon } from '../AppsIcons';

type HeaderType = "inherit" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "srOnly" | undefined;

export interface PanelHeaderProps {
    header: string;
    headerVariant?: HeaderType;
    subheader?: string;
    Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    iconClass?: string;
    ctaAction?: () => void;
    components?: React.ReactNode;
    actions?: {
        label: string;
        action: () => void;
    }[]
    links?: any[]
}

function PanelHeader({
    header,
    headerVariant = "h4",
    subheader,
    Icon,
    iconClass,
    ctaAction,
    components,
    actions,
    links,
    children
}: PropsWithChildren<PanelHeaderProps>) {
    return (
        <Grid container spacing={1} alignItems="center">
            { Icon && <Grid item><Icon className={iconClass} /></Grid> }
            <Grid item xs>
                <Typography variant={headerVariant}>{header}</Typography>
            </Grid>
            { components && <Grid item xs>
                { components }
            </Grid> }
            { actions && actions.map(a => (
                <Grid item>
                    { a && <Button onClick={a.action}>
                        {a.label}
                    </Button>}
                </Grid>
            )) }
            { links }
            <Grid item>
                { ctaAction && <IconButton onClick={ctaAction}>
                    <AppCtaIcon />
                </IconButton>}
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid item xs={12}>
                { subheader && <Typography variant="subtitle2">
                    {subheader}
                </Typography> }
                { children }
            </Grid>
        </Grid>
    )
}

export default PanelHeader;
