import { useRouteMatch } from "react-router-dom";

export interface NavigatorRedirect {
    path: string;
    redirectPath: string;    
}

/**
 * Function to setup any paths that redirect to another path
 */
function useNavigatorRedirects() {
    // Get the current path from the router
    const { path } = useRouteMatch();   

    const redirects: NavigatorRedirect[] = [{
        path,
        redirectPath: `${path}/vaults`
    }]

    return redirects;
}

export default useNavigatorRedirects;