import { useTaskBoardDispatchContext } from "@app/Areas/Optimus/Apps/TaskBoardApp";
import EditorDialog from "@app/Areas/Optimus/Components/Shared/EditorDialog";
import { TRANSFER_TASKITEM_DIALOG_ID } from "@app/Areas/Optimus/Ecosystems/ActionDialogs";
import useCurrentVault from "@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useCurrentVault";
import { Grid } from "@material-ui/core";
import { useCallback, useRef, useState } from "react";
import useTaskItemUnderTransfer from "../../../hooks/_TaskItems/useTaskItemUnderTransfer";
import createTaskItemService from "../../../services/TaskItemService";
import { BOARDS_CLEAR_TASKITEM_UNDER_TRANSFER, getBoardTaskListItems, getVaultBoardTaskLists } from "../../../state/TaskBoardReducer/TaskBoardReducer";
import TransferEditor from './TransferEditor';

function TransferTaskItem() {
    const [isSending, setIsSending] = useState(false);
    const [hasTransferred, setHasTransferred] = useState(false);
    const isMounted = useRef(true);
    const currentVault = useCurrentVault();
    const taskItemUnderTransfer = useTaskItemUnderTransfer();
    const taskItem = taskItemUnderTransfer?.taskItem;
    const taskItemDispatch = useTaskBoardDispatchContext();

    const [selectedBoard, setSelectedBoard] = useState<number | null>(null);
    const [selectedTaskList, setSelectedTaskList] = useState< number | null >(null);
    const taskItemService = createTaskItemService();

    const sendRequest = useCallback(async () => {
        if (
            taskItem?.id && taskItemUnderTransfer && selectedTaskList && selectedBoard
        ) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)

            const result = await taskItemService.transferTaskItem(
                taskItemUnderTransfer.parentVaultId,
                taskItemUnderTransfer.parentBoardId,
                taskItemUnderTransfer.parentTaskListId,
                taskItem.id, {
                destinationBoardId: selectedBoard,
                destinationTaskListId: selectedTaskList,
            })
            if (result) {
                setHasTransferred(true);
                taskItemDispatch(getVaultBoardTaskLists(
                    taskItemUnderTransfer.parentVaultId,
                    taskItemUnderTransfer.parentBoardId,
                    false
                ));
                taskItemDispatch(getBoardTaskListItems(
                    taskItemUnderTransfer.parentVaultId,
                    taskItemUnderTransfer.parentBoardId,
                    taskItemUnderTransfer.parentTaskListId,
                    false
                ));
            }
            

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
    }, [taskItemUnderTransfer, selectedTaskList, selectedBoard])

    const handleSave = () => {
        if (!hasTransferred && !isSending) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        taskItemDispatch({
            type: BOARDS_CLEAR_TASKITEM_UNDER_TRANSFER
        })
        setHasTransferred(false);
    }

    return currentVault && taskItem && (
        <EditorDialog
            title="Transfer Task Item"
            saveTitle="Transfer"
            dialogId={TRANSFER_TASKITEM_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            <Grid item xs={12}>
                <h3>Transfer Task - "{taskItem.title}"</h3>
            </Grid>
            { hasTransferred && <p>Successfully transferred "{taskItem.title}"</p>}
            { !hasTransferred && <TransferEditor
                selectedBoard={selectedBoard}
                selectedTaskList={selectedTaskList}
                setSelectedBoard={setSelectedBoard}
                setSelectedTaskList={setSelectedTaskList}
            /> }
        </EditorDialog> ) || null;
}

export default TransferTaskItem;

