import React from 'react';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { DeepThoughtColor, ZiggyColor, JarvisColor, FridayColor } from '@optimus/Tokens/colors';
import ConsoleWrapper from '@optimus/Components/Shared/ConsoleWrapper';
import { useOptimusDispatchContext } from '@optimus/Contexts/OptimusContext';
import { NEW_PROJECT_DIALOG_ID, NEW_DECK_DIALOG_ID } from '../../ActionDialogs/index';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import Breadcrumb from '@optimus/Components/Shared/Breadcrumb/Breadcrumb';
import FeatureCard from '@optimus/Components/Shared/FeatureCard';
import { VaultIcon } from '../../../Components/Shared/AppsIcons/AppsIcons';
import PaperContent from '@optimus/Components/Shared/PaperContent';
import Panel from '@optimus/Components/Shared/Panel';
import PanelHeader from '@optimus/Components/Shared/PanelHeader';
import { DeepThoughtIcon, DeckIcon, FridayIcon, ProjectIcon, ZiggyIcon, JarvisIcon } from '@optimus/Components/Shared/AppsIcons';
import EntityStats from '@optimus/Components/Shared/EntityStats';
import useVaultIdParam from '../../../State/App/NavigationContext/hooks/useVaultIdParam/useVaultIdParam';
import { OPTIMUS_OPEN_DIALOG } from '@app/Areas/Optimus/State/App/DialogContext/DialogReducer/DialogReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deepThought: {
        color: DeepThoughtColor
    },
    ziggy: {
      color: ZiggyColor
    },
    jarvis: {
      color: JarvisColor
    },
    friday: {
      color: FridayColor
    },
    launchButton: {
        marginLeft: 'auto'
    }
  }),
);

function VaultViewLanding() {
    const classes = useStyles();
    const history = useHistory();
    const { path} = useRouteMatch();
    const dispatch = useOptimusDispatchContext();
    const currentVaultId = useVaultIdParam();

    // const projects = useProjects();
    // const decks = useDecks();

    const openNewProjectDialog = () => {
        dispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: NEW_PROJECT_DIALOG_ID
        })
    }

    const openNewDeckDialog = () => {
        dispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: NEW_DECK_DIALOG_ID
        })
    }

    const goToFriday = () => {
        if (currentVaultId) {
            const basePath = generatePath(path, { vaultId: currentVaultId });
            history.push(`${basePath}/friday`)
        }
    }

    const goToDeepThought = () => {
        if (currentVaultId) {
            const basePath = generatePath(path, { vaultId: currentVaultId });
            history.push(`${basePath}/deepthought`)
        }
    }

    return (
        <>
            <Breadcrumb fragments={[{
                label: 'Vaults',
                path: '/vaults'
            }, {
                label: 'Vaults',
                path: '/vaults'
            }]} />
            <ConsoleWrapper>
                <Grid item container spacing={4}>
                    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                        <FeatureCard title="Vault" Icon={() => <VaultIcon style={{width: '2em', height: '2em'}} />}>
                            <PaperContent>
                                <Typography variant="body1">
                                    A Description for the Vault needs to go here
                                </Typography>
                            </PaperContent>
                        </FeatureCard>
                    </Grid>
                    <Grid key="apps" item xs={12}>
                        <Panel>
                            {/* <p>Vault View</p>
                            <ul>
                                <li><Link to={`/optimus/vaults/${vaultId}/resources`}>Resources</Link></li>
                                <li><Link to={`/optimus/vaults/${vaultId}/feeds`}>Feeds</Link></li>
                            </ul> */}
                            {/* <Switch>
                                <Route path={`${path}/resources`}>
                                    <ResourcesHub />
                                </Route>
                                <Route path={`${path}/feeds`}>
                                    <FeedsHub />
                                </Route>
                            </Switch> */}
                            <Grid item container spacing={4}>
                                <Grid key="description" item xs={12}>
                                    <PanelHeader
                                        header="Apps"
                                        subheader="Here are all the apps available in the Vault"
                                    />
                                </Grid>
                                <Grid key="deepthought" item xs={12}>
                                    <Panel>
                                        <Grid container spacing={1} alignItems="center">
                                            <PanelHeader
                                                header="DeepThought"
                                                subheader="DeepThought is a learning resource tool, used to collate bookmarked resources, videos and note taking."
                                                Icon={DeepThoughtIcon}
                                                iconClass={classes.deepThought}
                                                ctaAction={goToDeepThought}
                                            />
                                            <Grid item xs={12} container spacing={4}>
                                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                                    {/* <EntityStats /> */}
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                                    {/* <EntityStats /> */}
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                                    {/* <EntityStats
                                                        header="Decks and Cards"
                                                        description="Decks contain cards for note taking, revision and presentation purposes. Cards are meant to hold small, atomic pieces of information."
                                                        actions={[{
                                                            label: 'Add new Deck',
                                                            action: openNewDeckDialog
                                                        }]}
                                                        stats={[{
                                                            label: 'Decks',
                                                            total: Object.entries(decks).length,
                                                            icon: DeckIcon
                                                        }]}
                                                    /> */}
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                                    {/* <EntityStats /> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Panel>
                                </Grid>
                                <Grid key="jarvis" item xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <Panel>
                                        <Grid container spacing={1} alignItems="center">
                                            <PanelHeader
                                                header="Jarvis"
                                                subheader="Jarvis is a personal assistant and metric tracking tool. It collates feeds of data and acts as a conduit to send commands and messages to external services."
                                                Icon={JarvisIcon}
                                                iconClass={classes.jarvis}
                                            />
                                            {/* <Grid item>
                                                <IconButton aria-label="edit vault" className={classes.launchButton}>
                                                    <Launch />
                                                </IconButton>
                                            </Grid> */}
                                            <Grid item xs={12} container spacing={4}>
                                                <Grid item xs={12}>
                                                    {/* <EntityStats /> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Panel>
                                </Grid>
                                <Grid key="friday" item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <Panel>
                                        <Grid container spacing={1} alignItems="center">
                                            <PanelHeader
                                                header="Friday"
                                                subheader="Friday is a project tracking tool. You can use it to track projects, their repositories and their deployed locations."
                                                Icon={FridayIcon}
                                                iconClass={classes.friday}
                                                ctaAction={goToFriday}
                                            />
                                            <Grid item xs={12} container spacing={4}>
                                                <Grid item xs={12}>
                                                    {/* <EntityStats
                                                        header="Projects and Repositories"
                                                        description="Projects are a collection of Code Projects, Repositories are where the code for this project is stored, how to fetch it and where it's deployed."
                                                        actions={[{
                                                            label: 'Add new Project',
                                                            action: openNewProjectDialog
                                                        }]}
                                                        stats={[{
                                                            label: 'Projects',
                                                            total: Object.entries(projects).length,
                                                            icon: ProjectIcon
                                                        }]}
                                                    /> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Panel>
                                </Grid>
                                <Grid key="ziggy" item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <Panel>
                                        <Grid container spacing={1} alignItems="center">
                                            <PanelHeader
                                                header="Ziggy"
                                                subheader="Ziggy is a project and task management tool. It can be used to group Tasks and Goals into organised lists and workflows."
                                                Icon={ZiggyIcon}
                                                iconClass={classes.ziggy}
                                            />
                                            <Grid item xs={12} container spacing={4}>
                                                <Grid item xs={12}>
                                                    {/* <EntityStats /> */}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Panel>
                                </Grid>
                            </Grid>
                        </Panel>
                    </Grid>
                </Grid>
            </ConsoleWrapper>
        </>
    )
}

export default VaultViewLanding;
