import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useSectionStateContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider';
import { IResourcesContextModel } from '../../ResourceReducer/ResourceReducer';
import { ISectionApiModel } from '@app/Areas/Optimus/ApiClient/BookmarksClient';

function useVaultSectionsAsDictionary(presetVaultId: number | null = null): {
    [key: number]: ISectionApiModel,
 } {
    const vaultIdParam = useVaultIdParam();
    const vaultId = presetVaultId !== null ? presetVaultId : vaultIdParam;        

    const state: IResourcesContextModel = useSectionStateContext();

    if (vaultId === null) {
        return {};
    }

    const sections =
        Object.entries(state.sections.byId)
            .filter(([key, value]) => value.parentVaultId === vaultId)
            .reduce((map, [key, value]) => ({
                ...map,
                [key]: value.section,
            }), {});

    return sections;
}

export default useVaultSectionsAsDictionary;