import React from 'react';
import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';

const useMaterialClasses = makeStyles((theme: Theme) =>
    {
        return createStyles({
            root: {
              background: 'rgba(0,0,0,0.05)',
              backdropFilter: 'blur(10px)',
              boxShadow: `0 0px 10px #3cb8dc99`,
              border: `1px solid #3cb8dc99`,
            },
          })
    }
)

function Pane({
    rootClass,
    children
}:  React.PropsWithChildren<{
    rootClass?: string
}>) {
    const classes = useMaterialClasses();
    return (
        <Paper className={`${classes.root} ${rootClass}`}>
                {children}
        </Paper>
    )
}

export default Pane;
