import React from 'react';
import { createGlobalStyle } from 'styled-components'
import { GlobalStyles as TwinStyles } from 'twin.macro'

// Add global styles
const GlobalStyle = createGlobalStyle`
    html {
    }

    body {
        margin: 0;
        padding: 0;
        font-family: sans-serif;
    }

    p {
        margin: 0;
    }
`

function Styles() {
    return (
        <>
            <GlobalStyle />
            <TwinStyles />
        </>
    )
}

export default Styles;
