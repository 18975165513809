import { useVaultStateContext } from '../../VaultContextProvider';


function useVaultUnderEdit() {

    const vaultStateContext = useVaultStateContext();
    const vaultUnderEditId = vaultStateContext.vaults.selectedToEdit;

    if (!vaultUnderEditId) {
        return null;
    }

    const vault = vaultStateContext?.vaults?.byId[vaultUnderEditId];

    if (!vault) {
        return null;
    }

    return vault;
}

export default useVaultUnderEdit;
