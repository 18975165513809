import { Dispatch } from 'react';
import createVaultService from '@optimus/Services/VaultService';
import { IVaultApiModel } from '@app/Areas/Optimus/ApiClient/VaultsClient';

export const VAULTS_SET_INITIAL_VAULTS = 'VAULTS_SET_INITIAL_VAULTS';
export const VAULTS_SET_ACTIVE_VAULT = 'VAULTS_SET_ACTIVE_VAULT';
export const VAULTS_SET_VAULT_UNDER_EDIT = 'VAULTS_SET_VAULT_UNDER_EDIT';
export const VAULTS_CLEAR_VAULT_UNDER_EDIT = 'VAULTS_CLEAR_VAULT_UNDER_EDIT';
export const VAULTS_SET_VAULT_UNDER_DELETE = 'VAULTS_SET_VAULT_UNDER_DELETE';
export const VAULTS_CLEAR_VAULT_UNDER_DELETE = 'VAULTS_CLEAR_VAULT_UNDER_DELETE';

export interface IVaultsContextModel {
    vaults: {
        byId: {
            [vaultId: string]: {
                vault: IVaultApiModel
                sectionIds: string[];
                projectIds: string[];
                deckIds: string[];
                playlistIds: string[];
                boardIds: string[];
                initiativeIds: string[];
                feedIds: string[];
            }
        },
        allIds: string[];
        selected: string | null;
        selectedToEdit: string | null;
        selectedToDelete: string | null;
        loadingState: 'unloaded' | 'loading' | 'loaded' | 'error';
    }
}

export function reduceVaultsToState(
  state: IVaultsContextModel,
  vaults: IVaultApiModel[]
) {
  return {
    ...state,
    vaults: {
      ...state.vaults,
      byId: {
        ...vaults.reduce((newVaults, v) => {
          if (v.id) {
            return {
              ...newVaults,
              [v.id]: {
                vault: v,
                sectionIds: [],
                projectIds: [],
                deckIds: [],
                playlistIds: [],
                boardIds: [],
                initiativeIds: [],
                feedIds: [],
              }
            }
          }
          return newVaults;
        }, {})
      },
      allIds: [
        ...vaults.map(v => v.id)
      ],
      loadingState: 'loaded'
    }
  }
}

function VaultContextReducer(state: IVaultsContextModel, action: any) {
    switch (action.type) {
        case VAULTS_SET_INITIAL_VAULTS:
          return reduceVaultsToState(state, action.vaults);
        case VAULTS_SET_ACTIVE_VAULT:
          return {
            ...state,
            vaults: {
              ...state.vaults,
              selected: action.activeVaultId
            }
          };
        case VAULTS_SET_VAULT_UNDER_EDIT: {
            return {
                ...state,
                vaults: {
                  ...state.vaults,
                    selectedToEdit: action.vaultId
                }
            }
        }
        case VAULTS_CLEAR_VAULT_UNDER_EDIT: {
            return {
                ...state,
                vaults: {
                  ...state.vaults,
                    selectedToEdit: null
                }
            }
        }
        case VAULTS_SET_VAULT_UNDER_DELETE: {
            return {
                ...state,
                vaults: {
                    ...state.vaults,
                    selectedToDelete: action.vaultId
                }
            }
        }
        case VAULTS_CLEAR_VAULT_UNDER_DELETE: {
            return {
                ...state,
                vaults: {
                    ...state.vaults,
                    selectedToDelete: null
                }
            }
        }
        default:
            throw new Error();
    }
}

export default VaultContextReducer;

/**
 * Action creator for fetching the user vaults and setting the initial vault.
 */
export function getInitialUserVaults() {
    return async (dispatch: Dispatch<any>) => {
      const vaultService = await createVaultService();
      return vaultService.getVaults().then((result) => {
        const filteredVaults: IVaultApiModel[] = result.filter((v: IVaultApiModel) => !!v.id);
        dispatch({
          type: VAULTS_SET_INITIAL_VAULTS,
          vaults: filteredVaults,
        })
      });
    }
  }

/**
 * Action creator for setting the currently active vault id
 * @param activeVaultId
 */
export function setActiveVaultId(activeVaultId: number) {
  return {
    type: VAULTS_SET_ACTIVE_VAULT,
    activeVaultId
  };
}

  /**
 * Action creator for setting the currently active vault Id
 */
export function setActiveVaultIdFromPath(vaults: {
  [key: string]: IVaultApiModel;
},
  currentActiveVaultId: number | null,
  newActiveVaultId?: string
) {
  const activeVaultId =
    Object.keys(vaults).length > 0 &&
    (newActiveVaultId && vaults[newActiveVaultId] && +newActiveVaultId)
    || (Object.entries(vaults)[0][0])
    || null;

  return {
    type: VAULTS_SET_ACTIVE_VAULT,
    activeVaultId
  };
}