import React from 'react';
import { AppBar, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useOptimusStateContext } from '@optimus/Contexts/OptimusContext';
import DesktopToolbar from './Components/DesktopToolbar';
import useVaultsAsArray from '../../State/DataStores/VaultStore/hooks/useVaultsAsArray/useVaultsAsArray';
import useVaultIdParam from '../../State/App/NavigationContext/hooks/useVaultIdParam/useVaultIdParam';

const useBackgroundStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        background: 'rgba(0,0,0,0.2)',
        backdropFilter: 'blur(10px)',
        boxShadow: `0 2px 10px ${theme.palette.secondary.main}`,
    },
}))

function OptimalHeaderBar() {
    const classes = useBackgroundStyles()
    const { userprofile } = useOptimusStateContext();
    const vaults = useVaultsAsArray();
    const selectedVault = useVaultIdParam();
    const menuId = 'primary-optimus-app-bar-menu';

    return (
        <AppBar className={classes.root}>
            <DesktopToolbar
                menuId={menuId}
                username={userprofile?.username}
                selectedVault={selectedVault}
                vaults={vaults}
            />
        </AppBar>
    )
}

export default OptimalHeaderBar;
