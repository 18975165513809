import { matchPath, useLocation } from "react-router";

function useVideoIdParam() {
    const { pathname } = useLocation();
    const match = matchPath<{
        vaultId?: string;
        videoId?: string;
    }>(pathname, {
        path: "/optimus/vaults/:vaultId/playlists/:playlistId/videos/:videoId",
        exact: false,
        strict: false
    });

    const videoId = match?.params?.videoId;

    if (videoId) {
        return +videoId;
    }

    return null;
}

export default useVideoIdParam;
