import { IVideoApiModel } from '@app/Areas/Optimus/ApiClient/VideosClient';
import useVaultIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useVideoStateContext } from '../../VideoContextProvider/VideoContextProvider';
import { IVideoContextModel } from '../../VideoReducer/VideoReducer';
import usePlaylistIdParam from '../usePlaylistIdParam';

function usePlaylistVideosAsArray(): IVideoApiModel[] {
    const vaultId = useVaultIdParam();
    const playlistId = usePlaylistIdParam();

    const state: IVideoContextModel = useVideoStateContext();

    const videos =
        Object.values(state.videos.byId)
            .filter((value) => value.parents.some(({ parentVaultId, parentPlaylistId }) =>
                parentVaultId === vaultId && parentPlaylistId === playlistId
            ))
            .map(v => v.video);

    return videos;
}

export default usePlaylistVideosAsArray;
