import { BookmarksClient, GroupUpdateRequest, IGroupApiModel, IGroupCreationRequest, GroupCreationRequest, IGroupUpdateRequest } from "../../ApiClient/BookmarksClient";

export interface IGroupService {
    // Function to get Group, no data fetch lifecycle
    getGroups: (vaultId: number, sectionId: number) => Promise<IGroupApiModel[]>;
    // Function to create Groups
    createGroup: (vaultId: number, sectionId: number, creationRequest: IGroupCreationRequest) => Promise<IGroupApiModel>;
    // Function to update Group
    updateGroup: (vaultId: number, sectionId: number, groupId: number, updateRequest: IGroupUpdateRequest) => Promise<IGroupApiModel>;
    // Fiunction to archive Service
    archiveGroup: (vaultId: number, sectionId: number, groupId: number) => Promise<void>;
}

function createGroupService() {
    const client = new BookmarksClient();
    const groupService: IGroupService = {
        // Function to get Groups, no data fetch lifecycle
        getGroups: (vaultId: number, sectionId: number) => client.groupsAll(vaultId, sectionId),
        // Function to create Groups
        createGroup: (vaultId: number, sectionId: number, creationRequest: IGroupCreationRequest) => client.groupsPOST(vaultId, sectionId, new GroupCreationRequest(creationRequest)),
        updateGroup: (vaultId: number, sectionId: number, groupId: number, updateRequest: IGroupUpdateRequest) => client.groupsPUT(vaultId, sectionId, groupId, new GroupUpdateRequest(updateRequest)),
        archiveGroup: (vaultId: number, sectionId: number, groupId: number) => client.groupsDELETE(vaultId, sectionId, groupId, false),
    }

    return groupService;
}

export default createGroupService;
