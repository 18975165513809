import PanelHeader from '@app/Areas/Optimus/Components/Shared/PanelHeader';
import { createStyles, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { BoardEditorModel as BoardEditorModel } from './ImportBoardEditorModel';

export interface ImportBoardEditorProps {
    handleBoardChange: (event: any) => void;
    title?: string;
    isSaving: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
        marginBottom: 20
    },
    form: {
        padding: '0.75rem'
    }
  }),
);

function ImportBoardEditor({
    title,
    isSaving,
    handleBoardChange
}: ImportBoardEditorProps) {
    const classes = useStyles();

    return (
        <form className={classes.form}>
            { isSaving && <Typography variant="h4">Importing Board...</Typography> }
            <PanelHeader headerVariant="h5" header={`Board - ${title}`}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <input type="file" onChange={handleBoardChange}/>
                    </Grid>
                </Grid>
            </PanelHeader>
        </form>
    )
}

export default ImportBoardEditor;
