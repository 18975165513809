import { TaskboardsClient, ITaskItemApiModel, ITaskItemCreationRequest, ITaskItemUpdateRequest, ITaskMoveRequest, ITaskTransferRequest, TaskItemCreationRequest, TaskItemUpdateRequest, TaskMoveRequest, TaskTransferRequest } from "@app/Areas/Optimus/ApiClient/TaskboardsClient";

export interface ITaskItemService {
    getTaskItems: (vaultId: number, boardId: number, taskListId: number) => Promise<ITaskItemApiModel[]>;
    getTaskItem: (vaultId: number, boardId: number, taskListId: number, taskItemId: number) => Promise<ITaskItemApiModel>;
    createTaskItem: (vaultId: number, boardId: number, taskListId: number, creationRequest: ITaskItemCreationRequest) => Promise<ITaskItemApiModel>;
    updateTaskItem: (vaultId: number, boardId: number, taskListId: number, taskItemId: number, updateRequest: ITaskItemUpdateRequest) => Promise<ITaskItemApiModel>;
    archiveTaskItem: (vaultId: number, boardId: number, taskListId: number, taskItemId: number) => Promise<ITaskItemApiModel>;
    moveTaskItem: (vaultId: number, boardId: number, taskListId: number, taskItemId: number, creationRequest: ITaskMoveRequest) => Promise<ITaskItemApiModel>;
    transferTaskItem: (vaultId: number, boardId: number, taskListId: number, taskItemId: number, creationRequest: ITaskTransferRequest) => Promise<ITaskItemApiModel>;
}

const client = new TaskboardsClient();

function createTaskItemService() {
    const taskItemService: ITaskItemService = {
        getTaskItems: (vaultId: number, boardId: number, taskListId: number) => client.taskItemsAll(vaultId, boardId, taskListId),
        getTaskItem: (vaultId: number, boardId: number, taskListId: number, taskItemId: number) => client.taskItemsGET(vaultId, boardId, taskListId, taskItemId),
        createTaskItem: (vaultId: number, boardId: number, taskListId: number, creationRequest: ITaskItemCreationRequest) => client.taskItemsPOST(vaultId, boardId, taskListId, new TaskItemCreationRequest(creationRequest)),
        updateTaskItem: (vaultId: number, boardId: number, taskListId: number, taskItemId: number, updateRequest: ITaskItemUpdateRequest) => client.taskItemsPUT(vaultId, boardId, taskListId, taskItemId, new TaskItemUpdateRequest(updateRequest)),
        archiveTaskItem: (vaultId: number, boardId: number, taskListId: number, taskItemId: number) => client.taskItemsDELETE(vaultId, boardId, taskListId, taskItemId, false),
        moveTaskItem: (vaultId: number, boardId: number, taskListId: number, taskItemId: number, creationRequest: ITaskMoveRequest) => client.move(vaultId, boardId, taskListId, taskItemId, new TaskMoveRequest(creationRequest)),
        transferTaskItem: (vaultId: number, boardId: number, taskListId: number, taskItemId: number, creationRequest: ITaskTransferRequest) => client.transfer(vaultId, boardId, taskListId, taskItemId, new TaskTransferRequest(creationRequest))
    }

    return taskItemService;
}

export default createTaskItemService;
