import React from 'react';
import tw, { styled } from 'twin.macro';
import { Button, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';
import { DeepThoughtColor, FridayColor, JarvisColor, ZiggyColor } from '@optimus/Tokens/colors';
import { useOptimusDispatchContext } from '@optimus/Contexts/OptimusContext';
import PanelHeader from '@optimus/Components/Shared/PanelHeader';
import { FridayIcon, ZiggyIcon, JarvisIcon, DeepThoughtIcon } from '@optimus/Components/Shared/AppsIcons';
import { CommandIcon, ActivityIcon, ProjectIcon, RepositoryIcon } from '@optimus/Components/Shared/AppsIcons/AppsIcons';
import { NEW_DECK_DIALOG_ID, NEW_PROJECT_DIALOG_ID } from '../ActionDialogs';
import { DeckIcon } from '../../Components/Shared/AppsIcons/AppsIcons';
import { OPTIMUS_OPEN_DIALOG } from '../../State/App/DialogContext/DialogReducer/DialogReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    deepThought: {
        color: DeepThoughtColor
    },
    ziggy: {
      color: ZiggyColor
    },
    jarvis: {
      color: JarvisColor
    },
    friday: {
      color: FridayColor
    }
  }),
);

const ButtonList = styled.div`
    ${tw`py-1`}
    & Button {
        ${tw`my-2 mx-1`}
    }
`;

function ActionMenu() {
    const dispatch = useOptimusDispatchContext();
    const classes = useStyles();

    const openNewProjectDialog = () => {
        dispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: NEW_PROJECT_DIALOG_ID
        })
    }

    const openNewDeckDialog = () => {
        dispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: NEW_DECK_DIALOG_ID
        })
    }

    return (
        <Grid container>
            <Grid container item spacing={1}>
                <Grid item xs={12}>
                    <PanelHeader
                        header="Quick Actions..."
                        headerVariant="h6"
                    >
                        <Grid item xs={12}>
                            <PanelHeader
                                header="DeepThought"
                                headerVariant="subtitle2"
                                Icon={DeepThoughtIcon}
                                iconClass={classes.deepThought}
                            >
                                <ButtonList>
                                    <Button startIcon={<DeckIcon />} size="small" variant="outlined" color="primary" onClick={openNewDeckDialog}>
                                        Add New Deck
                                    </Button>
                                </ButtonList>
                            </PanelHeader>
                        </Grid>
                        <Grid item xs={12}>
                            <PanelHeader
                                header="Friday"
                                headerVariant="subtitle2"
                                Icon={FridayIcon}
                                iconClass={classes.friday}
                            >
                                <ButtonList>
                                    <Button startIcon={<ProjectIcon />} size="small" variant="outlined" color="primary" onClick={openNewProjectDialog}>
                                        Add New Project
                                    </Button>
                                    <Button startIcon={<RepositoryIcon />} size="small" variant="outlined" color="primary">
                                        Add Repository to Project
                                    </Button>
                                </ButtonList>
                            </PanelHeader>
                        </Grid>
                        <Grid item xs={12}>
                            <PanelHeader
                                header="Ziggy"
                                headerVariant="subtitle2"
                                Icon={ZiggyIcon}
                                iconClass={classes.ziggy}
                            >
                            </PanelHeader>
                        </Grid>
                        <Grid item xs={12}>
                            <PanelHeader
                                header="Jarvis"
                                headerVariant="subtitle2"
                                Icon={JarvisIcon}
                                iconClass={classes.jarvis}
                            >
                            </PanelHeader>
                        </Grid>
                        <Grid item xs={12}>
                            <PanelHeader
                                header="Custom Commands"
                                headerVariant="subtitle2"
                                Icon={CommandIcon}
                            >
                            </PanelHeader>
                        </Grid>
                        <Grid item xs={12}>
                            <PanelHeader
                                header="Custom Activities"
                                headerVariant="subtitle2"
                                Icon={ActivityIcon}
                            >
                            </PanelHeader>
                        </Grid>
                    </PanelHeader>
                </Grid>
                {/* <List>
            <ListItem button>
                <ListItemText primary="Phone ringtone" secondary="Titania" />
            </ListItem>
            <Divider />
            <ListItem button>
                <ListItemText primary="Default notification ringtone" secondary="Tethys" />
            </ListItem>
            </List> */}
            </Grid>
        </Grid>
    )
}

export default ActionMenu;
