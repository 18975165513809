import { useVaultStateContext } from "../../VaultContextProvider";
import { IVaultsContextModel } from "../../VaultReducer/VaultReducer";

function useVaultsLoading() {
    const vaultsContext: IVaultsContextModel = useVaultStateContext();

    return vaultsContext?.vaults?.loadingState !== 'unloaded';
}

export default useVaultsLoading;
