import React, { useState } from 'react';
import { generatePath, useHistory, useLocation } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import { transparentize } from 'polished';
import { Box, createStyles, Drawer, IconButton, makeStyles, Menu, MenuItem, Theme, Toolbar } from '@material-ui/core';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { AccountCircle, MenuOpen, AddCircle } from '@material-ui/icons';
import { setCurrentOptimusContextUserprofile, useOptimusDispatchContext } from '@optimus/Contexts/OptimusContext';
import withOpStyles from '@optimus/Helpers/withOpStyles';
import VaultSelector from '@optimus/Components/VaultSelector';

import ActionMenu from '@optimus/Ecosystems/ActionMenu';
import { VaultIcon } from '../../../../Components/Shared/AppsIcons/AppsIcons';
import { IVaultApiModel } from '@app/Areas/Optimus/ApiClient/VaultsClient';
import { OPTIMUS_LANDING_PATH, NEW_RESOURCE_PATH } from '@app/Areas/Optimus/Constants/Paths';

export interface DesktopToolbarProps {
    menuId: string;
    selectedVault: number | null;
    vaults: IVaultApiModel[];
    username?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerBg: {
      backdropFilter: 'blur(5px)',
    },
    drawerRight: {
        backgroundColor: transparentize(0.8, theme.palette.primary.main),
        backdropFilter: 'blur(5px)',
        boxShadow: `-5px 0px 10px #3cb8dc99`,
        borderRight: `1px solid #3cb8dc99`,
    },
    drawerLeft: {
        backgroundColor: transparentize(0.8, theme.palette.primary.main),
        backdropFilter: 'blur(5px)',
        boxShadow: `5px 0px 10px #3cb8dc99`,
        borderLeft: `1px solid #3cb8dc99`,
    }
  }),
);

const PanelContainer = styled.div`
    ${tw`py-2 px-4`}
`;

const Left = styled.div`
    padding: 0 0 0 8px;
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
`;

const Center = styled.div`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
`;

const Right = styled.div`
    padding: 0 8px 0 0;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
`;

const AvatarLabel = withOpStyles(styled.p`
    font-size: ${props => props.theme.typography.fontSize}px;
`);

/**
 * The Avatar 
 * @param menuId 
 * @param handleProfileMenuOpen 
 * @param username 
 */
function renderAvatar(
    menuId: string,
    handleProfileMenuOpen: (event: React.MouseEvent<HTMLElement>) => void,
    username?: string
) {
    return (
        <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
        >
            <AccountCircle />   
            <Box p={1} mx="auto" my="auto">
                <AvatarLabel>{username}</AvatarLabel>
            </Box>
        </IconButton>
    );
}

function renderVaults(
    goToVault: () => void,
    selectedVault: number | null,
    vaults: IVaultApiModel[],
    handleChange: ((event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => void),
    setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
) {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
        <>
            {/* <Link to={`/optimus/vaults/${selectedVault}`}>
            </Link> */}
            <IconButton edge="start" color="primary" aria-label="Go to vault" onClick={goToVault}>
                <VaultIcon />
            </IconButton>
            <VaultSelector
                selectedVault={selectedVault}
                handleChange={handleChange}
                vaults={vaults}
            />
        </>
    )
}

type Anchor = 'top' | 'left' | 'bottom' | 'right';

function DesktopToolbar({
    menuId,
    username,
    selectedVault,
    vaults
}: DesktopToolbarProps) {
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const dispatch = useOptimusDispatchContext();
    const history = useHistory();
    const classes = useStyles();
    const [state, setState] = useState({
        // top: false,
        left: false,
        // bottom: false,
        right: false,
      });

      const toggleDrawer = (anchor: Anchor, open: boolean) => (
        event: React.KeyboardEvent | React.MouseEvent,
      ) => {
        if (
          event &&
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        // setAnchorEl(event.currentTarget);
        // EXAMPLE: User triggered dispatch
        dispatch(setCurrentOptimusContextUserprofile());
    };

    const tryNavigateToNewVault = (pathFragment: string, selectedVault: string) => {
        if (location.pathname.includes(pathFragment))
        {
            const pathTemplate = location.pathname.replace(pathFragment, '/optimus/vaults/:vaultId');
            const updatedPath = generatePath(pathTemplate, { vaultId: selectedVault });
            alert(updatedPath)
            history.push(updatedPath);
            return true;
        }
        return false;
    }

    const vaultChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newSelectedVault = event.target.value as string;

        // Paths where we will force a redirect
        const vaultPaths = [
            `/optimus/vaults/${selectedVault}`,
            `/optimus/vaults/${selectedVault}/friday`,
            `/optimus/vaults/${selectedVault}/deepthought`
        ]

        for (let p of vaultPaths) {
            if (tryNavigateToNewVault(p, newSelectedVault)) {
                break;
            }
        }

        // Paths where we will force a redirect
        const optimusPaths = [
            OPTIMUS_LANDING_PATH,
            NEW_RESOURCE_PATH
        ]

        if (optimusPaths.some((v) => v === location.pathname)) {
            const pathTemplate = '/optimus/vaults/:vaultId';
            const updatedPath = generatePath(pathTemplate, { vaultId: newSelectedVault });
            history.push(updatedPath);
        }
      };

      const goToVault = () => {
          history.push(`/optimus/vaults/${selectedVault}`);
      }

    return (
        <>
            <Toolbar variant="dense" disableGutters>
                <Left>
                    <IconButton onClick={toggleDrawer('left', true)} edge="start" color="secondary" aria-label="open drawer">
                        <MenuOpen />
                    </IconButton>
                </Left>
                <Center>
                    { selectedVault && vaults && renderVaults(goToVault, selectedVault, vaults, vaultChange, setAnchorEl) }
                </Center>
                <Right>
                    <IconButton onClick={toggleDrawer('right', true)} edge="start" color="secondary" aria-label="add new item">
                        <AddCircle />
                    </IconButton>
                </Right>
            </Toolbar>
            <SwipeableDrawer PaperProps={{
                    className: classes.drawerLeft
            }}  className={`${classes.drawerBg} ${classes.drawerLeft}`} anchor={'left'} open={state['left']} onOpen={toggleDrawer('left', true)} onClose={toggleDrawer('left', false)}>
                <PanelContainer>
                    Tea
                </PanelContainer>
            </SwipeableDrawer>
            <SwipeableDrawer PaperProps={{
                    className: classes.drawerRight
            }} className={classes.drawerBg} anchor={'right'} open={state['right']} onOpen={toggleDrawer('right', true)} onClose={toggleDrawer('right', false)}>
                <PanelContainer>
                    <ActionMenu />
                </PanelContainer>
            </SwipeableDrawer>
            {/* 
            { renderAvatar(menuId, handleProfileMenuOpen, username) } */}
            {/* <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
             */}
          </>
    )
}

export default DesktopToolbar;
