import React from "react";
import { MenuItem, Select, Typography } from '@material-ui/core';
import { IVaultApiModel } from "../../ApiClient/VaultsClient";
import useVaultsLoading from "../../State/DataStores/VaultStore/hooks/useVaultsLoading/useVaultsLoading";

export interface VaultSelectorProps {
    selectedVault: number | null;
    handleChange: ((event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => void);
    vaults: IVaultApiModel[];
}

function VaultSelector({
    selectedVault,
    handleChange,
    vaults
}: VaultSelectorProps) {
    const vaultsLoaded = useVaultsLoading();
    return (
        <>
            { !vaultsLoaded && <Typography variant="subtitle1">Fetching Vaults...</Typography> }
            { vaultsLoaded && selectedVault && <Select
                labelId="vault-select-label"
                id="vault-select"
                value={selectedVault}
                onChange={handleChange}
            >
                { vaults.map((vault: IVaultApiModel) => (
                    <MenuItem key={`vault-selector-item-${vault.id}`} value={vault.id}>{vault.title}</MenuItem>
                )) }
            </Select> }
        </>
    )
}

export default VaultSelector;