import { FormEvent, ChangeEvent } from "react";
import TextField from '@material-ui/core/TextField';
import styled from 'styled-components';
import { DeepThoughtColor } from "@app/Areas/Optimus/Tokens/colors";
import { darken } from "polished";

const Input = styled(TextField)`
  text-align: center;
  margin-bottom: 1em;
`;

const Button = styled.button`
  text-align: center;
  padding: 0.5em;
  border: 1px solid ${DeepThoughtColor};  
  width: 100%;
  text-align: center;

  &:hover {
    background-color: ${darken(0.3, DeepThoughtColor)};
  }
`;

const Wrapper = styled.div`
  width: 100%;
  padding: 0.5em;
  border: 1px solid white;
`;

export interface BoardTextControlProps {
    onSubmit: (event: FormEvent<HTMLFormElement>) => void;
    onCancel: () => void;
    setValue: (event: ChangeEvent<HTMLInputElement>) => void;
    value: string;
  }

function BoardTextControl({
    onSubmit,
    onCancel,
    setValue,
    value,
  }: BoardTextControlProps) {
    const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event);
    };
  
    const submitForm = (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
  
      if (!value.length) {
          return;
      }
  
      onSubmit(event);
    };
  
    return <Wrapper>
      <form onSubmit={submitForm}>
        <Input fullWidth label="Name" name="name" value={value} onChange={onInputChange} />
      </form>
      <Button onClick={onCancel}>Cancel</Button>
    </Wrapper>
  }

  export default BoardTextControl;