import { createContext, useContext } from 'react';
import { DispatcherThunk, useThunkReducer } from '../../../../Hooks/useThunkReducer/index';
import TaskBoardReducer, { ITaskBoardContextModel, TaskBoardMode } from '../TaskBoardReducer/TaskBoardReducer';

const initialState: ITaskBoardContextModel = {
    boards: {
        byId: {},
        allIds: [],
        selected: null,
        selectedToEdit: null,
        selectedToDelete: null,
        selectedToProtect: null
    },
    archivedBoards: {
        byId: {},
        allIds: [],
        selectedToRestore: null,
        selectedToDelete: null
    },
    taskLists: {
        byId: {},
        allIds: [],
        selected: null,
        selectedToEdit: null,
        selectedToDelete: null,
        selectedToTransfer: null,
    },
    taskItems: {
        byId: {},
        allIds: [],
        selected: null,
        selectedToEdit: null,
        selectedToView: null,
        selectedToDelete: null,
        selectedToTransfer: null,
    },
    boardMode: TaskBoardMode.BOARDMODE_ACTIVE
}

let TaskBoardContextStateContext = createContext({} as ITaskBoardContextModel)
let TaskBoardContextDispatchContext = createContext({} as DispatcherThunk<(state: any, action: any) => any>)

function TaskBoardContextProvider({ children }: React.PropsWithChildren<{}>) {
    const [taskBoardState, taskBoardDispatch] = useThunkReducer(TaskBoardReducer, initialState, "taskBoard");

    return (
        <TaskBoardContextDispatchContext.Provider
            value={taskBoardDispatch}
        >
            <TaskBoardContextStateContext.Provider
                value={taskBoardState}
            >
                { children }
            </TaskBoardContextStateContext.Provider>
        </TaskBoardContextDispatchContext.Provider>
    );
}

function useTaskBoardStateContext() {
    return useContext<ITaskBoardContextModel>(TaskBoardContextStateContext);
}

function useTaskBoardDispatchContext() {
    return useContext<DispatcherThunk<(state: any, action: any) => any>>(TaskBoardContextDispatchContext);
}

export {
    TaskBoardContextStateContext,
    TaskBoardContextDispatchContext,
    TaskBoardContextProvider,
    useTaskBoardStateContext,
    useTaskBoardDispatchContext
};