import { IVideoPlaylistApiModel } from '@app/Areas/Optimus/ApiClient/VideosClient';
import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useVideoStateContext } from '../../VideoContextProvider/VideoContextProvider';
import { IVideoContextModel } from '../../VideoReducer/VideoReducer';

function useVaultPlaylistsArray(): IVideoPlaylistApiModel[] {
    const vaultId = useVaultIdParam();
    const state: IVideoContextModel = useVideoStateContext();

    if (vaultId === null) {
        return [];
    }

    const playlists =
        Object.values(state.playlists.byId)
            .filter((s) => s.parentVaultId === vaultId)
            .map(s => s.playlist);

    return playlists;
}

export default useVaultPlaylistsArray;
