import React from 'react';
import { Button, Card, CardActions, CardMedia, Link, makeStyles, Typography } from '@material-ui/core';
import { transparentize } from 'polished';
import { generatePath, useHistory } from 'react-router-dom';
import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import useSectionIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useSectionIdParam';
import useGroupIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useGroupIdParam';
import { useDialogDispatchContext } from '@app/Areas/Optimus/State/App/DialogContext/DialogContextProvider';
import { useSectionDispatchContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider';
import { openDialog } from '@app/Areas/Optimus/State/App/DialogContext/DialogReducer/DialogReducer';
import { RESOURCES_SET_RESOURCE_UNDER_DELETE, RESOURCES_SET_RESOURCE_UNDER_EDIT } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceReducer/ResourceReducer';
import { EDIT_RESOURCE_DIALOG_ID } from '../../ActionDialogs';
import { DELETE_RESOURCE_DIALOG_ID } from '../../ActionDialogs/index';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px',
      background: transparentize(0.9, theme.palette.primary.main),
      boxShadow: `0 0px 10px #3cb8dc99`,
      border: `1px solid #3cb8dc99`,
      height: '100%',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 10px'
    },
    cardColumn: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 1px',
        transform: 'scale(0.8)',
    },
    cardRow: {
        display: 'flex',
        flexDirection: 'row',
        padding: '5px',
    },
    content: {},
    cover: {
      width: 151,
    },
  }));

function ResourceCard({
    resourceId,
    title,
    description,
    url,
    thumbnail,
    siteName,
    resourceType,
    isRecommended,
    isPublic
}: {
    resourceId?: number;
    title?: string;
    description?: string;
    url?: string;
    thumbnail?: string;
    siteName?: string;
    resourceType?: string;
    isRecommended?: boolean;
    isPublic?: boolean;
}) {
    const dialogDispatch = useDialogDispatchContext();
    const resourceDispatch = useSectionDispatchContext();

    const vaultId = useVaultIdParam();
    const sectionId = useSectionIdParam();
    const groupId = useGroupIdParam();
    const history = useHistory();

    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;

    const renderRecommended = () => {
        if (isRecommended) {
            return <>{ bull } Recommended</>
        } else {
            return null;
        }
    }

    const renderPublic = () => {
        if (isPublic) {
            return <>{ bull } Public</>
        } else {
            return null;
        }
    }

    function navigateToDetails() {
        if (vaultId && sectionId && groupId && resourceId) {
            const resourcePath = generatePath('/optimus/vaults/:vaultId/deepthought/sections/:sectionId/groups/:groupId/resources/:resourceId', {
                vaultId,
                sectionId,
                groupId,
                resourceId
            });
            history.push(resourcePath);
        }
    }

    const openEditResourceDialog = (resourceId?: number) => {
        if (resourceId) {
            dialogDispatch(openDialog(EDIT_RESOURCE_DIALOG_ID));
            resourceDispatch({
                type: RESOURCES_SET_RESOURCE_UNDER_EDIT,
                resourceId
            })
        }
    }

    const openDeleteResourceDialog = (resourceId?: number) => {
        if (resourceId) {
            dialogDispatch(openDialog(DELETE_RESOURCE_DIALOG_ID));
            resourceDispatch({
                type: RESOURCES_SET_RESOURCE_UNDER_DELETE,
                resourceId
            })
        }
    }

    return (
        <Card className={classes.root}>
            <div className={classes.cardRow}>
                <div className={classes.details}>
                    <Typography component="h6" variant="subtitle1">
                        { title }
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                        { url }
                    </Typography>
                    <Typography variant="subtitle2">
                        { siteName }{ bull }{ resourceType }{renderRecommended()}{renderPublic()}
                    </Typography>
                </div>
                <CardMedia
                    className={classes.cover}
                    image={thumbnail}
                    title="Live from space album cover"
                />
            </div>
            <div className={classes.cardColumn}>
                <Typography variant="caption" color="textSecondary">
                    { description }
                </Typography>
            </div>
            <CardActions>
                <Button size="small" color="primary" onClick={navigateToDetails}>
                    Details
                </Button>
                { bull }
                <Link size="small" component={Button} color="primary" href={url} target="_blank">
                    Browse
                </Link>
                { bull }
                <Button size="small" color="primary" onClick={() => {
                    openEditResourceDialog(resourceId)
                }}>
                    Edit
                </Button>
                { bull }
                <Button size="small" color="primary" onClick={() => {
                    openDeleteResourceDialog(resourceId)
                }}>
                    Delete
                </Button>
            </CardActions>
        </Card>
    )
}

export default ResourceCard;