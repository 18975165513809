
import useSectionIdParam from '../../../../App/NavigationContext/hooks/useSectionIdParam/useSectionIdParam';
import useSectionGroupsFetch from '../useSectionGroupsFetch/useSectionGroupsFetch';

function useGroupsFetch() {
    const sectionId = useSectionIdParam();
    useSectionGroupsFetch(sectionId);
}

export default useGroupsFetch;
