import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { VAULTS_LANDING_PAGE_PATH } from "@app/Areas/Optimus/Constants/Paths";
import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import useVaultsAsArray from "@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useVaultsAsArray";
import useVaultsLoading from "@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useVaultsLoading/useVaultsLoading";

function useVaultsLandingRedirect() {
    const history = useHistory();
    const vaultsLoaded = useVaultsLoading();
    const vaults = useVaultsAsArray();
    const selectedVault = useVaultIdParam();

    const goToVaultsLanding = () => {
        history.push(VAULTS_LANDING_PAGE_PATH);
    }

    useEffect(() => {
        if (
            selectedVault !== null
            && vaultsLoaded
            && vaults
            && vaults.length > 0
            && !vaults.find(v => v.id === selectedVault)
        ) {
            goToVaultsLanding();
        }
    }, [vaults, selectedVault, vaultsLoaded]);
}

export default useVaultsLandingRedirect;
