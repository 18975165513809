import React from 'react';
import ReactJson from 'react-json-view'
import { transparentize } from 'polished';
import Iframe from 'react-iframe'
import PanelHeader from '@app/Areas/Optimus/Components/Shared/PanelHeader';
import TabPanel from '@app/Areas/Optimus/Components/Shared/TabPanel/TabPanel';
import { Button, Chip, Grid, Link, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import useCurrentResource from '../../../State/DataStores/ResourceStore/hooks/useCurrentResource/useCurrentResource';
import { url } from 'inspector';
import { useDialogDispatchContext } from '@app/Areas/Optimus/State/App/DialogContext/DialogContextProvider';
import { DELETE_RESOURCE_DIALOG_ID, EDIT_RESOURCE_DIALOG_ID } from '../../ActionDialogs/index';
import { openDialog } from '@app/Areas/Optimus/State/App/DialogContext/DialogReducer/DialogReducer';
import { useSectionDispatchContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider';
import { RESOURCES_SET_RESOURCE_UNDER_DELETE, RESOURCES_SET_RESOURCE_UNDER_EDIT } from '../../../State/DataStores/ResourceStore/ResourceReducer/ResourceReducer';


function a11yProps(id: any, index: any) {
    return {
      id: `reasource-detail-tab-${id}-${index}`,
      'aria-controls': `simple-tabpanel-${id}-${index}`,
    };
  }

const useStyles = makeStyles((theme) => ({
    root: {
      padding: '10px',
      background: transparentize(0.9, theme.palette.primary.main),
      boxShadow: `0 0px 10px #3cb8dc99`,
      border: `1px solid #3cb8dc99`,
      width: '100%',
      height: 'fit-content'
    },
    tab: {
        padding: '10px 0',
    },
    mainDetails: {
        padding: '0 20px',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 1px',
        transform: 'scale(0.8)',
    }
  }));

function ResourceDetailView() {
    const classes = useStyles();
    const [tabIndex, setTabIndex] = React.useState(0);
    const dialogDisoatch = useDialogDispatchContext();
    const resourceDispatch = useSectionDispatchContext();
    const currentResource = useCurrentResource();
    const resource = currentResource?.resource;

    const bull = <span className={classes.bullet}>•</span>;

    const openEditResourceDialog = (resourceId?: number) => {
        if (resourceId) {
            dialogDisoatch(openDialog(EDIT_RESOURCE_DIALOG_ID));
            resourceDispatch({
                type: RESOURCES_SET_RESOURCE_UNDER_EDIT,
                resourceId
            })
        }
    }

    const openDeleteResourceDialog = (resourceId?: number) => {
        if (resourceId) {
            dialogDisoatch(openDialog(DELETE_RESOURCE_DIALOG_ID));
            resourceDispatch({
                type: RESOURCES_SET_RESOURCE_UNDER_DELETE,
                resourceId
            })
        }
    }

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };

    const renderRecommended = (isRecommended: boolean | undefined) => {
        if (isRecommended) {
            return <>Recommended</>
        } else {
            return null;
        }
    }

    const renderPublic = (
        isRecommended: boolean | undefined,
        isPublic: boolean | undefined
    ) => {
        if (isPublic) {
            return <>{ isRecommended && bull } Public</>
        } else {
            return null;
        }
    }

    return (resource && (
        <div className={classes.root}>
            <PanelHeader
                headerVariant="h5"
                header={`${resource.title}`}
                actions={[{
                    label: 'Edit',
                    action: () => {
                        openEditResourceDialog(resource.id)
                    }
                }, {
                    label: 'Delete',
                    action: () => {
                        openDeleteResourceDialog(resource.id)
                    }
                }]}
            >
                <Grid container>
                    <Tabs value={tabIndex} onChange={handleChange} aria-label="">
                        <Tab label="Details" {...a11yProps(resource.id, 0)} />
                        <Tab label="Preview" {...a11yProps(resource.id, 1)} />
                    </Tabs>
                    <Link size="small" component={Button} color="primary" href={resource.url} target="_blank">
                        Browse
                    </Link>
                </Grid>
                <Grid>
                    <TabPanel value={tabIndex} index={0}>
                        <div className={classes.tab}>
                            <Grid container>
                                <Grid xs={6} item className={classes.mainDetails}>
                                    <PanelHeader headerVariant="caption" header="Description">
                                        <Typography>
                                            { resource.description }
                                        </Typography>
                                    </PanelHeader>
                                    <br />
                                    <PanelHeader headerVariant="caption" header="Site Name">
                                        <Typography>
                                            { resource.siteName }
                                        </Typography>
                                    </PanelHeader>
                                    <br />
                                    <PanelHeader headerVariant="caption" header="Publication Date">
                                        <Typography>
                                            { resource.publicationDate?.toLocaleDateString() }
                                        </Typography>
                                    </PanelHeader>
                                    <br />
                                    { resource?.classification && <PanelHeader headerVariant="caption" header="Classification">
                                        <Typography>
                                            { resource.classification }
                                        </Typography>
                                    </PanelHeader> }
                                    <br />
                                    { (resource?.isPublic || resource?.isRecommended) && <PanelHeader headerVariant="caption" header="Published State">
                                        {renderRecommended(resource.isRecommended)}{renderPublic(resource.isRecommended, resource.isPublic)}
                                    </PanelHeader> }
                                </Grid>
                                <Grid xs={6} item>
                                    <img src={resource.thumbnail} />
                                    <br />
                                    <PanelHeader headerVariant="caption" header="Url">
                                        <Typography>
                                            { resource.url }
                                        </Typography>
                                    </PanelHeader>
                                </Grid>
                            </Grid>
                            <br />
                            <Grid item className={classes.mainDetails}>
                                { resource?.tags && <PanelHeader headerVariant="caption" header="Tags">
                                    { resource?.tags && resource.tags.map((t: string, i: number) => (
                                        <Chip label={t} color="primary" />
                                    ))}
                                </PanelHeader> }
                                <br />
                                <PanelHeader headerVariant="caption" header="Additional Graph">
                                    { resource.additionalGraph && <ReactJson theme="monokai" src={resource.additionalGraph} /> }
                                </PanelHeader>
                            </Grid>
                        </div>
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        <div className={classes.tab}>
                            { resource?.url && <Iframe url={resource.url}
                                width="100%"
                                height="600px"
                                id="resource-preview-iframe"
                                className="myClassname"
                                display="block"
                                position="relative"
                            /> }
                        </div>
                    </TabPanel>
                </Grid>
            </PanelHeader>
        </div>
    )) || null;
}

export default ResourceDetailView;
