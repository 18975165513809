import React, { useState } from 'react';
import PanelHeader from '@app/Areas/Optimus/Components/Shared/PanelHeader';
import { Button, Chip, createStyles, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { DeckEditorModel } from './DeckEditorModel';

export interface DeckEditorProps {
    deck: DeckEditorModel;
    handleDeckChange: (event: any) => void;
    title?: string;
    // handleReset: (event: any) => void;
    isSaving: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tags: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    input: {
        marginBottom: 20
    },
    form: {
        padding: '0.75rem'
    },
    button: {
        width: '100%'
    }
  }),
);

function DeckEditor({
    title,
    isSaving,
    deck,
    handleDeckChange
}: DeckEditorProps) {
    const classes = useStyles();
    const [newTag, setNewTag] = useState();
    function removeTag(index: number) {

        const updatedTags = [
            ...deck.tags
        ];

        for(let i = 0; i < updatedTags.length; i++) {
            if (i === index) {
                updatedTags.splice(i, 1);
            }
        }

        const pseudoEvent = {
            target: {
                name: 'tags',
                value: updatedTags
            }
        }

        handleDeckChange(pseudoEvent);
    }

    function addTag() {
        const updatedTags: (string | undefined)[] = [
            ...deck.tags,
            newTag
        ];

        const pseudoEvent = {
            target: {
                name: 'tags',
                value: updatedTags
            }
        }

        handleDeckChange(pseudoEvent);
    }

    return (
        <form className={classes.form}>
            { isSaving && <Typography variant="h4">Saving Deck...</Typography> }
            <PanelHeader headerVariant="h5" header={`Add new Deck - ${title}`}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.input}
                            fullWidth id="deck-name-input"
                            label="Name"
                            name="title"
                            value={deck.name}
                            onChange={handleDeckChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.input}
                            fullWidth id="description-name-input"
                            label="Description"
                            name="description"
                            value={deck.description}
                            onChange={handleDeckChange}
                        />
                    </Grid>
                    <Grid item xs={12} container spacing={1}>
                        <Grid item xs={12} container spacing={1}>
                            <TextField
                                className={classes.input}
                                id="deck-tag-input"
                                name="tags"
                                label="Tags"
                                fullWidth
                                onChange={(event: any) => setNewTag(event.target.value)}
                            />
                        </Grid>
                        <Grid item md={2} xs={12}>
                            <Button className={classes.button} onClick={addTag} color="primary" variant="contained">Add Tag</Button>
                        </Grid>
                        <div className={classes.tags}>
                            { deck.tags.map((t: string, i: number) => (
                                <Chip label={t} onDelete={() => removeTag(i)} color="primary" />
                            ))}
                        </div>
                    </Grid>
                </Grid>
            </PanelHeader>
        </form>
    )
}

export default DeckEditor;
