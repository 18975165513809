import { IVideoPlaylistApiModel } from "@app/Areas/Optimus/ApiClient/VideosClient";
import EditorDialog from "@app/Areas/Optimus/Components/Shared/EditorDialog";
import { IMPORT_YT_PLAYLIST_DIALOG_ID } from "@app/Areas/Optimus/Ecosystems/ActionDialogs";
import createPlaylistService from "@app/Areas/Optimus/Services/PlaylistService";
import useCurrentVault from "@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useCurrentVault";
import { useVideoDispatchContext } from "@app/Areas/Optimus/State/DataStores/VideoStore/VideoContextProvider/VideoContextProvider";
import { getVaultPlaylists } from "@app/Areas/Optimus/State/DataStores/VideoStore/VideoReducer/VideoReducer";

import { TextField } from "@material-ui/core";
import { useCallback, useRef, useState } from "react";

function ImportPlaylist() {
    const playlistService = createPlaylistService();
    const playlistDispatch = useVideoDispatchContext();
    const currentVault = useCurrentVault();
    const [createdPlaylist, setCreatedPlaylist] = useState<IVideoPlaylistApiModel | null>(null);
    const [isSending, setIsSending] = useState(false)
    const isMounted = useRef(true);
    const [playlistId, setPlaylistId] = useState('');

    function playlistEdited(event: any) {
        setPlaylistId(event.target.value);
    }

    const sendRequest = useCallback(async () => {
        if (currentVault?.id) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await playlistService.importPlaylist(currentVault?.id, playlistId);
            if (result) {
                setCreatedPlaylist(result);
            }
            playlistDispatch(getVaultPlaylists(currentVault?.id));

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [ currentVault, playlistId, isSending]
    ); // update the callback if the state changes

    const handleSave = () => {
        if (!createdPlaylist && !isSending) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        setCreatedPlaylist(null);
    }

    return currentVault && (
        <EditorDialog
            title="Import Playlist"
            saveTitle="Import Playlist"
            dialogId={IMPORT_YT_PLAYLIST_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            { playlistId }
            { !createdPlaylist && <TextField
                fullWidth 
                label="YouTube Playlist Id"
                name="playlistId"
                value={playlistId}
                onChange={playlistEdited}
            /> }
            { createdPlaylist && JSON.stringify(createdPlaylist) }
        </EditorDialog>
    )
}

export default ImportPlaylist;
