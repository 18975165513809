import styled from "styled-components";
import ActionDialogs from './Ecosystems/ActionDialogs';
import Main from './Apps/Main';
import Providers from './Providers';
import DebugComponent from "./DebugComponent";

export const MatrixBg = styled.div`
    position: relative;
    /* &:before {
        content: '';
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAIElEQVQoU2NkmPX8v8uqWAZCgHFUIb4gIi14CAU2SB4AqsYkrtynWg4AAAAASUVORK5CYII=) repeat;
        opacity: 0.3;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    } */
`;

function Root() {
    return (
        <Providers>
            <DebugComponent>
                <MatrixBg>
                    <Main />
                    <ActionDialogs />
                </MatrixBg>
            </DebugComponent>
        </Providers>
    );
}

export default Root;
