import { createContext, useContext } from 'react';
import { DispatcherThunk, useThunkReducer } from '@optimus/Hooks/useThunkReducer';
import { initialState } from '../../../state/initial-state';

/**
 * Function to create state context and provider from a reducer
 * @param reducer 
 * @param name 
 */
function createReducerContexts<T>(
    reducer: (state: any, action: any) => any,
    name: string,
    initialState = {}
) {

    let StateContext = createContext({} as T)
    let DispatchContext = createContext({} as DispatcherThunk<(state: any, action: any) => any>)

    function Providers({ children }: React.PropsWithChildren<{}>) {
        const [state, dispatch] = useThunkReducer(reducer, initialState, name);
        return (
          <DispatchContext.Provider value={dispatch}>
            <StateContext.Provider value={state}>
              { children }
            </StateContext.Provider>
          </DispatchContext.Provider>
        );
      }

      function useStateContext() {
        return useContext<T>(StateContext);
      }

      function useDispatchContext() {
        return useContext<DispatcherThunk<(state: any, action: any) => any>>(DispatchContext);
      }

    return {
        StateContext,
        DispatchContext,
        Providers,
        useStateContext,
        useDispatchContext
    }
}

export default createReducerContexts;
