import DeckReducer, { IDecksContextModel } from '../DeckReducer/DeckReducer';
import createReducerContexts from '@app/Areas/Optimus/Contexts/createReducerContexts';

const initialState: IDecksContextModel = {
  decks: {
      byId: {},
      allIds: [],
      selected: null,
      selectedToEdit: null,
      selectedToDelete: null
  }
}

export const {
  StateContext: DeckStateContext,
  DispatchContext: DeckDispatchContext,
  Providers: DeckContextProvider,
  useStateContext: useDeckStateContext,
  useDispatchContext: useDeckDispatchContext,
} = createReducerContexts<IDecksContextModel>(DeckReducer, "decks", initialState);
