import { DeleteIcon } from '@app/Areas/Optimus/Components/Shared/AppsIcons';
import { DeepThoughtColor } from '@app/Areas/Optimus/Tokens/colors';
import { darken } from 'polished';
import { Droppable, DroppableProvided, DroppableStateSnapshot } from 'react-beautiful-dnd';
import styled from 'styled-components';

export interface WrapperProps {
    isDraggingOver: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  height: 100%;
  max-height: 4em;
  width: 100%;
  border: 1px solid ${DeepThoughtColor};
  bord-radius: 2px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.isDraggingOver ? darken(0.3, DeepThoughtColor) : 'inherit'};
`;

export interface DeleteDropTileProps {
    droppableId: string;
    itemType: string;
    label: string;
}

function DeleteDropTile({
    itemType,
    droppableId,
    label,
}: DeleteDropTileProps) {     
    return <Droppable droppableId={droppableId} type={itemType}>
    { (provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
        <Wrapper
            ref={provided.innerRef}
            { ...provided.droppableProps }
            isDraggingOver={snapshot.isDraggingOver}
        >
            <DeleteIcon />
        </Wrapper>
    )}        
    </Droppable>
}

export default DeleteDropTile;
