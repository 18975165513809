import createAuth0Client from "@auth0/auth0-spa-js";
import VectorAuthCache from './VectorAuthCache';
import appConfig from "../../../app_config.json";
import authConfig from "../../../auth_config.json";

/** VectorClientHelpers
 *  Helper functions for custom VectorClientBase Code
 *  Prevents having to regenerate clients in nswagstudio for every change and every client.
 */

export async function getAuth0Token() {
    const auth0 = await createAuth0Client({
        domain: authConfig.domain,
        client_id: authConfig.clientId,
        redirect_uri: window.location.origin,
        cache: VectorAuthCache
    });

    const token = await auth0.getTokenSilently();

    return token;
}

export function getVectorBaseUrl(urlTemplate: string, defaultUrl: string | undefined): string {
    const baseUrl = appConfig.apiBaseUrl;    

    // TODO: Add support for localstorage override
    // const [localStorageConfig] = useLocalStorage('config', { customBaseUrl: baseUrl });
    // const { customBaseUrl } = localStorageConfig;

    if (!defaultUrl && !baseUrl) {
      throw new Error('No base Url provided!');    
    }

    const url = baseUrl; // TODO: Allow for call override

    console.log('Using API Url: ', url);

    return url;
}

export function transformRequestOptions(token: string | undefined, options: any) {
    if (token) {
        options.headers["Authorization"] = "bearer " + token
    } else {
        console.warn("Authorization token have not been set please authorize first.");
    }
    return Promise.resolve(options); 
}

export function transformApiResult(url: string, response: Response, processor: (response: Response) => any) {
    // TODO: Return own result or throw exception to change default processing behavior, 
    // or call processor function to run the default processing logic

    console.log("Service call: " + url);
    return processor(response); 
}
