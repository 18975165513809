import { useCallback, useEffect, useRef, useState } from 'react';
import EditorDialog from '@app/Areas/Optimus/Components/Shared/EditorDialog';
import { EDIT_DECK_DIALOG_ID } from '../../../ActionDialogs/index';
import { DeckEditorModel } from '../DeckEditor/DeckEditorModel';
import DeckEditor from '../DeckEditor';
import createDeckService from '@app/Areas/Optimus/Services/DeckService/DeckService';
import { DECKS_CLEAR_EDIT, getVaultDecks } from '../../../../State/DataStores/DeckStore/DeckReducer/DeckReducer';
import useVaultsAsDictionary from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useVaultsAsDictionary';
import useDeckUnderEdit from '../../../../State/DataStores/DeckStore/hooks/useDeckUnderEdit/useDeckUnderEdit';
import { useDeckDispatchContext } from '@app/Areas/Optimus/State/DataStores/DeckStore/DeckContextProvider';
import { IDeckUpdateRequest, IDeckApiModel } from '@app/Areas/Optimus/ApiClient/DecksClient';

const blankDeck: IDeckUpdateRequest = {
    title: '',
    description: '',
    tags: [],
    newParentId: 0
};

function mapUpdateDetailsToDeck(
    deck: IDeckUpdateRequest
): DeckEditorModel {
    return {
        name: deck.title || '',
        description: deck.description || '',
        tags: deck.tags,
    }
}

function EditDeck() {    
    const deckUnderEdit = useDeckUnderEdit();
    const vaults = useVaultsAsDictionary();
    const [isSending, setIsSending] = useState(false)
    const [deckUpdateRequest, setDeckUpdateRequest] = useState<IDeckUpdateRequest | null>(blankDeck);
    const [updatedDeck, setUpdatedDeck] = useState<IDeckApiModel | null>(null);
    const deckService = createDeckService();
    const deckDispatch = useDeckDispatchContext();
    const isMounted = useRef(true);
    const deck = deckUnderEdit?.deck;
    const currentParentVaultId = deckUnderEdit?.parentVaultId;
    const currentParentVault = currentParentVaultId ? vaults[currentParentVaultId] : null;

    useEffect(() => {
        if (!!deckUnderEdit?.deck && !!deckUnderEdit.parentVaultId) {
            setDeckUpdateRequest({
                ...deckUpdateRequest,
                newParentId: deckUpdateRequest?.newParentId || deckUnderEdit.parentVaultId,
                title: deckUnderEdit.deck.title || '',
                description: deckUnderEdit.deck.description || '',
                tags: [] // TODO: Fix missing tags in Api Model
            });
        }
    }, [deckUnderEdit]);

    const sendRequest = useCallback(async () => {
        if (currentParentVaultId && deck?.id && !!deckUpdateRequest) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await deckService.updateDeck(currentParentVaultId, deck.id, deckUpdateRequest);
            if (result) {
                setUpdatedDeck(result);
            }
            deckDispatch(getVaultDecks(currentParentVaultId));
            // deckDispatch(getVaultDeck(getToken, vaultId, parsedDeckToEdit.id, false))

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [ currentParentVaultId, deckUpdateRequest, isSending]
    ); // update the callback if the state changes

    function deckEdited(event: any) {
        if (deckUpdateRequest !== null) {
            setDeckUpdateRequest({
                ...deckUpdateRequest,
                [event.target.name]: event.target.value
            })
        }
    }

    function handleSave() {
        if (!updatedDeck && !isSending) {
            sendRequest();
        }
    }

    function cleanUp() {
        setDeckUpdateRequest(null);
        setUpdatedDeck(null);
        deckDispatch({
            type: DECKS_CLEAR_EDIT
        })
    }

    return currentParentVault && deckUpdateRequest && (
        <EditorDialog
            title="Edit Deck"
            saveTitle="Save Changes"
            dialogId={EDIT_DECK_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            { !updatedDeck && <DeckEditor
                deck={mapUpdateDetailsToDeck(deckUpdateRequest)}
                handleDeckChange={deckEdited}
                title={`Edit - ${currentParentVault.title}`}
                isSaving={isSending}
            /> }
            { updatedDeck && JSON.stringify(updatedDeck) }
        </EditorDialog>
    )
}

export default EditDeck;
