import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import Breadcrumb from '@optimus/Components/Shared/Breadcrumb/Breadcrumb';
import ConsoleWrapper from '@optimus/Components/Shared/ConsoleWrapper';
import { FridayIcon, LinkIcon, ProjectIcon } from '@optimus/Components/Shared/AppsIcons/AppsIcons';
import FeatureCard from '@optimus/Components/Shared/FeatureCard';
import PaperContent from '@optimus/Components/Shared/PaperContent';
import Panel from '@optimus/Components/Shared/Panel';
import PanelHeader from '@optimus/Components/Shared/PanelHeader';
import { DELETE_PROJECT_DIALOG_ID, EDIT_PROJECT_DIALOG_ID, NEW_PROJECT_DIALOG_ID } from '../../ActionDialogs/index';
import { FridayColor } from '../../../Tokens/colors';
import { PROJECTS_SET_PROJECT_UNDER_DELETE, PROJECTS_SET_PROJECT_UNDER_EDIT } from '@app/Areas/Optimus/State/DataStores/ProjectStore/ProjectReducer/ProjectReducer';
import useProjectsFetch from '../../../State/DataStores/ProjectStore/hooks/useProjectsFetch/useProjectsFetch';
import useVaultProjectsAsArray from '../../../State/DataStores/ProjectStore/hooks/useVaultProjectsAsArray/useVaultProjectsAsArray';
import { useDialogDispatchContext } from '@app/Areas/Optimus/State/App/DialogContext/DialogContextProvider';
import { openDialog } from '../../../State/App/DialogContext/DialogReducer/DialogReducer';
import { useProjectDispatchContext } from '@app/Areas/Optimus/State/DataStores/ProjectStore/ProjectContextProvider';
import { IProjectApiModel } from '@app/Areas/Optimus/ApiClient/ProjectsClient';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    columns: {
        padding: '1rem'
    },
    friday: {
        color: FridayColor
    }
  })
);

function ProjectsHub() {
    const classes = useStyles();
    useProjectsFetch();

    const projects = useVaultProjectsAsArray();
    const dispatch = useDialogDispatchContext();
    const projectsDispatch = useProjectDispatchContext();

    const openNewProjectDialog = () => {
        dispatch(openDialog(NEW_PROJECT_DIALOG_ID));
    }

    const openEditProjectDialog = (project: IProjectApiModel) => {
        dispatch(openDialog(EDIT_PROJECT_DIALOG_ID));
        projectsDispatch({
            type: PROJECTS_SET_PROJECT_UNDER_EDIT,
            projectId: project.id
        });
    }

    const openDeleteProjectDialog = (project: IProjectApiModel) => {
        dispatch(openDialog(DELETE_PROJECT_DIALOG_ID));
        projectsDispatch({
            type: PROJECTS_SET_PROJECT_UNDER_DELETE,
            projectId: project.id
        })
    }

    return (
        <>
            <Breadcrumb fragments={[{
                label: 'Vaults',
                path: '/vaults'
            }, {
                label: 'Vaults',
                path: '/vaults'
            }]} />
            <ConsoleWrapper>
                <Grid item container spacing={4}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FeatureCard title="Friday" Icon={() => <FridayIcon className={classes.friday} style={{width: '1.5em', height: '1.5em'}} />}>
                            <PaperContent>
                                <Typography variant="body1">
                                    Friday is a project tracking tool. You can use it to track projects, their repositories and their deployed locations.
                                </Typography>
                            </PaperContent>
                        </FeatureCard>
                    </Grid>
                    <Grid key="projects-navigator" item xs={12}>
                        <Panel>
                            <Grid key="description" item xs={12}>
                                <PanelHeader
                                    header="Projects"
                                    actions={[{
                                        label: 'New Project',
                                        action: openNewProjectDialog
                                    }]}
                                >
                                    <Grid container spacing={4} alignItems="flex-start">
                                        { Object.entries(projects).map(([key, p]) => (
                                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                                <Panel>
                                                    <PanelHeader
                                                        header={`${p.name}`}
                                                        headerVariant="h6"
                                                        Icon={() => <ProjectIcon className={classes.friday} style={{width: '2em', height: '2em'}} />}
                                                        actions={[{
                                                            label: 'Edit',
                                                            action: () => openEditProjectDialog(p)
                                                        }, {
                                                            label: 'Delete',
                                                            action: () => openDeleteProjectDialog(p)
                                                        }]}
                                                    >
                                                        <Grid container spacing={8} alignItems="center" className={classes.columns}>
                                                            { p.urls && Object.entries(p.urls).length > 0 && <Grid item xs={12} md={6}>
                                                                <PanelHeader
                                                                    header="Urls"
                                                                    headerVariant="subtitle1"
                                                                >
                                                                    {p.urls && Object.entries(p.urls).map(([key, value]) => (
                                                                        <a href={`${value}`} target="_blank" rel="noreferrer">
                                                                            <Grid container direction="row" spacing={1} alignItems="center">
                                                                                <Grid item>
                                                                                    <LinkIcon className={classes.friday} style={{width: '1.7em', height: '1.7em'}} />
                                                                                </Grid>
                                                                                <Grid item>{key}</Grid>
                                                                            </Grid>
                                                                        </a>
                                                                    ))}
                                                                </PanelHeader>
                                                            </Grid> }
                                                            {/* TODO: Add Repository */}
                                                            <Grid item xs={12} md={6}>
                                                                <PanelHeader
                                                                    header="Repos"
                                                                    headerVariant="subtitle1"
                                                                >
                                                                </PanelHeader>
                                                            </Grid>
                                                        </Grid>
                                                    </PanelHeader>
                                                </Panel>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </PanelHeader>
                            </Grid>
                        </Panel>
                    </Grid>
                </Grid>
            </ConsoleWrapper>
        </>
    )
}

export default ProjectsHub;
