import { PropsWithChildren } from 'react';

import { VaultContextProvider } from '../VaultStore/VaultContextProvider';
import { ResourceContextProvider } from '../ResourceStore/ResourceContextProvider';
import { ProjectContextProvider } from '../ProjectStore/ProjectContextProvider';
import { DeckContextProvider } from '../DeckStore/DeckContextProvider';
import { TaskBoardContextProvider } from '@app/Areas/Optimus/Apps/TaskBoardApp';
import { VideoContextProvider } from '../VideoStore/VideoContextProvider/VideoContextProvider';

function DataStoreContext({ children }: PropsWithChildren<{}>) {
    return <>
        <VaultContextProvider>
            <ResourceContextProvider>
                <VideoContextProvider>
                    <ProjectContextProvider>
                        <TaskBoardContextProvider>
                            <DeckContextProvider>
                                { children }
                            </DeckContextProvider>
                        </TaskBoardContextProvider>
                    </ProjectContextProvider>
                </VideoContextProvider>
            </ResourceContextProvider>
        </VaultContextProvider>
    </>
}

export default DataStoreContext;
