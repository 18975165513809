import useNetworkRequest from "@optimus/Hooks/useNetworkRequest";
import { IUserProfileApiModel, UserProfileApiModel, UsersClient } from "../../ApiClient/UsersClient";

export interface IUserProfileService {
    getProfile: () => Promise<IUserProfileApiModel>;
    useGetProfileRequest: () => [Error | null, boolean, IUserProfileApiModel | null]
}

/**
 * Factory to create a user profile service for fetching data
 */
function createUserProfileService() {
    const client = new UsersClient();
    const userProfileService: IUserProfileService = {
        // Function to get Profile, no data fetch lifecycle
        getProfile: () => client.profiles(),
        // Hook to get Profile with data fetch lifecycle
        useGetProfileRequest: () => {
            const [error, isLoading, data] = useNetworkRequest<UserProfileApiModel>(() => client.profiles(), []);
            return [error, isLoading, data];
        }
    }

    return userProfileService;
}

export default createUserProfileService;