import { useCallback, useRef, useState } from 'react';
import { DELETE_PROJECT_DIALOG_ID } from '../../ActionDialogs/index';
import createProjectService from '@optimus/Services/ProjectService/ProjectService';
import { getVaultProjects } from '@app/Areas/Optimus/State/DataStores/ProjectStore/ProjectReducer/ProjectReducer';
import { PROJECTS_CLEAR_DELETE } from '../../../State/DataStores/ProjectStore/ProjectReducer/ProjectReducer';
import useProjectUnderDelete from '../../../State/DataStores/ProjectStore/hooks/useProjectUnderDelete/index';
import ConfirmationDialog from '../../../Components/Shared/ConfirmationDialog/ConfirmationDialog';
import useVaultsAsDictionary from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useVaultsAsDictionary';
import { useProjectDispatchContext } from '@app/Areas/Optimus/State/DataStores/ProjectStore/ProjectContextProvider';

function DeleteProject() {
    const parsedProjectToDelete = useProjectUnderDelete();
    const vaults = useVaultsAsDictionary();
    const [isSending, setIsSending] = useState(false)
    const currentParentVaultId = parsedProjectToDelete?.parentVaultId;
    const currentParentVault = currentParentVaultId ? vaults[currentParentVaultId] : null;
    const projectService = createProjectService();
    const projectsDispatch = useProjectDispatchContext();
    const isMounted = useRef(true);

    const sendRequest = useCallback(async (callback) => {
        if (currentParentVaultId && parsedProjectToDelete?.project.id) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await projectService.archiveProject(currentParentVaultId, parsedProjectToDelete.project.id);
            if (result) {
                projectsDispatch(getVaultProjects(currentParentVaultId));
                callback();
            }
            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [currentParentVault, parsedProjectToDelete, isSending]
    ); // update the callback if the state changes

    const handleConfirm = (callback: () => void) => {
        if (!isSending) {
            sendRequest(callback);
        }
    }

    const cleanUp = () => {
        projectsDispatch({
            type: PROJECTS_CLEAR_DELETE
        })
    }

    return currentParentVault && parsedProjectToDelete && (
        <ConfirmationDialog
            title="Delete Project"
            description={`Are you sure you want to delete the project '${parsedProjectToDelete?.project.name}' and all of its urls and repositories?`}
            dialogId={DELETE_PROJECT_DIALOG_ID}
            onConfirm={handleConfirm}
            onClose={cleanUp}
        />
    ) || null;
}

export default DeleteProject;
