import { useCallback, useRef, useState } from 'react';
import { DELETE_RESOURCE_DIALOG_ID } from '../../ActionDialogs/index';
import ConfirmationDialog from '../../../Components/Shared/ConfirmationDialog/ConfirmationDialog';
import useVaultsAsDictionary from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useVaultsAsDictionary';
import useResourceUnderDelete from '../../../State/DataStores/ResourceStore/hooks/useResourceUnderDelete/useResourceUnderDelete';
import { getVaultSectionGroupResources, RESOURCES_CLEAR_RESOURCE_UNDER_DELETE } from '../../../State/DataStores/ResourceStore/ResourceReducer/ResourceReducer';
import createResourceService from '../../../Services/ResourceService/ResourceService';
import useGroupIdParam from '../../../State/App/NavigationContext/hooks/useGroupIdParam/useGroupIdParam';
import useSectionIdParam from '../../../State/App/NavigationContext/hooks/useSectionIdParam/useSectionIdParam';
import useVaultIdParam from '../../../State/App/NavigationContext/hooks/useVaultIdParam/useVaultIdParam';
import { useSectionDispatchContext } from '../../../State/DataStores/ResourceStore/ResourceContextProvider';

function DeleteResource() {
    const vaultId = useVaultIdParam();
    const sectionId = useSectionIdParam();
    const groupId = useGroupIdParam();
    const  resourceService = createResourceService();
    const resourceDispatch = useSectionDispatchContext();
    const isMounted = useRef(true);
    const resourceUnderDelete = useResourceUnderDelete();
    const vaults = useVaultsAsDictionary();
    const [isSending, setIsSending] = useState(false)
    const currentParent = resourceUnderDelete?.parents.find((p) => p.parentVaultId === vaultId && p.parentSectionId === sectionId && p.parentGroupId === groupId);
    const currentParentVaultId = currentParent?.parentVaultId;
    const currentParentVault = currentParentVaultId ? vaults[currentParentVaultId] : null;
    const resource = resourceUnderDelete?.resource;

    const sendRequest = useCallback(async (callback) => {
        if (currentParentVaultId && sectionId && groupId && resource?.id) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await resourceService.archiveResource(currentParentVaultId, sectionId, groupId, resource.id);
            if (result) {
                resourceDispatch(getVaultSectionGroupResources( currentParentVaultId, sectionId, groupId, false));
                // TODO: Fix close transition
                resourceDispatch({
                    type: RESOURCES_CLEAR_RESOURCE_UNDER_DELETE
                })
                callback();
            }
            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [ currentParentVaultId, resource, isSending, sectionId, groupId]
    ); // update the callback if the state changes

    const handleConfirm = (callback: () => void) => {
        if (!isSending) {
            sendRequest(callback);
        }
    }

    const cleanUp = () => {
        resourceDispatch({
            type: RESOURCES_CLEAR_RESOURCE_UNDER_DELETE
        })
    }

    return currentParentVault && resource && (
        <ConfirmationDialog
            title="Delete Resource"
            description={`Are you sure you want to delete the resource '${resource.title}'?`}
            dialogId={DELETE_RESOURCE_DIALOG_ID}
            onConfirm={handleConfirm}
            onClose={cleanUp}
        />
    ) || null;
}

export default DeleteResource;
