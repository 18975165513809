import { useTaskBoardDispatchContext, useTaskBoardStateContext } from "@app/Areas/Optimus/Apps/TaskBoardApp";
import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { useEffect } from "react";
import { BOARDS_CLEAR_TASKITEM_UNDER_TRANSFER } from "../../../state/TaskBoardReducer/TaskBoardReducer";
import useBoardIdParam from "../../_Boards/useBoardIdParam";

function useTaskItemUnderTransfer() {
    const vaultId = useVaultIdParam();
    const boardId = useBoardIdParam();

    const boardStateContext = useTaskBoardStateContext();
    const taskItemUnderTransfer = boardStateContext.taskItems.selectedToTransfer;
    const boardDispatch = useTaskBoardDispatchContext();

    useEffect(() => {
        return function cleanup() {
            boardDispatch({
                type: BOARDS_CLEAR_TASKITEM_UNDER_TRANSFER
            })
        }
    }, [vaultId])

    if (!taskItemUnderTransfer) {
        return null;
    }

    const taskItem = boardStateContext?.taskItems?.byId[taskItemUnderTransfer];

    if (
        !taskItem
        || taskItem.parentVaultId !== vaultId
        || taskItem.parentBoardId !== boardId
    ) {
        return null;
    }

    return taskItem
}

export default useTaskItemUnderTransfer;