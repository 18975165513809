import { Dispatch, SetStateAction } from "react";
import useCreateVaultChildEntity from "@app/Areas/Optimus/Hooks/_operations/useCreateVaultChildEntity";
import createResourceService from "@app/Areas/Optimus/Services/ResourceService";
import { IResourceCreationRequest, IResourceApiModel } from "@app/Areas/Optimus/ApiClient/BookmarksClient";

function useCreateSaveResourceRequest(
    newResource: IResourceCreationRequest,
    setCreatedResource: Dispatch<SetStateAction<IResourceApiModel | null>>,
    onFinally: (vaultId: number) => void,
    presetVaultId: number | null = null
) {
    const resourceService = createResourceService();

    const {
        isSending,
        sendRequest,
    } = useCreateVaultChildEntity<
        IResourceCreationRequest,
        IResourceApiModel
    >(
        (v: number, cr: IResourceCreationRequest) => resourceService.createResource(v, cr),
        newResource,
        (result: IResourceApiModel) => setCreatedResource(result),
        onFinally,
        presetVaultId,
    );

    return {
        isSending,
        sendRequest
    }
}

export default useCreateSaveResourceRequest;
