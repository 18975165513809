import { useLocation, matchPath } from 'react-router-dom';

function usePlaylistIdParam() {
    const { pathname } = useLocation();
    const match = matchPath<{
        vaultId?: string;
        playlistId?: string;
    }>(pathname, {
        path: "/optimus/vaults/:vaultId/playlists/:playlistId",
        exact: false,
        strict: false
    });

    const playlistid = match?.params?.playlistId;

    if (playlistid) {
        return +playlistid;
    }

    return null;
}

export default usePlaylistIdParam;
