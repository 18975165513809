import { IBoardApiModel } from '@app/Areas/Optimus/ApiClient/TaskboardsClient';
import { useTaskBoardStateContext } from '@app/Areas/Optimus/Apps/TaskBoardApp';
import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { ITaskBoardContextModel } from '../../../state/TaskBoardReducer/TaskBoardReducer';

function useVaultBoardsArray(): IBoardApiModel[] {
    const vaultId = useVaultIdParam();
    const state: ITaskBoardContextModel = useTaskBoardStateContext();

    if (vaultId === null) {
        return [];
    }

    const boards =
        Object.values(state.archivedBoards.byId)
            .filter((s) => s.parentVaultId === vaultId)
            .sort((col1: any, col2: any) => col1.order - col2.order)
            .map(s => s.board);

    return boards;
}

export default useVaultBoardsArray;