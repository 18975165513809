import React from 'react';
import {  MenuItem, Select } from '@material-ui/core';
import { IVideoPlaylistApiModel } from '@app/Areas/Optimus/ApiClient/VideosClient';

export interface PlaylistSelectorProps {
    selectPlaylist: (event: React.ChangeEvent<{ value: unknown }>) => void;
    selectedPlaylist: number | null;
    playlists: IVideoPlaylistApiModel[];
}

function PlaylistSelector({
    selectedPlaylist,
    selectPlaylist,
    playlists
}: PlaylistSelectorProps) {
    return (
        <>
            <Select
                fullWidth
                labelId="playlist-selector-label"
                id="playlist"
                value={selectedPlaylist}
                onChange={selectPlaylist}
            >
                { playlists.map((playlist) => (<MenuItem value={playlist.id}>{playlist.title}</MenuItem>)) }
            </Select>
        </>
    )
}

export default PlaylistSelector;