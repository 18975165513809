import { matchPath, useLocation } from "react-router-dom";

const consoleRoutes = [
    "/optimus/vaults/:vaultId/deepthought/decks",
    "/optimus/vaults/:vaultId/deepthought/decks/:deckId",
    "/optimus/vaults/:vaultId/deepthought/sections/:sectionId/groups/:groupId/resources/:resourceId"
]

function useIsConsoleView() {
    const { pathname } = useLocation();

    for (const r of consoleRoutes) {
        const match = matchPath(pathname, {
            path: r,
            exact: true,
            strict: false
        })

        if (match) {
            return true;
        }
    };

    return false;
}

export default useIsConsoleView;
