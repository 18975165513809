import { IDeckApiModel } from '@app/Areas/Optimus/ApiClient/DecksClient';
import { Avatar, createStyles, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Theme } from '@material-ui/core';
import { DeckIcon } from '@optimus/Components/Shared/AppsIcons';

export interface DecksListProps {
    decks: IDeckApiModel[];
    goToDeck: (deckId?: number) => void;
}

const useClasses = makeStyles((theme: Theme) =>
    {
        return createStyles({
            iconWrapper: {
                backgroundColor: theme.palette.background.default
            },
            icon: {
                color: theme.palette.secondary.main
            }
          })
    }
)

function DecksList({
    decks,
    goToDeck
}: DecksListProps) {
    const classes = useClasses();
    return (
        <List>
            { Object.entries(decks).map(([key, d]) => (
                <ListItem onClick={() => goToDeck(d.id)}>
                    <ListItemAvatar>
                        <Avatar className={classes.iconWrapper}>
                            <DeckIcon className={classes.icon} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={d.title} secondary={d.description} />
                </ListItem>
            ))}
        </List>
    )
}

export default DecksList;
