import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch, useHistory, generatePath } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useOptimusDispatchContext, useOptimusStateContext } from '@app/Areas/Optimus/Contexts/OptimusContext';
import { createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import FeatureCard from '@optimus/Components/Shared/FeatureCard';
import PaperContent from '@optimus/Components/Shared/PaperContent';
import ConsoleWrapper from '@optimus/Components/Shared/ConsoleWrapper';
import Panel from '@optimus/Components/Shared/Panel';
import { FridayIcon, ZiggyIcon, JarvisIcon, DeepThoughtIcon, ProjectIcon } from '@optimus/Components/Shared/AppsIcons';
import { ZiggyColor, JarvisColor, FridayColor, DeepThoughtColor } from '@app/Areas/Optimus/Tokens/colors';
import EntityStats from '@optimus/Components/Shared/EntityStats';
import PanelHeader from '@optimus/Components/Shared/PanelHeader';
import { NEW_DECK_DIALOG_ID, NEW_PROJECT_DIALOG_ID } from '../../ActionDialogs';
import ProjectsHub from '@optimus/Ecosystems/Projects/ProjectsHub';
import Breadcrumb from '@optimus/Components/Shared/Breadcrumb/Breadcrumb';
import DeepThoughtRouting from '../../DeepThought/DeepThoughtRouting';
import { VaultIcon, DeckIcon } from '../../../Components/Shared/AppsIcons/AppsIcons';
import { useAppDispatchContext } from '@app/Areas/Optimus/Contexts/AppContext/AppContextComponent';
import { setCurrentVaultId } from '@app/Areas/Optimus/Contexts/AppContext/AppContextReducer';
import VaultViewLanding from '../VaultViewLanding/VaultViewLanding';
import PlaylistRouting from '../../Playlists/PlaylistRouting';
import { TaskBoardRouting } from '@app/Areas/Optimus/Apps/TaskBoardApp';

function VaultView() {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/friday`}>
                <ProjectsHub />
            </Route>
            <Route path={`${path}/deepthought`}>
                <DeepThoughtRouting />
            </Route>
            <Route path={`${path}/taskboards`}>
                <TaskBoardRouting />
            </Route>
            <Route path={`${path}/playlists`}>
                <PlaylistRouting />
            </Route>
            <Route path={`${path}`}>
                <VaultViewLanding />
            </Route>
        </Switch>
    )
}

export default VaultView;