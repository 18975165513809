import { useCallback, useRef, useState } from 'react';
import ConfirmationDialog from '@app/Areas/Optimus/Components/Shared/ConfirmationDialog';
import { generatePath, useHistory } from 'react-router-dom';
import { DELETE_BOARD_DIALOG_ID } from '@app/Areas/Optimus/Ecosystems/ActionDialogs';
import createBoardService from '../../../services/BoardsService';
import { BOARDS_CLEAR_BOARD_UNDER_DELETION, BOARDS_REMOVE_BOARD_UNDER_DELETION } from '../../../state/TaskBoardReducer/TaskBoardReducer';
import { useTaskBoardDispatchContext } from '../../../state/TaskBoardContextProvider/TaskBoardContextProvider';
import useBoardUnderDelete from '../../../hooks/_Boards/useBoardUnderDelete';

function DeleteBoard() {
    const history = useHistory();
    const boardUnderDelete = useBoardUnderDelete();    
    const currentParentVaultId = boardUnderDelete?.parentVaultId;
    const boardDispatch = useTaskBoardDispatchContext();
    const [isSending, setIsSending] = useState(false);
    const boardService = createBoardService();
    const isMounted = useRef(true);

    const sendRequest = useCallback(async (callback) => {
        if (boardUnderDelete?.board.id) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await boardService.deleteBoard(
                boardUnderDelete?.parentVaultId,
                boardUnderDelete.board.id
            );
            if (result) {
                boardDispatch({
                    type: BOARDS_REMOVE_BOARD_UNDER_DELETION,
                    boardId: boardUnderDelete.board.id
                })
                goToBoards();
                callback();
            }
            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [boardUnderDelete, isSending]
    ); // update the callback if the state changes

    const goToBoards = () => {
        if (currentParentVaultId) {
            const boardsPath = generatePath('/optimus/vaults/:vaultId/taskboards/boards', { vaultId: currentParentVaultId});
            history.push(boardsPath);
        }
    }

    const handleConfirm = (callback: () => void) => {
        if (!isSending) {
            sendRequest(callback);
        }
    }

    const cleanUp = () => {
        boardDispatch({
            type: BOARDS_CLEAR_BOARD_UNDER_DELETION
        })
    }

    return boardUnderDelete && (
        <ConfirmationDialog
            title="Delete Board"
            description={`Are you sure you want to delete the board '${boardUnderDelete?.board.name}' - once it's deleted it's gone forever!`}
            dialogId={DELETE_BOARD_DIALOG_ID}
            onConfirm={handleConfirm}
            onClose={cleanUp}
        />
    ) || null;
}

export default DeleteBoard;
