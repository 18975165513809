import { useEffect } from 'react';
import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useTaskBoardDispatchContext } from '@app/Areas/Optimus/Apps/TaskBoardApp';
import { getVaultBoardTaskLists, BOARDS_RESET } from '../../../state/TaskBoardReducer/TaskBoardReducer';
import useBoardIdParam from '../useBoardIdParam';

function useBoardTasklistsFetch(boardId: number | null = null) {
    const vaultId = useVaultIdParam();
    const boardIdParam = useBoardIdParam();

    boardId = boardId || boardIdParam;

    const boardDispatch = useTaskBoardDispatchContext();

    useEffect(() => {
        let isCancelled = false;
        async function fetchBoardTaskLists() {
            if (vaultId && boardId) {
                boardDispatch(getVaultBoardTaskLists(vaultId, boardId, isCancelled));
            }
        }
        fetchBoardTaskLists();

        return function cleanup() {
            isCancelled = true;
            boardDispatch({
                type: BOARDS_RESET,
                vaultId,
                boardId
            })
        }
    }, [vaultId, boardId])
}

export default useBoardTasklistsFetch;
