import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useSectionStateContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider';
import { IResourcesContextModel } from '../../ResourceReducer/ResourceReducer';
import { IGroupApiModel } from '@app/Areas/Optimus/ApiClient/BookmarksClient';

function useGroupsAsDictionary(
    sectionId: number | null,
    presetVaultId: number | null = null
): {
    [key: number]: IGroupApiModel
 } {
    const vaultIdParam = useVaultIdParam();
    const vaultId = presetVaultId !== null ? presetVaultId : vaultIdParam;

    const state: IResourcesContextModel = useSectionStateContext();

    if (vaultId === null || sectionId === null) {
        return {};
    }

    const groups =
        Object.entries(state.groups.byId)
            .filter(([key, value]) => value.parentVaultId === vaultId && value.parentSectionId === sectionId)
            .reduce((map, [key, value]) => ({
                ...map,
                [key]: value.group,
            }), {});

    return groups;
}

export default useGroupsAsDictionary;