import { ITaskItemCreationRequest, TaskStatus } from "@app/Areas/Optimus/ApiClient/TaskboardsClient";
import { useTaskBoardDispatchContext } from "@app/Areas/Optimus/Apps/TaskBoardApp";
import { useCallback, useRef, useState } from "react";
import createTaskItemService from "../../../services/TaskItemService";
import { getBoardTaskListItems } from "../../../state/TaskBoardReducer/TaskBoardReducer";
import ToggleInputButton from "../../BoardInputControl";

export interface NewTaskItemComponentProps {
    label: string;
    vaultId: number | null;
    boardId: number | null;
    taskListId: number | null;
}

function NewTaskItemComponent({
    label,
    vaultId,
    boardId,
    taskListId
}: NewTaskItemComponentProps) {
    const isMounted = useRef(true);

    const taskItemService = createTaskItemService();
    const [isSending, setIsSending] = useState(false)
    const [textValue, setTextValue] = useState("");
    const boardDispatch = useTaskBoardDispatchContext();

    const sendRequest = useCallback(async (final: () => void) => {
        if (vaultId && boardId && taskListId) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)    
    
            const request: ITaskItemCreationRequest = {
              title: textValue,
              description: '',
              status: TaskStatus._0,
              initiativeIds: []
            }
    
            // send the actual request
            var result = await taskItemService.createTaskItem(vaultId, boardId, taskListId, request);
    
            if (result) {
                boardDispatch(getBoardTaskListItems(vaultId, boardId, taskListId, false));
            }
    
            final();
    
            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [
        vaultId,
        textValue,
        isSending 
      ]); // update the callback if the state changes

    return <ToggleInputButton label={label} setValue={setTextValue} value={textValue} sendRequest={sendRequest} />
}

export default NewTaskItemComponent;