import appConfig from "../../../../../../app_config.json";
import { Button, Link } from "@material-ui/core";
import { useCallback, useRef, useState } from "react";
import createBoardService from "../../services/BoardsService";

export interface TaskBoardExportProps {
    vaultId: number | null;
    boardId: number | null;
}

function TaskBoardExport({
    vaultId,
    boardId
}: TaskBoardExportProps) {
    const boardService = createBoardService();

    const isMounted = useRef(true);
    const [isExporting, setIsExporting] = useState(false);

    const [exportDownloadUrl, setExportDownloadUrl] = useState<string | undefined>(undefined);

    const downloadDemo = () => {
        const data = [
            { state: "Arizona",        electors: 11 },
            { state: "Florida",        electors: 29 },
            { state: "Iowa",           electors:  6 },
            { state: "Michigan",       electors: 16 },
            { state: "North Carolina", electors: 15 },
            { state: "Ohio",           electors: 18 },
            { state: "Pennsylvania",   electors: 20 },
            { state: "Wisconsin",      electors: 10 },
        ]

        
    //     this.setState ({fileDownloadUrl: fileDownloadUrl}, 
    //     () => {
    //     this.dofileDownload.click(); 
    //     URL.revokeObjectURL(fileDownloadUrl);  // free up storage--no longer needed.
    //     this.setState({fileDownloadUrl: ""})
    // })   
    }

    const sendExportRequest = useCallback(async () => {
        if (vaultId && boardId) {
            // don't send again while we are sending
            if (isExporting) return
            // update state
            setIsExporting(true)
            // send the actual request
            var result = await boardService.exportBoard(vaultId, boardId);
            if (result) {
                const output = JSON.stringify(result, null, 4);
                const blob = new Blob([output]);
                const fileDownloadUrl = URL.createObjectURL(blob);
                setExportDownloadUrl(fileDownloadUrl);
            }

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsExporting(false)
            }
        }
      }, [ vaultId, boardId, isExporting ]
    ); // update the callback if the state changes

    const exportBoard = () => {
        sendExportRequest();
    }

    return (
        <>
            { !exportDownloadUrl && <Button onClick={() => exportBoard()}>
                Request Export
            </Button> }
            { exportDownloadUrl && <Link variant="button" color="textPrimary" download="board.json" href={exportDownloadUrl}>Download Export</Link> }
        </>
    )    
}

export default TaskBoardExport;