import React, { ReactNode } from 'react';
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { createMuiTheme, CssBaseline, MuiThemeProvider, StylesProvider, ThemeProvider } from '@material-ui/core';
import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

// export const themeOptions: ThemeOptions = {
//   palette: {
//     type: 'dark',
//     primary: {
//       main: '#598b9c',
//     },
//     secondary: {
//       main: '#00f5ec',
//     },
//     background: {
//       default: '#17212F',
//       paper: '#1f2d40',
//     },
//   },
// };

// export const themeOptions: ThemeOptions = {
//   palette: {
//     type: 'dark',
//     primary: {
//       main: '#598b9c',
//     },
//     secondary: {
//       main: '#00f5ec',
//     },
//     background: {
//       default: '#17212F',
//       paper: '#1f2d40',
//     },
//   },
// };

export const themeOptions: ThemeOptions = {
  palette: {
    type: 'dark',
    primary: {
      main: '#3CB8DC'
    },
    secondary: {
      main: '#00f5ec',
    },
    background: {
      default: '#17212F',
      paper: '#1f2d40',
    },
  },
  props: {
    MuiAppBar: {
      color: 'transparent',
    },
  },
  overrides: {
    // MuiButton: {
    //   root: {
    //     background: 'linear-gradient(45deg, #24738a 30%, #019792 90%)',
    //     border: 0,
    //     borderRadius: 3,
    //     boxShadow: '0 3px 5px 2px #019792',
    //     color: 'white',
    //     height: 48,
    //     padding: '0 30px',
    //   },
    // },
  },
  typography: {
    body2: {
      lineHeight: 1.67,
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    },
    h1: {
      fontFamily: '"Share Tech Mono", "Helvetica", "Arial", sans-serif',
    },
    h2: {
      fontFamily: '"Share Tech Mono", "Helvetica", "Arial", sans-serif',
    },
    h3: {
      fontFamily: '"Share Tech Mono", "Helvetica", "Arial", sans-serif',
    },
    h4: {
      fontFamily: '"Share Tech Mono", "Helvetica", "Arial", sans-serif',
    },
    h5: {
      fontFamily: '"Share Tech Mono", "Helvetica", "Arial", sans-serif',
    },
    h6: {
      fontFamily: '"Share Tech Mono", "Helvetica", "Arial", sans-serif',
    },
    subtitle1: {
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    },
    subtitle2: {
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    },
    body1: {
      fontFamily: '"Open Sans", "Helvetica", "Arial", sans-serif',
    },
    button: {
      fontFamily: '"Share Tech Mono", "Helvetica", "Arial", sans-serif',
    },
    caption: {
      fontFamily: '"Share Tech Mono", "Helvetica", "Arial", sans-serif',
    },
    overline: {
      fontFamily: '"Share Tech Mono", "Helvetica", "Arial", sans-serif',
    },
  },
};

export interface OptimusStylesProviderProps {
    children: ReactNode;
}

function OptimusStyleProvider({ children }: OptimusStylesProviderProps) {const defaultTheme = createMuiTheme(themeOptions);
    return (
        <>
            <CssBaseline />
            <StylesProvider injectFirst>
                <MuiThemeProvider theme={defaultTheme}>
                    <ThemeProvider theme={defaultTheme}>
                      <SCThemeProvider theme={defaultTheme}>
                        { children }
                      </SCThemeProvider>
                    </ThemeProvider>
                </MuiThemeProvider>
            </StylesProvider>
        </>
    )
}

export default OptimusStyleProvider;
