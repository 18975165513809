import { useCallback, useEffect, useRef, useState } from 'react';
import createSectionService from '@app/Areas/Optimus/Services/SectionService';
import { useSectionDispatchContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider/ResourceContextProvider';
import EditorDialog from '@optimus/Components/Shared/EditorDialog';
import { EDIT_SECTION_DIALOG_ID } from '../../ActionDialogs/index';
import { getVaultSections, RESOURCES_CLEAR_SECTION_UNDER_EDIT } from '../../../State/DataStores/ResourceStore/ResourceReducer/ResourceReducer';
import { SectionEditorModel } from '../NewSection/SectionEditorModel';
import SectionEditor from '../NewSection/SectionEditor';
import useSectionUnderEdit from '../../../State/DataStores/ResourceStore/hooks/useSectionUnderEdit/index';
import useVaultsAsDictionary from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useVaultsAsDictionary';
import { ISectionUpdateRequest, ISectionApiModel } from '@app/Areas/Optimus/ApiClient/BookmarksClient';

const blankSection: ISectionUpdateRequest = {
    name: '',
    newParentId: 0
}

function mapUpdateDetailsToSection(
    section: ISectionUpdateRequest
): SectionEditorModel {
    return {
        name: section.name,
    }
}

function EditSection() {
    const vaults = useVaultsAsDictionary();
    const [isSending, setIsSending] = useState(false)
    const sectionUnderEdit = useSectionUnderEdit();
    const section = sectionUnderEdit?.section;
    const [sectionUpdateRequest, setSectionUpdateRequest] = useState<ISectionUpdateRequest>(blankSection);
    const [updatedSection, setUpdatedSection] = useState<ISectionApiModel | null>(null);
    const sectionService = createSectionService();
    const sectionDispatch = useSectionDispatchContext();
    const isMounted = useRef(true);

    const currentParentVaultId = sectionUnderEdit?.parentVaultId;
    const currentParentVault = currentParentVaultId ? vaults[currentParentVaultId] : null;

    useEffect(() => {
        if (!!sectionUnderEdit?.section && !!sectionUnderEdit.parentVaultId) {
            const { section } = sectionUnderEdit;
            setSectionUpdateRequest({
                ...sectionUpdateRequest,
                name: section.name || '',
                newParentId: sectionUpdateRequest?.newParentId || sectionUnderEdit.parentVaultId,
            });
        }
    }, [sectionUnderEdit]);

    function handleSave() {
        if (!updatedSection && !isSending) {
            sendRequest();
        }
    }

    const sendRequest = useCallback(async () => {
        if (currentParentVaultId && section?.id && !!sectionUpdateRequest) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await sectionService.updateSection(currentParentVaultId, section.id, sectionUpdateRequest);
            if (result) {
                setUpdatedSection(result);
            }
            sectionDispatch(getVaultSections(currentParentVaultId));

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [ currentParentVaultId, sectionUpdateRequest, isSending]
    ); // update the callback if the state changes


    function sectionEdited(event: any) {
        if (sectionUpdateRequest !== null) {
            setSectionUpdateRequest({
                ...sectionUpdateRequest,
                [event.target.name]: event.target.value
            })
        }
    }

    function cleanUp() {
        setSectionUpdateRequest(blankSection);
        setUpdatedSection(null);
        sectionDispatch({
            type: RESOURCES_CLEAR_SECTION_UNDER_EDIT
        })
    }

    return currentParentVault && sectionUpdateRequest && (
        <EditorDialog
            title="Edit Section"
            saveTitle="Save Changes"
            dialogId={EDIT_SECTION_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            { !updatedSection && <SectionEditor
                section={mapUpdateDetailsToSection(sectionUpdateRequest)}
                handleSectionChange={sectionEdited}
                title={currentParentVault.title}
                isSaving={isSending}
            /> }
            { updatedSection && JSON.stringify(updatedSection) }
        </EditorDialog>
    ) || null;
}

export default EditSection;
