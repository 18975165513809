import { useCallback, useEffect, useRef, useState } from "react";
import TaskListEditor from './TaskListEditor';
import EditorDialog from "@app/Areas/Optimus/Components/Shared/EditorDialog";
import { EDIT_TASKLIST_DIALOG_ID } from "@app/Areas/Optimus/Ecosystems/ActionDialogs";
import useVaultsAsDictionary from "@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useVaultsAsDictionary";
import { BOARDS_CLEAR_TASKLIST_UNDER_EDIT, getVaultBoardTaskLists } from "../../../state/TaskBoardReducer/TaskBoardReducer";
import { useTaskBoardDispatchContext } from "../../../state/TaskBoardContextProvider/TaskBoardContextProvider";
import { ITaskListApiModel, ITaskListUpdateRequest } from "@app/Areas/Optimus/ApiClient/TaskboardsClient";
import useTaskListUnderEdit from "../../../hooks/_TaskLists/useTaskListUnderEdit";
import { TaskListEditorModel } from "./TaskListEditorModel";
import createTaskListService from "../../../services/TaskListsService";

const blankTaskList: ITaskListUpdateRequest = {
    name: '',
    newParentId: 0,
    lifeCycleStage: 0
};

function mapCreationDetailsToTaskList(
    tasklist: ITaskListUpdateRequest
): TaskListEditorModel {
    return {
        name: tasklist.name || '',
        lifecycleStage: tasklist.lifeCycleStage
    }
}

function EditTaskList() {
    const taskListUnderEdit = useTaskListUnderEdit();
    const vaults = useVaultsAsDictionary();
    const [isSending, setIsSending] = useState(false)
    const [taskListUpdateRequest, setTaskListUpdateRequest] = useState<ITaskListUpdateRequest | null>(blankTaskList);
    const [updatedTaskList, setUpdatedTaskList] = useState<ITaskListApiModel | null>(null);
    const taskListService = createTaskListService();
    const boardsDispatch = useTaskBoardDispatchContext();
    const isMounted = useRef(true);
    const taskList = taskListUnderEdit?.taskList;
    const currentParentVaultId = taskListUnderEdit?.parentVaultId;
    const currentParentVault = currentParentVaultId ? vaults[currentParentVaultId] : null;
    const currentTaskBoardId = taskListUnderEdit?.parentBoardId;

    useEffect(() => {
        if (!!taskListUnderEdit?.taskList && !!taskListUnderEdit.parentVaultId && !!currentTaskBoardId) {
            setTaskListUpdateRequest({
                ...taskListUpdateRequest,
                name: taskListUnderEdit.taskList?.name || '',
                lifeCycleStage: taskListUnderEdit.taskList?.lifecycleStage?.lifecycleId || 0,
                newParentId: currentTaskBoardId
            });
        }
    }, [taskListUnderEdit]);

    function taskListEdited(event: any) {
        if (taskListUpdateRequest !== null) {
            setTaskListUpdateRequest({
                ...taskListUpdateRequest,
                [event.target.name]: event.target.value
            })
        }
    }

    const handleSave = () => {
        if (!updatedTaskList && !isSending) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        setTaskListUpdateRequest(null);
        setUpdatedTaskList(null);
        boardsDispatch({
            type: BOARDS_CLEAR_TASKLIST_UNDER_EDIT
        });
    }

    const sendRequest = useCallback(async () => {
        if (currentParentVaultId && currentTaskBoardId && taskList?.id && !!taskListUpdateRequest) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await taskListService.updateTaskList(currentParentVaultId, currentTaskBoardId, taskList.id, taskListUpdateRequest);
            if (result) {
                setUpdatedTaskList(result);
            }
            boardsDispatch(getVaultBoardTaskLists(currentParentVaultId, currentTaskBoardId, false));

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [ currentParentVaultId, taskListUpdateRequest, isSending]
    ); // update the callback if the state changes

    return currentParentVault && taskListUpdateRequest && (
        <EditorDialog
            title="Edit TaskList"
            saveTitle="Update TaskList"
            dialogId={EDIT_TASKLIST_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            { !updatedTaskList && <TaskListEditor
                taskList={mapCreationDetailsToTaskList(taskListUpdateRequest)}
                handleTaskListChange={taskListEdited}
                title={currentParentVault.title}
                isSaving={isSending}
            /> }
            { updatedTaskList && JSON.stringify(updatedTaskList) }
        </EditorDialog>
    ) || null;
}

export default EditTaskList;
