import React, { useReducer, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';

import config from "./auth_config.json";
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from "./react-auth0-spa";
import history from "./utils/history";
import rootReducer from './state/root.reducer';
import { initialState } from './state/initial-state';
import { ApplicationState } from './state/root.reducer';
import { AppStoreProvider } from './domains/vaults/store/app-store-provider';
import './index.css';
import AuthorizedApolloProvider from './apollo/AuthorizardApolloProvider';

interface StateProviderProps {
  children: any;
}

interface IContextProps {
  state: ApplicationState;
  dispatch: ({type}:{type:string}) => void;
}

export const GlobalStore = React.createContext({} as IContextProps);

// export const useGlobalStore = () => React.useContext(GlobalStore);

const asyncer = (dispatch: any, state: ApplicationState) => (action: any) =>
  typeof action === 'function' ? action(dispatch, state) : dispatch(action);

const StateProvider = (props: StateProviderProps) => {
  const [state, dispatchBase] = useReducer(rootReducer, initialState);

  const dispatch  = useCallback(asyncer(dispatchBase, state), [])

  return (
    <GlobalStore.Provider value={{ state, dispatch }}>
      { props.children }
    </GlobalStore.Provider>
  )
}

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: any) => {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };


ReactDOM.render(
    <Auth0Provider
        domain={config.domain}
        client_id={config.clientId}
        redirect_uri={window.location.origin}
        onRedirectCallback={onRedirectCallback}
        audience={config.audience}
    >
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <AuthorizedApolloProvider>
          <StateProvider>
            <AppStoreProvider>
              <App />
            </AppStoreProvider>
          </StateProvider>
        </AuthorizedApolloProvider>
      </MuiPickersUtilsProvider>
    </Auth0Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
