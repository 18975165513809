import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useTaskBoardStateContext } from '@app/Areas/Optimus/Apps/TaskBoardApp';
import { ITaskBoardContextModel } from '../../../state/TaskBoardReducer/TaskBoardReducer';
import { ITaskListApiModel } from '@app/Areas/Optimus/ApiClient/TaskboardsClient';

function useTaskListsAsDictionary(boardId: number | null): {
    [key: number]: ITaskListApiModel
} {
    const vaultId = useVaultIdParam();
    const state: ITaskBoardContextModel = useTaskBoardStateContext();

    if (vaultId === null || boardId === null) {
        return {};
    }

    const taskLists =
        Object.entries(state.taskLists.byId)
            .filter(([key, value]) => value.parentVaultId === vaultId && value.parentBoardId === boardId)
            .sort((col1: any, col2: any) => col1.order - col2.order)
            .reduce((map, [key, value]) => ({
                ...map,
                [key]: value.taskList,
            }), {});

    return taskLists;

}

export default useTaskListsAsDictionary;