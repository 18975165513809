import { BookmarksClient, ISectionApiModel, ISectionCreationRequest, ISectionUpdateRequest, SectionCreationRequest, SectionUpdateRequest } from "../../ApiClient/BookmarksClient";

export interface ISectionService {
    getSections: (vaultId: number) => Promise<ISectionApiModel[]>;
    createSection: (vaultId: number, creationRequest: ISectionCreationRequest) => Promise<ISectionApiModel>;
    updateSection: (vaultId: number, sectionId: number, updateRequest: ISectionUpdateRequest) => Promise<ISectionApiModel>;
    archiveSection: (vaultId: number, sectionId: number) => Promise<ISectionApiModel>;
}

function createSectionService() {
    const client = new BookmarksClient();
    const sectionService: ISectionService = {
        // Function to get Sections, no data fetch lifecycle
        getSections: (vaultId: number) => client.sectionsAll(vaultId),
        // Function to create Sections
        createSection: (vaultId: number, creationRequest: ISectionCreationRequest) => client.sectionsPOST(vaultId, new SectionCreationRequest(creationRequest)),
        // Function to update Section
        updateSection: (vaultId: number, sectionId: number, updateRequest: ISectionUpdateRequest) => client.sectionsPUT(vaultId, sectionId, new SectionUpdateRequest(updateRequest)),
        // Function to archive a section and all of its groups and resources
        archiveSection: (vaultId: number, sectionId: number) => client.sectionsDELETE(vaultId, sectionId, false)
    }
    return sectionService;
}

export default createSectionService;
