export const appColor = '#1b1b1b';
export const vaultColor = '#495867';
export const resourceColor = '#f7f7ff';
export const cardColor = '#438a86';
export const videoColor = '#f9dbbd';
export const groupColor = '#bdd5ae';
export const sectionColor = '#577399';
export const playlistColor = '#007991';
export const deckColor = '#007991';

export const cardOtherColor = '#bcd8c1';
export const highlightColor = '#fe5f55';

export const charcoal = '#282828';