import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useEffect } from 'react';
import { useDeckDispatchContext } from '@app/Areas/Optimus/State/DataStores/DeckStore/DeckContextProvider';
import { getVaultDecks, DECKS_DECKS_RESET } from '../../DeckReducer/DeckReducer';

function useDecksFetch() {
    const vaultId = useVaultIdParam();
    const deckDispatch = useDeckDispatchContext();

    useEffect(() => {
        async function fetchDecks() {
            if (vaultId) {
                deckDispatch(getVaultDecks(vaultId));
            }
        }
        fetchDecks();

        return function cleanup() {
            deckDispatch({ type: DECKS_DECKS_RESET })
        }
    }, [vaultId]);
}

export default useDecksFetch;