import { useEffect } from "react";
import { useTaskBoardStateContext, useTaskBoardDispatchContext } from "@app/Areas/Optimus/Apps/TaskBoardApp";
import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { BOARDS_CLEAR_BOARD_UNDER_DELETION } from "../../../state/TaskBoardReducer/TaskBoardReducer";

function useBoardUnderDelete() {
    const vaultId = useVaultIdParam();

    const boardStateContext = useTaskBoardStateContext();
    const boardUnderDelete = boardStateContext.archivedBoards.selectedToDelete;
    const boardDispatch = useTaskBoardDispatchContext();

    useEffect(() => {
        return function cleanup() {
            boardDispatch({
                type: BOARDS_CLEAR_BOARD_UNDER_DELETION
            })
        }
    }, [vaultId])

    if (!boardUnderDelete) {
        return null;
    }

    const board = boardStateContext?.archivedBoards?.byId[boardUnderDelete];

    if (
        !board
        || board.parentVaultId !== vaultId
    ) {
        return null;
    }

    return board;
}

export default useBoardUnderDelete;
