import React from "react";
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { ISectionApiModel } from "@app/Areas/Optimus/ApiClient/BookmarksClient";

export interface SectionSelectorProps {
    selectSection: (event: React.ChangeEvent<{ value: unknown }>) => void;
    selectedSection: number | null;
    sections: ISectionApiModel[];
}

function SectionSelector({
    selectedSection,
    selectSection,
    sections,
}: SectionSelectorProps) {
    return (
        <>
            <InputLabel id="section-selector-label">Sections</InputLabel>
            <Select
                fullWidth
                labelId="section-selector-label"
                id="section"
                value={selectedSection}
                onChange={selectSection}
            >
                { sections.map((section) => (<MenuItem value={section.id}>{section.name}</MenuItem>))}
            </Select>
        </>
    )
}

export default SectionSelector;