import { ApplicationState } from './root.reducer';

export const initialState: ApplicationState = {
  saving: {
    isSaving: false
  },
  fetching: {
    isFetching: false,
    payloadName: null
  },
  deleting: {
    isDeleting: false,
    payloadName: null,
    payloadId: null
  },
  editing: {
    isEditing: false
  },
  verifying: {
    isVerifying: false,
    urlToVerify: null
  },
  resource: {
    temporaryNewResource: null,
    temporaryExistingResource: null,
    resources: [],
    temporaryAssignmentsForResource: [],
    selectedResource: null,
    temporaryResourceToDelete: null
  },
  section: {
    isCreatingSection: false,
    temporaryNewSection: null,
    sections: [],
    selectedSection: null,
    temporaryExistingSection: null,
    temporarySectionToDelete: null
  },
  group: {
    isEditingGroup: false,
    temporaryGroupUnderEdit: null,
    selectedGroup: null
  }
}