
import { IVideoPlaylistApiModel, IVideoApiModel } from '@app/Areas/Optimus/ApiClient/VideosClient';
import createPlaylistService from '@app/Areas/Optimus/Services/PlaylistService';
import createVideoService from '@app/Areas/Optimus/Services/VideoService';
import { Dispatch } from 'react';

export const VIDEOS_SET_PLAYLISTS = 'VIDEOS_SET_PLAYLISTS';
export const PLAYLISTS_RESET = 'PLAYLISTS_RESET';

export const VIDEOS_SET_PLAYLIST_UNDER_EDIT = 'VIDEOS_SET_PLAYLIST_UNDER_EDIT';
export const VIDEOS_CLEAR_PLAYLIST_UNDER_EDIT = 'VIDEOS_CLEAR_PLAYLIST_UNDER_EDIT';
export const VIDEOS_SET_PLAYLIST_UNDER_DELETE = 'VIDEOS_SET_PLAYLIST_UNDER_DELETE';
export const VIDEOS_CLEAR_PLAYLIST_UNDER_DELETE = 'VIDEOS_CLEAR_PLAYLIST_UNDER_DELETE';

export const VIDEOS_SET_VIDEOS = 'VIDEOS_SET_VIDEOS';
export const VIDEOS_RESET = 'VIDEOS_RESET';
export const VIDEOS_SET_VIDEO_UNDER_EDIT = 'VIDEOS_SET_VIDEO_UNDER_EDIT';
export const VIDEOS_CLEAR_VIDEO_UNDER_EDIT = 'VIDEOS_CLEAR_VIDEO_UNDER_EDIT';
export const VIDEOS_SET_VIDEO_UNDER_DELETE = 'VIDEOS_SET_VIDEO_UNDER_DELETE';
export const VIDEOS_CLEAR_VIDEOS_UNDER_DELETE = 'VIDEOS_CLEAR_VIDEOS_UNDER_DELETE';

export interface IVideoContextModel {
    playlists: {
        byId: {
            [playlistId: string]: {
                playlist: IVideoPlaylistApiModel;
                parentVaultId: number;
                videoIds: number[];
            }
        },
        allIds: number[];
        selected: string | null;
        selectedToEdit: string | null;
        selectedToDelete: string | null;
    },
    videos: {
        byId: {
            [videoId: string]: {
                video: IVideoApiModel;
                parents: {
                    parentVaultId: number;
                    parentPlaylistId: number;
                } []
            }
        },
        allIds: string[];
        selected: string | null;
        selectedToEdit: string | null;
        selectedToDelete: string | null;
    }
}

/**
 * The reducer for managing video state (see ResourceReducer for common pattern)
 * @param state
 * @param action
 */
function VideoReducer(state: IVideoContextModel, action: any): IVideoContextModel {
    switch (action.type) {
        case VIDEOS_SET_PLAYLISTS:
            return {
                ...state,
                playlists: { // TODO: Fix nuking of playlists when assigning playlists
                    ...state.playlists,
                    byId: {
                        ...action.playlists.reduce((newPlaylists: {
                            [playlistId: string]: {
                                playlist: IVideoPlaylistApiModel;
                                parentVaultId: string;
                                videoIds: string[];
                            }
                        }, p: IVideoPlaylistApiModel) => {
                            if (p.id) {
                                return {
                                    ...newPlaylists,
                                    [p.id]: {
                                        playlist: p,
                                        parentVaultId: action.vaultId,
                                        videoIds: []
                                    }
                                }
                            }
                            return newPlaylists;
                        }, {})
                    },
                    allIds: [
                        // TODO: Merge existing Ids
                        ...action.playlists.map((p: IVideoPlaylistApiModel) => p.id)
                    ],
                }
            }
            case VIDEOS_SET_VIDEOS:
                return {
                    ...state,
                    videos: {
                        ...state.videos,
                        byId: {
                            ...action.videos.reduce((newVideos: {
                                [videoId: string]: {
                                    resource: IVideoApiModel;
                                    parents: {
                                        parentVaultId: number;
                                        parentPlaylistId: number;
                                    } []
                                }
                            }, v: IVideoApiModel) => {
                                if (v.id) {
                                    return {
                                        ...newVideos,
                                        [v.id]: {
                                            video: v,
                                            // TODO: Merge existing parents
                                            parents: [{
                                                parentVaultId: action.vaultId,
                                                parentPlaylistId: action.playlistId
                                            }]
                                        }
                                    }
                                }
                                return newVideos;
                            }, {})
                        },
                        allIds: [
                            // TODO: Merge existing Ids
                            ...action.videos.map((v: IVideoApiModel) => v.id)
                        ],
                    }
                }
        default:
            throw new Error();
    }
}

export default VideoReducer;

/**
 * Action creator for fetch the vault playlists
 */
export function getVaultPlaylists(vaultId: number) {
    return async (dispatch: Dispatch<any>) => {
        const playlistService = createPlaylistService();
        return playlistService.getPlaylists(vaultId).then((result) => {
            dispatch({
                type: VIDEOS_SET_PLAYLISTS,
                vaultId,
                playlists: result
            })
        });
    }
}

/**
 * Action create for fetching the playlist videos
 */
export function getPlaylistVideos(vaultId: number, playlistId: number) {
    return async (dispatch: Dispatch<any>) => {
        const videoService = createVideoService();
        return videoService.getVideos(vaultId, playlistId).then((result) => {
            dispatch({
                type: VIDEOS_SET_VIDEOS,
                vaultId,
                playlistId,
                videos: result
            })
        });
    }
}