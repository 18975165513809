import PanelHeader from '@app/Areas/Optimus/Components/Shared/PanelHeader';
import { createStyles, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';

export interface TaskItemEditorModel {
    title: string;
    description: string;
}

export interface TaskItemEditorProps {
    title: string;
    taskItem: TaskItemEditorModel;
    handleTaskItemChange: (event: any) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
        marginBottom: 20
    },
    form: {
        padding: '0.75rem'
    }
  }),
);

function TaskItemEditor({
    title: editorTitle,
    taskItem,
    handleTaskItemChange
}: TaskItemEditorProps) {
    const classes = useStyles();
    const { title, description } = taskItem;

    return (
        <form className={classes.form}>
            <PanelHeader headerVariant="h5" header={`TaskItem - ${editorTitle}`}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.input}
                            fullWidth id="taskitem-title-input"
                            label="Title"
                            name="title"
                            value={title}
                            onChange={handleTaskItemChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            className={classes.input}
                            fullWidth id="taskitem-description-input"
                            label="Description"
                            name="description"
                            value={description}
                            onChange={handleTaskItemChange}
                        />
                    </Grid>
                </Grid>
            </PanelHeader>
        </form>
    )
}

export default TaskItemEditor;
