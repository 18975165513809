// InMemoryCache 
// LocalStorageCache 

import { Cacheable, ICache, InMemoryCache } from "@auth0/auth0-spa-js";

const memoryCache = new InMemoryCache();

const VectorAuthCache: ICache = {
    get<T = Cacheable>(key: string) {

        console.log('Getting entry from cache ', key);

        const cacheEntry = memoryCache.enclosedCache.get(key) as T;

        console.log('Entry result ,', cacheEntry);

        return cacheEntry;
    },  

    set<T = Cacheable>(key: string, entry: T) {
        console.log('Setting entry in cache ', key, entry);

        memoryCache.enclosedCache.set(key, entry);

        console.log('Entry set: ', key, entry);
    },
  
    remove: function (key) {
        console.log('removing cache item: ', key);

        memoryCache.enclosedCache.remove(key);

        console.log('removed cache item: ', key);
    },
  
    // Optional
    allKeys: function () {
        console.log('getting all keys');

        const allKeys = memoryCache.enclosedCache.allKeys; 

        if (!allKeys) {
            console.log('allKeys not found');
            return [];
        }

        const result = allKeys();

        console.log('retrieved all key: ', result);

        return result;
    }
};

export default VectorAuthCache;
