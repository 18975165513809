import { IVideoApiModel, IVideoCreationRequest, VideoApiModel } from "@app/Areas/Optimus/ApiClient/VideosClient";
import useCreateVaultChildEntity from "@app/Areas/Optimus/Hooks/_operations/useCreateVaultChildEntity";
import createVideoService from "@app/Areas/Optimus/Services/VideoService";
import { useVideoDispatchContext } from "@app/Areas/Optimus/State/DataStores/VideoStore/VideoContextProvider/VideoContextProvider";
import { getPlaylistVideos } from "@app/Areas/Optimus/State/DataStores/VideoStore/VideoReducer/VideoReducer";
import { AppBar, Button, createStyles, IconButton, makeStyles, Theme, Toolbar, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { transparentize } from "polished";
import { useState } from "react";
import YouTube from "react-youtube";
import VideoEditor, { VideoEditorModel } from "../VideoEditor/VideoEditor";
import VideoVerifier from "../VideoVerifier/VideoVerifier";

export interface NewVideoProps {
    playlistId: number;
    close: () => void;
}

const blankVideo: IVideoCreationRequest = {
    title: '',
    tags: [],
    publishDate: new Date(),
    isPublic: false,
    source: '',
    sourceId: '',
    url: '',
    publisher: ' ',
    assignments: [],
    duration: '0.00:00:00.0000'
}

function mapCreationDetailsToVideo(newVideo: IVideoCreationRequest) {
    const model: VideoEditorModel = {
        title: newVideo.title,
        url: newVideo.url || '',
        sourceId: newVideo.sourceId,
        description: newVideo.description || '',
        thumbnailUrl: newVideo.thumbnailUrl || '',
        tags: newVideo.tags,
        source: newVideo.source,
        publisher: newVideo.publisher || ' ',
        duration: newVideo.duration || '0.00:00:00.0000',
        publishDate: newVideo.publishDate,
        isPublic: newVideo.isPublic
    };

    return model;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        background: 'rgba(0,0,0,0.1)',
        backdropFilter: 'blur(10px)',
    },
    appBar: {
      position: 'relative',
      background: transparentize(0.9, theme.palette.primary.main),
      boxShadow: `0 0px 10px #3cb8dc99`,
      borderBottom: `1px solid #3cb8dc99`,
      marginBottom: '2em',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

function NewVideo({
    playlistId,
    close
}: NewVideoProps) {
    const classes = useStyles();
    const [newVideo, setNewVideo] = useState<IVideoCreationRequest>(blankVideo);
    const [createdVideo, setCreatedVideo] = useState<IVideoApiModel | null>(null);
    const videoService = createVideoService();
    const videoDispatch = useVideoDispatchContext();

    const {
        isSending,
        sendRequest,
    } = useCreateVaultChildEntity<
        IVideoCreationRequest,
        VideoApiModel
    >(
        (v: number, cr: IVideoCreationRequest) => videoService.createVideo(v, cr),
        newVideo,
        (result: VideoApiModel) => setCreatedVideo(result),
        (vaultId: number) => {
            if (playlistId) {
                videoDispatch(getPlaylistVideos(vaultId, playlistId))
            }
        },
    );

    function videoEdited(event: any) {
        setNewVideo({
            ...newVideo,
            [event.target.name]: event.target.value
        })
    }

    function resetVideo() {
        setCreatedVideo(null);
        setNewVideo(blankVideo);
    }

    const handleSave = () => {
        if (!createdVideo && !isSending) {
            sendRequest();
        }
    }

    return <>
        <AppBar className={classes.appBar}>
            <Toolbar>
                <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="subtitle1">
                    New Video
                </Typography>
                <Button color="secondary" variant="contained" autoFocus onClick={handleSave}>
                    Save Video to Playlist
                </Button>
            </Toolbar>
        </AppBar>

        { JSON.stringify(newVideo) }

        { createdVideo && <>
                { isSending && 'is sending' }
                <Button onClick={resetVideo} color="primary" variant="contained">Add New Video</Button>
                { JSON.stringify(createdVideo) }
        </> }

        <VideoVerifier reset={resetVideo} newVideo={newVideo} setNewVideo={setNewVideo} playlistId={playlistId} />

        { JSON.stringify(newVideo) }

        { newVideo?.sourceId?.length && <YouTube
            videoId={newVideo.sourceId}
        /> }

        <VideoEditor
            video={mapCreationDetailsToVideo(newVideo)}
            handleVideoChange={videoEdited}
            handleReset={resetVideo}
            isSaving={false}
        />
        
    </>
}

export default NewVideo;
