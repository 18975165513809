import { generatePath, useHistory } from "react-router-dom";
import { Route, Switch, useRouteMatch } from "react-router";
import Panel from "../../Components/Shared/Panel";
import PanelHeader from "../../Components/Shared/PanelHeader";
import { useDialogDispatchContext } from "../../State/App/DialogContext/DialogContextProvider";
import { OPTIMUS_OPEN_DIALOG } from "../../State/App/DialogContext/DialogReducer/DialogReducer";
import useVaultIdParam from "../../State/App/NavigationContext/hooks/useVaultIdParam";
import usePlaylistIdParam from "../../State/DataStores/VideoStore/hooks/usePlaylistIdParam";
import usePlaylistsFetch from "../../State/DataStores/VideoStore/hooks/usePlaylistsFetch";
import useVaultPlaylistsAsDictionary from "../../State/DataStores/VideoStore/hooks/useVaultBoardsAsDictionary";
import useVaultPlaylistsAsArray from "../../State/DataStores/VideoStore/hooks/useVaultPlaylistsAsArray";
import { IMPORT_YT_PLAYLIST_DIALOG_ID, NEW_PLAYLIST_DIALOG_ID } from "../ActionDialogs";
import PlaylistSelector from "./components/PlaylistSelector";
import PlaylistView from "./PlaylistView/PlaylistView";
import { useState } from "react";

function PlaylistRouting({}: {}) {
    usePlaylistsFetch();

    const [isNewVideoOpen, setIsNewVideoOpen] = useState(false);

    const vaultId = useVaultIdParam();
    const playlistId = usePlaylistIdParam();
    const playlists = useVaultPlaylistsAsArray();
    const playlistDictionary = useVaultPlaylistsAsDictionary();

    const { path } = useRouteMatch();
    const history = useHistory();

    const dialogDispatch = useDialogDispatchContext();

    const openNewPlaylistDialog = () => {
        dialogDispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: NEW_PLAYLIST_DIALOG_ID
        })
    }

    const openImportPlaylistDialog = () => {
        dialogDispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: IMPORT_YT_PLAYLIST_DIALOG_ID
        })
    }

    const openNewVideoView = () => {
        setIsNewVideoOpen(true);
    }

    const closeNewVideoView = () => {
        setIsNewVideoOpen(false);
    }

    const selectPlaylist = (event: React.ChangeEvent<{ value: unknown }>) => {
        const playlist = playlistDictionary[event.target.value as number];
        if (vaultId && playlist && playlist.id) {
            const newpath = generatePath('/optimus/vaults/:vaultId/playlists/:playlistId', {
                vaultId,
                playlistId: playlist.id
            })
            history.push(newpath)
        }
    }

    return (
        <>
            <Panel>
                <PanelHeader
                    headerVariant="h5"
                    header="Video Playlists"
                    components={
                        <PlaylistSelector
                            playlists={playlists}
                            selectPlaylist={selectPlaylist}
                            selectedPlaylist={playlistId}
                        />
                    }
                    actions={[{
                        label: 'New Playlist',
                        action: openNewPlaylistDialog
                    }, {
                        label: 'Import YouTube Playlist',
                        action: openImportPlaylistDialog
                    }, {
                        label: 'Add Video',
                        action: openNewVideoView
                    }
                    ]}
                />
                <Switch>
                    <Route path={`${path}/:playlistId`}>
                        <PlaylistView closeNewVideo={closeNewVideoView} isNewVideoOpen={isNewVideoOpen} />
                    </Route>
                </Switch>
            </Panel>
        </>
    )
}

export default PlaylistRouting;