import { ProjectsClient, IProjectCreationRequest, ProjectCreationRequest, IProjectApiModel, IProjectUpdateRequest, ProjectUpdateRequest } from "../../ApiClient/ProjectsClient";

export interface IProjectService {
    getProjects: (vaultId: number) => Promise<IProjectApiModel[]>;
    // useGetProjectsRequest: (vaultId: number) => [Error | null, boolean, IProjectApiModel[] | null];
    createProject: (vaultId: number, creationRequest: IProjectCreationRequest) => Promise<IProjectApiModel>;
    updateProject: (vaultId: number, projectId: number, updateRequest: IProjectUpdateRequest) => Promise<IProjectApiModel>;
    archiveProject: (vaultId: number, projectId: number) => Promise<IProjectApiModel>;
}

function createProjectService() {
    const client = new ProjectsClient();
    const projectService: IProjectService = {
        // Function to get Vaults, no data fetch lifecycle
        getProjects: (vaultId: number) => client.projectsAll(vaultId),
        // // Hook to get Vault Projects with data fetch lifecycle
        // useGetProjectsRequest: (vaultId: number) => {
        //     const [error, isLoading, data] = useNetworkRequest<ProjectApiModel[]>(() => getToken().then((token: string) => {
        //         const client = new VectorClient(baseUrl, token);
        //         return client.projectsAll(vaultId)
        //     }), []);
        //     return [error, isLoading, data];
        // },
        archiveProject: (vaultId: number, projectId: number) => client.projectsDELETE(vaultId, projectId, false),
        createProject: (vaultId: number, creationRequest: IProjectCreationRequest) => client.projectsPOST(vaultId, new ProjectCreationRequest(creationRequest)),
        updateProject: (vaultId: number, projectId: number, updateRequest: IProjectUpdateRequest) => client.projectsPUT(vaultId, projectId, new ProjectUpdateRequest(updateRequest))
    }

    return projectService;
}

export default createProjectService;