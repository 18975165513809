import { useLocation, matchPath } from 'react-router-dom';

function useVaultIdParam() {
    const { pathname } = useLocation();
    const match = matchPath<{
        vaultId?: string
    }>(pathname, {
        path: "/optimus/vaults/:vaultId",
        exact: false,
        strict: false
    });

    const vaultId = match?.params?.vaultId;

    if (vaultId) {
        return +vaultId;
    }

    return null;
}

export default useVaultIdParam;
