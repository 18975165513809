import StoreContextFactory from "../../../_framework/factories/store-context.factory";

import { AppStoreFactory } from './app-store-factory';
import InitialAppState from './initial-app-state';
import { AppStore } from './app-store';
import { AppState } from './initial-app-state';


const factory = new StoreContextFactory<AppStore, AppState>(new AppStoreFactory());
const result = factory.GetStoreContext(InitialAppState)

export const useAppStore = result.hook;
export const AppStoreProvider = result.provider;