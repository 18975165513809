import PanelHeader from '@app/Areas/Optimus/Components/Shared/PanelHeader';
import { createStyles, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { BoardEditorModel as BoardEditorModel } from './BoardEditorModel';

export interface BoardEditorProps {
    board: BoardEditorModel;
    handleBoardChange: (event: any) => void;
    title?: string;
    // handleReset: (event: any) => void;
    isSaving: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
        marginBottom: 20
    },
    form: {
        padding: '0.75rem'
    }
  }),
);

function BoardEditor({
    title,
    isSaving,
    board,
    handleBoardChange
}: BoardEditorProps) {
    const classes = useStyles();

    return (
        <form className={classes.form}>
            { isSaving && <Typography variant="h4">Saving Board...</Typography> }
            <PanelHeader headerVariant="h5" header={`Board - ${title}`}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.input}
                            fullWidth id="board-name-input"
                            label="Name"
                            name="name"
                            value={board.name}
                            onChange={handleBoardChange}
                        />
                    </Grid>
                </Grid>
            </PanelHeader>
        </form>
    )
}

export default BoardEditor;
