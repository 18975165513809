import NewProject from '@optimus/Ecosystems/Projects/NewProject';
import EditProject from '@optimus/Ecosystems/Projects/EditProject';
import DeleteProject from '@optimus/Ecosystems/Projects/DeleteProject';
import NewDeck from '@optimus/Ecosystems/Decks/DecksHub/NewDeck';
import EditDeck from '@optimus/Ecosystems/Decks/DecksHub/EditDeck';
import DeleteDeck from '@optimus/Ecosystems/Decks/DeleteDeck';
import NewSection from '@optimus/Ecosystems/Sections/NewSection';
import EditSection from '@optimus/Ecosystems/Sections/EditSection';
import DeleteSection from '../Sections/DeleteSection/DeleteSection';
import NewGroup from '../Groups/NewGroup';
import NewResource from '../Resources/NewResource';
import EditResource from '../Resources/EditResource/EditResource';
import DeleteResource from '../Resources/DeleteResource/DeleteResource';
import EditGroup from '../Groups/EditGroup/EditGroup';
import DeleteGroup from '../Groups/DeleteGroup/DeleteGroup';
import NewVault from '../Vaults/NewVault';
import EditVault from '../Vaults/EditVault/EditVault';
import DeleteVault from '../Vaults/DeleteVault/DeleteVault';
import ArchiveBoard from '../../Apps/TaskBoard/components/_Boards/ArchiveBoard/ArchiveBoard';
import DeleteTaskItem from '../../Apps/TaskBoard/components/_TaskItems/DeleteTaskItem/DeleteTaskItem';
import EditTaskItem from '../../Apps/TaskBoard/components/EditTaskItem';
import EditTaskList from '../../Apps/TaskBoard/components/_TaskLists/EditTaskList';
import DeleteTaskList from '../../Apps/TaskBoard/components/_TaskLists/DeleteTaskList/DeleteTaskList';
import EditBoard from '../../Apps/TaskBoard/components/_Boards/EditBoard/EditBoard';
import NewBoard from '../../Apps/TaskBoard/components/_Boards/NewBoard';
import NewPlaylist from '../Playlists/components/_Playlists/NewPlaylist/NewPlaylist';
import ImportPlaylist from '../Playlists/components/_Playlists/ImportPlaylist/ImportPlaylist';
import TransferTaskItem from '../../Apps/TaskBoard/components/_TaskItems/TransferTaskItem';
import TransferTaskList from '../../Apps/TaskBoard/components/_TaskLists/TransferTaskList';
import ProtectBoard from '../../Apps/TaskBoard/components/_Boards/ProtectBoard';
import ImportBoard from '../../Apps/TaskBoard/components/_Boards/ImportBoard';
import DeleteBoard from '../../Apps/TaskBoard/components/_Boards/DeleteBoard';
import RestoreBoard from '../../Apps/TaskBoard/components/RestoreBoard';

function ActionDialogs() {
    return (
        <>
            <NewVault />
            <EditVault />
            <DeleteVault />

            <NewProject />
            <EditProject />
            <DeleteProject />

            <NewDeck />
            <EditDeck />
            <DeleteDeck />

            <NewSection />
            <EditSection />
            <DeleteSection />

            <NewGroup />
            <EditGroup />
            <DeleteGroup />

            <NewResource />
            <EditResource />
            <DeleteResource />

            <NewBoard />
            <ArchiveBoard />
            <EditBoard />  
            <ProtectBoard />  
            <ImportBoard />  
            <ArchiveBoard />      
            <DeleteBoard />
            <RestoreBoard />

            <DeleteTaskItem />
            <EditTaskItem />
            <TransferTaskItem />

            <EditTaskList />
            <DeleteTaskList />
            <TransferTaskList />

            <NewPlaylist />        
            <ImportPlaylist />        
        </>
    )
}

export default ActionDialogs;
