import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/link-context';
import { useAuth0 } from "@app/react-auth0-spa";
import { PropsWithChildren } from "react";
import { config } from '../config';

function AuthorizedApolloProvider({ children }: PropsWithChildren<any>) {
    const { getTokenSilently } = useAuth0();

    const httpLink = new HttpLink({ uri: `${config.apiBaseUrl}/graphql` });

    const authLink = setContext(async () => {
        const token = await getTokenSilently();
        console.log(`apollo token found ${token}`);
        return {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }
    })

    const apolloClient = new ApolloClient({
        name: 'our-awesome-client',
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
        connectToDevTools: true
    });

    return (
        <ApolloProvider client={apolloClient}>
            { children }
        </ApolloProvider>
    )
}

export default AuthorizedApolloProvider;