import React from 'react';
import PanelHeader from '@app/Areas/Optimus/Components/Shared/PanelHeader';
import { createStyles, Grid, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { SectionEditorModel } from './SectionEditorModel';

export interface SectionEditorProps {
    section: SectionEditorModel;
    handleSectionChange: (event: any) => void;
    title?: string;
    // handleReset: (event: any) => void;
    isSaving: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
        marginBottom: 20
    },
    form: {
        padding: '0.75rem'
    }
  }),
);

function SectionEditor({
    title,
    isSaving,
    section,
    handleSectionChange
}: SectionEditorProps) {
    const classes = useStyles();

    return (
        <form className={classes.form}>
            { isSaving && <Typography variant="h4">Saving Section...</Typography> }
            <PanelHeader headerVariant="h5" header={`Add new Section - ${title}`}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.input}
                            fullWidth id="section-name-input"
                            label="Name"
                            name="name"
                            value={section.name}
                            onChange={handleSectionChange}
                        />
                    </Grid>
                </Grid>
            </PanelHeader>
        </form>
    )
}

export default SectionEditor;
