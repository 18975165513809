import PanelHeader from '@app/Areas/Optimus/Components/Shared/PanelHeader';
import { TaskListEditorModel } from './TaskListEditorModel';
import { createStyles, Grid, makeStyles, MenuItem, Select, TextField, Theme, Typography } from '@material-ui/core';

export interface TaskListEditorProps {
    taskList: TaskListEditorModel;
    handleTaskListChange: (event: any) => void;
    title?: string;
    lifecycleStage?: number;
    isSaving: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
        marginBottom: 20
    },
    form: {
        padding: '0.75rem'
    }
  }),
);

function TaskListEditor({
    title,
    isSaving,
    taskList,
    handleTaskListChange
}: TaskListEditorProps) {
    const classes = useStyles();

    return (
        <form className={classes.form}>
            { isSaving && <Typography variant="h4">Saving TaskList...</Typography> }
            <PanelHeader headerVariant="h5" header={`TaskList - ${title}`}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            className={classes.input}
                            fullWidth id="tasklist-name-input"
                            label="Name"
                            name="name"
                            value={taskList.name}
                            onChange={handleTaskListChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            className={classes.input}
                            fullWidth id="tasklist-lifecyclestage-input"
                            labelId="tasklist-lifecyclestage-label"
                            name="lifeCycleStage"
                            value={taskList.lifecycleStage}
                            onChange={handleTaskListChange}
                        >
                            <MenuItem key={`tasklist-lifecyclestage-item-0`} value={0}>Unknown</MenuItem>                            
                            <MenuItem key={`tasklist-lifecyclestage-item-1`} value={1}>Backlog</MenuItem>
                            <MenuItem key={`tasklist-lifecyclestage-item-2`} value={2}>Ready</MenuItem>
                            <MenuItem key={`tasklist-lifecyclestage-item-3`} value={3}>In Progress</MenuItem>
                            <MenuItem key={`tasklist-lifecyclestage-item-4`} value={4}>Testing</MenuItem>
                            <MenuItem key={`tasklist-lifecyclestage-item-5`} value={5}>Review</MenuItem>
                            <MenuItem key={`tasklist-lifecyclestage-item-6`} value={6}>Approval</MenuItem>
                            <MenuItem key={`tasklist-lifecyclestage-item-7`} value={7}>Done</MenuItem>
                            <MenuItem key={`tasklist-lifecyclestage-item-8`} value={8}>Blocked</MenuItem>
                        </Select>
                    </Grid>
                </Grid>
            </PanelHeader>
        </form>
    )
}

export default TaskListEditor;
