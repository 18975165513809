import { useLocation, matchPath } from 'react-router-dom';

function useBoardIdParam() {
    const { pathname } = useLocation();
    const match = matchPath<{
        vaultId?: string;
        boardId?: string;
    }>(pathname, {
        path: "/optimus/vaults/:vaultId/taskboards/boards/:boardId",
        exact: false,
        strict: false
    });

    const boardId = match?.params?.boardId;

    if (boardId) {
        return +boardId;
    }

    return null;
}

export default useBoardIdParam;
