import React from 'react';
import { styled } from 'twin.macro';

const ConsoleWrapper = styled.div`
    padding: 4px;
    ${props => props.theme.breakpoints.up("md")} {
        padding: 16px;
    }
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
`;

export default ConsoleWrapper;
