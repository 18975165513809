import { ITaskListApiModel } from '@app/Areas/Optimus/ApiClient/TaskboardsClient';
import { useTaskBoardStateContext } from '@app/Areas/Optimus/Apps/TaskBoardApp';
import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { ITaskBoardContextModel } from '../../../state/TaskBoardReducer/TaskBoardReducer';

function useTaskListsAsArray(boardId: number | null): ITaskListApiModel[] {
    const vaultId = useVaultIdParam();
    const state: ITaskBoardContextModel = useTaskBoardStateContext();

    if (vaultId === null || boardId === null) {
        return [];
    }

    const taskLists =
        Object.values(state.taskLists.byId)
            .filter((value) => value.parentVaultId === vaultId && value.parentBoardId === boardId)
            .sort(({ taskList: taskList1 }: {
                taskList: ITaskListApiModel
            }, { taskList: taskList2 }: {
                taskList: ITaskListApiModel
            }) => {
                if (taskList1?.order !== undefined && taskList2?.order !== undefined) {
                    return taskList1.order - taskList2.order;
                }

                return 0;                
            })
            .map(s => s.taskList);

    return taskLists;
}

export default useTaskListsAsArray;
