import { useEffect } from "react";
import { useTaskBoardStateContext, useTaskBoardDispatchContext } from "@app/Areas/Optimus/Apps/TaskBoardApp";
import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { BOARDS_CLEAR_BOARD_UNDER_RESTORE } from "../../../state/TaskBoardReducer/TaskBoardReducer";
import useBoardIdParam from "../useBoardIdParam";

function useBoardUnderRestoration() {
    const vaultId = useVaultIdParam();
    const boardId = useBoardIdParam();

    const boardStateContext = useTaskBoardStateContext();
    const boarUnderRestoration = boardStateContext.archivedBoards.selectedToRestore;
    const boardDispatch = useTaskBoardDispatchContext();

    useEffect(() => {
        return function cleanup() {
            boardDispatch({
                type: BOARDS_CLEAR_BOARD_UNDER_RESTORE
            })
        }
    }, [vaultId])

    if (!boarUnderRestoration) {
        return null;
    }

    const board = boardStateContext?.archivedBoards?.byId[boarUnderRestoration];

    if (
        !board
        || board.parentVaultId !== vaultId
    ) {
        return null;
    }

    return board;
}

export default useBoardUnderRestoration;
