import { createStyles, Grid, InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import useBoardTaskListsFetch from "../../../hooks/_Boards/useBoardTaskListsFetch";
import useVaultBoardsAsDictionary from "../../../hooks/_Boards/useVaultBoardsAsDictionary";
import useTaskListsAsDictionary from "../../../hooks/_TaskLists/useTaskListsAsDictionary";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: '1px solid #000',
      padding: 20,
      width: '100%',
      margin: 0,
      clear: 'both'
    },
    groups: {
        paddingLeft: 20,
        paddingRight: 20
    }
  }),
);

export interface TransferEditorProps {
    selectedBoard: number | null;
    selectedTaskList: number | null;
    setSelectedTaskList: (taskListId: number) => void;
    setSelectedBoard: (taskListId: number) => void;
}

function TransferEditor({
    selectedBoard,
    selectedTaskList,
    setSelectedTaskList,
    setSelectedBoard,
}: TransferEditorProps) {    
    const classes = useStyles();
    
    const boards = useVaultBoardsAsDictionary();
    const taskLists = useTaskListsAsDictionary(selectedBoard);

    useBoardTaskListsFetch(selectedBoard);

    function selectBoard(event: React.ChangeEvent<{ value: unknown }>) {
        const board = boards[event.target.value as number];
        if (board && board.id) {
            setSelectedBoard(board.id)
        }
    }

    function selectTaskList(event: React.ChangeEvent<{ value: unknown }>) {
        const taskList = taskLists[event.target.value as number];
        if (taskList && taskList.id) {
            setSelectedTaskList(taskList.id);
        }
    }

    return (
        <form>
            <Grid container spacing={3} className={classes.root}>
                <Grid item xs={12}>
                    <InputLabel id="assigned-board-label">Task Board</InputLabel>
                    <Select
                        fullWidth
                        labelId="assigned-board-label"
                        id="board"
                        value={selectedBoard}
                        onChange={selectBoard}
                    >
                        {
                            Object.entries(boards)
                                .sort(([aKey, a], [bKey, b]) => {

                                    if (!a?.name || !b?.name) {
                                        return 0;
                                    }

                                    if(a.name < b.name) { return -1; }
                                    if(a.name > b.name) { return 1; }

                                    return 0;
                                })
                                .map(([key, value]) => (<MenuItem value={key}>{value.name}</MenuItem>))
                        }
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel id="assigned-tasklist-label">Task List</InputLabel>
                    <Select
                        fullWidth
                        labelId="assigned-tasklist-label"
                        id="tasklist"
                        value={selectedTaskList}
                        onChange={selectTaskList}
                    >
                        {
                            Object.entries(taskLists)                                
                                .map(([key, value]) => (<MenuItem value={key}>{value.name}</MenuItem>))
                        }
                    </Select>
                </Grid>
            </Grid>
        </form>
    )
}

export default TransferEditor;