import { ResourceModel } from '../domains/resources/components/resource-editor/resource-model.interface';
import { ResourceAssignment } from '../domains/resources/components/resource-editor/resource-assignment.interface';
import { SectionModel } from '../domains/sections/components/section-editor/section-model.interface';
import { GroupModel } from '../domains/groups/group-model-interface';
interface SavingState {
    isSaving: boolean
}

interface FetchingState {
    isFetching: boolean,
    payloadName: string | null
}

interface VerificationState {
    isVerifying: boolean;
    urlToVerify: string | null;
}

interface DeletingState {
    isDeleting: boolean;
    payloadName: string | null;
    payloadId: string | null;
}

interface EditingState {
    isEditing: boolean;
}

interface ResourceManagementState {
    temporaryNewResource: ResourceModel | null; // TODO: This will not have an id
    temporaryExistingResource: ResourceModel | null; //TODO: This will have an id
    temporaryAssignmentsForResource: ResourceAssignment[];
    temporaryResourceToDelete: ResourceModel | null;
    resources: ResourceModel[];
    selectedResource: ResourceModel | null;
}

interface SectionManagementState {
    isCreatingSection: boolean;
    temporaryNewSection: SectionModel | null; // TODO: This will not have an id
    temporaryExistingSection: SectionModel | null // TODO: This will have an id
    temporarySectionToDelete: SectionModel | null,
    sections: SectionModel[];
    selectedSection: SectionModel | null;
}

interface GroupManagementState {
    isEditingGroup: boolean;
    temporaryGroupUnderEdit: GroupModel | null;
    selectedGroup: GroupModel | null;
}

interface StateAction {
    type: string;
    payload: any;
}

export interface ApplicationState {
    saving: SavingState,
    fetching: FetchingState,
    section: SectionManagementState,
    group: GroupManagementState,
    resource: ResourceManagementState,
    verifying: VerificationState,
    deleting: DeletingState,
    editing: EditingState
}

function removeGroupByIndex(groups: GroupModel[], index: number) {
    for(let i = 0; i < groups.length; i++) {
        if (i === index) {
            groups.splice(i, 1);
        }
    }

    return groups;
}

const resourceManagementReducer = (state: ResourceManagementState = {
    temporaryNewResource: null,
    temporaryExistingResource: null,
    temporaryAssignmentsForResource: [],
    temporaryResourceToDelete: null,
    resources: [],
    selectedResource: null
}, action: StateAction) => {
    switch(action.type) {
        case 'SET_NEW_RESOURCE': {
            return {
                ...state,
                temporaryNewResource: action.payload.resource
            }
        }
        case 'SET_EXISTING_RESOURCE': {
            return {
                ...state,
                temporaryExistingResource: action.payload.resource
            }
        }
        case 'SET_ALL_RESOURCES': {
            return {
                ...state,
                resources: action.payload.resources
            }
        }
        case 'CLEAR_NEW_RESOURCE': {
            return {
                ...state,
                temporaryNewResource: null
            }
        }
        case 'CLEAR_EXISTING_RESOURCE': {
            return {
                ...state,
                temporaryExistingResource: null
            }
        }
        case 'SET_SELECTED_RESOURCE': {
            return {
                ...state,
                selectedResource: action.payload.resource
            }
        }
        case 'CLEAR_SELECTED_RESOURCE': {
            return {
                ...state,
                selectedResource: null
            }
        }
        case 'SET_ASSIGNMENTS_TO_USE': {
            return {
                ...state,
                temporaryAssignmentsForResource: action.payload.assignments
            }
        }
        case 'CLEAR_ASSIGNMENTS_TO_USE': {
            return {
                ...state,
                temporaryAssignmentsForResource: []
            }
        }
        case 'SET_RESOURCE_TO_DELETE': {
            return {
                ...state,
                temporaryResourceToDelete: action.payload.resource
            }
        }
        case 'CLEAR_RESOURCE_TO_DELETE': {
            return {
                ...state,
                temporaryResourceToDelete: null
            }
        }
        default:
            return state;
    }
}

const groupManagementReducer = (state: GroupManagementState = {
    isEditingGroup: false,
    temporaryGroupUnderEdit: null,
    selectedGroup: null
}, action: StateAction) => {
    switch(action.type) {
        case 'SET_SELECTED_GROUP': {
            return {
                ...state,
                selectedGroup: action.payload.group
            }
        }
        case 'EDIT_GROUP': {
            return {
                ...state,
                isEditingGroup: true,
                temporaryGroupUnderEdit: action.payload.group
            }
        }
        case 'CLEAR_EDITED_GROUP': {
            return {
                ...state,
                isEditingGroup: false,
                temporaryGroupUnderEdit: null
            }
        }
        default:
            return state;
    }
}

const sectionManagementReducer = (state: SectionManagementState = {
    isCreatingSection: false,
    temporaryNewSection: null,
    temporaryExistingSection: null,
    temporarySectionToDelete: null,
    sections: [],
    selectedSection: null
}, action: StateAction) => {
    switch(action.type) {
        case 'SET_SELECTED_SECTION': {
            return {
                ...state,
                selectedSection: action.payload.section
            }
        }
        case 'INSTANTIATE_NEW_SECTION': {
            return {
                ...state,
                isCreatingSection: true,
                temporaryNewSection: action.payload.section
            }
        }
        case 'NEW_SECTION_EDITED': {
            return {
                ...state,
                temporaryNewSection: {
                    ...state.temporaryNewSection,
                    [action.payload.propertyName]: action.payload.propertyValue
                }
            }
        }
        case 'EXISTING_SECTION_EDITED': {
            return {
                ...state,
                temporaryExistingSection: {
                    ...state.temporaryExistingSection,
                    [action.payload.propertyName]: action.payload.propertyValue
                }
            }
        }
        case 'COMPLETED_CREATING_NEW_SECTION': {
            return {
                ...state,
                isCreatingSection: false,
                temporaryNewSection: null
            }
        }
        case 'GROUP_ADDED_TO_NEW_SECTION': {
            return {
                ...state,
                temporaryNewSection: {
                    ...state.temporaryNewSection,
                    groups: [
                        ...(state.temporaryNewSection && state.temporaryNewSection.groups) || [],
                        action.payload.group
                    ]
                }
            }
        }
        case 'PRESET_GROUPS_ADD_TO_NEW_SECTION': {
            return {
                ...state,
                temporaryNewSection: {
                    ...state.temporaryNewSection,
                    groups: [
                        ...(state.temporaryNewSection && state.temporaryNewSection.groups) || [],
                        ...action.payload.groups
                    ]
                }
            }
        }
        case 'GROUP_ADDED_TO_EXISTING_SECTION': {
            return {
                ...state,
                temporaryExistingSection: {
                    ...state.temporaryExistingSection,
                    groups: [
                        ...(state.temporaryExistingSection && state.temporaryExistingSection.groups) || [],
                        action.payload.group
                    ]
                }
            }
        }
        case 'PRESET_GROUPS_ADD_TO_EXISTING_SECTION': {
            return {
                ...state,
                temporaryExistingSection: {
                    ...state.temporaryExistingSection,
                    groups: [
                        ...(state.temporaryExistingSection && state.temporaryExistingSection.groups) || [],
                        ...action.payload.groups
                    ]
                }
            }
        }
        case 'GROUP_REMOVED_FROM_NEW_SECTION': {
            return {
                ...state,
                temporaryNewSection: {
                    ...state.temporaryNewSection,
                    groups: [
                        ...removeGroupByIndex((state.temporaryNewSection && state.temporaryNewSection.groups || []), action.payload.index)
                    ]
                }
            }
        }
        case 'GROUP_REMOVED_FROM_EXISTING_SECTION': {
            return {
                ...state,
                temporaryExistingSection: {
                    ...state.temporaryExistingSection,
                    groups: [
                        ...removeGroupByIndex((state.temporaryExistingSection && state.temporaryExistingSection.groups || []), action.payload.index)
                    ]
                }
            }
        }
        case 'SET_ALL_SECTIONS': {
            return {
                ...state,
                sections: action.payload.sections
            }
        }
        case 'SET_EXISTING_SECTION': {
            return {
                ...state,
                temporaryExistingSection: action.payload.section
            }
        }
        case 'CLEAR_EXISTING_SECTION': {
            return {
                ...state,
                temporaryExistingSection: null
            }
        }
        case 'SET_SECTION_TO_DELETE': {
            return {
                ...state,
                temporarySectionToDelete: action.payload.section
            }
        }
        case 'CLEAR_SECTION_TO_DELETE': {
            return {
                ...state,
                temporarySectionToDelete: null
            }
        }
        default:
            return state
    }
}

const verifyReducer = (state: VerificationState = { isVerifying: false, urlToVerify: null }, action: StateAction) => {
    switch(action.type) {
        case 'VERIFYING_START': {
            return {
                ...state,
                isVerifying: true
            }
        }
        case 'VERIFYING_END': {
            return {
                ...state,
                isVerifying: false
            }
        }
        case 'VERIFICATION_URL_SET': {
            return {
                ...state,
                urlToVerify: action.payload.url
            }
        }
        default:
            return state;
    }
}

const savingReducer = (state: SavingState = { isSaving: false}, action: StateAction) => {
    switch(action.type) {
        case 'SAVING_START': {
            return {
                isSaving: true
            };
        }
        case 'SAVING_END': {
            return {
                isSaving: false
            }
        }
        default:
            return state
    }
}

const fetchingReducer = (state: FetchingState = { isFetching: false, payloadName: null }, action: StateAction) => {
    switch(action.type) {
        case 'FETCHING_START': {
            return {
                isFetching: true,
                payloadName: action.payload.name
            }
        }
        case 'FETCHING_END': {
            return {
                isFetching: false,
                payloadName: null
            }
        }
        default:
            return state
    }
}

const deletingReducer = (state: DeletingState = { isDeleting: false, payloadName: null, payloadId: null }, action: StateAction) => {
    switch(action.type) {
        case 'DELETING_START': {
            return {
                isDeleting: true,
                payloadName: action.payload.name,
                payloadId: action.payload.id
            }
        }
        case 'DELETING_END': {
            return {
                isDeleting: false,
                payloadName: null,
                payloadId: null
            }
        }
        default:
            return state
    }
}

const editingReducer = (state: EditingState = { isEditing: false }, action: StateAction) => {
    switch(action.type) {
        case 'EDITING_START': {
            return {
                isEditing: true
            }
        }
        case 'EDITING_END': {
            return {
                isEditing: false
            }
        }
        default:
            return state;
    }
}

export default function rootReducer(state: ApplicationState, action: StateAction): ApplicationState {
    const { saving, fetching, deleting, editing, section, group, resource, verifying } = state;

    return {
        saving: savingReducer(saving, action),
        fetching: fetchingReducer(fetching, action),
        deleting: deletingReducer(deleting, action),
        editing: editingReducer(editing, action),
        verifying: verifyReducer(verifying, action),
        resource: resourceManagementReducer(resource, action),
        section: sectionManagementReducer(section, action),
        group: groupManagementReducer(group, action)
    }
}