import { useEffect } from "react";
import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { useTaskBoardDispatchContext } from "@app/Areas/Optimus/Apps/TaskBoardApp";
import { getBoardTaskListItems, BOARDS_RESET } from "../../../state/TaskBoardReducer/TaskBoardReducer";
import useBoardIdParam from "../../_Boards/useBoardIdParam";

function useBoardTaskItemsFetch(taskListId: number) {
    const vaultId = useVaultIdParam();
    const boardId = useBoardIdParam();
    const boardDispatch = useTaskBoardDispatchContext();

    useEffect(() => {
        let isCancelled = false;
        async function fetchTaskItems() {
            if (vaultId && boardId && taskListId) {
                boardDispatch(getBoardTaskListItems(vaultId, boardId, taskListId, isCancelled));
            }
        }
        fetchTaskItems();

        return function cleanup() {
            isCancelled = true;
            boardDispatch({
                type: BOARDS_RESET,
                vaultId,
                boardId
            })
        }
    }, [vaultId, boardId, taskListId])
}

export default useBoardTaskItemsFetch;