import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import { Box, Breadcrumbs, Card, CardActions, CardContent, createStyles, Divider, Grid, IconButton, makeStyles, Paper, Tab, Tabs, Theme, Typography } from '@material-ui/core';
import { Lock, Launch, Delete, Edit } from '@material-ui/icons';
import { useOptimusStateContext } from '@app/Areas/Optimus/Contexts/OptimusContext';
import FeatureCard from '@optimus/Components/Shared/FeatureCard';
import PaperContent from '@optimus/Components/Shared/PaperContent';
import ConsoleWrapper from '@optimus/Components/Shared/ConsoleWrapper';
import BreadcrumbPaper from '@optimus/Components/Shared/BreadcrumbPaper';
import useVaultsAsArray from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useVaultsAsArray';
import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import TabPanel from '../../../Components/Shared/TabPanel/TabPanel';
import { OPTIMUS_OPEN_DIALOG } from '@app/Areas/Optimus/State/App/DialogContext/DialogReducer/DialogReducer';
import { useDialogDispatchContext } from '@app/Areas/Optimus/State/App/DialogContext/DialogContextProvider';
import { DELETE_VAULT_DIALOG_ID, NEW_VAULT_DIALOG_ID } from '../../ActionDialogs';
import { EDIT_VAULT_DIALOG_ID } from '../../ActionDialogs/index';
import { VAULTS_SET_VAULT_UNDER_EDIT } from '@app/Areas/Optimus/State/DataStores/VaultStore/VaultReducer/VaultReducer';
import { useVaultDispatchContext } from '@app/Areas/Optimus/State/DataStores/VaultStore/VaultContextProvider';
import { VAULTS_SET_VAULT_UNDER_DELETE } from '../../../State/DataStores/VaultStore/VaultReducer/VaultReducer';
import { gql, useQuery } from '@apollo/client';

const useBackgroundStyles = makeStyles((theme: Theme) =>
    {
        return createStyles({
            root: {
              background: 'transparent',
              backdropFilter: 'blur(10px)',
              boxShadow: `0 0px 10px ${theme.palette.primary.main}`,
              border: `1px solid ${theme.palette.primary.main}`,
            },
            intro: {
                background: 'rgba(0,0,0,0.2)',
            },
            divider: {
              backgroundColor: `${theme.palette.primary.main}`
            }
          })
    }
)

const useCardStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
          width: '100%',
          margin: '0',
          minHeight: '300px',
          height: '100%',
          display: 'grid',
          '&:hover': {
            background: 'transparent',
            boxShadow: `0 0px 10px ${theme.palette.secondary.main}`,
            border: `1px solid ${theme.palette.secondary.main}`,
          },
        },
        editButton: {
            marginLeft: 'auto'
        }
      })
});



function a11yProps(id: any, index: any) {
    return {
      id: `vault-tab-${id}-${index}`,
      'aria-controls': `simple-tabpanel-${id}-${index}`,
    };
  }

const VaultTile = ({ id, isSelected, title, children, icon }: any) => {
    const vaultDispatch = useVaultDispatchContext();
    const dialogDispatch = useDialogDispatchContext();
    const backgroundClasses = useBackgroundStyles();
    const classes = useCardStyles();

    const openEditVaultDialog = (vaultId: string) => {
        dialogDispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: EDIT_VAULT_DIALOG_ID
        })
        vaultDispatch({
            type: VAULTS_SET_VAULT_UNDER_EDIT,
            vaultId
        })
    }

    const openDeleteVaultDialog = (vaultId: string) => {
        dialogDispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: DELETE_VAULT_DIALOG_ID
        })
        vaultDispatch({
            type: VAULTS_SET_VAULT_UNDER_DELETE,
            vaultId
        })
    }

    return (
        <Card className={`${backgroundClasses.root} ${classes.root}`}>
            <CardContent>
                <Grid container spacing={1} alignItems="center">
                    <Grid item>{icon}</Grid>
                    <Grid item>
                        <Typography variant="h6" color="secondary">
                            <Link to={`/optimus/vaults/${id}`}>{title} {isSelected && "(active)"}</Link>
                        </Typography>
                    </Grid>
                </Grid>
          </CardContent>
          <Divider className={backgroundClasses.divider} />
          <Tabs value={0} onChange={() => {}} aria-label="simple tabs example">
                <Tab label="About" {...a11yProps(id, 0)} />
                <Tab label="Stats" {...a11yProps(id, 1)} />
          </Tabs>
          <TabPanel value={0} index={0}>
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
          </TabPanel>
          <TabPanel value={0} index={1}>
                <Box p={3}>
                    <Typography>
                        Metadata goes here
                    </Typography>
                </Box>
          </TabPanel>
          <CardActions disableSpacing>
                <IconButton aria-label="open vault">
                    <Launch />
                </IconButton>
                <IconButton aria-label="edit vault" className={classes.editButton} onClick={() => openEditVaultDialog(id)}>
                    <Edit />
                </IconButton>
                <IconButton aria-label="edit vault">
                    <Delete onClick={() => openDeleteVaultDialog(id)} />
                </IconButton>
                {/* <IconButton
                className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                >
                <ExpandMoreIcon />
                </IconButton> */}
          </CardActions>
        </Card>
    )
}

const VaultContainer = styled.div`
    ${tw`py-14 px-8`}
`;

const FETCH_VAULTS = gql`{
    vaults {
        id
        title
        description
    }
}`;

function VaultsList() {
    const classes = useBackgroundStyles()
    const vaults = useVaultsAsArray();
    const selectedVaultId = useVaultIdParam();
    const dialogDispatch = useDialogDispatchContext();

    const openNewVaultDialog = () => {
        dialogDispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: NEW_VAULT_DIALOG_ID
        })
    }


    const { loading, data, error } = useQuery(FETCH_VAULTS);

    return (
        <>
            <BreadcrumbPaper>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link to="vaults">
                    {/* <Link color="inherit" href="/" onClick={handleClick} className={classes.link}> */}
                        {/* <HomeIcon className={classes.icon} /> */}
                        Vaults
                    </Link>
                    <Link to="vaults">
                    {/* <Link color="inherit" href="/" onClick={handleClick} className={classes.link}> */}
                        {/* <HomeIcon className={classes.icon} /> */}
                        Vaults
                    </Link>
                    <Link to="vaults">
                    {/* <Link color="inherit" href="/" onClick={handleClick} className={classes.link}> */}
                        {/* <HomeIcon className={classes.icon} /> */}
                        Vaults
                    </Link>
                    {/* <Link
                        color="inherit"
                        href="/getting-started/installation/"
                        onClick={handleClick}
                        className={classes.link}
                    >
                        <WhatshotIcon className={classes.icon} />
                        Core
                    </Link>
                    <Typography color="textPrimary" className={classes.link}>
                        <GrainIcon className={classes.icon} />
                        Breadcrumb
                    </Typography> */}
                </Breadcrumbs>
            </BreadcrumbPaper>
            <ConsoleWrapper>
                    <FeatureCard title="Vaults" Icon={Lock} actions={[{
                        label: 'New Vault',
                        action: openNewVaultDialog
                    }]}>
                    <PaperContent>
                        <Typography variant="body1">
                            Vaults contain all your data across all apps. Select a Vault to get started. Or create, update or archive a vault.
                        </Typography>
                    </PaperContent>
                </FeatureCard>
                <VaultContainer>
                    <Grid item container spacing={10}>
                        {
                            vaults.map((vault) => (
                                <Grid key={vault.id} item xs={12} sm={6} md={4} lg={3} xl={3} >
                                    <VaultTile
                                        title={vault.title}
                                        icon={<Lock />}
                                        id={vault.id}
                                        isSelected={vault.id === selectedVaultId}
                                    >
                                        {vault.description}
                                    </VaultTile>
                                    {/* <Paper className={classes.paper} /> */}
                                </Grid>
                            ))
                        }
                    {/* {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((value) => (
                        <Grid key={value} item>
                        <Paper className={classes.paper} />
                        </Grid>
                    ))} */}
                        { loading && <p>LOADING VAULTS GRAPH</p> }
                        { error && <p>GRAPH OOPSIE!</p> }
                        { data && JSON.stringify(data) }
                    </Grid>
                </VaultContainer>
                {/* <div>
                {
                    vaults && Object.entries(vaults.vaults).map(([key, value]) => (
                        <li>
                            <Link to={`/optimus/vaults/${value.id}`}>{value.title} {value.id === vaults.selectedVault && "(active)"}</Link>
                        </li>
                    ))
                }
                </div> */}
            </ConsoleWrapper>
        </>
    )
}

export default VaultsList;
