import { IBoardApiModel, ITaskListApiModel, ITaskItemApiModel, ITaskReorderRequest, ITaskMoveRequest } from '@app/Areas/Optimus/ApiClient/TaskboardsClient';
import { Dispatch } from 'react';
import createBoardService from '../../services/BoardsService';
import createTaskItemService from '../../services/TaskItemService';
import createTaskListService from '../../services/TaskListsService';

export const BOARDS_RESET = 'BOARDS_RESET';
export const ARCHIVED_BOARDS_RESET = 'ARCHIVED_BOARDS_RESET';
export const BOARDS_SET_BOARDS = 'BOARDS_SET_BOARDS';
export const BOARDS_SET_ARCHIVED_BOARDS = 'BOARDS_SET_ARCHIVED_BOARDS';

export const BOARDS_SET_BOARD = 'BOARDS_SET_BOARD';
export const BOARDS_SET_BOARD_UNDER_ARCHIVE = 'BOARDS_SET_BOARD_UNDER_ARCHIVE';
export const BOARDS_CLEAR_BOARD_UNDER_ARCHIVE = 'BOARDS_CLEAR_BOARD_UNDER_ARCHIVE';
export const BOARDS_REMOVE_BOARD_UNDER_ARCHIVE = 'BOARDS_REMOVE_BOARD_UNDER_ARCHIVE';
export const BOARDS_SET_BOARD_UNDER_EDIT = 'BOARDS_SET_BOARD_UNDER_EDIT';
export const BOARDS_CLEAR_BOARD_UNDER_EDIT = 'BOARDS_CLEAR_BOARD_UNDER_EDIT';
export const BOARDS_SET_BOARD_TO_PROTECT = 'BOARDS_SET_BOARD_TO_PROTECT';
export const BOARDS_CLEAR_BOARD_TO_PROTECT = 'BOARDS_CLEAR_BOARD_TO_PROTECT';
export const BOARDS_SET_BOARD_UNDER_RESTORE = 'BOARDS_SET_BOARD_UNDER_RESTORE';
export const BOARDS_CLEAR_BOARD_UNDER_RESTORE = 'BOARDS_CLEAR_BOARD_UNDER_RESTORE';
export const BOARDS_REMOVE_BOARD_UNDER_RESTORE = 'BOARDS_REMOVE_BOARD_UNDER_RESTORE';
export const BOARDS_SET_BOARD_UNDER_DELETION = 'BOARDS_SET_BOARD_UNDER_DELETION';
export const BOARDS_CLEAR_BOARD_UNDER_DELETION = 'BOARDS_CLEAR_BOARD_UNDER_DELETION';
export const BOARDS_REMOVE_BOARD_UNDER_DELETION = 'BOARDS_REMOVE_BOARD_UNDER_DELETION';

export const BOARDS_SET_TASKLISTS = 'BOARDS_SET_TASKLISTS';
export const BOARDS_SET_TASKLIST_UNDER_DELETE = 'BOARDS_SET_TASKLIST_UNDER_DELETE';
export const BOARDS_CLEAR_TASKLIST_UNDER_DELETE = 'BOARDS_CLEAR_TASKLIST_UNDER_DELETE';
export const BOARDS_REMOVE_TASKLIST_UNDER_DELETE = 'BOARDS_REMOVE_TASKLIST_UNDER_DELETE';
export const BOARDS_SET_TASKLIST_UNDER_EDIT = 'BOARDS_SET_TASKLIST_UNDER_EDIT';
export const BOARDS_CLEAR_TASKLIST_UNDER_EDIT = 'BOARDS_CLEAR_TASKLIST_UNDER_EDIT';
export const BOARDS_SET_TASKLIST_UNDER_TRANSFER = 'BOARDS_SET_TASKLIST_UNDER_TRANSFER';
export const BOARDS_CLEAR_TASKLIST_UNDER_TRANSFER = 'BOARDS_CLEAR_TASKLIST_UNDER_TRANSFER';

export const BOARDS_SET_TASKITEMS = 'BOARDS_SET_TASKITEMS';
export const BOARDS_SET_TASKITEM_UNDER_DELETE = 'BOARDS_SET_TASKITEM_UNDER_DELETE';

export const BOARDS_CLEAR_TASKITEM_UNDER_DELETE = 'BOARDS_CLEAR_TASKITEM_UNDER_DELETE';
export const BOARDS_REMOVE_TASKITEM_UNDER_DELETE = 'BOARDS_REMOVE_TASKITEM_UNDER_DELETE';

export const BOARDS_SET_TASKITEM_UNDER_EDIT = 'BOARDS_SET_TASKITEM_UNDER_EDIT';
export const BOARDS_CLEAR_TASKITEM_UNDER_EDIT = 'BOARDS_CLEAR_TASKITEM_UNDER_EDIT';

export const BOARDS_SET_TASKITEM_UNDER_VIEW = 'BOARDS_SET_TASKITEM_UNDER_VIEW';
export const BOARDS_CLEAR_TASKITEM_UNDER_VIEW = 'BOARDS_CLEAR_TASKITEM_UNDER_VIEW';

export const BOARDS_SET_TASKITEM_UNDER_TRANSFER = 'BOARDS_SET_TASKITEM_UNDER_TRANSFER';
export const BOARDS_CLEAR_TASKITEM_UNDER_TRANSFER = 'BOARDS_CLEAR_TASKITEM_UNDER_TRANSFER';

export const BOARDS_SET_BOARDMODE = 'BOARDS_SET_BOARDMODE';

export enum TaskBoardMode {
    BOARDMODE_ACTIVE,
    BOARDMODE_ARCHIVE
}

export interface ITaskBoardContextModel {
    boards: {
        byId: {
            [boardId: string]: {
                board: IBoardApiModel;
                parentVaultId: number;
                taskListIds: number[];
            }
        },
        allIds: number[];
        selected: string | null;
        selectedToEdit: string | null;
        selectedToDelete: string | null;
        selectedToProtect: string | null;
    },
    archivedBoards: {
        byId: {
            [boardId: string]: {
                board: IBoardApiModel;
                parentVaultId: number;
                taskListIds: number[];
            }
        },
        allIds: number[];
        selectedToDelete: string | null;
        selectedToRestore: string | null;
    },
    taskLists: {
        byId: {
            [taskListId: string]: {
                taskList: ITaskListApiModel;
                parentVaultId: number;
                parentBoardId: number;
                taskItemIds: string[];
            }
        },
        allIds: string[];
        selected: string | null;
        selectedToEdit: string | null;
        selectedToDelete: string | null;
        selectedToTransfer: string | null;
    },
    taskItems: {
        byId: {
            [taskItemId: string]: {
                taskItem: ITaskItemApiModel;
                parentVaultId: number;
                parentBoardId: number;
                parentTaskListId: number;
            }
        },
        allIds: string[];
        selected: string | null;
        selectedToEdit: string | null;
        selectedToView: string | null;
        selectedToDelete: string | null;
        selectedToTransfer: string | null;
    },
    boardMode: TaskBoardMode;
}

function InitiativeReducer(state: ITaskBoardContextModel, action: any): ITaskBoardContextModel {
    switch (action.type) {
        case BOARDS_SET_BOARDMODE: {
            return {
                ...state,
                boardMode: action.mode
            }
        }
        case BOARDS_SET_BOARDS:
            return {
                ...state,
                boards: { // TODO: Fix wiping of boards when assigning boards and tasklists
                    ...state.boards,
                    byId: {
                        ...action.boards.reduce((newBoards: {
                            [sectionId: string]: {
                                board: IBoardApiModel;
                                parentVaultId: string;
                                taskListIds: string[];
                            }
                        }, s: IBoardApiModel) => {
                            if (s.id) {
                                return {
                                    ...newBoards,
                                    [s.id]: {
                                        board: s,
                                        parentVaultId: action.vaultId,
                                        taskListIds: []
                                    }
                                }
                            }
                            return newBoards;
                        }, {})
                    },
                    allIds: [
                        // TODO: Merge existing Ids
                        ...action.boards.map((s: IBoardApiModel) => s.id)
                    ],
                }
            }
        case BOARDS_SET_ARCHIVED_BOARDS:
            return {
                ...state,
                archivedBoards: { // TODO: Fix wiping of boards when assigning boards and tasklists
                    ...state.archivedBoards,
                    byId: {
                        ...action.boards.reduce((newBoards: {
                            [sectionId: string]: {
                                board: IBoardApiModel;
                                parentVaultId: string;
                                taskListIds: string[];
                            }
                        }, s: IBoardApiModel) => {
                            if (s.id) {
                                return {
                                    ...newBoards,
                                    [s.id]: {
                                        board: s,
                                        parentVaultId: action.vaultId,
                                        taskListIds: []
                                    }
                                }
                            }
                            return newBoards;
                        }, {})
                    },
                    allIds: [
                        // TODO: Merge existing Ids
                        ...action.boards.map((s: IBoardApiModel) => s.id)
                    ],
                }
            }
        case BOARDS_SET_BOARD:
            return {
                ...state,
                boards: {
                    ...state.boards,
                    byId: {
                        ...state.boards.byId,
                        [action.boardId]: {
                            board: action.board,
                            parentVaultId: action.vaultId,
                            taskListIds: []
                        }
                    },
                    allIds: [
                        ...state.boards.allIds,
                        action.board.id
                    ]
                }
            }
        case BOARDS_SET_BOARD_UNDER_ARCHIVE: {
            return {
                ...state,
                boards: {
                    ...state.boards,
                    selectedToDelete: action.boardId
                }
            }
        }
        case BOARDS_CLEAR_BOARD_UNDER_ARCHIVE: {
            return {
                ...state,
                boards: {
                    ...state.boards,
                    selectedToDelete: null,
                }
            }
        }
        case BOARDS_SET_BOARD_UNDER_DELETION: {
            return {
                ...state,
                archivedBoards: {
                    ...state.archivedBoards,
                    selectedToDelete: action.id
                }
            }
        }
        case BOARDS_CLEAR_BOARD_UNDER_DELETION: {
            return {
                ...state,
                archivedBoards: {
                    ...state.archivedBoards,
                    selectedToDelete: null,
                }
            }
        }
        case BOARDS_SET_BOARD_UNDER_RESTORE: {
            return {
                ...state,
                archivedBoards: {
                    ...state.archivedBoards,
                    selectedToRestore: action.id
                }
            }
        }
        case BOARDS_CLEAR_BOARD_UNDER_RESTORE: {
            return {
                ...state,
                archivedBoards: {
                    ...state.archivedBoards,
                    selectedToRestore: null,
                }
            }
        }
        case BOARDS_SET_BOARD_TO_PROTECT: {
            return {
                ...state,
                boards: {
                    ...state.boards,
                    selectedToProtect: action.boardId
                }
            }
        }
        case BOARDS_CLEAR_BOARD_TO_PROTECT: {
            return {
                ...state,
                boards: {
                    ...state.boards,
                    selectedToProtect: null,
                }
            }
        }
        case BOARDS_REMOVE_BOARD_UNDER_ARCHIVE: {
            return {
                ...state,
                boards: {
                    ...state.boards,
                    byId: Object.values(state.boards.byId).reduce((t, currentValue) => {
                        if (currentValue.board.id && currentValue.board.id !== action.boardId) {
                            return {
                                ...t,
                                [currentValue.board.id]: currentValue
                            }
                        }
                        return t;
                    } , {}),
                    allIds: state.boards.allIds.filter(i => i !== action.boardId)
                }
            }
        }
        case BOARDS_REMOVE_BOARD_UNDER_DELETION: {
            return {
                ...state,
                archivedBoards: {
                    ...state.archivedBoards,
                    byId: Object.values(state.archivedBoards.byId).reduce((t, currentValue) => {
                        if (currentValue.board.id && currentValue.board.id !== action.boardId) {
                            return {
                                ...t,
                                [currentValue.board.id]: currentValue
                            }
                        }
                        return t;
                    } , {}),
                    allIds: state.archivedBoards.allIds.filter(i => i !== action.boardId)
                }
            }
        }
        case BOARDS_REMOVE_BOARD_UNDER_RESTORE: {
            return {
                ...state,
                archivedBoards: {
                    ...state.archivedBoards,
                    byId: Object.values(state.archivedBoards.byId).reduce((t, currentValue) => {
                        if (currentValue.board.id && currentValue.board.id !== action.boardId) {
                            return {
                                ...t,
                                [currentValue.board.id]: currentValue
                            }
                        }
                        return t;
                    } , {}),
                    allIds: state.archivedBoards.allIds.filter(i => i !== action.boardId)
                }
            }
        }
        case BOARDS_SET_TASKLISTS:
            return {
                ...state,
                taskLists: {
                    ...state.taskLists,
                    byId: {
                        ...action.taskLists.reduce((newTaskLists: {
                            [taskListId: string]: {
                                taskList: ITaskListApiModel;
                                parentVaultId: number;
                                parentBoardId: number;
                                taskItemIds: string[];
                            }
                        }, t: ITaskListApiModel) => {
                            if (t.id) {
                                return {
                                    ...newTaskLists,
                                    [t.id]: {
                                        taskList: t,
                                        parentVaultId: action.vaultId,
                                        parentBoardId: action.boardId,
                                        taskItemIds: []
                                    }
                                }
                            }
                        }, {})
                    },
                    allIds: [
                        ...action.taskLists.map((t :ITaskListApiModel) => t.id)
                    ]
                }
            }
        case BOARDS_SET_TASKITEMS:
            return {
                ...state,
                taskItems: {
                    ...state.taskItems,
                    byId: {
                        ...state.taskItems.byId,
                        ...action.taskItems.reduce((newTaskItems: {
                            [taskListId: string]: {
                                taskItem: ITaskItemApiModel;
                                parentVaultId: number;
                                parentBoardId: number;
                                parentTaskListId: number;
                            }
                        }, t: ITaskListApiModel) => {
                            if (t.id) {
                                return {
                                    ...newTaskItems,
                                    [t.id]: {
                                        taskItem: t,
                                        parentVaultId: action.vaultId,
                                        parentBoardId: action.boardId,
                                        parentTaskListId: action.taskListId
                                    }
                                }
                            }
                        }, {})
                    },
                    allIds: [
                        ...state.taskItems.allIds,
                        ...action.taskItems.map((t: ITaskItemApiModel) => t.id)
                    ]
                }
            }
        case BOARDS_SET_TASKITEM_UNDER_DELETE: {
            return {
                ...state,
                taskItems: {
                    ...state.taskItems,
                    selectedToDelete: action.taskItemId
                }
            }
        }
        case BOARDS_SET_TASKITEM_UNDER_EDIT: {
            return {
                ...state,
                taskItems: {
                    ...state.taskItems,
                    selectedToEdit: action.taskItemId
                }
            }
        }
        case BOARDS_SET_TASKITEM_UNDER_VIEW: {
            return {
                ...state,
                taskItems: {
                    ...state.taskItems,
                    selectedToView: action.taskItemId
                }
            }
        }
        case BOARDS_SET_TASKITEM_UNDER_TRANSFER: {
            return {
                ...state,
                taskItems: {
                    ...state.taskItems,
                    selectedToTransfer: action.taskItemId
                }
            }
        }
        case BOARDS_CLEAR_TASKITEM_UNDER_TRANSFER: {
            return {
                ...state,
                taskItems: {
                    ...state.taskItems,
                    selectedToTransfer: null,
                }
            }
        }
        case BOARDS_SET_TASKLIST_UNDER_EDIT: {
            return {
                ...state,
                taskLists: {
                    ...state.taskLists,
                    selectedToEdit: action.taskListId
                }
            }
        }
        case BOARDS_SET_TASKLIST_UNDER_TRANSFER: {
            return {
                ...state,
                taskLists: {
                    ...state.taskLists,
                    selectedToTransfer: action.taskListId
                }
            }
        }
        case BOARDS_CLEAR_TASKLIST_UNDER_TRANSFER: {
            return {
                ...state,
                taskLists: {
                    ...state.taskLists,
                    selectedToTransfer: null,
                }
            }
        }
        case BOARDS_SET_BOARD_UNDER_EDIT: {
            return {
                ...state,
                boards: {
                    ...state.boards,
                    selectedToEdit: action.boardId
                }
            }
        }
        case BOARDS_CLEAR_TASKITEM_UNDER_DELETE: {
            return {
                ...state,
                taskItems: {
                    ...state.taskItems,
                    selectedToDelete: null,
                }
            }
        }
        case BOARDS_CLEAR_TASKITEM_UNDER_EDIT: {
            return {
                ...state,
                taskItems: {
                    ...state.taskItems,
                    selectedToEdit: null,
                }
            }
        }
        case BOARDS_CLEAR_TASKITEM_UNDER_VIEW: {
            return {
                ...state,
                taskItems: {
                    ...state.taskItems,
                    selectedToView: null,
                }
            }
        }
        case BOARDS_CLEAR_TASKLIST_UNDER_EDIT: {
            return {
                ...state,
                taskLists: {
                    ...state.taskLists,
                    selectedToEdit: null,
                }
            }
        }
        case BOARDS_CLEAR_BOARD_UNDER_EDIT: {
            return {
                ...state,
                boards: {
                    ...state.boards,
                    selectedToEdit: null,
                }
            }
        }
        case BOARDS_REMOVE_TASKITEM_UNDER_DELETE: {
            return {
                ...state,
                taskItems: {
                    ...state.taskItems,
                    byId: Object.values(state.taskItems.byId).reduce((t, currentValue) => {
                        if (currentValue.taskItem.id && currentValue.taskItem.id !== action.taskItemId) {
                            return {
                                ...t,
                                [currentValue.taskItem.id]: currentValue
                            }
                        }
                        return t;
                    } , {}),
                    allIds: state.taskItems.allIds.filter(i => i !== action.taskItemId)
                }
            }
        }
        case BOARDS_SET_TASKLIST_UNDER_DELETE: {
            return {
                ...state,
                taskLists: {
                    ...state.taskLists,
                    selectedToDelete: action.taskListId
                }
            }
        }
        case BOARDS_CLEAR_TASKLIST_UNDER_DELETE: {
            return {
                ...state,
                taskLists: {
                    ...state.taskLists,
                    selectedToDelete: null,
                }
            }
        }
        case BOARDS_REMOVE_TASKLIST_UNDER_DELETE: {
            return {
                ...state,
                taskLists: {
                    ...state.taskLists,
                    byId: Object.values(state.taskLists.byId).reduce((t, currentValue) => {
                        if (currentValue.taskList.id && currentValue.taskList.id !== action.taskListId) {
                            return {
                                ...t,
                                [currentValue.taskList.id]: currentValue
                            }
                        }
                        return t;
                    } , {}),
                    allIds: state.taskLists.allIds.filter(i => i !== action.taskListId)
                }
            }
        }
        case ARCHIVED_BOARDS_RESET: {
            return state;
        }
        case BOARDS_RESET: {
            return state;
        }
        default: {
            console.log(action);
            throw new Error('Unable to reduce dispatched action');
        }
    }
}

export default InitiativeReducer;

/**
 * Action creator for fetching the vault sections.
 */
 export function getVaultBoards(vaultId: number) {
    return async (dispatch: Dispatch<any>) => {
        const boardService = createBoardService();
        return boardService.getBoards(vaultId).then((result) => {
            dispatch({
                type: BOARDS_SET_BOARDS,
                vaultId: vaultId,
                boards: result,
            })
        });
    }
}

/**
 * Action creator for fetching the vault sections.
 */
 export function getArchivedVaultBoards(vaultId: number) {
    return async (dispatch: Dispatch<any>) => {
        const boardService = createBoardService();
        return boardService.getArchivedBoards(vaultId).then((result) => {
            dispatch({
                type: BOARDS_SET_ARCHIVED_BOARDS,
                vaultId: vaultId,
                boards: result,
            })
        });
    }
}

/**
 * Action creator for fetching the vault board.
 */
export function getVaultBoard(
    vaultId: number,
    boardId: number,
    isCancelled: boolean
) {
    return async (dispatch: Dispatch<any>) => {
        const boardService = createBoardService();
        return boardService.getBoard(vaultId, boardId).then((result) => {
            if (!isCancelled) {
                dispatch({
                    type: BOARDS_SET_BOARD,
                    vaultId: vaultId,
                    boardId: boardId,
                    board: result
                })
            }
        });
    }
}

/**
 * Action creator for fetching the vault board tasklist.
 */
export function getVaultBoardTaskLists(
    vaultId: number,
    boardId: number,
    isCancelled: boolean
) {
    return async (dispatch: Dispatch<any>) => {
        const taskListService = createTaskListService();
        return taskListService.getTaskLists(vaultId, boardId).then((result) => {
            if (!isCancelled) {
                dispatch({
                    type: BOARDS_SET_TASKLISTS,
                    vaultId: vaultId,
                    boardId: boardId,
                    taskLists: result
                })
            }
        })
    }
}

/**
 * Action creator for fetching board tasklist items.
 */
export function getBoardTaskListItems(
    vaultId: number,
    boardId: number,
    taskListId: number,
    isCancelled: boolean
) {
    return async (dispatch: Dispatch<any>) => {
        const taskItemService = createTaskItemService();
        return taskItemService.getTaskItems(vaultId, boardId, taskListId).then((result) => {
            if (!isCancelled) {
                dispatch({
                    type: BOARDS_SET_TASKITEMS,
                    vaultId: vaultId,
                    boardId: boardId,
                    taskListId: taskListId,
                    taskItems: result
                })
            }
        })
    }
}

/**
 * Action creator for reorder tasklists on a board
 */
export function reorderTaskList(
    vaultId: number,
    boardId: number,
    taskListId: number,
    newPosition: number,
    isCancelled: boolean   
) {
    return async (dispatch: Dispatch<any>) => {
        const taskListService = createTaskListService();
        const request: ITaskReorderRequest = {
            position: newPosition   
        };

        const reorderResult = await taskListService.reorderTaskList(vaultId, boardId, taskListId, request);

        if (reorderResult) {
            return taskListService.getTaskLists(vaultId, boardId).then((result) => {
                if (!isCancelled) {
                    dispatch({
                        type: BOARDS_SET_TASKLISTS,
                        vaultId: vaultId,
                        boardId: boardId,
                        taskLists: result
                    })
                }
            })
        }
    }
}

/**
 * Action creator to move task items on a board
 */
 export function moveTaskItem(
    vaultId: number,
    boardId: number,
    sourceTaskListId: number,
    targetTaskListId: number,
    taskItemId: number,
    newPosition: number,
    isCancelled: boolean   
) {
    return async (dispatch: Dispatch<any>) => {
        const taskItemService = createTaskItemService();
        const request: ITaskMoveRequest = {
            destinationTaskListId: targetTaskListId,
            position: newPosition   
        };

        const reorderResult = await taskItemService.moveTaskItem(vaultId, boardId, sourceTaskListId, taskItemId, request);

        if (reorderResult) {

            const sourcePromise = taskItemService.getTaskItems(vaultId, boardId, sourceTaskListId);
            const targetPromise = taskItemService.getTaskItems(vaultId, boardId, targetTaskListId);

            const [source, target] = await Promise.all([sourcePromise, targetPromise]);

            if (!isCancelled) {
                dispatch({
                    type: BOARDS_SET_TASKITEMS,
                    vaultId: vaultId,
                    boardId: boardId,
                    taskListId: sourceTaskListId,
                    taskItems: source
                })

                dispatch({
                    type: BOARDS_SET_TASKITEMS,
                    vaultId: vaultId,
                    boardId: boardId,
                    taskListId: targetTaskListId,
                    taskItems: target
                })
            }
        }
    }
}