import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { VaultEditorModel } from './VaultEditorModel';

interface VaultEditorProps {
    vault: VaultEditorModel;
    handleVaultChange: (event: any) => void;
}

function VaultEditor({
    vault,
    handleVaultChange
}: VaultEditorProps) {
    const { title, description } = vault;
    return (
        <>
            <form>
                <TextField fullWidth id="vault-title-input" label="Title" name="vaultName" value={title} onChange={handleVaultChange} />
                <TextField fullWidth id="vault-description-input" label="Description" name="description" value={description} onChange={handleVaultChange} />
            </form>
        </>
    )
}

export default VaultEditor