import { DELETE_BOARD_DIALOG_ID, RESTORE_BOARD_DIALOG_ID } from "@app/Areas/Optimus/Ecosystems/ActionDialogs";
import { useDialogDispatchContext } from "@app/Areas/Optimus/State/App/DialogContext/DialogContextProvider";
import { OPTIMUS_OPEN_DIALOG } from "@app/Areas/Optimus/State/App/DialogContext/DialogReducer/DialogReducer";
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import useVaultArchivedBoardsAsArray from "../../hooks/_Boards/useVaultArchivedBoardsAsArray";
import { useTaskBoardDispatchContext } from "../../state/TaskBoardContextProvider";
import { BOARDS_SET_BOARD_UNDER_DELETION, BOARDS_SET_BOARD_UNDER_RESTORE } from "../../state/TaskBoardReducer/TaskBoardReducer";

export function ArchiveView() {
    const boards = useVaultArchivedBoardsAsArray();
    const dialogDispatch = useDialogDispatchContext();
    const boardDispatch = useTaskBoardDispatchContext();

    const selectBoardForRestoration = (id?: number) => {
        if (id) {
            boardDispatch({
                type: BOARDS_SET_BOARD_UNDER_RESTORE,
                id
            });
            dialogDispatch({
                type: OPTIMUS_OPEN_DIALOG,
                openedDialog: RESTORE_BOARD_DIALOG_ID
            })
        }        
    };

    const selectBoardForDeletion = (id?: number) => {
        if (id) {
            boardDispatch({
                type: BOARDS_SET_BOARD_UNDER_DELETION,
                id
            });
            dialogDispatch({
                type: OPTIMUS_OPEN_DIALOG,
                openedDialog: DELETE_BOARD_DIALOG_ID
            })
        }
    };

    return <>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Id</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {boards.map((board) => (
                        <TableRow
                            key={`${board.name}-${board.id}`}
                        >
                            <TableCell component="th" scope="row">
                                {board.id}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {board.name}
                            </TableCell>
                            <TableCell align="right">
                                <Button onClick={() => selectBoardForRestoration(board.id)}>Restore</Button>
                            </TableCell>
                            <TableCell align="right">
                                <Button onClick={() => selectBoardForDeletion(board.id)}>Delete</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    </>
}

export default ArchiveView;
