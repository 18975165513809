import React, { PropsWithChildren } from 'react';
import { DialogContextProvider } from '../DialogContext/DialogContextProvider';

function AppContext({ children }: PropsWithChildren<{}>) {
    return <>
        <DialogContextProvider>
            { children }
        </DialogContextProvider>
    </>
}

export default AppContext;
