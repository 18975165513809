import Breadcrumb from '@optimus/Components/Shared/Breadcrumb/Breadcrumb';
import ConsoleWrapper from '@optimus/Components/Shared/ConsoleWrapper';
import { Grid } from '@material-ui/core';
import PanelHeader from '@app/Areas/Optimus/Components/Shared/PanelHeader';
import Panel from '@optimus/Components/Shared/Panel';
import { EDIT_DECK_DIALOG_ID, NEW_DECK_DIALOG_ID } from '../../ActionDialogs';
import styled from 'styled-components';
import DeckViewCard from './DeckViewCard/DeckViewCard';
import { DeckIcon, DeleteIcon, EditIcon } from '@app/Areas/Optimus/Components/Shared/AppsIcons';
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom';
import DecksList from '../DecksList';
import { DELETE_DECK_DIALOG_ID } from '../../ActionDialogs/index';
import useDecksFetch from '../../../State/DataStores/DeckStore/hooks/useDecksFetch/useDecksFetch';
import useVaultDecksAsArray from '../../../State/DataStores/DeckStore/hooks/useVaultDecksAsArray/useVaultDecksAsArray';
import { useDialogDispatchContext } from '@app/Areas/Optimus/State/App/DialogContext/DialogContextProvider';
import { openDialog } from '../../../State/App/DialogContext/DialogReducer/DialogReducer';
import useVaultIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useDeckDispatchContext } from '@app/Areas/Optimus/State/DataStores/DeckStore/DeckContextProvider';
import { DECKS_SET_DECK_UNDER_EDIT, DECKS_SET_DECK_UNDER_DELETE } from '@app/Areas/Optimus/State/DataStores/DeckStore/DeckReducer/DeckReducer';
import { IDeckApiModel } from '@app/Areas/Optimus/ApiClient/DecksClient';

const DeckGrid = styled.div`
    display: none;
    @media screen and (min-width: 768px) {
        display: grid;
        padding: 40px 0;
        grid-template-columns: repeat(auto-fill, 210px);
        grid-auto-rows: 260px;
        grid-gap: 40px;
    }
`;

const DeckList = styled.div`
    @media screen and (min-width: 768px) {
        display: none;
    }
`;

function DecksHub() {
    useDecksFetch();

    const dialogDispatch = useDialogDispatchContext();
    const deckDispatch = useDeckDispatchContext();
    const decks = useVaultDecksAsArray();
    const history = useHistory();
    const { path } = useRouteMatch();
    const vaultId = useVaultIdParam();

    const openNewDeckDialog = () => {
        dialogDispatch(openDialog(NEW_DECK_DIALOG_ID));
    }

    const goToDeck = (deckId?: number) => {
        if (deckId && vaultId !== null) {
            const deckPath = generatePath(`${path}/:deckId`, { deckId });
            history.push(deckPath);
        }
    }

    const openEditDeckDialog = (deck: IDeckApiModel) => {
        dialogDispatch(openDialog(EDIT_DECK_DIALOG_ID));
        deckDispatch({
            type: DECKS_SET_DECK_UNDER_EDIT,
            deckId: deck.id
        })
    }

    const openDeleteDeckDialog = (deck: IDeckApiModel) => {
        dialogDispatch(openDialog(DELETE_DECK_DIALOG_ID));
        deckDispatch({
            type: DECKS_SET_DECK_UNDER_DELETE,
            deckId: deck.id
        })
    }

    return (
        <>
            <Breadcrumb fragments={[{
                label: 'Vaults',
                path: '/vaults'
            }, {
                label: 'Vaults',
                path: '/vaults'
            }]} />
            <ConsoleWrapper>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Panel>
                            <PanelHeader
                                headerVariant="h5"
                                header="Decks"
                                subheader="Decks contain cards for note taking, revision and presentation purposes. Cards are meant to hold small, atomic pieces of information."
                                Icon={() => <DeckIcon style={{width: '1.5em', height: '1.5em'}} />}
                                actions={[{
                                    label: 'New Deck',
                                    action: openNewDeckDialog
                                }]}
                            />
                                <DeckGrid>
                                    { decks.map((d) => (
                                        <DeckViewCard
                                            title={`${d.title}`}
                                            description={`${d.description}`}
                                            actions={[{
                                                Icon: EditIcon,
                                                action: () => openEditDeckDialog(d)
                                            }, {
                                                Icon: DeleteIcon,
                                                action: () => openDeleteDeckDialog(d)
                                            }]}
                                            onSelect={() => goToDeck(d.id)}
                                        />
                                    ))}
                                </DeckGrid>
                                <DeckList>
                                    <DecksList decks={decks} goToDeck={goToDeck} />
                                </DeckList>
                        </Panel>
                    </Grid>
                </Grid>
            </ConsoleWrapper>
        </>
    )
}

export default DecksHub;

