import { useState } from 'react';
import EditorDialog from '@app/Areas/Optimus/Components/Shared/EditorDialog';
import { NEW_RESOURCE_DIALOG_ID } from '../../ActionDialogs';
import { ResourceAssignmentModel } from './assigner/ResourceAssignmentModel';
import { useSectionDispatchContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider';
import { getVaultSectionGroupResources } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceReducer/ResourceReducer';
import useGroupIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useGroupIdParam/useGroupIdParam';
import useSectionIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useSectionIdParam';
import NewResourceForm from './NewResourceForm';
import useCreateSaveResourceRequest from './factories/createSaveResourceRequest';
import useVaultIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { IResourceCreationRequest, IResourceApiModel } from '@app/Areas/Optimus/ApiClient/BookmarksClient';

const blankResource: IResourceCreationRequest = {
    title: '',
    url: '',
    tags: [],
    publicationDate: new Date(),
    isRecommended: false,
    isPublic: false,
    classification: '',
    description: '',
    thumbnail: '',
    additionalGraph: {},
    siteName: '',
    author: '',
    assignments: [],
}

function NewResource() {
    const vaultId = useVaultIdParam();
    const currentSectionId = useSectionIdParam();
    const currentGroupId = useGroupIdParam();

    const sectionDispatch = useSectionDispatchContext();

    const [newResource, setNewResource] = useState<IResourceCreationRequest>(blankResource);
    const [assignments, setAssignments] = useState<ResourceAssignmentModel[]>([])
    const [createdResource, setCreatedResource] = useState<IResourceApiModel | null>(null);

    const {
        isSending,
        sendRequest
    } = useCreateSaveResourceRequest(
        newResource,
        setCreatedResource,
        (vaultId: number) => {
            if (currentSectionId && currentGroupId) {
                sectionDispatch(getVaultSectionGroupResources(vaultId, currentSectionId, currentGroupId, false))
            }
        },
    )

    const handleSave = () => {
        if (!createdResource && !isSending) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        setNewResource(blankResource);
        setCreatedResource(null);
        setAssignments([]);
    }    
    

    return <EditorDialog
        title="New Resource"
        saveTitle="Save Resource"
        dialogId={NEW_RESOURCE_DIALOG_ID}
        handleSave={handleSave}
        onClose={cleanUp}
    >
        <NewResourceForm
            vaultId={vaultId}
            presetUrlToVerify={null}
            newResource={newResource}
            setNewResource={setNewResource}            
            assignments={assignments}
            setAssignments={setAssignments}
            createdResource={createdResource}
            setCreatedResource={setCreatedResource}
            blankResource={blankResource}
            isSaving={isSending}
        />
    </EditorDialog>
}

export default NewResource;
