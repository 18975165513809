import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import useVaultsAsDictionary from '../useVaultsAsDictionary';

function useCurrentVault() {
    const vaultId = useVaultIdParam();
    const vaults = useVaultsAsDictionary();

    return (vaultId && vaults[vaultId]) || null;
}

export default useCurrentVault;
