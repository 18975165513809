import { useCallback, useEffect, useRef, useState } from 'react';
import EditorDialog from '@app/Areas/Optimus/Components/Shared/EditorDialog';
import useGroupUnderEdit from '../../../State/DataStores/ResourceStore/hooks/useGroupUnderEdit';
import useVaultsAsDictionary from '../../../State/DataStores/VaultStore/hooks/useVaultsAsDictionary/useVaultsAsDictionary';
import { EDIT_GROUP_DIALOG_ID } from '../../ActionDialogs/index';
import { GroupEditorModel } from '../NewGroup/GroupEditorModel';
import GroupEditor from '../NewGroup/GroupEditor';
import useCurrentSection from '../../../State/DataStores/ResourceStore/hooks/useCurrentSection/useCurrentSection';
import createGroupService from '../../../Services/GroupService/GroupService';
import { getVaultSectionGroups, RESOURCES_CLEAR_GROUP_UNDER_EDIT } from '../../../State/DataStores/ResourceStore/ResourceReducer/ResourceReducer';
import { useSectionDispatchContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider/ResourceContextProvider';
import { IGroupApiModel, IGroupUpdateRequest } from '@app/Areas/Optimus/ApiClient/BookmarksClient';

const blankGroup: IGroupUpdateRequest = {
    newName: '',
    newParentId: 0,
};

function mapUpdateDetailsToGroup(updateDetails: IGroupUpdateRequest): GroupEditorModel {
    return {
        name: updateDetails.newName
    }
}

function EditGroup() {
    const groupDispatch = useSectionDispatchContext();
    const groupService = createGroupService();
    const [isSending, setIsSending] = useState(false)
    const groupUnderEdit = useGroupUnderEdit();
    const group = groupUnderEdit?.group;
    const vaults = useVaultsAsDictionary();
    const currentSection = useCurrentSection();
    const [groupUpdateRequest, setGroupUpdateRequest] = useState<IGroupUpdateRequest>(blankGroup);
    const [updatedGroup, setUpdatedGroup] = useState<IGroupApiModel | null>(null);
    const isMounted = useRef(true);
    const currentParentVaultId = groupUnderEdit?.parentVaultId;
    const currentParentSectionId = groupUnderEdit?.parentSectionId;
    const currentParentVault = currentParentVaultId ? vaults[currentParentVaultId] : null;

    useEffect(() => {
        if (!!groupUnderEdit?.group && !!groupUnderEdit.parentSectionId) {
            const { group } = groupUnderEdit;
            setGroupUpdateRequest({
                ...groupUpdateRequest,
                newName: group.name || '',
                newParentId: groupUpdateRequest?.newParentId || groupUnderEdit.parentSectionId
            })        }
    }, [groupUnderEdit]);

    const handleSave = () => {
        if (!updatedGroup && !isSending) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        setGroupUpdateRequest(blankGroup);
        setUpdatedGroup(null);
        groupDispatch({
            type: RESOURCES_CLEAR_GROUP_UNDER_EDIT
        })
    }

    const sendRequest = useCallback(async () => {
        if (currentParentVaultId && currentParentSectionId && group?.id && !!groupUpdateRequest) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await groupService.updateGroup(currentParentVaultId, currentParentSectionId, group.id, groupUpdateRequest);
            if (result) {
                setUpdatedGroup(result);
            }
            groupDispatch(getVaultSectionGroups(currentParentVaultId, currentParentSectionId, false));

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [ currentParentVaultId, currentParentSectionId, groupUpdateRequest, isSending]
    ); // update the callback if the state changes

    const groupEdited = (event: any) => {
        const propName = event.target.name === 'name'
            ? 'newName'
            : event.target.name;


        setGroupUpdateRequest({
            ...groupUpdateRequest,
            [propName]: event.target.value
        })
    }

    return currentParentVault && groupUpdateRequest && (
        <>
            <EditorDialog
                title="Edit Group"
                saveTitle="Update Group"
                dialogId={EDIT_GROUP_DIALOG_ID}
                handleSave={handleSave}
                onClose={cleanUp}
            >
                { !updatedGroup && <GroupEditor
                    group={mapUpdateDetailsToGroup(groupUpdateRequest)}
                    handleGroupChange={groupEdited}
                    title={`${currentParentVault.title} - ${currentSection?.name}`}
                    isSaving={isSending}
                /> }
                { updatedGroup && JSON.stringify(updatedGroup) }
            </EditorDialog>
        </>
    ) || null;
}

export default EditGroup;
