import React, { useCallback, useEffect, useRef, useState } from "react";
import { UrlInput } from '@app/components/url-input';
import createUrlVerifyService from '@app/Areas/Optimus/Services/UrlVerifyService/UrlVerifyService';
import { Typography } from "@material-ui/core";
import { IResourceApiModel, IResourceCreationRequest } from "@app/Areas/Optimus/ApiClient/BookmarksClient";

function mapVerifiedResource({
    title,
    url,
    classification,
    description,
    thumbnail,
    tags,
    additionalGraph,
    siteName,
    author,
    publicationDate
}: IResourceApiModel,
editorModel: IResourceCreationRequest
) {
    const mappedResource: IResourceCreationRequest = {
        title: title || '',
        url: url || '',
        classification,
        description,
        thumbnail,
        tags: tags || [],
        additionalGraph,
        siteName,
        author,
        publicationDate: publicationDate || editorModel.publicationDate,
        isRecommended: editorModel.isRecommended,
        isPublic: editorModel.isPublic,
        assignments: editorModel.assignments
    }
    return mappedResource;
}

export interface VerifierProps {
    vaultId: number | null;
    newResource: IResourceCreationRequest;
    setNewResource:  React.Dispatch<any>;
    presetUrlToVerify: string | null;
}

function Verifier({
    vaultId,
    presetUrlToVerify,
    newResource,
    setNewResource,
}: VerifierProps) {
    const urlVerifyService = createUrlVerifyService();
    const isMounted = useRef(true);
    const [isVerifying, setIsVerifying] = useState(false);
    const [urlToVerify, setUrlToVerify] = useState<string | null>(null);

    useEffect(() => {
        if (presetUrlToVerify !== null && urlToVerify === null) {
            setUrlToVerify(presetUrlToVerify);
        }
    }, [presetUrlToVerify, urlToVerify]);

    const lookupUrl = useCallback(async () => {
        // https://www.designerblogs.com/how-to-block-google-analytics-from-tracking-your-own-visits/
        // https://itnext.io/how-to-build-a-dynamic-controlled-form-with-react-hooks-2019-b39840f75c4f

        if (!vaultId || !urlToVerify || isVerifying) return
        setIsVerifying(true)
        const verificationresult = await urlVerifyService.verifyUrl(vaultId, urlToVerify);
        console.log(verificationresult);
        if (verificationresult?.resource) {
            setNewResource(mapVerifiedResource(verificationresult?.resource, newResource));
        }
        // once the request is sent, update state again
        if (isMounted.current) { // only update if we are still mounted
            setIsVerifying(false)
        }
      }, [vaultId, urlToVerify, isVerifying]
    ); // update the callback if the state changes

    function handleUrlChange(event: any) {
        setUrlToVerify(event.target.value);
    }

    return (
        <>
            { !isVerifying &&
                <UrlInput onVerify={() => lookupUrl()} handleInput={handleUrlChange} currentUrl={urlToVerify || ''} />
            }
            {
                isVerifying && <Typography variant="h5" gutterBottom>Verifying Url...</Typography>
            }
        </>
    )
}

export default Verifier;
