import { IProjectsContextModel } from '../../ProjectReducer/ProjectReducer';
import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useProjectStateContext } from '../../ProjectContextProvider';

function useVaultProjectsAsArray() {
    const vaultId = useVaultIdParam();
    const state: IProjectsContextModel = useProjectStateContext();

    if (vaultId === null) {
        return [];
    }

    const projects =
        Object.values(state.projects.byId)
            .filter((p) => p.parentVaultId === vaultId)
            .map(p => p.project);

    return projects;
}

export default useVaultProjectsAsArray;