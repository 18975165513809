import { useCallback, useEffect, useRef, useState } from "react";
import BoardEditor from '../NewBoard/BoardEditor';
import { BoardEditorModel } from '../NewBoard/BoardEditorModel';
import EditorDialog from "@app/Areas/Optimus/Components/Shared/EditorDialog";
import { EDIT_BOARD_DIALOG_ID } from "@app/Areas/Optimus/Ecosystems/ActionDialogs";
import useVaultsAsDictionary from "@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useVaultsAsDictionary";
import createBoardService from "../../../services/BoardsService";
import { BOARDS_CLEAR_BOARD_UNDER_EDIT, getVaultBoards } from "../../../state/TaskBoardReducer/TaskBoardReducer";
import { useTaskBoardDispatchContext } from "../../../state/TaskBoardContextProvider/TaskBoardContextProvider";
import useBoardUnderEdit from "../../../hooks/_Boards/useBoardUnderEdit";
import { IBoardUpdateRequest, IBoardApiModel } from "@app/Areas/Optimus/ApiClient/TaskboardsClient";

const blankBoard: IBoardUpdateRequest = {
    name: '',
    newParentId: 0
};

function mapCreationDetailsToBoard(
    board: IBoardUpdateRequest
): BoardEditorModel {
    return {
        name: board.name || ''
    }
}

function EditBoard() {
    const boardUnderEdit = useBoardUnderEdit();
    const vaults = useVaultsAsDictionary();
    const [isSending, setIsSending] = useState(false)
    const [boardUpdateRequest, setBoardUpdateRequest] = useState<IBoardUpdateRequest | null>(blankBoard);
    const [updatedBoard, setUpdatedBoard] = useState<IBoardApiModel | null>(null);
    const boardService = createBoardService();
    const boardsDispatch = useTaskBoardDispatchContext();
    const isMounted = useRef(true);
    const board = boardUnderEdit?.board;    
    const currentParentVaultId = boardUnderEdit?.parentVaultId;
    const currentParentVault = currentParentVaultId ? vaults[currentParentVaultId] : null;

    useEffect(() => {
        if (!!boardUnderEdit?.board && !!boardUnderEdit.parentVaultId) {
            setBoardUpdateRequest({
                ...boardUpdateRequest,
                name: boardUnderEdit.board?.name,
                newParentId: boardUnderEdit.parentVaultId
            });
        }
    }, [boardUnderEdit]);

    function boardEdited(event: any) {
        if (boardUpdateRequest !== null) {
            setBoardUpdateRequest({
                ...boardUpdateRequest,
                [event.target.name]: event.target.value
            })
        }
    }

    const handleSave = () => {
        if (!updatedBoard && !isSending) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        setBoardUpdateRequest(null);
        setUpdatedBoard(null);
        boardsDispatch({
            type: BOARDS_CLEAR_BOARD_UNDER_EDIT
        });
    }

    const sendRequest = useCallback(async () => {
        if (currentParentVaultId && board?.id && !!boardUpdateRequest) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await boardService.updateBoard(currentParentVaultId, board.id, boardUpdateRequest);
            if (result) {
                setUpdatedBoard(result);
            }
            boardsDispatch(getVaultBoards(currentParentVaultId));

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [ currentParentVaultId, boardUpdateRequest, isSending]
    ); // update the callback if the state changes

    return currentParentVault && boardUpdateRequest && (
        <EditorDialog
            title="Edit Board"
            saveTitle="Update Board"
            dialogId={EDIT_BOARD_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            { !updatedBoard && <BoardEditor
                board={mapCreationDetailsToBoard(boardUpdateRequest)}
                handleBoardChange={boardEdited}
                title={currentParentVault.title}
                isSaving={isSending}
            /> }
            { updatedBoard && JSON.stringify(updatedBoard) }
        </EditorDialog>
    ) || null;
}

export default EditBoard;
