import { Dispatch } from "react";
import createProjectService from '@optimus/Services/ProjectService';
import { IProjectApiModel } from "@app/Areas/Optimus/ApiClient/ProjectsClient";

export const PROJECTS_SET_PROJECTS = 'PROJECTS_SET_PROJECTS';
export const PROJECTS_RESET = 'PROJECTS_RESET';
export const PROJECTS_SET_PROJECT_UNDER_EDIT = 'PROJECTS_SET_PROJECT_UNDER_EDIT';
export const PROJECTS_CLEAR_EDIT = 'PROJECTS_CLEAR_EDIT';
export const PROJECTS_SET_PROJECT_UNDER_DELETE = 'PROJECTS_SET_PROJECT_UNDER_DELETE';
export const PROJECTS_CLEAR_DELETE = 'PROJECTS_CLEAR_DELETE';

export interface IProjectsContextModel {
    projects: {
        byId: {
            [projectId: string]: {
                project: IProjectApiModel;
                parentVaultId: number;
            }
        },
        allIds: number[];
        selected: string | null;
        selectedToEdit: string | null;
        selectedToDelete: string | null;
    },
}

/**
  * The reducer for managing Projects State
  * @param state
  * @param action
  */
 function ProjectReducer(state: IProjectsContextModel, action: any) {
    switch (action.type) {
        case PROJECTS_SET_PROJECTS:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    byId: {
                        ...action.projects.reduce((newProjects: {
                            [projectId: string]: {
                                section: IProjectApiModel;
                                parentVaultId: string;
                                respositoryIds: string[];
                            }
                        }, p: IProjectApiModel) => {
                            if (p.id) {
                                return {
                                    ...newProjects,
                                    [p.id]: {
                                        project: p,
                                        parentVaultId: action.vaultId,
                                        respositoryIds: []
                                    }
                                }
                            }
                        }, {})
                    },
                    allIds: [
                        // TODO: Merge existing Ids
                        ...action.projects.map((p: IProjectApiModel) => p.id)
                    ]
                }
            }
        case PROJECTS_SET_PROJECT_UNDER_EDIT:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    selectedToEdit: action.projectId
                }
            }
        case PROJECTS_CLEAR_EDIT:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    selectedToEdit: null
                }
            }
        case PROJECTS_SET_PROJECT_UNDER_DELETE:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    selectedToDelete: action.projectId
                }
            }
        case PROJECTS_CLEAR_DELETE:
            return {
                ...state,
                projects: {
                    ...state.projects,
                    selectedToDelete: null
                }
            }
        case PROJECTS_RESET:
            return state;
        //     return {}
        default:
            throw new Error();
    }
}

export default ProjectReducer;

/**
 * Action creator for fetching the vault projects.
 */
export function getVaultProjects(vaultId: number) {
    return async (dispatch: Dispatch<any>) => {
        const projectService = createProjectService();
        return projectService.getProjects(vaultId).then((result) => {
            dispatch({
                type: PROJECTS_SET_PROJECTS,
                vaultId: vaultId,
                projects: result,
            })
        });
    }
  }