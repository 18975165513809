import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import { useSectionStateContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider';
import { IResourcesContextModel } from '../../ResourceReducer/ResourceReducer';
import { ISectionApiModel } from '@app/Areas/Optimus/ApiClient/BookmarksClient';

function useVaultSectionsAsArray(): ISectionApiModel[] {
    const vaultId = useVaultIdParam();
    const state: IResourcesContextModel = useSectionStateContext();

    if (vaultId === null) {
        return [];
    }

    const sections =
        Object.values(state.sections.byId)
            .filter((s) => s.parentVaultId === vaultId)
            .map(s => s.section);

    return sections;
}

export default useVaultSectionsAsArray;