import { Launch, MenuBook, DataUsage, ViewQuilt, SettingsRemote, DoubleArrow, AccountTree  } from '@material-ui/icons';
import { BsFillCollectionFill } from 'react-icons/bs';
import { AiOutlineFundProjectionScreen } from 'react-icons/ai';
import { FaLink, FaTrashAlt } from 'react-icons/fa';
import { BiCodeBlock, BiInfoCircle } from 'react-icons/bi';
import { RiDoorLockBoxLine, RiEdit2Fill } from 'react-icons/ri';

// Entities
export const VaultIcon = RiDoorLockBoxLine;
export const AppCtaIcon = Launch;
export const DeepThoughtIcon = MenuBook;
export const JarvisIcon = DataUsage;
export const FridayIcon = AccountTree;
export const ZiggyIcon = ViewQuilt;
export const CommandIcon = SettingsRemote;
export const ActivityIcon = DoubleArrow;
export const ProjectIcon = AiOutlineFundProjectionScreen;
export const RepositoryIcon = BiCodeBlock;
export const LinkIcon = FaLink;
export const DeckIcon = BsFillCollectionFill;

// Actions
export const DeleteIcon = FaTrashAlt;
export const EditIcon = RiEdit2Fill;
export const InfoIcon = BiInfoCircle;