import React, { createContext, useContext, useState } from "react";
import { IStoreFactory } from "../../domains/vaults/store/app-store-factory";

export interface StoreContext<T> {
    hook: () => T | null
    provider: (props: any) => JSX.Element
}

export default class StoreContextFactory<Store, State> {
    private storeFactory: IStoreFactory<Store>

    constructor(storeFactory: IStoreFactory<Store>) {
        this.storeFactory = storeFactory
    }

    GetStoreContext(initialState: State): StoreContext<Store> {
        const StoreContext = createContext<Store | null>(null);

        const StoreProvider = (props: any) => {
            const { children } = props;
            const [state, setState] = useState(initialState);
            const store = this.storeFactory.GetStore(state, setState);

            const value: Store = store;

            return (
                <StoreContext.Provider value={value}>
                    { children }
                </StoreContext.Provider>
            )
        }

        const useStoreContext = () => useContext(StoreContext)

        const hook = useStoreContext;

        const result = {
            hook,
            provider: StoreProvider
        };

        return result;
    }
}