import { BoardCreationRequest, BoardExportModel, BoardProtectionRequest, BoardUpdateRequest, FileParameter, IBoardApiModel, IBoardCreationRequest, IBoardProtectionRequest, IBoardProtectionResult, IBoardUpdateRequest, ITaskListApiModel, ITaskListTransferRequest, TaskboardsClient, TaskListTransferRequest } from "@app/Areas/Optimus/ApiClient/TaskboardsClient";

export interface IBoardsService {
    getBoards: (vaultId: number) => Promise<IBoardApiModel[]>;
    getArchivedBoards: (vaultId: number) => Promise<IBoardApiModel[]>;
    getBoard: (vaultId: number, boardId: number) => Promise<IBoardApiModel>;
    createBoard: (vaultId: number, creationRequest: IBoardCreationRequest) => Promise<IBoardApiModel>;
    updateBoard: (vaultId: number, boardId: number, updateRequest: IBoardUpdateRequest) => Promise<IBoardApiModel>;
    archiveBoard: (vaultId: number, boardId: number) => Promise<IBoardApiModel>;
    deleteBoard: (vaultId: number, boardId: number) => Promise<IBoardApiModel>;
    unarchiveBoard: (vaultId: number, boardId: number) => Promise<IBoardApiModel>;
    protectBoard: (vaultId: number, boardId: number, protectionRequest: IBoardProtectionRequest) => Promise<IBoardProtectionResult>;
    exportBoard: (vaultId: number, boardId: number) => Promise<BoardExportModel>;
    importBoard: (vaultId: number, file: FileParameter) => Promise<IBoardApiModel>;
}

const client = new TaskboardsClient();

function createBoardService() {
    const boardService: IBoardsService = {
        // Function to get Boards, no data fetch lifecycle
        getBoards: (vaultId: number) => client.boardsAll(vaultId),
        // Function to get archived Boards
        getArchivedBoards: (vaultId: number) => client.archived(vaultId),
        // Function to get Board
        getBoard: (vaultId: number, boardId: number) => client.boardsGET(vaultId, boardId),
        // Function to create Boards
        createBoard: (vaultId: number, creationRequest: IBoardCreationRequest) => client.boardsPOST(vaultId, new BoardCreationRequest(creationRequest)),
        // Function to update Board
        updateBoard: (vaultId: number, boardId: number, updateRequest: IBoardUpdateRequest) => client.boardsPUT(vaultId, boardId, new BoardUpdateRequest(updateRequest)),
        // Function to archive a board and all of its tasklists and tasks
        archiveBoard: (vaultId: number, boardId: number) => client.boardsDELETE(vaultId, boardId, false),
        // Function to delete a board permanently and all of its tasklists and tasks
        deleteBoard: (vaultId: number, boardId: number) => client.boardsDELETE(vaultId, boardId, true),
        // Function to unarchive a board and all of its tasklists and tasks
        unarchiveBoard: (vaultId: number, boardId: number) => client.unarchive(vaultId, boardId),
        // Function to protect a board
        protectBoard: (vaultId: number, boardId: number, protectionRequest: IBoardProtectionRequest) => client.protect(vaultId, boardId, new BoardProtectionRequest(protectionRequest)),
        // Function to export a board
        exportBoard: (vaultId: number, boardId: number) => client.export(vaultId, boardId),
        // Function to import a board
        importBoard: (vaultId: number, file: FileParameter) => client.import(vaultId, file)
    }
    return boardService;
}

export default createBoardService;