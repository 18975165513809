import { useCallback, useEffect, useRef, useState } from 'react';
import EditorDialog from '@optimus/Components/Shared/EditorDialog';
import { EDIT_VAULT_DIALOG_ID } from '../../ActionDialogs/index';
import { VaultEditorModel } from '../NewVault/VaultEditorModel';
import VaultEditor from '../NewVault/VaultEditor';
import useVaultUnderEdit from '../../../State/DataStores/VaultStore/hooks/useVaultUnderEdit/useVaultUnderEdit';
import { useVaultDispatchContext } from '@app/Areas/Optimus/State/DataStores/VaultStore/VaultContextProvider';
import { VAULTS_CLEAR_VAULT_UNDER_EDIT } from '@app/Areas/Optimus/State/DataStores/VaultStore/VaultReducer/VaultReducer';
import createVaultService from '@app/Areas/Optimus/Services/VaultService';
import { getInitialUserVaults } from '../../../State/DataStores/VaultStore/VaultReducer/VaultReducer';
import { ISectionApiModel } from '@app/Areas/Optimus/ApiClient/BookmarksClient';
import { IVaultUpdateRequest } from '@app/Areas/Optimus/ApiClient/VaultsClient';

const blankVault: IVaultUpdateRequest = {
    vaultName: '',
    description: ''
}

function mapUpdateDetailsToVault(
    vault: IVaultUpdateRequest
): VaultEditorModel {
    return {
        title: vault.vaultName || '',
        description: vault.description || ''
    }
}

function EditVault() {
    const [isSending, setIsSending] = useState(false)
    const vaultUnderEdit = useVaultUnderEdit();
    const vault = vaultUnderEdit?.vault;
    const [vaultUpdateRequest, setVaultUpdateRequest] = useState<IVaultUpdateRequest>(blankVault);
    const [updatedVault, setUpdatedVault] = useState<ISectionApiModel | null>(null);
    const vaultService = createVaultService();
    const vaultDispatch = useVaultDispatchContext();
    const isMounted = useRef(true);

    useEffect(() => {
        if (!!vaultUnderEdit?.vault) {
            const { vault } = vaultUnderEdit;
            setVaultUpdateRequest({
                ...vaultUpdateRequest,
                vaultName: vault.title,
                description: vault.description,
            });
        }
    }, [vaultUnderEdit]);

    function handleSave() {
        if (!updatedVault && !isSending) {
            sendRequest();
        }
    }

    const sendRequest = useCallback(async () => {
        if (vault?.id && !!vaultUpdateRequest) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await vaultService.updateVault(vault.id, vaultUpdateRequest);
            if (result) {
                setUpdatedVault(result);
                vaultDispatch(getInitialUserVaults());
            }

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [ vault, vaultUpdateRequest, isSending]
    ); // update the callback if the state changes


    function vaultEdited(event: any) {
        if (vaultUpdateRequest !== null) {
            setVaultUpdateRequest({
                ...vaultUpdateRequest,
                [event.target.name]: event.target.value
            })
        }
    }

    function cleanUp() {
        setVaultUpdateRequest(blankVault);
        setUpdatedVault(null);
        vaultDispatch({
            type: VAULTS_CLEAR_VAULT_UNDER_EDIT
        });
    }

    return  vaultUpdateRequest && (
        <EditorDialog
            title="Edit Vault"
            saveTitle="Save Changes"
            dialogId={EDIT_VAULT_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            { !updatedVault && <VaultEditor
                vault={mapUpdateDetailsToVault(vaultUpdateRequest)}
                handleVaultChange={vaultEdited}
            /> }
            { updatedVault && JSON.stringify(updatedVault) }
        </EditorDialog>
    ) || null;
}

export default EditVault;
