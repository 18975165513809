import { useState } from 'react';
import { NEW_GROUP_DIALOG_ID } from '../../ActionDialogs/index';
import EditorDialog from '@optimus/Components/Shared/EditorDialog';
import { GroupEditorModel } from './GroupEditorModel';
import GroupEditor from './GroupEditor';
import createGroupService from '@app/Areas/Optimus/Services/GroupService';
import { getVaultSectionGroups } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceReducer/ResourceReducer';
import { useSectionDispatchContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider/ResourceContextProvider';
import useCurrentSection from '@app/Areas/Optimus/State/DataStores/ResourceStore/hooks/useCurrentSection';
import useCurrentVault from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useCurrentVault';
import useCreateVaultGrandChildEntity from '../../../Hooks/_operations/useCreateVaultGrandChildEntity.ts/useCreateVaultGrandChildEntity';
import { IGroupCreationRequest, IGroupApiModel, ISectionApiModel } from '@app/Areas/Optimus/ApiClient/BookmarksClient';

const blankGroup: IGroupCreationRequest = {
    name: ''
};

function mapCreationDetailsToGroup(section: IGroupCreationRequest): GroupEditorModel {
    return {
        name: section.name
    }
}

function NewGroup() {
    const groupService = createGroupService();
    const sectionDispatch = useSectionDispatchContext();
    const currentVault = useCurrentVault();
    const currentSection = useCurrentSection();
    const [groupCreationRequest, setGroupCreationRequest] = useState<IGroupCreationRequest>(blankGroup);
    const [createdGroup, setCreatedGroup] = useState<IGroupApiModel | null>(null);

    const {
        isSending,
        sendRequest,
    } = useCreateVaultGrandChildEntity<
        ISectionApiModel,
        IGroupCreationRequest,
        IGroupApiModel
    >(
        currentSection,
        (v: number, g: number, cr: IGroupCreationRequest) => groupService.createGroup(v, g, cr),
        groupCreationRequest,
        (result: IGroupApiModel) => setCreatedGroup(result),
        (vaultId: number, sectionId: number) => sectionDispatch(getVaultSectionGroups(vaultId, sectionId, false))
    );

    function groupEdited(event: any) {
        setGroupCreationRequest({
            ...groupCreationRequest,
            [event.target.name]: event.target.value
        })
    }

    const handleSave = () => {
        if (!createdGroup && !isSending) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        setGroupCreationRequest(blankGroup);
        setCreatedGroup(null);
    }

    return currentVault && currentSection && (
        <EditorDialog
            title="New Group"
            saveTitle="Save Group"
            dialogId={NEW_GROUP_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            { !createdGroup && <GroupEditor
                group={mapCreationDetailsToGroup(groupCreationRequest)}
                handleGroupChange={groupEdited}
                title={`${currentVault.title} - ${currentSection?.name}`}
                isSaving={isSending}
            /> }
            { createdGroup && JSON.stringify(createdGroup) }
        </EditorDialog>
    ) || null;
}

export default NewGroup;
