import { useEffect } from 'react';
import { DECKS_CLEAR_DELETE } from '../../DeckReducer/DeckReducer';
import { useDeckDispatchContext } from '@app/Areas/Optimus/State/DataStores/DeckStore/DeckContextProvider/DecksContextComponent';
import { useDeckStateContext } from '@app/Areas/Optimus/State/DataStores/DeckStore/DeckContextProvider';
import useVaultIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam';

function useDeckUnderDelete() {
    const vaultId = useVaultIdParam();
    const decksDispatch = useDeckDispatchContext();
    const deckStateContext = useDeckStateContext();
    const deckUnderDeleteId = deckStateContext.decks.selectedToDelete;

    useEffect(() => {
        return function cleanup() {
            decksDispatch({ type: DECKS_CLEAR_DELETE })
        }
    }, [vaultId])

    if (!deckUnderDeleteId) {
        return null;
    }

    const deck = deckStateContext?.decks?.byId[deckUnderDeleteId];

    if (!deck || deck.parentVaultId !== vaultId)
    {
        return null;
    }

    return deck;
}

export default useDeckUnderDelete;
