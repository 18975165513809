import { useCallback, useRef, useState } from 'react';
import ConfirmationDialog from '../../../Components/Shared/ConfirmationDialog/ConfirmationDialog';
import { DELETE_VAULT_DIALOG_ID } from '../../ActionDialogs';
import { useVaultDispatchContext } from '../../../State/DataStores/VaultStore/VaultContextProvider';
import { VAULTS_CLEAR_VAULT_UNDER_DELETE } from '../../../State/DataStores/VaultStore/VaultReducer/VaultReducer';
import useVaultUnderDelete from '../../../State/DataStores/VaultStore/hooks/useVaultUnderDelete/useVaultUnderDelete';
import createVaultService from '@optimus/Services/VaultService';
import { getInitialUserVaults } from '@app/Areas/Optimus/State/DataStores/VaultStore/VaultReducer/VaultReducer';

function DeleteVault() {
    const vaultUnderDelete = useVaultUnderDelete();
    const vault = vaultUnderDelete?.vault;    
    const [isSending, setIsSending] = useState(false);
    const vaultService = createVaultService();
    const vaultDispatch = useVaultDispatchContext();
    const isMounted = useRef(true);

    const sendRequest = useCallback(async (callback) => {
        if (vault?.id) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await vaultService.archiveVault(vault.id);
            if (result) {
                vaultDispatch(getInitialUserVaults());
                callback();
            }
            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [vault, isSending]
    ); // update the callback if the state changes

    const handleConfirm = (callback: () => void) => {
        if (!isSending) {
            sendRequest(callback);
        }
    }

    const cleanUp = () => {
        vaultDispatch({
            type: VAULTS_CLEAR_VAULT_UNDER_DELETE
        })
    }

    return vault && (
        <ConfirmationDialog
            title="Archive Vault"
            description={`Are you sure you want to archive the vault '${vault?.title}' and all of its data?`}
            dialogId={DELETE_VAULT_DIALOG_ID}
            onConfirm={handleConfirm}
            onClose={cleanUp}
        />
    ) || null;
}

export default DeleteVault;
