import { useCallback, useRef, useState } from 'react';
import ConfirmationDialog from '../../../Components/Shared/ConfirmationDialog/ConfirmationDialog';
import createSectionService from '@app/Areas/Optimus/Services/SectionService';
import { useSectionDispatchContext } from '@app/Areas/Optimus/State/DataStores/ResourceStore/ResourceContextProvider/ResourceContextProvider';
import { getVaultSections, RESOURCES_CLEAR_SECTION_UNDER_DELETE } from '../../../State/DataStores/ResourceStore/ResourceReducer/ResourceReducer';
import { DELETE_SECTION_DIALOG_ID } from '../../ActionDialogs';
import useVaultsAsDictionary from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useVaultsAsDictionary';
import useSectionUnderDelete from '@app/Areas/Optimus/State/DataStores/ResourceStore/hooks/useSectionUnderDelete';

function DeleteSection() {
    const parsedSectionToDelete = useSectionUnderDelete();
    const sectionUnderDelete = parsedSectionToDelete?.section;
    const vaults = useVaultsAsDictionary();
    const currentParentVaultId = parsedSectionToDelete?.parentVaultId;
    const currentParentVault = currentParentVaultId ? vaults[currentParentVaultId] : null;
    const [isSending, setIsSending] = useState(false);
    const sectionService = createSectionService();
    const sectionDispatch = useSectionDispatchContext();
    const isMounted = useRef(true);

    const sendRequest = useCallback(async (callback) => {
        if (currentParentVaultId && parsedSectionToDelete?.section.id) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await sectionService.archiveSection(currentParentVaultId, parsedSectionToDelete?.section.id);
            if (result) {
                sectionDispatch(getVaultSections(currentParentVaultId));
                callback();
            }
            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [currentParentVault, parsedSectionToDelete, isSending]
    ); // update the callback if the state changes

    const handleConfirm = (callback: () => void) => {
        if (!isSending) {
            sendRequest(callback);
        }
    }

    const cleanUp = () => {
        sectionDispatch({
            type: RESOURCES_CLEAR_SECTION_UNDER_DELETE
        })
    }

    return currentParentVault && sectionUnderDelete && (
        <ConfirmationDialog
            title="Delete Section"
            description={`Are you sure you want to delete the section '${sectionUnderDelete.name}' and all of its groups and resources?`}
            dialogId={DELETE_SECTION_DIALOG_ID}
            onConfirm={handleConfirm}
            onClose={cleanUp}
        />
    ) || null;
}

export default DeleteSection;
