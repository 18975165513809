import React from 'react';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';
import { Card, CardContent, createStyles, makeStyles, Theme } from '@material-ui/core';
import PanelHeader from '../PanelHeader';

const useMaterialClasses = makeStyles((theme: Theme) =>
    {
        return createStyles({
            root: {
              background: 'transparent',
              backdropFilter: 'blur(10px)',
              boxShadow: `0 0px 10px ${theme.palette.primary.main}`,
              border: `1px solid ${theme.palette.primary.main}`,
            },
            intro: {
                background: 'rgba(0,0,0,0.2)',
            },
            divider: {
              backgroundColor: `${theme.palette.primary.main}`
            }
          })
    }
)

export interface FeatureCardProps {
    Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
    title: string;
    actions?: {
        label: string;
        action: () => void;
    }[];
}

function FeatureCard({ Icon, title, actions, children }: React.PropsWithChildren<FeatureCardProps>) {
    const classes = useMaterialClasses();
    return (
        <Card className={`${classes.root} ${classes.intro}`}>
          <CardContent>
            <PanelHeader
              Icon={Icon}
              header={title}
              actions={actions}
            >
              { children }
              </PanelHeader>
          </CardContent>
        </Card>
    )
}

export default FeatureCard;
