import { Button, CardMedia, Checkbox, Chip, FormControlLabel, Grid, TextField, Typography } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useState } from "react";

export interface VideoEditorModel {
    title: string;
    url: string;
    sourceId: string;
    description: string;
    thumbnailUrl: string;
    tags: string[];
    source: string;
    publisher: string;
    duration: string;
    publishDate: Date;
    isPublic: boolean;
    // classification: string;
    // additionalGraph: { [key: string]: string; };
    // isRecommended: boolean;
}

export interface VideoEditorProps {
    video: VideoEditorModel;
    handleVideoChange: (event: any) => void;
    handleReset: (event: any) => void;
    isSaving: boolean;
}

function VideoEditor({
    video,
    handleVideoChange,
    handleReset,
    isSaving
}: VideoEditorProps) {

    const [newTag, setNewTag] = useState();

    function removeTag(index: number) {

        const updatedTags = [
            ...video.tags
        ];

        for(let i = 0; i < updatedTags.length; i++) {
            if (i === index) {
                updatedTags.splice(i, 1);
            }
        }

        const pseudoEvent = {
            target: {
                name: 'tags',
                value: updatedTags
            }
        }

        handleVideoChange(pseudoEvent);
    }

    function addTag() {
        const updatedTags: (string | undefined)[] = [
            ...video.tags,
            newTag
        ];

        const pseudoEvent = {
            target: {
                name: 'tags',
                value: updatedTags
            }
        }

        handleVideoChange(pseudoEvent);
    }

    return (
        <>
            {!isSaving &&
                <>
                    <Typography variant="h5" gutterBottom>Video: { video.title }</Typography>
                    <Typography gutterBottom>Url: { video.url }</Typography>
                    <Typography gutterBottom>SourceId: { video.sourceId }</Typography>
                    <form>
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                <TextField  fullWidth id="video-title-input" label="Title" name="title" value={video.title} onChange={handleVideoChange} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField  fullWidth id="video-description-input" label="Description" name="description" value={video.description} onChange={handleVideoChange} />
                            </Grid>
                            <Grid item xs={5}>
                                <TextField fullWidth id="video-thumbnail-input" label="Thumbnail" name="thumbnailUrl" value={video.thumbnailUrl} onChange={handleVideoChange} />
                                { video.thumbnailUrl &&
                                    <CardMedia
                                        component="img"
                                        height="140"
                                        image={video.thumbnailUrl}
                                    />
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={5}>
                            <Grid container spacing={1}>
                                <Grid item xs={8}>
                                    <TextField
                                        id="video-tag-input"
                                        name="tags"
                                        label="tags"
                                        fullWidth
                                        onChange={(event: any) => setNewTag(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Button onClick={addTag} color="primary" variant="contained">Add Tag</Button> 
                                </Grid>
                            </Grid>
                            <div>
                                { video.tags.map((t: string, i: number) => (
                                    <Chip label={t} onDelete={() => removeTag(i)} color="primary" />
                                ))}
                            </div>
                            <Grid item xs={3}>
                                <TextField fullWidth id="video-publisher-input" label="Publisher" name="publisher" value={video.publisher} onChange={handleVideoChange} />
                            </Grid>
                            <Grid item xs={9}>
                                <TextField fullWidth id="video-duration-input" label="Duration (0.00:00:00.0000)" name="duration" value={video.duration} onChange={handleVideoChange} />
                            </Grid>
                            <Grid item xs={9}>
                                <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="normal"
                                        id="video-publishdate-input"
                                        label="Publish Date"
                                        value={video.publishDate}
                                        onChange={(date: any) => {
                                            const pseudoEvent = {
                                                target: {
                                                    name: "publishDate",
                                                    value: date.toJSDate()
                                                }
                                            }
                                            handleVideoChange(pseudoEvent);
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />  
                            </Grid>
                            <Grid item xs={2}>
                                <FormControlLabel
                                    control={
                                    <Checkbox
                                        name="isPublic"
                                        checked={video.isPublic}
                                        onChange={(event: any) => {
                                            const pseudoEvent = {
                                                target: {
                                                    name: event.target.name,
                                                    value: event.target.checked
                                                }
                                            }
                                            handleVideoChange(pseudoEvent);
                                        }}
                                        value={video.isPublic}
                                        color="primary"
                                    />
                                    }
                                    label="Public?"
                                />
                            </Grid>
                        </Grid>
                    </form>
                </>
            }
        </>
    )
}

export default VideoEditor;
