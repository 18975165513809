import Panel from '@optimus/Components/Shared/Panel';
import TaskBoard from '../TaskBoard.tsx/TaskBoard';
import { Switch, Route, useRouteMatch, Redirect, generatePath, useHistory } from 'react-router-dom';
import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import PanelHeader from '@app/Areas/Optimus/Components/Shared/PanelHeader';
import BoardSelector from '../BoardSelector/BoardSelector';
import { useDialogDispatchContext } from '@app/Areas/Optimus/State/App/DialogContext/DialogContextProvider';
import { OPTIMUS_OPEN_DIALOG } from '@app/Areas/Optimus/State/App/DialogContext/DialogReducer/DialogReducer';
import { NEW_BOARD_DIALOG_ID, ARCHIVE_BOARD_DIALOG_ID, EDIT_BOARD_DIALOG_ID, PROTECT_BOARD_DIALOG_ID, IMPORT_BOARD_DIALOG_ID } from '@app/Areas/Optimus/Ecosystems/ActionDialogs';
import { BOARDS_SET_BOARDMODE, BOARDS_SET_BOARD_TO_PROTECT, BOARDS_SET_BOARD_UNDER_ARCHIVE, BOARDS_SET_BOARD_UNDER_EDIT, TaskBoardMode } from '../../state/TaskBoardReducer/TaskBoardReducer';
import { useTaskBoardDispatchContext } from '../../state/TaskBoardContextProvider/TaskBoardContextProvider';
import useBoardIdParam from '../../hooks/_Boards/useBoardIdParam';
import useBoardsFetch from '../../hooks/_Boards/useBoardsFetch';
import useBoardTasklistsFetch from '../../hooks/_Boards/useBoardTaskListsFetch';
import useVaultBoardsAsArray from '../../hooks/_Boards/useVaultBoardsAsArray';
import useVaultBoardsAsDictionary from '../../hooks/_Boards/useVaultBoardsAsDictionary';
import TaskBoardExport from "./TaskBoardExport";
import useBoardMode from '../../hooks/_Boards/useBoardMode';
import ArchiveView from '../ArchiveView';
import useArchivedBoardsFetch from '../../hooks/_Boards/useArchivedBoardsFetch';


function BoardsHub() {
    useBoardsFetch();
    useArchivedBoardsFetch();
    useBoardTasklistsFetch();    
    
    const vaultId = useVaultIdParam();
    const boardId = useBoardIdParam();
    const boards = useVaultBoardsAsArray();
    const boardMode = useBoardMode();
    const boardDictionary = useVaultBoardsAsDictionary();
    const dialogDispatch = useDialogDispatchContext();
    const boardDispatch = useTaskBoardDispatchContext();
    const { path } = useRouteMatch();
    const history = useHistory();
    const currentBoard = boardId ? boardDictionary[boardId as number] : null;

    const openNewBoardDialog = () => {
        dialogDispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: NEW_BOARD_DIALOG_ID
        })
    }

    const openImportBoardDialog = () => {
        dialogDispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: IMPORT_BOARD_DIALOG_ID
        })
    }

    const openArchiveBoardDialog = () => {
        boardDispatch({
            type: BOARDS_SET_BOARD_UNDER_ARCHIVE,
            boardId
        });
        dialogDispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: ARCHIVE_BOARD_DIALOG_ID
        })
    }

    const openEditBoardDialog = () => {
        boardDispatch({
            type: BOARDS_SET_BOARD_UNDER_EDIT,
            boardId
        });
        dialogDispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: EDIT_BOARD_DIALOG_ID
        })
    }

    const openProtectBoardDialog = () => {
        boardDispatch({
            type: BOARDS_SET_BOARD_TO_PROTECT,
            boardId
        });
        dialogDispatch({
            type: OPTIMUS_OPEN_DIALOG,
            openedDialog: PROTECT_BOARD_DIALOG_ID
        })
    }

    const openBoardProtectionInfoDialog = () => {
    }

    const switchToArchiveView = () => {
        boardDispatch({
            type: BOARDS_SET_BOARDMODE,
            mode: TaskBoardMode.BOARDMODE_ARCHIVE
        })
    }

    const switchToActiveView = () => {
        boardDispatch({
            type: BOARDS_SET_BOARDMODE,
            mode: TaskBoardMode.BOARDMODE_ACTIVE
        })
    }

    const selectBoard = (event: React.ChangeEvent<{ value: unknown }>) => {
        const board = boardDictionary[event.target.value as number];
        if (vaultId && board && board.id) {
            const newpath = generatePath('/optimus/vaults/:vaultId/taskboards/boards/:boardId', {
                vaultId,
                boardId: board.id
            })
            history.push(newpath)
        }
    }

    const boardActions = [{
        label: 'Edit Board',
        action: openEditBoardDialog
    },{
        label: 'Archive Board',
        action: openArchiveBoardDialog
    }, {
        label: 'New Board',
        action: openNewBoardDialog
    }, {
        label: 'Import Board',
        action: openImportBoardDialog
    }];

    if (boardMode === TaskBoardMode.BOARDMODE_ACTIVE) {
        boardActions.push({
            label: 'View Archive',
            action: switchToArchiveView
        });
    } else {
        boardActions.push({
            label: 'View Active',
            action: switchToActiveView
        });
    }

    const boardProtected = currentBoard?.isProtected;
    if (!boardProtected) {
        boardActions.push({
            label: 'Protect Board',
            action: openProtectBoardDialog
        })
    } else {
        boardActions.push({
            label: '(Protected Board)',
            action: openBoardProtectionInfoDialog
        })
    }

    return (
        <>
            <Panel>
                <PanelHeader
                    headerVariant="h5"
                    header="Task Boards"
                    components={
                        <BoardSelector
                            boards={boards}
                            selectBoard={selectBoard}
                            selectedBoard={boardId}
                        />
                    }
                    actions={boardActions}
                    links={[
                        <TaskBoardExport vaultId={vaultId} boardId={boardId} />
                    ]}
                />                
                <Switch>
                    <Route path={`${path}/:boardId`}>
                        {
                            boardMode === TaskBoardMode.BOARDMODE_ACTIVE && <TaskBoard />
                        }
                        {
                            boardMode === TaskBoardMode.BOARDMODE_ARCHIVE && <ArchiveView />
                        }                        
                    </Route>
                    <Route path={`${path}`}>
                        { boards && boards.length && <Redirect to={`/optimus/vaults/${vaultId}/taskboards/boards/${boards[0].id}`} /> }
                    </Route>
                </Switch>
            </Panel>
        </>
    )
}

export default BoardsHub;
