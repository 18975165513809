import { VideosClient, IVideoCreationRequest, VideoCreationRequest, VideoApiModel, IVideoUpdateRequest, VideoUpdateRequest } from "../../ApiClient/VideosClient";

export interface IVideoService {
    getVideos: (vaultId: number, playlistId: number) => Promise<VideoApiModel[]>;
    createVideo: (vaultId: number, creationRequest: IVideoCreationRequest) => Promise<VideoApiModel>;
    updateVideo: (vaultId: number, playlistId: number, videoId: number, updateRequest: IVideoUpdateRequest) => Promise<VideoApiModel>;
    archiveVideo: (vaultId: number, playlistId: number, videoId: number) => Promise<VideoApiModel>;
}

function createVideoService() {
    const client = new VideosClient();
    const videoService: IVideoService = {
        getVideos: function (vaultId: number, playlistId: number): Promise<VideoApiModel[]> {
            return client.videosAll(vaultId, playlistId);
        },
        createVideo: function (vaultId: number, creationRequest: IVideoCreationRequest): Promise<VideoApiModel> {
            return client.videosPOST(vaultId, new VideoCreationRequest(creationRequest));
        },
        updateVideo: function (vaultId: number, playlistId: number, videoId: number, updateRequest: IVideoUpdateRequest): Promise<VideoApiModel> {
            return client.videosPUT(vaultId, playlistId, videoId, new VideoUpdateRequest(updateRequest));
        },
        archiveVideo: function (vaultId: number, playlistId: number, videoId: number): Promise<VideoApiModel> {
            return client.videosDELETE(vaultId, playlistId, videoId, false);
        }
    }

    return videoService;
}

export default createVideoService;
