import useSectionIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useSectionIdParam';
import useVaultIdParam from '@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import useSectionGroupsAsArray from '@app/Areas/Optimus/State/DataStores/ResourceStore/hooks/useSectionGroupsAsArray';
import useVaultSectionsAsArray from '@app/Areas/Optimus/State/DataStores/ResourceStore/hooks/useVaultSectionsAsArray';
import useVaultSectionsAsDictionary from '@app/Areas/Optimus/State/DataStores/ResourceStore/hooks/useVaultSectionsAsDictionary';
import { Grid, GridSize, makeStyles, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useHistory, generatePath, Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import GroupNavigator from '../../Resources/GroupNavigator';
import useGroupIdParam from '../../../State/App/NavigationContext/hooks/useGroupIdParam/useGroupIdParam';
import useSectionGroupsAsDictionary from '@app/Areas/Optimus/State/DataStores/ResourceStore/hooks/useSectionGroupsAsDictionary';
import useSectionGroupResourcesAsArray from '../../../State/DataStores/ResourceStore/hooks/useSectionGroupResourcesAsArray/useSectionGroupResourcesAsArray';
import ResourceCard from '../ResourceCard/ResourceCard';
import ResourceDetailView from '../../Resources/ResourceDetailView/ResourceDetailView';
import { IResourceApiModel } from '@app/Areas/Optimus/ApiClient/BookmarksClient';

const useStyles = makeStyles((theme) => ({
    resourceItems: {
        padding: '20px 0',
    },
    input: {
        marginBottom: 20
    }
}));

function ResourcesList({
    gridSpan,
    resources,
}: {
    gridSpan: GridSize;
    resources: IResourceApiModel[];
}) {
    const [resourceFilter, setResourceFilter] = useState('');

    const classes = useStyles();

    // TODO: Use css to drive this responsive behaviour
    const cardsPerRow = gridSpan > 6 ? 3 : 12;

    const filteredResources = resources.filter(r => {
        const term = resourceFilter.toLocaleLowerCase();
        return r.title && r.title.toLocaleLowerCase().includes(term)
        || r.description && r.description.toLocaleLowerCase().includes(term)
        || r.tags && r.tags?.some(t => t.toLocaleLowerCase().includes(term))
        || r.siteName && r.siteName.toLocaleLowerCase().includes(term)
    })

    return (
        <Grid xs={gridSpan} item container spacing={3} className={classes.resourceItems}>
            <TextField className={classes.input} fullWidth id="resource-filter-input" label="Filter" name="filter" value={resourceFilter} onChange={(event) => setResourceFilter(event.target.value)} />
            { filteredResources.map(r => (
                <Grid item xs={cardsPerRow}>
                    <ResourceCard
                        resourceId={r.id}
                        title={r.title}
                        description={r.description}
                        url={r.url}
                        thumbnail={r.thumbnail}
                        siteName={r.siteName}
                        resourceType={r.classification}
                        isRecommended={r.isRecommended}
                        isPublic={r.isPublic}
                    />
                </Grid>
            )) }
        </Grid>
    )
}

function SectionView() {
    const { path } = useRouteMatch();
    const history = useHistory();
    const vaultId = useVaultIdParam();
    const sections =
        useVaultSectionsAsArray()
        .sort((a, b) => {

            if (!a?.name || !b?.name) {
                return 0;
            }

            if(a.name < b.name) { return -1; }
            if(a.name > b.name) { return 1; }

            return 0;
        });
    const sectionDictionary = useVaultSectionsAsDictionary();
    const sectionId = useSectionIdParam();
    const groupId = useGroupIdParam();
    const groups = useSectionGroupsAsArray();
    const groupDictionary = useSectionGroupsAsDictionary();
    const resources = useSectionGroupResourcesAsArray();

    function selectSection(event: React.ChangeEvent<{ value: unknown }>) {
        const section = sectionDictionary[event.target.value as number];
        if (vaultId && section && section.id) {
            const newpath = generatePath('/optimus/vaults/:vaultId/deepthought/sections/:sectionId', {
                vaultId,
                sectionId: section.id
            })
            history.push(newpath)
        }
    }

    function selectGroup (event: React.ChangeEvent<{}>, value: any) {
        const group = groupDictionary[value as number];
        if (vaultId && sectionId && group.id) {
            const newpath = generatePath('/optimus/vaults/:vaultId/deepthought/sections/:sectionId/groups/:groupId', {
                vaultId,
                sectionId,
                groupId: group.id
            })
            history.push(newpath)
        }
    }

    return (
        <>
            <Grid container>
                <GroupNavigator
                    sections={sections}
                    selectSection={selectSection}
                    selectedSection={sectionId}
                    groups={groups}
                    selectGroup={selectGroup}
                    selectedGroup={groupId}
                />
            </Grid>
            <Switch>
                <Route path={`${path}/groups/:groupId`}>
                    <Grid container spacing={4}>
                        <Switch>
                            <Route path={`${path}/groups/:groupId/resources/:resourceId`}>
                                <ResourcesList resources={resources} gridSpan={4} />
                                <Grid xs={8} item container spacing={3} style={{padding: '30px'}}>
                                    <ResourceDetailView />
                                </Grid>
                            </Route>
                            <Route path={`${path}`}>
                                <ResourcesList resources={resources} gridSpan={12} />
                            </Route>
                        </Switch>
                    </Grid>
                </Route>
                <Route path={`${path}`}>
                    { groups && groups.length && <Redirect to={`/optimus/vaults/${vaultId}/deepthought/sections/${sectionId}/groups/${groups[0].id}`} /> }
                </Route>
            </Switch>
        </>
    )
}

export default SectionView;
