import useIsConsoleView from '../../State/App/NavigationContext/hooks/useIsConsoleView/index';
import OptimusAppBar from '@optimus/Ecosystems/OptimusAppBar';
import OptimusConsoleBar from '@optimus/Ecosystems/OptimusConsoleBar';

import { Console, Top, Center, Bottom } from './styles';
import SmartBar from "../SmartBar";
import SmartTabs from "../SmartTabs";
import Navigator from "../Navigator";

function Main () {
    const isConsoleView = useIsConsoleView();
    return (
        <Console isConsoleView={isConsoleView}>
            <Top>
                <OptimusAppBar />
                <SmartBar />
            </Top>
            <Center>
                <Navigator />
            </Center>            
             <Bottom>
                <SmartTabs />
                <OptimusConsoleBar />
            </Bottom>
        </Console>
    )
}

export default Main;
