import { useTaskBoardDispatchContext, useTaskBoardStateContext } from "@app/Areas/Optimus/Apps/TaskBoardApp";
import useVaultIdParam from "@app/Areas/Optimus/State/App/NavigationContext/hooks/useVaultIdParam";
import { useEffect } from "react";
import { BOARDS_CLEAR_TASKLIST_UNDER_TRANSFER } from "../../../state/TaskBoardReducer/TaskBoardReducer";
import useBoardIdParam from "../../_Boards/useBoardIdParam";

function useTaskListUnderTransfer() {
    const vaultId = useVaultIdParam();
    const boardId = useBoardIdParam();

    const boardStateContext = useTaskBoardStateContext();
    const taskListUnderTransfer = boardStateContext.taskLists.selectedToTransfer;
    const boardDispatch = useTaskBoardDispatchContext();

    useEffect(() => {
        return function cleanup() {
            boardDispatch({
                type: BOARDS_CLEAR_TASKLIST_UNDER_TRANSFER
            })
        }
    }, [vaultId])

    if (!taskListUnderTransfer) {
        return null;
    }

    const taskList = boardStateContext?.taskLists?.byId[taskListUnderTransfer];

    if (
        !taskList
        || taskList.parentVaultId !== vaultId
        || taskList.parentBoardId !== boardId
    ) {
        return null;
    }

    return taskList
}

export default useTaskListUnderTransfer;