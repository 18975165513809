import { useState, FormEvent, ChangeEvent } from "react";
import BoardTextControl from '../BoardTextControl/BoardTextControl';
import styled from 'styled-components';
import { DeepThoughtColor } from "@app/Areas/Optimus/Tokens/colors";
import { darken } from "polished";

const Button = styled.button`
  text-align: center;
  padding: 0.5em;
  color: white;
  border: 1px solid ${DeepThoughtColor};  
  width: 100%;
  text-align: center;

  &:hover {
    background-color: ${darken(0.3, DeepThoughtColor)};
  }
`;

export interface ToggleInputButtonProps {
  label: string;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  sendRequest: (final: () => void) => Promise<void>;
}

function ToggleInputButton({ label, value, setValue, sendRequest }: ToggleInputButtonProps) {

  const [textboxVisible, setTextboxVisible] = useState(false);
  
  const resetForm = () => {
    setValue("");
    setTextboxVisible(false);
  };

  const showTextbox = () => {
    setTextboxVisible(true);
  };

  const hideTextbox = () => {
    setTextboxVisible(false);
    resetForm();
  };

  const submitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!value.length) {
        return;
    }

    sendRequest(() => resetForm());
  };

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <>
      {textboxVisible ? (
        <BoardTextControl onSubmit={submitForm} onCancel={hideTextbox} setValue={onInputChange} value={value} />
      ) : (
        <Button onClick={showTextbox}>{ label }</Button>
      )}
    </>
  );
}

export default ToggleInputButton;