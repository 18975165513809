import { useCallback, useRef, useState } from 'react';
import ConfirmationDialog from '@app/Areas/Optimus/Components/Shared/ConfirmationDialog';
import { generatePath, useHistory } from 'react-router-dom';
import { ARCHIVE_BOARD_DIALOG_ID } from '@app/Areas/Optimus/Ecosystems/ActionDialogs';
import createBoardService from '../../../services/BoardsService';
import { BOARDS_REMOVE_BOARD_UNDER_ARCHIVE, BOARDS_CLEAR_BOARD_UNDER_ARCHIVE } from '../../../state/TaskBoardReducer/TaskBoardReducer';
import { useTaskBoardDispatchContext } from '../../../state/TaskBoardContextProvider/TaskBoardContextProvider';
import useBoardUnderArchive from '../../../hooks/_Boards/useBoardUnderArchive';

function ArchiveBoard() {
    const history = useHistory();
    const boardUnderArchive = useBoardUnderArchive();    
    const currentParentVaultId = boardUnderArchive?.parentVaultId;
    const boardDispatch = useTaskBoardDispatchContext();
    const [isSending, setIsSending] = useState(false);
    const boardService = createBoardService();
    const isMounted = useRef(true);

    const sendRequest = useCallback(async (callback) => {
        if (boardUnderArchive?.board.id) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await boardService.archiveBoard(
                boardUnderArchive?.parentVaultId,
                boardUnderArchive.board.id
            );
            if (result) {
                boardDispatch({
                    type: BOARDS_REMOVE_BOARD_UNDER_ARCHIVE,
                    boardId: boardUnderArchive.board.id
                })
                goToBoards();
                callback();
            }
            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [boardUnderArchive, isSending]
    ); // update the callback if the state changes

    const goToBoards = () => {
        if (currentParentVaultId) {
            const boardsPath = generatePath('/optimus/vaults/:vaultId/taskboards/boards', { vaultId: currentParentVaultId});
            history.push(boardsPath);
        }
    }

    const handleConfirm = (callback: () => void) => {
        if (!isSending) {
            sendRequest(callback);
        }
    }

    const cleanUp = () => {
        boardDispatch({
            type: BOARDS_CLEAR_BOARD_UNDER_ARCHIVE
        })
    }

    return boardUnderArchive && (
        <ConfirmationDialog
            title="Archive Board"
            description={`Are you sure you want to archive the board '${boardUnderArchive?.board.name}'`}
            dialogId={ARCHIVE_BOARD_DIALOG_ID}
            onConfirm={handleConfirm}
            onClose={cleanUp}
        />
    ) || null;
}

export default ArchiveBoard;
