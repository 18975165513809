import { useCallback, useRef, useState } from 'react';
import useCurrentVault from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useCurrentVault';

function useCreateVaultChildEntity<
    CreationRequest,
    ApiModel
>(
    create: (c: number, cr: CreationRequest) => Promise<ApiModel>,
    creationRequest: CreationRequest,
    onSuccess: (result: ApiModel) => void,
    onFinally: (vaultId: number) => void,
    presetVaultId: number | null = null,
) {
    const isMounted = useRef(true);
    const currentVault = useCurrentVault();
    const vaultId = presetVaultId !== null ? presetVaultId : currentVault?.id;

    const [isSending, setIsSending] = useState(false);

    const sendRequest = useCallback(async () => {
        if (vaultId && creationRequest) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // send the actual request
            var result = await create(vaultId, creationRequest);
            if (result) {
                onSuccess(result);
            }
            onFinally(vaultId);

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [ currentVault, creationRequest, isSending, ]
    ); // update the callback if the state changes

    return {
        isSending,
        sendRequest,
    }
}

export default useCreateVaultChildEntity;
