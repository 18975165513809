import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

export interface UrlInputProps {
    handleInput: (event: any) => void;
    onVerify: () => Promise<void>;
    currentUrl: string;
}

export const UrlInput = ({
        handleInput,
        currentUrl,
        onVerify,
    }: UrlInputProps) => {
    return (
        <>
            <Typography variant="h5" gutterBottom>Paste a Url to verify</Typography>
            <Grid container spacing={3}>
                <Grid item xs={10}>
                    <TextField fullWidth id="standard-basic" label="Lookup a Url" onChange={handleInput} value={currentUrl} />
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" color="primary" onClick={onVerify}>
                        Lookup URL
                    </Button>
                </Grid>
            </Grid>
        </>
    );
} 