import React from 'react';
import { Avatar, Card, createStyles, IconButton, makeStyles, SvgIconTypeMap, Theme } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { DeckIcon } from '@app/Areas/Optimus/Components/Shared/AppsIcons';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

const useClasses = makeStyles((theme: Theme) =>
    {
        return createStyles({
            root: {
              display: 'flex',
              flexDirection: 'column',
              backdropFilter: 'blur(10px)',
              boxShadow: `0 0px 10px #3cb8dc99`,
              border: `1px solid #3cb8dc99`,
            },
            content: {
                flexGrow: 1,
                display: 'grid',
                gridTemplateColumns: '1fr',
                gridTemplateRows: '50% 50%',
                paddingTop: 0,
                paddingBottom: 0
            },
            title: {
                cursor: 'pointer',
                fontSize: 14,
                textAlign: 'center',
                marginBottom: '10px'
            },
            iconWrapper: {
                cursor: 'pointer',
                backgroundColor: theme.palette.background.default,
                width: '3em',
                height: '3em',
                marginRight: 'auto',
                marginLeft: 'auto'
            },
            icon: {
                color: theme.palette.secondary.main,
                width: '1.3em',
                height: '1.3em'
            },
            actionIcon: {
                width: '0.6em',
                height: '0.6em'
            },
            pos: {
                marginBottom: 12,
            },
            center: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }
          })
    }
)

export interface DeckViewCardProps {
    title: string;
    description:  string;
    onSelect: () => void;
    actions: {
        Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
        action: () => void;
    }[]
}

function DeckViewCard({
    title,
    description,
    onSelect,
    actions
}: DeckViewCardProps) {
    const classes = useClasses();

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <div className={classes.center}>
                    <Avatar onClick={onSelect} className={classes.iconWrapper}>
                        <DeckIcon className={classes.icon} />
                    </Avatar>
                </div>
                <div>
                    <Typography
                        className={classes.title}
                        color="textPrimary"
                        onClick={onSelect}
                        gutterBottom
                    >
                        {title}
                    </Typography>
                    <Typography variant="caption">
                        {description}
                    </Typography>
                </div>
            </CardContent>
            <CardActions>
                { actions.map(a => (
                    <IconButton color="inherit" onClick={a.action}>
                        <a.Icon className={classes.actionIcon} />
                    </IconButton>
                ))}
            </CardActions>
        </Card>
    )
}

export default DeckViewCard;
