import { useCallback, useRef, useState } from 'react';
import EditorDialog from '@optimus/Components/Shared/EditorDialog';
import { NEW_DECK_DIALOG_ID } from '../../../ActionDialogs/index';
import { DeckEditorModel } from '../DeckEditor/DeckEditorModel';
import DeckEditor from '../DeckEditor';
import createDeckService from '../../../../Services/DeckService/DeckService';
import { getVaultDecks } from '../../../../State/DataStores/DeckStore/DeckReducer/DeckReducer';
import useCurrentVault from '@app/Areas/Optimus/State/DataStores/VaultStore/hooks/useCurrentVault';
import { useDeckDispatchContext } from '@app/Areas/Optimus/State/DataStores/DeckStore/DeckContextProvider';
import { IDeckCreationRequest, IDeckApiModel } from '@app/Areas/Optimus/ApiClient/DecksClient';

const blankDeck: IDeckCreationRequest = {
    title: '',
    description: '',
    tags: []
};

function mapCreationDetailsToProject(deck: IDeckCreationRequest): DeckEditorModel {
    return {
        name: deck.title,
        description: deck.description || '',
        tags: deck.tags
    }
}

function NewDeck() {
    const [deckCreationRequest, setDeckCreationRequest] = useState<IDeckCreationRequest>(blankDeck);
    const [createdDeck, setCreatedDeck] = useState<IDeckApiModel | null>(null);
    const [isSending, setIsSending] = useState(false)
    const isMounted = useRef(true)
    const deckService = createDeckService();
    const deckDispatch = useDeckDispatchContext();
    const currentVault = useCurrentVault();

    function deckEdited(event: any) {
        setDeckCreationRequest({
            ...deckCreationRequest,
            [event.target.name]: event.target.value
        })
    }

    const handleSave = () => {
        if (!createdDeck && !isSending) {
            sendRequest();
        }
    }

    const cleanUp = () => {
        setDeckCreationRequest(blankDeck);
        setCreatedDeck(null);
    }

    const sendRequest = useCallback(async () => {
        if (currentVault?.id) {
            // don't send again while we are sending
            if (isSending) return
            // update state
            setIsSending(true)
            // // send the actual request
            var result = await deckService.createDeck(currentVault.id, deckCreationRequest);
            if (result) {
                setCreatedDeck(result);
            }
            deckDispatch(getVaultDecks(currentVault.id));

            // once the request is sent, update state again
            if (isMounted.current) { // only update if we are still mounted
                setIsSending(false)
            }
        }
      }, [ currentVault, deckCreationRequest, isSending]
    ); // update the callback if the state changes

    return currentVault && (
        <EditorDialog
            title="New Deck"
            saveTitle="Save Deck"
            dialogId={NEW_DECK_DIALOG_ID}
            handleSave={handleSave}
            onClose={cleanUp}
        >
            { !createdDeck && <DeckEditor
                deck={mapCreationDetailsToProject(deckCreationRequest)}
                handleDeckChange={deckEdited}
                title={currentVault.title}
                isSaving={isSending}
            /> }
            { createdDeck && JSON.stringify(createdDeck) }
        </EditorDialog>
    ) || null;
}

export default NewDeck;
