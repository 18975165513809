import useVaultIdParam from '@optimus/State/App/NavigationContext/hooks/useVaultIdParam';
import useSectionIdParam from '../../../../App/NavigationContext/hooks/useSectionIdParam/useSectionIdParam';
import { useSectionStateContext } from '../../ResourceContextProvider';
import { IResourcesContextModel } from '../../ResourceReducer/ResourceReducer';

function useCurrentSection() {
    const vaultId = useVaultIdParam();
    const sectionId = useSectionIdParam();
    const { sections }: IResourcesContextModel = useSectionStateContext();

    if (!vaultId || !sectionId) {
        return null;
    }

    const section = sections.byId[sectionId];

    if (!section) {
        return null;
    }

    if (section.parentVaultId !== vaultId) {
        return null;
    }

    return section.section;
}

export default useCurrentSection;
